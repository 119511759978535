// src/pages/PricingPage.tsx

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import PricingOne from '../components/pricingOne'
import PricingMonthly from '../components/pricingMonthly'


const PricingPage: React.FC = () => {


  const [tabToDisplay, setTabToDisplay] = useState('monthly');



  return (
    <div className="min-h-screen text-center p-2 md:p-6">
      <Helmet>
        <title>Optima Inventory - Pricing</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <title>Optima Inventory</title>

        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />

        <meta property="og:title" content="Optima Inventory - Pricing" />
        <meta
          property="og:description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.optima-inventory.com" />
        <meta
          property="og:image"
          content="https://www.optima-inventory.com/logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Optima Inventory - Pricing" />
        <meta
          name="twitter:description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <meta
          name="twitter:image"
          content="https://www.optima-inventory.com/logo.png"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
      <div className="flex justify-center items-center space-x-2">
        {/*<div className="h-6 w-6 rounded-sm overflow-hidden space-y-px mb-10 mt-px">
          <div
            className={`${
              selectedOptions["Accounting integration"] && "bg-emerald-500"
            } h-1 w-8`}
          ></div>
          <div
            className={`${
              selectedOptions["Shipping, manufacturing, and ordering"] &&
              "bg-teal-500"
            } h-1 w-8`}
          ></div>
          <div
            className={`${
              selectedOptions["Update public-facing website"] && "bg-sky-500"
            } h-1 w-8`}
          ></div>
          <div
            className={`${
              selectedOptions["Rental Management"] && "bg-violet-500"
            } h-1 w-8`}
          ></div>
          <div
            className={`${
              selectedOptions["Forecasting and analytics"] && "bg-pink-500"
            } h-1 w-8`}
          ></div>
        </div>*/}
        <h1 className="text-4xl font-bold text-center mb-10">Pricing</h1>
      </div>
      <section className="">
    <div className="grid grid-cols-2 text-slate-900 text-center gap-1">
      <div className={`rounded-lg p-1 cursor-pointer ${tabToDisplay === "monthly" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('monthly')}>Monthly</div>

      <div className={`rounded-lg p-1 cursor-pointer ${tabToDisplay === "one" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('one')}>One-time</div>
    </div>
    {tabToDisplay === 'monthly' && (

      <PricingMonthly />
      
    )}
    {tabToDisplay === 'one' && (
      <PricingOne />
    )}
    

    </section>
    </div>
  );
};

export default PricingPage;
