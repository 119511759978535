// src/components/ContactForm.tsx

import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import CalendlyWidget from './calendlyWidget';

const ContactForm: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    // Initialize EmailJS with your user ID (public key)
    emailjs.init("uzd_-uTNyGMC9EakZ");
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const serviceID = "service_avvjmbh"; // replace with your EmailJS service ID
    const templateID = "template_odtkctq"; // replace with your EmailJS template ID
    //console.log(e.currentTarget);

    var templateParams = {
      name: name,
      email: email,
      url: url
    };


    emailjs.send(serviceID, templateID, templateParams )
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setEmailSent(true); // Set the emailSent state to true when email is successfully sent
      })
      .catch((error) => {
        console.error('Failed to send email. Error:', error);
        alert("There was an error sending the email.")
      });

   setEmail('');
   setName('');
   setUrl('');
  };

  return (
    <div>
      {!emailSent ? (
        <form id="contact-form" onSubmit={handleSubmit} className="">
          <div>

            <input
              type="text"
              placeholder="Name"
              value={name}
              required
              onChange={(e)=>setName(e.target.value)}
              className="px-1 py-1 m-1 w-full flex-grow rounded-md border-px focus:outline-none focus:ring-0 ring-slate-400 bg-white text-center placeholder-slate-700"
            />
          </div>
          <div>
   
            <input
              type="email"
              placeholder="Email"
              required
              onChange={(e)=>setEmail(e.target.value)}
              className="px-1 py-1 m-1 w-full rounded-md border-px focus:outline-none focus:ring-0 bg-white text-center placeholder-slate-700"
            />
          </div>
          <div>
   
   <input
     type="text"
     placeholder="Website"
     onChange={(e)=>setUrl(e.target.value)}
     value={url}
     className="px-1 py-1 m-1 w-full rounded-md border-px focus:outline-none focus:ring-0 bg-white text-center placeholder-slate-700"
   />
 </div>
          <button className="text-white px-2 py-1 rounded-md bg-black w-full m-1" type="submit">Schedule a meeting</button>
        </form>
      ) : (
        <div>
        <p className="my-5">Thanks for reaching out. We'll respond to your email within one business day or, if you'd like, schedule a meeting below.</p>
        <div className="rounded-lg overflow-hidden">
        <CalendlyWidget />
        </div>
        </div>
      )}
      
    </div>
  );
};

export default ContactForm;