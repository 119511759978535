import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TooltipItem } from 'chart.js';

  ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Define an interface for the chart data
interface ChartData {
  labels: any[];
 datasets: any[];
 
}

interface BarChartProps {
  chartData: ChartData;
}

const ChartCategories: React.FC<BarChartProps> = ({ chartData }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 4,
        scales: {
          y: {
            stacked: true,
            ticks: {
              callback: (tickValue: string | number, index: number, ticks: any[]) => {
                if (typeof tickValue === 'number') {
                  return `$${tickValue.toLocaleString()}`;
                }
                return tickValue;
              }
            }
          },
          x: {
            stacked: true
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                const value = typeof tooltipItem.raw === 'number' ? tooltipItem.raw : 0;
                return `${tooltipItem.dataset.label}: $${value.toLocaleString()}`;
              }
            }
          },
          legend: {
            position: 'bottom' as const,
            labels: {
              useBorderRadius: true,
              borderRadius: 5
            }
          },
          title: {
            display: false,
            text: 'Money Generated by Category Each Month',
          },
          
        },
      };
      
      const data = {
        labels: chartData.labels,
        datasets: chartData.datasets.map(dataset => ({
          ...dataset,
        borderRadius: 5,
        borderColor: 'white',
        borderWidth: 1
        }))
      };



  return <Bar options={options} data={data} />;
};

export default ChartCategories;