import React, { useRef, useState } from 'react';

interface ImageUploadInventoryProps {
    partNumber: string;
    setUnsavedChanges: (changed: boolean) => void;
    setCurrentImageUrl: (imageUrl: string) => void;
    handleSave: () => void;
}

const ImageUploadInventory: React.FC<ImageUploadInventoryProps> = ({
    partNumber,
    setCurrentImageUrl,
    setUnsavedChanges,
    handleSave
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Update Image');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFileBase64, setSelectedFileBase64] = useState<string | null>(null);

    const uploadFile = async () => {
        setIsUploading(true);
    
        if (selectedFileBase64) {
            // Use the entire base64 string including the data URL part
            setCurrentImageUrl(selectedFileBase64);
            setUnsavedChanges(true);
            setButtonLabel('Update Image')
        }
    
        setIsUploading(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event.target.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSelectedFileBase64(reader.result as string);
                    setButtonLabel('Upload');
                    const imagesContainer = document.getElementById(`images-${partNumber}`);
                    if (imagesContainer) {
                        let imgTag = imagesContainer.querySelector('img');
                        if (!imgTag) {
                            imgTag = document.createElement('img');
                            imagesContainer.appendChild(imgTag);
                        }
                        imgTag.src = reader.result as string;
                    }
                };
                reader.readAsDataURL(file);  // This line was missing
            }
        } catch (error) {
            console.error('Error during image compression', error);
        }
    };

    const handleButtonClick = () => {
        if (buttonLabel === 'Upload') {
            uploadFile();
        } else {
            fileInputRef.current?.click();
            setUnsavedChanges(true); // Assuming intention to change is an unsaved change
        }
    };

    const reTake = () => {
        fileInputRef.current?.click();
        setUnsavedChanges(true); // Assuming intention to change is an unsaved change
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                disabled={isUploading}
            />
            {buttonLabel === 'Upload' && (
                <button
                    onClick={reTake}
                    disabled={isUploading}
                    className="text-blue-600 bg-blue-100 w-full py-1 px-2"
                >
                    Retake
                </button>
            )}
            <button
                onClick={handleButtonClick}
                disabled={isUploading}
                className={`${buttonLabel === 'Upload' ? 'bg-blue-600 text-white' : 'text-blue-600 bg-blue-100'} ${buttonLabel !== 'Replace Image' ? 'rounded-bl-lg' : ''} w-full py-1 px-2`}
            >
                {isUploading ? 'Uploading...' : buttonLabel}
            </button>
            {buttonLabel === 'Replace Image' && (
                <button
                    onClick={handleSave}
                    className="bg-blue-600 text-white rounded-bl-lg w-full py-1 px-2"
                >
                    Save
                </button>
            )}
        </div>
    );
};

export default ImageUploadInventory;