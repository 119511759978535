'use client'

import React, { useEffect, useState } from 'react'
import ToOrderCardEditable from './ToOrderCardEditable'



interface Vendor {
  vName: string
  vUrl: string
  vPartNumber: string
  vLeadTime: number
  vCost: number
  vPaymentMethod: string
}

interface toOrderItem {
  vendors: Vendor[]
  quantity: number
  editor: string
  status: string
  imageUrl: string
  partNumber: string
  internalDescription: string
  dateEdited: string
  partLocation: string
  dateCreated: string
  dateOrdered: string
  manufacturer: string
  manufactPartNum: string
  PoNumber: string
  paymentMethod: string
  cost: number
  vendorUsed: string
  leadTime: number
  dateNeeded: string
  origin: string
  id: number
}

const ToOrder = () => {
  const [toOrderItems, setToOrderItems] = useState<toOrderItem[]>([]);
  const [showAllOrders, setShowAllOrders] = useState(false);
  const [waitingForCards, setWaitingForCards] = useState(true);

const allOrderItems:toOrderItem[] =   [
  {
        "partNumber": "33487",
        "status": "ordered",
        "imageUrl": "https://boretec.com/images/CS602-2024-06-19T11:38:07-h-200.png",
        "quantity": 6,
        "dateCreated": "2024-05-03T09:00:45.092Z",
    
        "dateOrdered": "2024-06-19T14:52:05.000Z",
        "editor": "chad",
        "origin": "Par Level",
        "vendors": [
            {
                "vName": "Pressure Washers Direct",
               
             
                "vUrl": "https://www.pressurewashersdirect.com/General-Pump-DHRA50450/p71304.html",
                "vPartNumber": "DHRA50450",
                "vLeadTime": 10,
                "vCost": 273.99,
                "vPaymentMethod": ""
            }
        ],
        "partLocation": "",
        "cost": 273.99,
        "internalDescription": "CONTROL STATION HOSE REEL (UNMODIFIED- PAINTED ONLY) WITHOUT HYD. HOSE",
     
        "dateEdited": "Wed Jun 19 2024 10:52:05 GMT-0400 (Eastern Daylight Time)",
        "manufactPartNum": "",
        "manufacturer": "",
        "paymentMethod": "CC-9804",
        "vendorUsed": "Pressure Washers Direct",
        "PoNumber": '',
        "leadTime": 100,
   
        "dateNeeded": "2024-05-24 09:00:45",
        "id": 133
    },
    {
        "partNumber": "77603",
        "status": "placeOrder",
        "imageUrl": "https://boretec.com/images/image-coming-soon.png",
        "quantity": 1,
        "dateCreated": "2024-05-10T09:00:44.918Z",

        "dateOrdered": '',
        "editor": "chad",
        "origin": "Par Level",
        "vendors": [
            {
                "vName": "Logan Clutch Corporation",
       
    
                "vUrl": "sales@loganclutch.com",
                "vPartNumber": "P211-Z008",
                "vLeadTime": 10,
                "vCost": 1,
                "vPaymentMethod": ""
            }
        ],
        "partLocation": "",
        "cost": 2405.6,
        "internalDescription": "Hyd clutch for 3642D boring machine P211-Z008",

        "dateEdited": "Thu May 16 2024 07:26:55 GMT-0400 (Eastern Daylight Time)",
        "manufactPartNum": "P211-Z008",
        "manufacturer": "Logan Clutch",
        "paymentMethod": '',
        "vendorUsed": '',
        "PoNumber": '',
        "leadTime": 0,

        "dateNeeded": "2024-06-21 09:00:44",
        "id": 145
    },
    {
        "partNumber": "7765",
        "status": "ordered",
        "imageUrl": "https://boretec.com/images/E0010-2024-05-16T19:20:15-h-200.png",
        "quantity": 10,
        "dateCreated": "2024-05-16T19:21:35.836Z",

        "dateOrdered": "2024-05-16T19:43:39.000Z",
        "editor": "chad",
        "origin": "chad",
        "vendors": [
            {
                "vName": "Amazon",
           
      
                "vUrl": "https://www.amazon.com/dp/B08R3F75MB?smid=A38OW55VZMFA6S&ref_=chk_typ_imgToDp&th=1",
                "vPartNumber": "N/A",
                "vLeadTime": 5,
                "vCost": 5,
                "vPaymentMethod": ""
            }
        ],
        "partLocation": "",
        "cost": 5,
        "internalDescription": "24VDC FLOOD LIGHT FOR CONTROL STATION",

        "dateEdited": "Thu May 16 2024 15:43:39 GMT-0400 (Eastern Daylight Time)",
        "manufactPartNum": "N/A",
        "manufacturer": "SAN YOUNG",
        "paymentMethod": "CC-Amex",
        "vendorUsed": "Amazon",
        "PoNumber": '',
        "leadTime": 5,
  
        "dateNeeded": "2024-05-16T19:21:20.222Z",
        "id": 155
    }

];

  const fetchToOrderItems = async () => {

      setToOrderItems(allOrderItems); // Assuming the payload contains the array of items
      setWaitingForCards(false);
  };



  useEffect(() => {
    fetchToOrderItems();
  }, []);

    

  return (
    <section className="my-5">

      

      {!waitingForCards && toOrderItems.length === 0 && (<p className="w-full p-2 font-light">No pending Orders.</p>)}
      {waitingForCards && (<div className="w-full py-10 flex justify-center items-center font-light">
        <p className="animate-pulse">Loading...</p>
      </div>)}
      <div className="flex flex-wrap">
      {
          toOrderItems.map((item) => (
            <div key={item.dateCreated} className={`px-1 mb-4 w-full md:w-1/2 lg:w-1/3`}>
            <ToOrderCardEditable  
              status={item.status}
              PoNumber={item.PoNumber}
              manufacturer={item.manufacturer}
              manufactPartNum={item.manufactPartNum}
              quantity={item.quantity}
              partNumber={item.partNumber}
              imageUrl={item.imageUrl}
              vendorUsed={item.vendorUsed}
              dateCreated={item.dateCreated}
              dateNeeded={item.dateNeeded}
              leadTime={item.leadTime}
              paymentMethod={item.paymentMethod}
              internalDescription={item.internalDescription}
              vendors={JSON.stringify(item.vendors)}
              partLocation={item.partLocation}
              cost={item.cost}
              dateEdited={item.dateEdited}
              dateOrdered={item.dateOrdered}
              origin={item.origin}
              id={item.id}
            />
            </div>
          ))
        }
      </div>
  
      
    </section>
  )
}

export default ToOrder;
