"use client";

import React, { useEffect, useState } from "react";

import ToBuildCardEditable from "./ToBuildCardEditable";
import { InventoryItem } from "./inventorySearchBreakdown";
import { VendorItem } from "./vendorSearch";

interface ToBuildItem {
  partNumber: string;
  quantity: number;
  docFileName: string;
  status: string;
  images: string;
  location: string;
  dueDate: string;
  dateCreated: string;
  partsUrl: string;
  assignedTo: string;
  internalDescription: string;
  origin: string;
  missing: string;
  items: InventoryItem[];
  itemsAddedToLists: string;
  vendor: VendorItem[];
  externalVendor: boolean;
  buildNumber: number;
}

const ToBuild = () => {
  const [toBuildItems, setToBuildItems] = useState<ToBuildItem[]>([]);
  const [waitingForCards, setWaitingForCards] = useState(true);
  const [showAllOrders, setShowAllOrders] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchDescription, setSearchDescription] = useState("");

  const [allItems, setAllItems] = useState<InventoryItem[]>([]);

  useEffect(() => {
    fetchToBuildItems();
    inventoryItems();
  }, []);

  const allBuildItems: ToBuildItem[] = [
    {
      partNumber: "18-4836XTRMx4",
      origin: "Chad",
      images: "",
      assignedTo: "",
      docFileName: "",
      partsUrl: "",
      status: "assign",
      dateCreated: "2024-05-23T11:26:45.061Z",

      dueDate: "2024-05-23",
      quantity: 1,
      internalDescription:
        '48" xtrm OTS cuttting head on 4" hex w/King Kong teeth',
      externalVendor: false,
      location: "",
      missing: "",
      items: [],
      itemsAddedToLists: "",
      vendor: [],
      buildNumber: 82,
    },
    {
      partNumber: "19-2420ROLLX4W\\C",
      origin: "Par Level",
      images: "https://boretec.com/images/image-coming-soon.png",
      assignedTo: "Blake",
      externalVendor: false,
      docFileName: "",
      partsUrl: "",
      status: "progress",
      dateCreated: "2024-05-23T19:07:11.751Z",

      dueDate: "2024-07-05T00:00:00.000Z",

      quantity: 1,

      internalDescription:
        'RUSH 24" XTRM ROLLER CUTTER WITH WING CUTTERS ON 4" HEX. CUTS 27".',

      location: "",
      missing: "",
      items: [],
      itemsAddedToLists: "[]",
      vendor: [],
      buildNumber: 83,
    },
    {
      partNumber: "16-18COMBOx3",
      origin: "Par Level",
      images: "https://boretec.com/images/image-coming-soon.png",
      assignedTo: "",
      docFileName: "",
      partsUrl: "",
      externalVendor: false,
      status: "assign",
      dateCreated: "2024-05-31T16:32:39.827Z",

      dueDate: "2024-06-02",

      quantity: 1,

      internalDescription:
        '18" combo cutting head with wing cutters on 3" hex with carbide teeth',

      location: "",
      missing: "",
      items: [
        {
          partNumber: "15-S600801",
          name: "NON ROTATING FLAT CARBIDE TEETH FOR COMBO CUTTING HEADS AND STD OTS CUTTING HEADS",
          quantity: 467,

          cost: 11.3,

          location: "18-A-4",

          type: "buy",
          description:
            "AR150-87 FLAT NON ROTATING TEETH FOR COMBO AND STD OTS CUTTING HEADS. COMMONLY CALLED 150 TEETH. COST INCLUDES VENDORS FREIGHT COST",
          docFileName: "",
          images:
            "https://boretec.com/images/15-S600801-2024-02-27T18:38:51-h-200.png",

          manufacturer: "BEIJING ZIPEI TECHNOLIGY CO., LTD",
          manufactPartNum: "AR150-87",
          vendors: [],

          items: [],

          isVisible: true,
          quantityNeeded: 10,
        },
      ],
      itemsAddedToLists: "",
      vendor: [],
      buildNumber: 89,
    },
  ];

  const fetchToBuildItems = async () => {
    setToBuildItems(allBuildItems);
    setWaitingForCards(false);
  };

  const filteredItems = toBuildItems.filter((item) =>
    (
      item.assignedTo?.toLowerCase() +
      item.partNumber?.toLowerCase() +
      item.internalDescription?.toLowerCase()
    ).includes(searchTerm.toLowerCase())
  );

  const inventoryItems = async () => {
    //setAllItems(payloadObject) // Assuming the payload contains the array of items
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };
  // Determine the count of visible items
  const visibleItemCount = filteredItems.length;

  return (
    <section className="mt-5">



      {!waitingForCards && toBuildItems.length === 0 && (
        <div className="h-48 flex justify-center items-center">
          <p className="w-full p-2 text-center font-light">No Builds.</p>
        </div>
      )}
      {waitingForCards && (
        <div className="w-full py-10 flex justify-center items-center font-light">
          <p className="animate-pulse">Loading...</p>
        </div>
      )}
      <div className="flex flex-wrap">
        {filteredItems.map((item) => (
          <div
            key={item.dateCreated}
            className={`px-1 mb-4 w-full md:w-1/2 lg:w-1/3`}
          >
            <ToBuildCardEditable {...item} allItems={allItems} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ToBuild;
