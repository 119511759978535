'use client'

import React, { useEffect, useState } from 'react'
import ToShipCardEditable from './toShipCardEditable'
import { ToShipCardEditableProps } from './toShipCardEditable'

const ToShip = () => {
  const [toShipItemsShow, setToShipItemsShow] = useState<
    ToShipCardEditableProps[]
  >([])
  const [toShipItemsTable, setToShipItemsTable] = useState<
    ToShipCardEditableProps[]
  >([])
  const [allItems, setAllItems] = useState<
  ToShipCardEditableProps[]
>([])

  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [sortedBy, setSortedBy] = useState<string>('')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    fetchToShipItems()
    // Function to detect if the device is mobile
    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    }
    // Set isOpen to true if isMobile returns true
    setIsMobile(isMobile())
  }, [])

  const allShipItems: ToShipCardEditableProps[] = [
    {
      serialNumbers: '',
      status: 'Sent',
      shippedDate: '',
      invoiceNumber: '123558',
      NumberOfPieces: 0,
      totalWeight: 0,
      dimensions: '',
      trackingNumber: '["BOL"]',
      shipAddressLine1: '14 Sylvania Rd',
      shipAddressLine2: '',
      shipAddressLine3: '',
      shipAddressLine4: '',
      shipMethod: 'freight',
      companyName: 'Plumbing',
      itemsDescription: '18" auger 10\' long on 3" hex with 3/8" flights',
      customerEmail: 'example@example.com',
      customerPhone: '(555) 555-5555',
      itemsQuantity: '4',
      InvoiceDueDate: '2024-06-08',
      billEmail: 'example@example.com',
      dateAdded: '2024-06-03 13:57:35',
      images: '[]',
      shipAddressCity: 'Millen',
      shipAddressState: 'Goergia',
      shipAddressPostalCode: '30442',
      itemsDetailType: '80-1810X3',
      shipDate: '2024-06-24',
      checked: true,
      missing: "80-1810X3",
      tracking: '',
      shippingWeight: '',
      invoiceItems: '[{"quantity":4,"partNumber":"80-1810X3","description":"18\\" auger 10\' long on 3\\" hex with 3/8\\" flights","unitPrice":2872,"lineAmount":11488}]'
    },
    {
      serialNumbers: '[]',
      status: 'Paid',
      shippedDate: '',
      invoiceNumber: '123544',
      NumberOfPieces: 1,
      tracking: '',
      shippingWeight: '',
      totalWeight: 0,
      dimensions: '42x42x46',
      trackingNumber: '["BOL"]',
      shipAddressLine1: 'Hwy 8',
      shipAddressLine2: '',
      shipAddressLine3: '',
      shipAddressLine4: '',
      shipMethod: 'other',
      companyName: 'Company LLC',
      itemsDescription: 'PIGGY-BACK FOR CONTROL STATION, one reel 400\' 1/2 hose, one reel 400\' 3/8" hose,RUSH 24" XTRM ROLLER',
      customerEmail: 'example@example.com',
      customerPhone: '555-555-5555',
      itemsQuantity: '1,1',
      InvoiceDueDate: '2024-05-23',
      billEmail: 'example@example.com',
      dateAdded: '2024-05-23 19:05:57',
      images: '["https://boretec.com/images/undefined-2024-05-30T10:17:30-h-200.png"]',
      shipAddressCity: 'Dawsonville',
      shipAddressState: 'GA',
      shipAddressPostalCode: '30534',
      itemsDetailType: '5-CS600PB,19-2420ROLLX4W\\C',
      shipDate: '2024-05-23',
      checked: true,
      missing: '',
      invoiceItems: '[{"quantity":1,"partNumber":"5-CS600PB","description":"PIGGY-BACK FOR CONTROL STATION, one reel 400\' 1/2 hose, one reel 400\' 3/8\\" hose","unitPrice":4500,"lineAmount":4500},{"quantity":1,"partNumber":"19-2420ROLLX4W\\\\C","description":"RUSH 24\\" XTRM ROLLER CUTTER WITH WING CUTTERS ON 4\\" HEX. CUTS 27\\".","unitPrice":24900,"lineAmount":24900}]'
    }
  ];

  const fetchToShipItems = async () => {


      setToShipItemsShow(allShipItems)


  }



  

  return (
    <section className="mt-5">
      <div className={`flex justify-between`}>
      
        
      </div>

      <div id="toShipCards" className="my-1 md:px-1">
        {toShipItemsShow.length > 0 ? (
          toShipItemsShow.map((item, index) => (
            <div key={index} className="mb-2">
              <ToShipCardEditable
                serialNumbers={item.serialNumbers}
                status={item.status}
                shippedDate={item.shippedDate}
                invoiceNumber={item.invoiceNumber}
                NumberOfPieces={item.NumberOfPieces}
                totalWeight={item.totalWeight}
                dimensions={item.dimensions}
                trackingNumber={item.trackingNumber}
                shipAddressLine1={item.shipAddressLine1}
                shipAddressLine2={item.shipAddressLine2}
                shipAddressLine3={item.shipAddressLine3}
                shipAddressLine4={item.shipAddressLine4}
                shipMethod={item.shipMethod}
                companyName={item.companyName}
                itemsDescription={item.itemsDescription}
                customerEmail={item.customerEmail}
                customerPhone={item.customerPhone}
                itemsQuantity={item.itemsQuantity}
                InvoiceDueDate={item.InvoiceDueDate}
                billEmail={item.billEmail}
                dateAdded={item.dateAdded}
                tracking={item.tracking}
                shippingWeight={item.shippingWeight}
                images={item.images}
                shipAddressCity={item.shipAddressCity}
                shipAddressState={item.shipAddressState}
                shipAddressPostalCode={item.shipAddressPostalCode}
                itemsDetailType={item.itemsDetailType}
                shipDate={item.shipDate}
                checked = {item.checked}
                missing={item.missing}
                invoiceItems={item.invoiceItems}
              />
            </div>
          ))
        ) : (
          <div className="p-2 text-sm font-thin">
            Everything&apos;s shipped.
          </div>
        )}
      </div>
    </section>
  )
}

export default ToShip
