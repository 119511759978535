'use client'

import React, { useEffect, useState } from 'react'

import Invoices from './invoices'
import Customers from './customers'
import Estimates from './estimates'

// Define a type for individual tasks
type Task = {
  id: number;
  name: string;
  checked: boolean;
};

const SalesTabs = () => {
  const [tabToDisplay, setTabToDisplay] = useState("invoices")
  const [outstandingEstimates, setOutstandingEstimates] = useState(0);

  //accept search from URL
  useEffect(() => {
    const fullUrl = window.location.href;
  
    if (fullUrl.includes('?')){
      const tabToDisplay = fullUrl.split('?')[1];
      setTabToDisplay(tabToDisplay)

    }
  },[]);
 const fetchOustandingestimates = async () => {
   

/*
      if (Array.isArray(payloadObject) && payloadObject.length > 0) {
        setOutstandingEstimates(payloadObject.length);
        setTabToDisplay('estimates');
        }*/

  

  };

  useEffect(() => {
    fetchOustandingestimates();
  }, []); 

  

      // Render function for alert bubble
      const renderAlertBubble = () => (
        <span className="top-0 right-0 absolute -mt-2 -mr-2 bg-pink-600 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center animate-pulse">
          {outstandingEstimates}
        </span>
      );
/*

  useEffect(() => {
    fetchToBuildItems();
  }, []);



  const fetchToBuildItems = async () => {
    const params: InvokeCommandInput = {
      FunctionName: 'boretec_toShip_select_all',
      Payload: JSON.stringify({}),
    };

    try {
      const command = new InvokeCommand(params);
      const response = await lambdaClient.send(command);
      const payloadString = new TextDecoder().decode(response.Payload);
      const payloadObject = JSON.parse(payloadString);
      setToShipItems(payloadObject); // Assuming the payload contains the array of items
    } catch (error) {
      console.error('Error fetching inventory items', error);
    }
  };



      */
    
//customer card


  return (
    <section className="">
    <div className="grid grid-cols-3 text-slate-900 text-center gap-1">
      <div className={`rounded-lg p-1 cursor-pointer ${tabToDisplay === "invoices" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('invoices')}>Invoices</div>
      <div className={`rounded-lg p-1 cursor-pointer relative ${tabToDisplay === "estimates" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('estimates')}>{outstandingEstimates === 0 && "Estimates"}
      {outstandingEstimates > 0 && <div className="flex justify-center items-center"><p className="flex">Estimates<span className="bg-pink-600 text-white text-xs rounded-full h-4 w-4 -ml-1 -mt-1 flex items-center justify-center">{outstandingEstimates}</span></p></div>}
      </div>
      <div className={`rounded-lg p-1 cursor-pointer ${tabToDisplay === "customers" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('customers')}>Customers</div>
    </div>
    {tabToDisplay === 'invoices' && (

      <Invoices />
      
    )}
    {tabToDisplay === 'estimates' && (
      <Estimates />
    )}
    
    
    {tabToDisplay==="customers" && (
  
  <Customers />
)}
    </section>
  )}

export default SalesTabs;
