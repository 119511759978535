// src/components/Navbar.tsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  //style={{ mixBlendMode: 'difference' }}
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="sticky top-0 opacity-95 z-50">
      <div className="">
        <div className="grid grid-cols-2 md:grid-cols-3 items-center items-center">
          <Link to="/">
            {/*<img src="https://s3.amazonaws.com/optima-holdings.com/inventory.png" alt="Optima Inventory Logo" className="px-1 h-8 w-auto invert" />*/}
            <div className="flex justify-start items-center space-x-2">
              <div className="h-6 w-6 ml-1 rounded-sm overflow-hidden space-y-px">
                <div className="h-1 w-8 bg-emerald-500"></div>
                <div className="h-1 w-8 bg-teal-500"></div>
                <div className="h-1 w-8 bg-sky-500"></div>
                <div className="h-1 w-8 bg-violet-500"></div>
                <div className="h-1 w-8 bg-pink-500"></div>
              </div>
              <h1
                className={`${
                  isHidden || isOpen
                    ? "hidden"
                    : "md:text-xl text-md font-semibold text-center"
                }`}
              >
                Optima Inventory
              </h1>
            </div>
          </Link>
          <div className="hidden md:flex items-center justify-center space-x-4 px-4 p-1 mt-1 rounded-md md:bg-slate-100">
            <div className="space-x-5">
              <Link to="/pricing" className="text-slate-800 hover:text-black">
                Pricing
              </Link>
              <Link to="/setup" className="text-slate-800 hover:text-black">
                Setup
              </Link>
              <Link to="/contact" className="text-slate-800 hover:text-black">
                Contact
              </Link>
              <Link to="/about" className="text-slate-8000 hover:text-black">
                About
              </Link>
            </div>
          </div>
          <div className="hidden md:flex justify-end items-center space-x-2 p-2">
            <a href="sms:+19733817333">
              <svg
                className="h-4 pb-px"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
              >
                <path d="M 25 4 C 12.316406 4 2 12.972656 2 24 C 2 30.1875 5.335938 36.066406 10.949219 39.839844 C 10.816406 40.890625 10.285156 43.441406 8.183594 46.425781 L 7.078125 47.992188 L 9.054688 48 C 14.484375 48 18.15625 44.671875 19.363281 43.394531 C 21.195313 43.796875 23.089844 44 25 44 C 37.683594 44 48 35.027344 48 24 C 48 12.972656 37.683594 4 25 4 Z"></path>
              </svg>
            </a>
            <a href="tel:+19733817333">
              <svg className="h-3" viewBox="0 0 256 256">
                <defs></defs>
                <g
                  style={{
                    stroke: "none",
                    strokeWidth: 0,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "none",
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 83.576 87.253 C 56.503 104.918 -15.416 32.615 3.794 6.316 c 0.902 -1.235 2.258 -2.065 3.736 -2.454 l 13.988 -3.681 c 2.512 -0.661 5.144 0.53 6.306 2.853 l 7.475 14.949 c 0.798 1.595 0.77 3.479 -0.073 5.051 l -5.855 10.907 c -1.145 2.133 -0.757 4.765 0.955 6.477 l 19.56 19.56 c 1.671 1.671 4.223 2.085 6.336 1.028 l 11.313 -5.657 c 1.477 -0.738 3.208 -0.773 4.712 -0.094 l 13.946 6.294 c 2.295 1.036 3.591 3.497 3.144 5.974 c -1.016 5.635 -2.104 11.666 -2.725 15.112 C 86.271 84.525 85.183 86.205 83.576 87.253 z"
                    style={{
                      stroke: "none",
                      strokeWidth: 1,
                      strokeDasharray: "none",
                      strokeLinecap: "round",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "#0f172a",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                  />
                </g>
              </svg>
            </a>

            <a href="mailto: support@optima-holdings.com">
              <svg className="h-4 pb-px" viewBox="0 0 256 256">
                <defs></defs>
                <g
                  style={{
                    stroke: "none",
                    strokeWidth: 0,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "none",
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 45 42.768 l 45 -20.785 v -3.587 c 0 -3.186 -2.582 -5.768 -5.768 -5.768 H 5.768 C 2.582 12.627 0 15.209 0 18.395 v 3.587 L 45 42.768 z"
                    style={{
                      stroke: "none",
                      strokeWidth: 1,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "#0f172a",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                  />
                  <path
                    d="M 45 52.626 L 0 31.84 v 39.765 c 0 3.186 2.582 5.768 5.768 5.768 h 78.464 c 3.186 0 5.768 -2.582 5.768 -5.768 V 31.84 L 45 52.626 z"
                    style={{
                      stroke: "none",
                      strokeWidth: 1,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "#0f172a",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                  />
                </g>
              </svg>
            </a>
          </div>
          <div className="md:hidden flex justify-end px-1 py-1">
            <button
              onClick={toggleMenu}
              className="text-slate-900 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden -mt-8 z-60 rounded-md bg-slate-100">
          <div className="px-2 py-2 flex justify-center space-x-3 items-center">
            <Link
              to="/pricing"
              className="block text-slate-900 hover:text-black"
            >
              Pricing
            </Link>
            <Link to="/setup" className="block text-slate-900 hover:text-black">
              Setup
            </Link>
            <Link
              to="/contact"
              className="block text-slate-900 hover:text-black"
            >
              Contact
            </Link>
            <Link to="/about" className="block text-slate-900 hover:text-black">
              About
            </Link>
          </div>
          <div className="px-2 pb-2 flex justify-center space-x-3 items-center rounded-md bg-slate-100">
            <a href="sms:+19733817333">
              <svg
                className="h-4 opacity-70"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
              >
                <path d="M 25 4 C 12.316406 4 2 12.972656 2 24 C 2 30.1875 5.335938 36.066406 10.949219 39.839844 C 10.816406 40.890625 10.285156 43.441406 8.183594 46.425781 L 7.078125 47.992188 L 9.054688 48 C 14.484375 48 18.15625 44.671875 19.363281 43.394531 C 21.195313 43.796875 23.089844 44 25 44 C 37.683594 44 48 35.027344 48 24 C 48 12.972656 37.683594 4 25 4 Z"></path>
              </svg>
            </a>
            <a href="tel:+19733817333">
              <svg className="h-3 opacity-70" viewBox="0 0 256 256">
                <defs></defs>
                <g
                  style={{
                    stroke: "none",
                    strokeWidth: 0,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "none",
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 83.576 87.253 C 56.503 104.918 -15.416 32.615 3.794 6.316 c 0.902 -1.235 2.258 -2.065 3.736 -2.454 l 13.988 -3.681 c 2.512 -0.661 5.144 0.53 6.306 2.853 l 7.475 14.949 c 0.798 1.595 0.77 3.479 -0.073 5.051 l -5.855 10.907 c -1.145 2.133 -0.757 4.765 0.955 6.477 l 19.56 19.56 c 1.671 1.671 4.223 2.085 6.336 1.028 l 11.313 -5.657 c 1.477 -0.738 3.208 -0.773 4.712 -0.094 l 13.946 6.294 c 2.295 1.036 3.591 3.497 3.144 5.974 c -1.016 5.635 -2.104 11.666 -2.725 15.112 C 86.271 84.525 85.183 86.205 83.576 87.253 z"
                    style={{
                      stroke: "none",
                      strokeWidth: 1,
                      strokeDasharray: "none",
                      strokeLinecap: "round",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "#0f172a",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                  />
                </g>
              </svg>
            </a>

            <a href="mailto: support@optima-holdings.com">
              <svg className="h-4 pb-px opacity-70" viewBox="0 0 256 256">
                <defs></defs>
                <g
                  style={{
                    stroke: "none",
                    strokeWidth: 0,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "none",
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 45 42.768 l 45 -20.785 v -3.587 c 0 -3.186 -2.582 -5.768 -5.768 -5.768 H 5.768 C 2.582 12.627 0 15.209 0 18.395 v 3.587 L 45 42.768 z"
                    style={{
                      stroke: "none",
                      strokeWidth: 1,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "#0f172a",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                  />
                  <path
                    d="M 45 52.626 L 0 31.84 v 39.765 c 0 3.186 2.582 5.768 5.768 5.768 h 78.464 c 3.186 0 5.768 -2.582 5.768 -5.768 V 31.84 L 45 52.626 z"
                    style={{
                      stroke: "none",
                      strokeWidth: 1,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "#0f172a",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
