'use client'

import React, { useEffect, useState } from 'react'

import ToRent from './rentalCards'
import Rented from './rentedCards'

// Define a type for individual tasks
type Task = {
  id: number;
  name: string;
  checked: boolean;
};

const SalesTabs = () => {
  const [tabToDisplay, setTabToDisplay] = useState("rented")


  //accept search from URL
  useEffect(() => {
    const fullUrl = window.location.href;
  
    if (fullUrl.includes('?')){
      const tabToDisplay = fullUrl.split('?')[1];
      setTabToDisplay(tabToDisplay)

    }
  },[]);




  return (
    <section className="">
    <div className="flex text-slate-900 text-center">
      <div className={`flex-grow rounded-lg p-1 cursor-pointer ${tabToDisplay === "rented" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('rented')}>Rented</div>
      <div className={`mx-1 flex-grow rounded-lg p-1 cursor-pointer ${tabToDisplay === "available" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('available')}>Available</div>
    </div>
    {tabToDisplay === 'rented' && (

      
      <Rented />

      
    )}
    {tabToDisplay === 'available' && (
      <ToRent />
    )}
    
    

    </section>
  )}

export default SalesTabs;
