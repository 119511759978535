// src/pages/PricingPage.tsx

import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

const pricingOptions = [
  {
    label: "Inventory management",
    explanation: "Track, edit, and manage your inventory",
    value: 10000,
  },
  {
    label: "Accounting integration",
    explanation: "Invoicing, estimating, and customer management",
    value: 10000,
  },
  {
    label: "Shipping, manufacturing, and ordering",
    explanation:
      "Link your inventory and accounting to shipping, manufacturing, and ordering",
    value: 10000,
  },
  {
    label: "Update public-facing website",
    explanation: "Add estimates, payments, invoices, etc.",
    value: 10000,
  },
  {
    label: "Rental Management",
    explanation:
      "Rent out items from your inventory. Document the condition, track schedules, and send reminders",
    value: 10000,
  },
  {
    label: "Forecasting and analytics",
    explanation:
      "Predict sales and inventory changes and chart historical trends. (Requires accounting integration.)",
    value: 5000,
  },
  {
    label: "AI-generated, data-driven messaging",
    explanation:
      "Use machine learning and your past sales and outreach data to understand when, how, and what to include in your messaging to convert current and future customers.",
    value: 30000,
  },
  {
    label: "10 hours of additional updates and/or training",
    explanation: "Update your current software or add training time",
    value: 2000,
  },
  {
    label: "Remote setup",
    explanation: "10 days, 4 hours per day, includes training",
    value: 5000,
  },
  {
    label: "On-site setup",
    explanation: "5 days, 8 hours per day, includes training",
    value: 10000,
  },
];

const defaultSelectedOptions = {
  "Inventory management": true,
  "Accounting integration - customer tracking, estimating and invoicing": false,
  "Task management - shipping, ordering, and manufacturing": false,
  "Rental Management": false,
  "Forecasting and analytics": false,
  "10 hours of additional updates and/or training": false,
  "Add Estimates to public-facing website": false,
  "Remote setup": false,
  "On-site setup": false,
  "Sales and marketing predictions": false,
};

const PricingPage: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: boolean;
  }>(defaultSelectedOptions);
  const [getEstimate, setGetEstimate] = useState(false);

  const handleDivClick = (label: string) => {
    setSelectedOptions((prevState) => {
      const newState = { ...prevState, [label]: !prevState[label] };

      if (label === "Remote setup" && newState[label]) {
        newState["On-site setup"] = false;
      } else if (label === "On-site setup" && newState[label]) {
        newState["Remote setup"] = false;
      }

      return newState;
    });
  };

  useEffect(() => {
    emailjs.init("uzd_-uTNyGMC9EakZ");
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const serviceID = "service_avvjmbh";
    const templateID = "template_odtkctq";
    emailjs
      .sendForm(serviceID, templateID, e.currentTarget)
      .then((response) => {
        console.log("Email successfully sent!", response.status, response.text);
      })
      .catch((error) => {
        console.error("Failed to send email. Error:", error);
      });
    e.currentTarget.reset();
  };

  const calculatePrice = () => {
    const totalPrice = pricingOptions.reduce((total, option) => {
      return total + (selectedOptions[option.label] ? option.value : 0);
    }, 0);
    return totalPrice;
  };

  const formatUSD = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };
  //bg-[#EBF0F6]
  return (
   
   

      <div>
        <div className="p-2 mt-2">
          {/*<div
          className={`py-2 rounded-lg bg-black mb-2 text-slate-50`}
          
        >
          <p className="text-lg">Inventory management</p>
          <p className="text-sm text-slate-400">Track, edit, and manage your inventory.</p>
          <p className="text-slate-300">{formatUSD(10000)}</p>
        </div>*/}

          <div className="mb-2 text-slate-900 text-sm text-center">
            Click the options below to estimate your project's one-time price.
          </div>

          <div className="grid md:grid-cols-2 gap-4">
            {pricingOptions.map((option) => (
              <div
                key={option.label}
                className={`p-2 rounded-lg cursor-pointer 
              
                ${
                  selectedOptions[option.label]
                    ? "bg-black text-slate-50"
                    : "bg-slate-200 bg-opacity-50"
                }`}
                onClick={() => handleDivClick(option.label)}
              >
                <p className="text-lg">{option.label}</p>
                <p
                  className={`${
                    selectedOptions[option.label]
                      ? "text-slate-400"
                      : "text-slate-800"
                  } text-sm`}
                >
                  {option.explanation}
                </p>
                <p
                  className={`${
                    selectedOptions[option.label]
                      ? "text-slate-300"
                      : "text-slate-600"
                  }`}
                >
                  {formatUSD(option.value)}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="mx-2">
          {!getEstimate && (
            <button
              className="rounded-md text-center text-white py-2 w-full mt-4 bg-black"
              onClick={() => setGetEstimate(true)}
            >
              Get an Estimate: {formatUSD(calculatePrice())}
            </button>
          )}
          {getEstimate && (
            <div>
              <div className="mb-2 text-sm text-slate-600 text-center">
                Add your name and email, and we'll get the process started.
              </div>
              <div className="flex justify-center text-center">
                <form
                  id="contact-form"
                  onSubmit={handleSubmit}
                  className="grid md:grid-cols-2 gap-4 w-full"
                >
                  <input
                    type="text"
                    className="placeholder-slate-500 text-center rounded-md py-1 focus:ring-0 outline-0"
                    placeholder="Name"
                    required
                  />

                  <input
                    type="email"
                    className="placeholder-slate-500 text-center rounded-md py-1 focus:ring-0 outline-0"
                    placeholder="Email"
                    required
                  />
                </form>
              </div>
              <button
                type="submit"
                className="bg-slate-800 hover:bg-slate-900 rounded-md text-center text-white py-2 w-full mt-4"
              >
                Send Estimate: {formatUSD(calculatePrice())}
              </button>
            </div>
          )}
          <div className="mb-2 text-slate-900 text-sm text-center mt-10">
            <p>
              To get the process started, send your estimate, and we'll contact
              you to set up a meeting.
            </p>
            <p>
              {" "}
              In the initial meeting, we'll figure out exactly what you need and
              establish a timeline and set a project price.
            </p>
          </div>

          <section className="mb-32 mt-10">
            <h2 className="text-3xl font-semibold text-center mb-4">
              What's Included
            </h2>
            <div className="flex justify-center">
              <ul className="space-y-2 list-disc list-inside text-left">
                <li>
                  Full rights, no subscriptions. The software is yours as an
                  asset. The only monthly fee you'll pay is the hosting fee
                  (around $20.00), and that's paid directly to Amazon Web
                  Services.
                </li>
                <li>
                  Ability for multiple locations. If you have more than one
                  location, it's still one setup fee.
                </li>
                <li>
                  The software is exclusively React and Node.js. This means that
                  any React developer can understand and edit the software.
                </li>
                <li>
                  Full customization. When setting everything up, we'll assess
                  your company's needs and add, update, and delete features
                  accordingly.
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>

  );
};

export default PricingPage;
