'use client'

import React, { useEffect, useState } from 'react'

import CustomerCard from './CustomerCard'

import { customerCards } from './CustomerCard'


const Customers = () => {
  const [showAddCustomer, setShowAddCustomer] = useState(false)
  const [customers, setCustomers] = useState<customerCards[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)



  useEffect(() => {
    fetchCustomers();
  }, []);

  const allCustomers:customerCards[] = [
    {
        "displayName": "Caltrans",
        "familyName": "Doe",
        "givenName": "Jon",
        "companyName": "CalTrans",
        "primaryEmail": "example@dot.ca.gov",
        "qboID": 347,
        "primaryPhone": "(555) 555-5555",
        "billAddressCity": "Los Angeles",
        "billAddressLine1": "24 Survey Rd.",
        "billAddressLine2": "Suite 300",
        "billAddressState": "CA",
        "billAddressPostalCode": "90640",
        "country": "USA",
        "fullyQualifiedName": "Caltrans",
        "shipAddressState": "CA",
        "shipAddressCity": "Los Angeles",
        "shipAddressLine1": "24 Survey Rd.",
        "shipAddressLine2": "",
        "shipAddressPostalCode": "99349",
        "dateCreated": "2024-03-12T07:11:20-07:00",
        "balance": 902.8400268554688,
        "notes": '',
        "taxable": '',
        "customerType": "Direct",
        "equipment": [],
        "taxResaleNumber": "",
        "mobilePhone": "(555) 555-5555",
        "isVisible": true
    },
    {
      "displayName": "So. Cal Edison",
      "familyName": "Gonzales",
      "givenName": "Fulano",
      "companyName": "So. Cal Edison",
      "primaryEmail": "example@sce.com",
      "qboID": 347,
      "primaryPhone": "(555) 555-5555",
      "billAddressCity": "Fontana",
      "billAddressLine1": "16 Electricity",
      "billAddressLine2": "",
      "billAddressState": "CA",
      "billAddressPostalCode": "92352",
      "country": "USA",
      "fullyQualifiedName": "Fulano Gonzales",
      "shipAddressState": "CA",
      "shipAddressCity": "Fontana",
      "shipAddressLine1": "16 Electricity",
      "shipAddressLine2": "",
      "shipAddressPostalCode": "92352",
      "dateCreated": "2024-03-12T07:11:20-07:00",
      "balance": 0,
      "notes": '',
      "taxable": '',
      "customerType": "Direct",
      "equipment": [],
      "taxResaleNumber": "",
      "mobilePhone": "(555) 555-5555",
      "isVisible": true
  }
];

  const fetchCustomers = async () => {
    setIsLoading(true);


        setCustomers(allCustomers)
  
        setIsLoading(false)
      }
 
 
  

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value.toLowerCase()
    setSearchTerm(newSearchTerm)

    if (!newSearchTerm.trim()) {
      // If the search term is empty, set all items to visible
      setCustomers(
        customers.map((item) => ({ ...item, isVisible: true }))
      )
    } else {
      // Otherwise, filter based on the search term
      const searchWords = newSearchTerm.split(/\s+/)
      setCustomers(
        customers.map((item) => {
          const itemText =
            `${item.displayName} ${item.primaryPhone} ${item.familyName} ${item.givenName}`.toLowerCase()
          const isVisible = searchWords.every((word) => itemText.includes(word))
          return { ...item, isVisible }
        })
      )
    }
  }

  // Determine the count of visible items
  const visibleItemCount = customers.filter(
    (customer) => customer.isVisible
  ).length
    
  const handleClearSearch = () => {

    setSearchTerm('');
    // Reset all items to be visible when the search is cleared
    setCustomers(customers.map(item => ({ ...item, isVisible: true })));
    
  };



    const handleClose = () => {
      setShowAddCustomer(false);
    };

    const setCustomerFromAdd = (newCustomer: customerCards) => {
  
          setCustomers(prevCustomers => [...prevCustomers, newCustomer]);
    }

  return (
    <section className="md:h-48">
      <div className="py-1 ">
      <div className="md:flex my-2">
      <div className={`md:flex-grow w-full flex justify-between items-end ${isInputFocused ? 'border-gray-500' : 'border-gray-200 hover:border-gray-300'} border bg-white rounded-lg py-1 pl-3`}>
      <input
        type="text"
        id="searchInput"
        className="w-full outline-none focus:outline-none focus:ring-0 placeholder:text-gray-500 placeholder:font-light"
        placeholder="Search customers"
        value={searchTerm} // Ensure this correctly references the state
        onChange={handleSearch}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        
      />
              {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="pr-2 border-r border-gray-500 text-xs text-bottom text-gray-500 font-light hover:text-gray-600"
            type="button"
          >
            clear {/* This is a Unicode 'X' character: &#x2715; */}
          </button>
        )}
      <div className="whitespace-nowrap text-bottom text-right text-xs text-gray-500 font-light ml-2 pr-1">
        {visibleItemCount} item(s)
      </div>
    </div>

      </div>
   
      {isLoading && (
          <div className="flex w-full justify-center items-center">
            <div>
        <p className="text-center animate-pulse">Loading...</p>


            </div>
            </div>
        )}
      {customers.map((customer, index) => (
        <div key={index} className={`${
          customer.isVisible ? 'px-2' : 'hidden'
        }`}>
          <CustomerCard
            mobilePhone={customer.mobilePhone}
            isVisible={customer.isVisible}
            displayName={customer.displayName}
            familyName={customer.familyName}
            givenName={customer.givenName}
            companyName={customer.companyName}
            primaryEmail={customer.primaryEmail}
            qboID={customer.qboID}
            primaryPhone={customer.primaryPhone}
            billAddressCity={customer.billAddressCity}
            billAddressLine1={customer.billAddressLine1}
            billAddressLine2={customer.billAddressLine2}
            billAddressState={customer.billAddressState}
            billAddressPostalCode={customer.billAddressPostalCode}
            country={customer.country}
            fullyQualifiedName={customer.fullyQualifiedName}
            shipAddressState={customer.shipAddressState}
            shipAddressCity={customer.shipAddressCity}
            shipAddressLine1={customer.shipAddressLine1}
            shipAddressLine2={customer.shipAddressLine2}
            shipAddressPostalCode={customer.shipAddressPostalCode}
            dateCreated={customer.dateCreated}
            balance={customer.balance}
            notes={customer.notes}
            taxable={customer.taxable}
            customerType={customer.customerType}
            equipment={customer.equipment}
            taxResaleNumber={customer.taxResaleNumber}
          />
          </div>
        ))}
      </div>
 
    </section>
  )
}

export default Customers;
