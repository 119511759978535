import React, {useState } from 'react';


interface CancelReturnConfirmationOrder {
  dateCreated: string;
  updatedStatus: string;
  editor: string | undefined;
  onHide: () => void;
}

  const CancelReturnConfirmation: React.FC<CancelReturnConfirmationOrder> = ({ updatedStatus, dateCreated, editor, onHide }) => {
    const [isVisible, setIsVisible] = useState(true); // Control the visibility of the component
    const [status, setStatus] = useState('confirmation'); // Control the display of confirmation or processing message

  
    const hideComponentConfirm = () => setIsVisible(false);
  

    const hideComponent = () => {
      // Call the onHide function passed from the parent component
      onHide();
    };
    const markAsCanceledReturned = async () => {
      setStatus('processing')
   
    }
  
    if (!isVisible) return null;
  
    return (
      <div className={`{${status === 'processing' ? "hidden" : "text-gray-700 bg-white border border-gray-200 rounded-lg overflow-hidden lg:text-sm flex items-center justify-center w-full p-5"} `}>
       {status === 'confirmation' && <div>
          <p className={`confirmation text-center`}>
            Are you sure you want to {updatedStatus === 'canceled' ? "cancel" : "return"} this order?
          </p>

          
          <div className={`buttons flex justify-center items-center mt-5`}>
            
            <button className="bg-gray-200 text-black hover:bg-gray-300 text-center m-1 py-1 flex-grow px-2 rounded-lg" onClick={hideComponent}>Close</button>

            <button className="bg-gray-700 text-white hover:bg-black text-center py-1 m-1 px-2 flex-grow rounded-lg" onClick={markAsCanceledReturned}>Yes</button>
            
          </div>
          
          </div>}

      </div>
    );
  };

  export default CancelReturnConfirmation