// src/pages/ContactPage.tsx

import React, { useEffect } from 'react';
import { Helmet } from "react-helmet-async";
const ContactPage: React.FC = () => {


    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  

  return (
    <div className="min-h-screen bg-gray-100 p-8">
            <Helmet>
      <title>Optima Inventory - About</title>
      <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <title>Optima Inventory</title>


    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" />

 
    <meta property="og:title" content="Optima Inventory - About" />
    <meta property="og:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.optima-inventory.com" />
    <meta property="og:image" content="https://www.optima-inventory.com/logo.png" />

 
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Optima Inventory - About" />
    <meta name="twitter:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta name="twitter:image" content="https://www.optima-inventory.com/logo.png" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
      <header className="mb-10 text-center">
        <h1 className="text-4xl font-bold">About</h1>
      </header>
      <div className="grid md:grid-cols-2">
      <div className="flex items-center">
        <div className="space-y-5">

        <p>Like most products, <span className="font-semibold">Optima Inventory</span> originated out of necessity.</p>
        <p>There wasn't a program that combined inventory, task management, sales, forecasting, and analytics. So, we listened to current and future clients to understand what they wanted, and got to work to combine the most essential parts of their businesses with the ability for complete customization.</p>
        <p>The result is a product that you own outright, no hidden fees or monthly subscriptions.</p>
        </div>
        </div> 
        <div className="flex justify-center items-center">
        <video className="w-full md:w-auto md:h-72 rounded-md mx-2 mb-2 md:mb-0" controls autoPlay muted>
              <source src="/aboutSmall.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
      </div> 
      </div>
      

    </div>
  );
};

export default ContactPage;