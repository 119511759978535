import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PricingPage from './pages/Pricing';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import ContactPage from './pages/ContactPage';
import About from './pages/About';
import Terms from './pages/Terms';
import Setup from './pages/Setup';

// Import other pages as needed

const App: React.FC = () => {



  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/setup" element={<Setup />} />
        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
};



export default App;