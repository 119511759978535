import React, { useState, useEffect } from 'react';

// Define a type for the component's props
type CompanyLogoProps = {
    companyName: string;
};

const CompanyLogo: React.FC<CompanyLogoProps> = ({ companyName }) => {
    const [logoUrl, setLogoUrl] = useState<string | null>(null);

    useEffect(() => {
        // Construct the URL for Clearbit's Logo API
        if (companyName !== "") {
            const fetchLogoUrl = `https://logo.clearbit.com/${encodeURIComponent(companyName)}`;
            setLogoUrl(fetchLogoUrl);
        } else {
            setLogoUrl(null);
        }
    }, [companyName]);

    return logoUrl ? <img src={logoUrl} alt={`${companyName} logo`} className="h-20 object-contain" /> : null;
};

export default CompanyLogo;