import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import VendorSearch, {VendorItem} from './vendorSearch'

interface AddToToOrderListProps {
  description: string
  initialQuantity: number
  partNumber: string
  images: string
  vendors: VendorItem[]
  location: string
  cost: number
  manufactPartNum: string
  manufacturer: string
  onHide: () => void
  onUpdate: (data: any) => void; // New callback prop
}

const AddToToOrderList: React.FC<AddToToOrderListProps> = ({
  partNumber,
  description,
  images,
  location,
  vendors,
  cost,
  initialQuantity,
  manufactPartNum,
  manufacturer,
  onHide,
  onUpdate
}) => {

  const [status, setStatus] = useState('confirmation') // Control the display of confirmation or processing message
  const [quantity, setQuantity] = useState(initialQuantity) // Control the display of confirmation or processing message
  const [dateNeeded, setDateNeeded] = useState<Date | null>(new Date());



  const newTime = () => {
    const dateTimeArray = new Date()
    return dateTimeArray
  }



  const hideComponent = () => {
    // Call the onHide function passed from the parent component
    onHide()
  }
  const addQuantity = async () => {
    

    if (quantity < 1) {
      alert("Quantity must be at least 1.")
      return
    }
    setStatus('processing')
   
        //onUpdate(data); // Trigger the callback
        //console.log('Lambda function succeeded', payload);
        setStatus('added')
        setTimeout(() => {
          hideComponent()
          // Place any additional actions you want to perform after the delay inside this block
        }, 1500)
     
  }



  return (
    <div>
      {status === 'processing' && (
        <div className="w-full h-96 flex justify-center items-center text-white font-medium bg-blue-600">
          <p className="animate-pulse">Adding to Order List</p>
        </div>
      )}
      {status === 'added' && (
        <div className="w-full h-96 flex justify-center items-center text-white text-lg font-medium bg-blue-600">
          <p className="">Added</p>
        </div>
      )}
      {status === 'confirmation' && (
        <div
          id={`confirm-${partNumber}`}
          className="w-full"
        >

          <div className="w-full">
            <p className="px-2 text-center font-medium text-lg">
              How many would you like to order?
            </p>
            <div className="flex justify-center mt-2">
              <div>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.round(Number(e.target.value)))} // Fix applied here
                  className="text-center m-1 p-1 flex-grow rounded-lg border border-gray-300" 
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div>
            <p className="px-2 text-center font-medium text-lg">
              When do you need it?
            </p>
          <DatePicker
            selected={dateNeeded}
            onChange={setDateNeeded}
            dateFormat="MM-dd-yy" // Customize the date format
            className={`text-center px-2 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 border border-gray-300 w-full`} // Apply Tailwind or custom styling here
            wrapperClassName="date-picker" // Wrapper class for additional styling
            popperPlacement="top-end" // Customize the popper placement
          />
          </div>
          </div>
          <div className="">
            <div className="buttons flex justify-center items-center m-2">
              <button
                className="bg-gray-200 text-black hover:bg-gray-300 text-center m-1 py-1 flex-grow rounded-md"
                onClick={hideComponent}
              >
                Close
              </button>
              <button
                className="bg-gray-700 text-white hover:bg-black text-center py-1 m-1 flex-grow rounded-md"
                onClick={addQuantity}
              >
                Add to Order List
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AddToToOrderList
