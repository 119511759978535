'use client'

import React, { useEffect, useState } from 'react'
import { invoiceCards } from './InvoiceCard'
import InvoiceCard from './InvoiceCard'



const Invoices = () => {
const [showAddInvoice, setShowAddInvoice] = useState(false)
const [invoices, setInvoices] = useState<invoiceCards[]>([])
const [searchTerm, setSearchTerm] = useState('')
const [isInputFocused, setIsInputFocused] = useState(false)
const [isLoading, setIsLoading] = useState(false)


useEffect(() => {
  fetchinvoices();
}, []);

const allInvoices:invoiceCards[] = [
  {
      "invoiceNumber": "123648",
      "displayName": "Edison",
      "companyName": "Edison",
      "itemsDescription": "PIN SHOULDER, auger pin for 3\" HEX augers,AUGER CLIP, cotter pin 1-5/8\" and 2-1/4\" SHANK",
      "itemsDetailType": "R700150,R700175",
      "customerEmail": "payables@sce.com",
      "customerPhone": "(555) 555-5555",
      "itemsQuantity": "10,10",
      "linesRefNames": "SalesItemLineDetail,SalesItemLineDetail,SubTotalLineDetail,DiscountLineDetail,SalesItemLineDetail",
      "total": 147.13,
      "linesAmounts": "150.2,9.5,159.7,31.94,19.37",
      "linesTypes": "SalesItemLineDetail,SalesItemLineDetail,SalesItemLineDetail,DiscountLineDetail",
      "linesUnitPrice": "15.02,0.95",
      "dueDate": "2024-07-20",
      "pdf": "",
      "billEmail": "payables@sce.com",
      "shipMethod": "fedex",
      "salesTerm": '',
      "subTotal": 0,
      "taxable": false,
      "invoiceStatus": "Sent",
      "shipAddressLine1": "11 State Hwy",
      "shipAddressLine2": '',
      "mobilePhone": "",
      "shipAddressLine4": '',
      "emailStatus": "EmailSent",
      "deliveryType": "Email",
      "deliveryTime": '',
      "billAddressCountry": '',
      "billAddressLine1": "So Cal Edison",
      "billAddressCity": '',
      "billAddressPostalCode": '',
      "billAddressState": '',
      "dateCreated": "2024-06-20T12:35:41.104Z",
      "totalTax": 0,
    
      "customerBalance": 0,
    
      "trackingNumber": "[\"8869 6072 6562\"]",
      "message": "",
      "billAddressLine2": "11 State Hwy",
  
      "salesPerson": "Lois",
      "shipAddressCity": "Jacksonville",
      "shipAddressState": "Florida",
      "shipAddressPostalCode": "32256",
      "billAddressLine3": "Jacksonville, Florida 32256",
      "billAddressLine4": "US",
      "poNumber": "99-05807",
      "shipDate": "2024-06-20",
      "invoiceItems": "[{\"quantity\":10,\"partNumber\":\"R700150\",\"description\":\"PIN SHOULDER, auger pin for 3\\\" HEX augers\",\"unitPrice\":15.02,\"lineAmount\":150.2},{\"quantity\":10,\"partNumber\":\"R700175\",\"description\":\"AUGER CLIP, cotter pin 1-5/8\\\" and 2-1/4\\\" SHANK\",\"unitPrice\":0.95,\"lineAmount\":9.5}]",
      "shipping": 19.37,
      "discount": 20,
      "isVisible": true
  },
  {
      "invoiceNumber": "123647",
      "displayName": "West Coast Construction",
      "companyName": "West Coast Construction",
      "itemsDescription": "HYD. CYLINDER 50mm 50 Ton",
      "itemsDetailType": "C501381",
      "customerEmail": "accounting@example.com",
      "customerPhone": "555-555-5555",
      "itemsQuantity": "8",
      "linesRefNames": "SalesItemLineDetail,SubTotalLineDetail,DiscountLineDetail,SalesItemLineDetail",
      "total": 4980.69,
      "linesAmounts": "5600.0,5600.0,1120.0,500.69",
      "linesTypes": "SalesItemLineDetail,SalesItemLineDetail,DiscountLineDetail",
      "linesUnitPrice": "700",
      "dueDate": "2024-07-19",
      "pdf": "",
      "billEmail": "accounting@example.com",
      "shipMethod": "freight",
      "salesTerm": '',
      "subTotal": 0,
      "taxable": false,
      "invoiceStatus": "Paid",
      "shipAddressLine1": "HD Repairs",
      "shipAddressLine2": "56 Park Rd",
 
      "shipAddressLine4": '',
      "emailStatus": "EmailSent",
      "deliveryType": "Email",
      "deliveryTime": '',
      "billAddressCountry": '',
      "billAddressLine1": "West Coast Construction",
      "billAddressCity": '',
      "billAddressPostalCode": '',
      "billAddressState": '',
      "dateCreated": "2024-06-19T20:18:09.208Z",
      "totalTax": 0,
      "mobilePhone": '',
      "customerBalance": 0,

      "trackingNumber": "[\"456677785\"]",
      "message": "",
      "billAddressLine2": "8 Redwood Rd",
 
      "salesPerson": "lois",
      "shipAddressCity": "Jordan",
      "shipAddressState": "UT",
      "shipAddressPostalCode": "84081",
      "billAddressLine3": "Salt Lake City, UT 84104",
      "billAddressLine4": "US",
      "poNumber": "721",
      "shipDate": "2024-06-20",
      "invoiceItems": "[{\"quantity\":8,\"partNumber\":\"C501381\",\"description\":\"HYD. CYLINDER 50mm 50 Ton\",\"unitPrice\":700,\"lineAmount\":5600}]",
      "shipping": 500.69,
      "discount": 20,
      "isVisible": true
  }
  
];

const fetchinvoices = async () => {
  setIsLoading(true);


      setInvoices(allInvoices)
      setIsLoading(false)

};

const handleInputFocus = () => {
  setIsInputFocused(true)
}

const handleInputBlur = () => {
  setIsInputFocused(false)
}

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newSearchTerm = event.target.value.toLowerCase()
  setSearchTerm(newSearchTerm)

  if (!newSearchTerm.trim()) {
    // If the search term is empty, set all items to visible
    setInvoices(
      invoices.map((item) => ({ ...item, isVisible: true }))
    )
  } else {
    // Otherwise, filter based on the search term
    const searchWords = newSearchTerm.split(/\s+/)
    setInvoices(
      invoices.map((item) => {
        const itemText =
          `${item.displayName} ${item.customerEmail} ${item.invoiceNumber}`.toLowerCase()
        const isVisible = searchWords.every((word) => itemText.includes(word))
        return { ...item, isVisible }
      })
    )
  }
}

// Determine the count of visible items
const visibleItemCount = invoices.filter(
  (invoice) => invoice.isVisible
).length
  
const handleClearSearch = () => {

  setSearchTerm('');
  // Reset all items to be visible when the search is cleared
  setInvoices(invoices.map(item => ({ ...item, isVisible: true })));
  
};




  
    

  return (
    <section className="md:h-60">
    <div className={`${showAddInvoice ? "mb-10 border rounded-r-lg rounded-b-lg border-gray-300":""} bg-gray-100 overflow-hidden`}>


   <div className="py-1 ">
      <div className="flex my-2">
      <div className={`md:flex-grow w-full flex justify-between items-end ${isInputFocused ? 'border-gray-500' : 'border-gray-200 hover:border-gray-300'} border bg-white rounded-lg py-1 mb-3 pl-3`}>
      <input
        type="text"
        id="searchInput"
        className="w-full outline-none focus:outline-none focus:ring-0 placeholder:text-gray-500 placeholder:font-light"
        placeholder="Search invoices"
        value={searchTerm} // Ensure this correctly references the state
        onChange={handleSearch}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        
      />
              {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="pr-2 border-r border-gray-500 text-xs text-bottom text-gray-500 font-light hover:text-gray-600"
            type="button"
          >
            clear {/* This is a Unicode 'X' character: &#x2715; */}
          </button>
        )}
      <div className="whitespace-nowrap text-bottom text-right text-xs text-gray-500 font-light ml-2 pr-1">
        {visibleItemCount} item(s)
      </div>
    </div>

      </div>
      
      {isLoading && (
          <div className="flex w-full justify-center items-center">
            <div>
        <p className="text-center animate-pulse">Loading...</p>


            </div>
            </div>
        )}
        <div className="overflow-hidden">
      {invoices.map((invoice, index) => (
        <div key={index} className={`${
          invoice.isVisible ? '' : 'hidden'
        }`}>
<InvoiceCard
isVisible={invoice.isVisible}
totalTax={invoice.totalTax}
customerBalance={invoice.customerBalance}
mobilePhone={invoice.mobilePhone}
  invoiceNumber={invoice.invoiceNumber}
  displayName={invoice.displayName}
  companyName={invoice.companyName}
  itemsDescription={invoice.itemsDescription}
  itemsDetailType={invoice.itemsDetailType}
  customerEmail={invoice.customerEmail}
  customerPhone={invoice.customerPhone}
  itemsQuantity={invoice.itemsQuantity}
  linesRefNames={invoice.linesRefNames}
  total={invoice.total}
  linesAmounts={invoice.linesAmounts}
  linesTypes={invoice.linesTypes}
  linesUnitPrice={invoice.linesUnitPrice}
  dueDate={invoice.dueDate}
  pdf={invoice.pdf}
  billEmail={invoice.billEmail}
  shipMethod={invoice.shipMethod}
  salesTerm={invoice.salesTerm}
  subTotal={invoice.subTotal}
  taxable={invoice.taxable}
  invoiceStatus={invoice.invoiceStatus}
  shipAddressLine1={invoice.shipAddressLine1}
  shipAddressLine2={invoice.shipAddressLine2}
  invoiceItems={invoice.invoiceItems}
  shipAddressLine4={invoice.shipAddressLine4}
  emailStatus={invoice.emailStatus}
  deliveryType={invoice.deliveryType}
  deliveryTime={invoice.deliveryTime}
  billAddressCountry={invoice.billAddressCountry}
  billAddressLine1={invoice.billAddressLine1}
  billAddressLine2={invoice.billAddressLine2}
  message={invoice.message}
  salesPerson={invoice.salesPerson}
  trackingNumber={invoice.trackingNumber}
  billAddressCity={invoice.billAddressCity}
  billAddressPostalCode={invoice.billAddressPostalCode}
  billAddressState={invoice.billAddressState}
  dateCreated={invoice.dateCreated}
  billAddressLine3 = {invoice.billAddressLine3} 
  billAddressLine4 ={invoice.billAddressLine4} 
  shipAddressCity = {invoice.shipAddressCity}
  shipAddressState={invoice.shipAddressState} 
  shipAddressPostalCode = {invoice.shipAddressPostalCode}
  poNumber= {invoice.poNumber}
  shipDate = {invoice.shipDate}
  shipping = {invoice.shipping}
  discount = {invoice.discount}
/>

          </div>
        ))}
        </div>
      </div>
      </div>
    </section>
  )
}

export default Invoices;
