// src/HomePage.tsx

import React from "react";
import { Helmet } from "react-helmet-async";
import ContactForm from "../components/ContactForm";
import InventoryCards from "../components/InventoryCards";
import SalesTabs from "../components/salesTabs";
import RentalsTabs from "../components/rentalTabs";
import TaskTabs from "../components/taskTabs";
import DataAndAnalytics from "../components/dataAndAnalytics";

const HomePage: React.FC = () => {
  return (
    <div className="min-h-screen p-2 md:p-6">
      <Helmet>
      <title>Optima Inventory</title>
      <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <title>Optima Inventory</title>


    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" />

 
    <meta property="og:title" content="Optima Inventory" />
    <meta property="og:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.optima-inventory.com" />
    <meta property="og:image" content="https://www.optima-inventory.com/logo.png" />

 
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Optima Inventory" />
    <meta name="twitter:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta name="twitter:image" content="https://www.optima-inventory.com/logo.png" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>

      

      <section className="mb-32 mt-20">
      <div className="flex justify-center items-center space-x-2 mb-10">
        <div className="hidden xl:block h-6 w-6 rounded-sm overflow-hidden space-y-px">
          <div className="h-1 w-8 bg-emerald-500"></div>
          <div className="h-1 w-8 bg-teal-500"></div>
          <div className="h-1 w-8 bg-sky-500"></div>
          <div className="h-1 w-8 bg-violet-500"></div>
          <div className="h-1 w-8 bg-pink-500"></div>
        </div>
        <h1 className="font-bold text-3xl text-center">Affordable, customized, and integrated solutions for small business manufacturing</h1>
      </div>
        <div className="grid md:grid-cols-2">
          <div className="flex justify-center items-center">
            <video className="w-full md:w-auto md:h-96 rounded-md mx-2 md:mx-4 mb-2 md:mb-0" controls autoPlay loop muted>
              <source src="/homeSmall.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="flex justify-center items-center">
          <div className="">
            {/*<h1 className="font-bold text-3xl text-center mb-5">Affordable, customized, and integrated solutions for small business manufacturing</h1>*/}
            <a href="#inventory">
              <button className="w-full rounded-md bg-black text-white text-center py-2 px-2 mb-2">
                Inventory Management
              </button>
            </a>
            <a href="#tasks">
              <button className="w-full rounded-md bg-black text-white text-center py-2 px-2 mb-2">
                Shipping, Manufacturing, and Ordering
              </button>
            </a>
            <a href="#sales">
              <button className="w-full rounded-md bg-black text-white text-center py-2 px-2 mb-2">
                Sales and Accounting
              </button>
            </a>
            <a href="#rentals">
              <button className="w-full rounded-md bg-black text-white text-center py-2 px-2 mb-2">
                Rental Management
              </button>
            </a>
            <a href="#analytics">
              <button className="w-full rounded-md bg-black text-white text-center py-2 px-2">
                Forecasting and Analytics
              </button>
            </a>
          </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-10" id="inventory">
        <div className="flex justify-center items-center p-2">
          <div>
            <ul className="space-y-2 text-center">
              <li className="font-bold text-3xl">Inventory Management</li>
              <li>
                Easily track and update inventory from your browser, the desktop app, or the mobile app.
              </li>
              <li>Integrate ordering, manufacturing, and shipping.</li>
              <li>Customize to fit your business's needs.</li>
              
            </ul>
            <p className="text-sm text-center mt-10 font-light">
              Double click to edit. Click images to enlarge.
            </p>
          </div>
        </div>
        <div className="">
          <InventoryCards />
          {/*

            <div className="w-full">
          <button className="bg-violet-500 w-full text-white rounded-md my-1 py-1 px-2">Demo</button>
          <button className="bg-indigo-500 w-full text-white rounded-md my-1 py-1 px-2">Learn more</button>
          </div>*/}
        </div>
      </section>

      <section className="mb-20 pt-10" id="tasks">
        <div className="flex justify-center items-center">
          <ul className="space-y-2 text-center">
            <li className="font-bold text-3xl">
              Shipping, Manufacturing, and Ordering
            </li>
            <li>
              Seamlessly, integrate your accounting and inventory with your shipping, manufacturing, and ordering. 
            </li>
            <li>
              Easily add parts and labor to manufactured items to update inventory as well as cost. 
            </li>
            <li>
              Automatically generate and show purchase orders, expenses, cancelations, and refunds when processing orders. 
            </li>
          </ul>
        </div>
        <div className="mt-10">
          <TaskTabs />
        </div>
      </section>

      <section className="mb-20 pt-10" id="sales">
        <div className="flex justify-center items-center">
          <ul className="space-y-2 text-center">
            <li className="font-bold text-3xl">Sales and Accounting</li>
            <li>Integrate your estimating, invoicing, and customer records.</li>
            <li>Email and/or text customers estimates and invoices.</li>
            <li>
              Allow customers to create and view invoices on your public-facing
              website.
            </li>
          </ul>
        </div>
        <div className="grid md:grid-rows-3 md:grid-flow-col gap-6 mt-10">
          <SalesTabs />
        </div>
      </section>

      <section id="rentals" className="mb-20 pt-10">
        <div className="flex justify-center items-center">
          <ul className="space-y-2 text-center">
            <li className="font-bold text-3xl">Rental Management</li>
            <li>Easily transfer items between inventory and rentals.</li>
            <li>Set up reminders and automated billing for each rental.</li>
            <li>Confirm terms with video recordings, digital signatures, and on-click acceptance.</li>
          </ul>
        </div>
        <div className="mt-10">
        <RentalsTabs />
        </div>
      </section>

      <section id="analytics" className="mb-20 pt-10">
        <div className="flex justify-center items-center">
          <ul className="space-y-2 text-center">
            <li className="font-bold text-3xl">Forecasting and Analytics</li>
            <li>Real-time, customized forecasting and reporting.</li>
            <li>Integrate your accounting, inventory, ordering, rentals, and manufacturing into your forecasting and reporting.</li>
          </ul>
        </div>
        <div className="mt-10">
          <DataAndAnalytics />
        </div>
      </section>

      {/*<section className="mb-32">
        <h2 className="text-3xl font-semibold mb-4 text-center">Reviews</h2>
        <div className="p-6">
          <p className="">Place for video review (to be added).</p>
        </div>
      </section>*/}

      <section className="text-center">
        <h2 className="text-3xl font-semibold">See for yourself</h2>
        <p className="my-4">
          We'll add in a few of your inventory items and deploy a
          version for you and your employees to try.
        </p>
        <ContactForm />

      </section>
    </div>
  );
};

export default HomePage;
