// src/components/Navbar.tsx

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {


  //style={{ mixBlendMode: 'difference' }}

  return (
    <div className="mb-2 h-5 text-xs flex justify-between opacity-95">
      <div className="flex justify-center items-center space-x-4 px-4">
        <div className="flex space-x-4">

          <Link to="/terms" className="text-slate-800 hover:text-black">
            Terms
          </Link>
        </div>
      </div>

      <p className="px-4">&copy; 2024 Optima Holdings. All rights reserved.</p>
    </div>
  );
};

export default Footer;
