'use client'

import React, { useEffect, useState } from 'react'
import ChartMoneyInOut from './ChartMoneyInOut'
import ChartCategories from './ChartCategories'
import { Bar } from 'react-chartjs-2';
import regression from 'regression';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Define the types for the data
interface CategoryData {
  partNumber: string;
  category: string;
}

interface TransactionItem {
  partNumber: string;
  quantity: number;
}

interface Transaction {
  transactionType: string;
  items: TransactionItem[];
  date: string; // Assuming date is available to calculate monthly sales
}

interface Props {
  categories: CategoryData[];
  transactions: Transaction[];
}


interface Item {
  transactionType: string
  amount: number
  dateCreated: string
  dateFulfilled: string
  origin: string
  vendor: string
  customer: string
  idNumber: string
  items: string
  discount?: number
}

type ItemType = {
  quantity: number;
  partNumber?: string;
  description: string;
  unitPrice: number;
  lineAmount: number;
  dateCreated?: string;
  invoiceNumber: string;
  discount?: number;
};

type Royalty = {
  quantity: number;
  partNumber?: string;
  description: string;
  unitPrice: number;
  lineAmount: number;
  dateCreated?: string;
  invoiceNumber: string;
  discount?: number;
  royalty: number;
};

interface ItemCategory {
  category: string
  partNumber: string
}

interface ChartData {
  labels: string[];
  moneyIn: { [origin: string]: number }[];
  moneyOut: { expenses: number, pos: number, royalties: number }[];
}

interface ChartCategoriesData {
  labels: any[]
  datasets: any[]
}

const DataAndAnalytics = () => {
  const [items, setItems] = useState<Item[]>([])
  const [royaltiesItems, setRoyaltiesItems] = useState<ItemCategory[]>([])
  const [royaltiesArray, setRoyaltiesArray] = useState<Royalty[]>([])
  const [royalties, setRoyalties] = useState<{ [key: string]: number }>({})
  const [invoiceItems, setInvoiceItems] = useState<ItemType[]>([])
  const [itemCategories, setItemCategories] = useState<ItemCategory[]>([])
  const [isMobile, setIsMobile] = useState(true)
  const [isLoading, setIsLoading] = useState(true) // Start with loading true
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [sortedBy, setSortedBy] = useState<string>("")
  const [moneyInToDate, setMoneyInToDate] = useState(0)
  const [moneyOutToDate, setMoneyOutToDate] = useState(0)
  const [chartMoneyInOut, setChartMoneyInOut] = useState<ChartData>({
    labels: [],
    moneyIn: [],
    moneyOut: [],
  })
  const [chartCategoriesData, setChartCategoriesData] =
    useState<ChartCategoriesData>({ labels: [], datasets: [] })


const allCategories:ItemCategory[] = [
  {
      "partNumber": "R03611E104",
      "category": "Auger"
  },
  {
      "partNumber": "R05026F204",
      "category": "Auger"
  },
  {
      "partNumber": "R07026F204",
      "category": "Auger"
  },
  {
      "partNumber": "R09026F204",
      "category": "Auger"
  },
  {
      "partNumber": "R11036Q204",
      "category": "Part"
  },
  {
      "partNumber": "N500220",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500262",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500280",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500320",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500330",
      "category": "Raw Material"
  },
  {
      "partNumber": "N710120",
      "category": "Raw Material"
  },
  {
      "partNumber": "N710240",
      "category": "Raw Material"
  },
  {
      "partNumber": "N710260",
      "category": "Raw Material"
  },
  {
      "partNumber": "N710250",
      "category": "Raw Material"
  },
  {
      "partNumber": "G700000",
      "category": "Raw Material"
  },
  {
      "partNumber": "G700040",
      "category": "Raw Material"
  },
  {
      "partNumber": "G110060",
      "category": "Raw Material"
  },
  {
      "partNumber": "R500130",
      "category": "Part"
  },
  {
      "partNumber": "R500150",
      "category": "Part"
  },
  {
      "partNumber": "R500160",
      "category": "Part"
  },
  {
      "partNumber": "R500165",
      "category": "Part"
  },
  {
      "partNumber": "R500170",
      "category": "Part"
  },
  {
      "partNumber": "N600120",
      "category": "Raw Material"
  },
  {
      "partNumber": "N600140",
      "category": "Raw Material"
  },
  {
      "partNumber": "R600150",
      "category": "Part"
  },
  {
      "partNumber": "R600160",
      "category": "Part"
  },
  {
      "partNumber": "R600170",
      "category": "Part"
  },
  {
      "partNumber": "R600171",
      "category": "Part"
  },
  {
      "partNumber": "R600175",
      "category": "Part"
  },
  {
      "partNumber": "R600177",
      "category": "Part"
  },
  {
      "partNumber": "R600181",
      "category": "Part"
  },
  {
      "partNumber": "S600330",
      "category": "Part"
  },
  {
      "partNumber": "S600340",
      "category": "Part"
  },
  {
      "partNumber": "S600510",
      "category": "Part"
  },
  {
      "partNumber": "S600530",
      "category": "Part"
  },
  {
      "partNumber": "S600560B",
      "category": "Part"
  },
  {
      "partNumber": "S600750",
      "category": "Part"
  },
  {
      "partNumber": "S600120",
      "category": "Part"
  },
  {
      "partNumber": "S600140",
      "category": "Part"
  },
  {
      "partNumber": "S600150",
      "category": "Part"
  },
  {
      "partNumber": "S600170",
      "category": "Part"
  },
  {
      "partNumber": "S600760",
      "category": "Part"
  },
  {
      "partNumber": "S600770B",
      "category": "Part"
  },
  {
      "partNumber": "S600780",
      "category": "Part"
  },
  {
      "partNumber": "S600790",
      "category": "Part"
  },
  {
      "partNumber": "S600800",
      "category": "Part"
  },
  {
      "partNumber": "S601120",
      "category": "Part"
  },
  {
      "partNumber": "S601130",
      "category": "Part"
  },
  {
      "partNumber": "S601140",
      "category": "Part"
  },
  {
      "partNumber": "S601260",
      "category": "Part"
  },
  {
      "partNumber": "S601270",
      "category": "Part"
  },
  {
      "partNumber": "S500232",
      "category": "Part"
  },
  {
      "partNumber": "S600175",
      "category": "Part"
  },
  {
      "partNumber": "S600205",
      "category": "Part"
  },
  {
      "partNumber": "S600210",
      "category": "Part"
  },
  {
      "partNumber": "S600211",
      "category": "Part"
  },
  {
      "partNumber": "S601240",
      "category": "Part"
  },
  {
      "partNumber": "S601245",
      "category": "Part"
  },
  {
      "partNumber": "S900401",
      "category": "Part"
  },
  {
      "partNumber": "S900402",
      "category": "Part"
  },
  {
      "partNumber": "S900501",
      "category": "Part"
  },
  {
      "partNumber": "S900701",
      "category": "Part"
  },
  {
      "partNumber": "S900702",
      "category": "Part"
  },
  {
      "partNumber": "S900703",
      "category": "Part"
  },
  {
      "partNumber": "S910901",
      "category": "Part"
  },
  {
      "partNumber": "S910902",
      "category": "Part"
  },
  {
      "partNumber": "S911101",
      "category": "Part"
  },
  {
      "partNumber": "S911102",
      "category": "Part"
  },
  {
      "partNumber": "S911301",
      "category": "Part"
  },
  {
      "partNumber": "S911302",
      "category": "Part"
  },
  {
      "partNumber": "S911501",
      "category": "Part"
  },
  {
      "partNumber": "S911502",
      "category": "Part"
  },
  {
      "partNumber": "S911701",
      "category": "Part"
  },
  {
      "partNumber": "S911702",
      "category": "Part"
  },
  {
      "partNumber": "S911901",
      "category": "Part"
  },
  {
      "partNumber": "S911902",
      "category": "Part"
  },
  {
      "partNumber": "S912301",
      "category": "Part"
  },
  {
      "partNumber": "S913001",
      "category": "Part"
  },
  {
      "partNumber": "S912302",
      "category": "Part"
  },
  {
      "partNumber": "S913871",
      "category": "Part"
  },
  {
      "partNumber": "S913872",
      "category": "Part"
  },
  {
      "partNumber": "S913873",
      "category": "Part"
  },
  {
      "partNumber": "S913881",
      "category": "Part"
  },
  {
      "partNumber": "S913882",
      "category": "Part"
  },
  {
      "partNumber": "S913883",
      "category": "Part"
  },
  {
      "partNumber": "S920710",
      "category": "Part"
  },
  {
      "partNumber": "S920711",
      "category": "Part"
  },
  {
      "partNumber": "S920720",
      "category": "Part"
  },
  {
      "partNumber": "S920950",
      "category": "Part"
  },
  {
      "partNumber": "S920960",
      "category": "Part"
  },
  {
      "partNumber": "S921108",
      "category": "Part"
  },
  {
      "partNumber": "S921115",
      "category": "Part"
  },
  {
      "partNumber": "S921302",
      "category": "Part"
  },
  {
      "partNumber": "S921312",
      "category": "Part"
  },
  {
      "partNumber": "S921703",
      "category": "Part"
  },
  {
      "partNumber": "S921712",
      "category": "Part"
  },
  {
      "partNumber": "S922303",
      "category": "Part"
  },
  {
      "partNumber": "S922903",
      "category": "Part"
  },
  {
      "partNumber": "S911703",
      "category": "Part"
  },
  {
      "partNumber": "S911704",
      "category": "Part"
  },
  {
      "partNumber": "S913860",
      "category": "Part"
  },
  {
      "partNumber": "S920705",
      "category": "Part"
  },
  {
      "partNumber": "S921104",
      "category": "Part"
  },
  {
      "partNumber": "S923507",
      "category": "Part"
  },
  {
      "partNumber": "S923513",
      "category": "Part"
  },
  {
      "partNumber": "S923514",
      "category": "Part"
  },
  {
      "partNumber": "S923521",
      "category": "Part"
  },
  {
      "partNumber": "S923536",
      "category": "Part"
  },
  {
      "partNumber": "S923537",
      "category": "Part"
  },
  {
      "partNumber": "S923542",
      "category": "Part"
  },
  {
      "partNumber": "S923551",
      "category": "Part"
  },
  {
      "partNumber": "S923552",
      "category": "Part"
  },
  {
      "partNumber": "G820020",
      "category": "Raw Material"
  },
  {
      "partNumber": "G820040",
      "category": "Raw Material"
  },
  {
      "partNumber": "R700130",
      "category": "Part"
  },
  {
      "partNumber": "R700140",
      "category": "Part"
  },
  {
      "partNumber": "R700150",
      "category": "Part"
  },
  {
      "partNumber": "R700155",
      "category": "Part"
  },
  {
      "partNumber": "R700165",
      "category": "Part"
  },
  {
      "partNumber": "R700175",
      "category": "Part"
  },
  {
      "partNumber": "R700180",
      "category": "Part"
  },
  {
      "partNumber": "YDP527F",
      "category": "Part"
  },
  {
      "partNumber": "YDP536F",
      "category": "Part"
  },
  {
      "partNumber": "NRD0286",
      "category": "Raw Material"
  },
  {
      "partNumber": "NRD0287",
      "category": "Raw Material"
  },
  {
      "partNumber": "C400010",
      "category": "Part"
  },
  {
      "partNumber": "C400100",
      "category": "Part"
  },
  {
      "partNumber": "C400101",
      "category": "Part"
  },
  {
      "partNumber": "C400106",
      "category": "Part"
  },
  {
      "partNumber": "C400108",
      "category": "Part"
  },
  {
      "partNumber": "C400109",
      "category": "Part"
  },
  {
      "partNumber": "C400111",
      "category": "Part"
  },
  {
      "partNumber": "C400113",
      "category": "Part"
  },
  {
      "partNumber": "C400118",
      "category": "Part"
  },
  {
      "partNumber": "C400119",
      "category": "Part"
  },
  {
      "partNumber": "C400122",
      "category": "Part"
  },
  {
      "partNumber": "C500200",
      "category": "Part"
  },
  {
      "partNumber": "C500214",
      "category": "Part"
  },
  {
      "partNumber": "C500215",
      "category": "Part"
  },
  {
      "partNumber": "20-P300000",
      "category": "Part"
  },
  {
      "partNumber": "24-P360000",
      "category": "Part"
  },
  {
      "partNumber": "P330100",
      "category": "Part"
  },
  {
      "partNumber": "3620301",
      "category": "Part"
  },
  {
      "partNumber": "4220002",
      "category": "Part"
  },
  {
      "partNumber": "5400301",
      "category": "Part"
  },
  {
      "partNumber": "6000019",
      "category": "Part"
  },
  {
      "partNumber": "P030210",
      "category": "Part"
  },
  {
      "partNumber": "3002000",
      "category": "Part"
  },
  {
      "partNumber": "6000021",
      "category": "Part"
  },
  {
      "partNumber": "3600114",
      "category": "Part"
  },
  {
      "partNumber": "4800346",
      "category": "Part"
  },
  {
      "partNumber": "6000008",
      "category": "Part"
  },
  {
      "partNumber": "2050091",
      "category": "Part"
  },
  {
      "partNumber": "2400252",
      "category": "Part"
  },
  {
      "partNumber": "2400253",
      "category": "Part"
  },
  {
      "partNumber": "3600211",
      "category": "Part"
  },
  {
      "partNumber": "3600210",
      "category": "Part"
  },
  {
      "partNumber": "4810398",
      "category": "Part"
  },
  {
      "partNumber": "4810399",
      "category": "Part"
  },
  {
      "partNumber": "6000011",
      "category": "Part"
  },
  {
      "partNumber": "6000013",
      "category": "Part"
  },
  {
      "partNumber": "P200000",
      "category": "Part"
  },
  {
      "partNumber": "P210000",
      "category": "Part"
  },
  {
      "partNumber": "P220000",
      "category": "Part"
  },
  {
      "partNumber": "8011125",
      "category": "Part"
  },
  {
      "partNumber": "P230020",
      "category": "Part"
  },
  {
      "partNumber": "2400437",
      "category": "Part"
  },
  {
      "partNumber": "3600124",
      "category": "Part"
  },
  {
      "partNumber": "3600380",
      "category": "Part"
  },
  {
      "partNumber": "6000324",
      "category": "Part"
  },
  {
      "partNumber": "8080017",
      "category": "Part"
  },
  {
      "partNumber": "8080390",
      "category": "Part"
  },
  {
      "partNumber": "8080391",
      "category": "Part"
  },
  {
      "partNumber": "8082075",
      "category": "Part"
  },
  {
      "partNumber": "HD30620",
      "category": "Part"
  },
  {
      "partNumber": "8011100-01",
      "category": "Part"
  },
  {
      "partNumber": "2050145",
      "category": "Part"
  },
  {
      "partNumber": "3000165",
      "category": "Part"
  },
  {
      "partNumber": "4800769",
      "category": "Part"
  },
  {
      "partNumber": "6000252",
      "category": "Part"
  },
  {
      "partNumber": "6000254",
      "category": "Part"
  },
  {
      "partNumber": "8011100",
      "category": "Part"
  },
  {
      "partNumber": "C203100",
      "category": "Part"
  },
  {
      "partNumber": "CM00010",
      "category": "Part"
  },
  {
      "partNumber": "T100160",
      "category": "Part"
  },
  {
      "partNumber": "T100165",
      "category": "Part"
  },
  {
      "partNumber": "T100170",
      "category": "Part"
  },
  {
      "partNumber": "T200080",
      "category": "Part"
  },
  {
      "partNumber": "T200110",
      "category": "Part"
  },
  {
      "partNumber": "T200135",
      "category": "Part"
  },
  {
      "partNumber": "2050170",
      "category": "Part"
  },
  {
      "partNumber": "2400096",
      "category": "Part"
  },
  {
      "partNumber": "2400167",
      "category": "Part"
  },
  {
      "partNumber": "2400171",
      "category": "Part"
  },
  {
      "partNumber": "2400262",
      "category": "Part"
  },
  {
      "partNumber": "2400277",
      "category": "Part"
  },
  {
      "partNumber": "2400356",
      "category": "Part"
  },
  {
      "partNumber": "2400357",
      "category": "Part"
  },
  {
      "partNumber": "2400359",
      "category": "Part"
  },
  {
      "partNumber": "2400362",
      "category": "Part"
  },
  {
      "partNumber": "2400364",
      "category": "Part"
  },
  {
      "partNumber": "2400371",
      "category": "Part"
  },
  {
      "partNumber": "2400450",
      "category": "Part"
  },
  {
      "partNumber": "2400493",
      "category": "Part"
  },
  {
      "partNumber": "2400618",
      "category": "Part"
  },
  {
      "partNumber": "2400621",
      "category": "Part"
  },
  {
      "partNumber": "2400713",
      "category": "Part"
  },
  {
      "partNumber": "3000084",
      "category": "Part"
  },
  {
      "partNumber": "3000085",
      "category": "Part"
  },
  {
      "partNumber": "3600119",
      "category": "Part"
  },
  {
      "partNumber": "3600280",
      "category": "Part"
  },
  {
      "partNumber": "3600345",
      "category": "Part"
  },
  {
      "partNumber": "3600346",
      "category": "Part"
  },
  {
      "partNumber": "3620713",
      "category": "Part"
  },
  {
      "partNumber": "3620725",
      "category": "Part"
  },
  {
      "partNumber": "3620725-01",
      "category": "Part"
  },
  {
      "partNumber": "4800730",
      "category": "Part"
  },
  {
      "partNumber": "4800765",
      "category": "Part"
  },
  {
      "partNumber": "4800774",
      "category": "Part"
  },
  {
      "partNumber": "4800777",
      "category": "Part"
  },
  {
      "partNumber": "4800778",
      "category": "Part"
  },
  {
      "partNumber": "4800779",
      "category": "Part"
  },
  {
      "partNumber": "4800785",
      "category": "Part"
  },
  {
      "partNumber": "4801701",
      "category": "Part"
  },
  {
      "partNumber": "4801702",
      "category": "Part"
  },
  {
      "partNumber": "4801703",
      "category": "Part"
  },
  {
      "partNumber": "4801720",
      "category": "Part"
  },
  {
      "partNumber": "4810712",
      "category": "Part"
  },
  {
      "partNumber": "4810730",
      "category": "Part"
  },
  {
      "partNumber": "481073002",
      "category": "Part"
  },
  {
      "partNumber": "4810735",
      "category": "Part"
  },
  {
      "partNumber": "6000253",
      "category": "Part"
  },
  {
      "partNumber": "6000269",
      "category": "Part"
  },
  {
      "partNumber": "6000316",
      "category": "Part"
  },
  {
      "partNumber": "8010017",
      "category": "Part"
  },
  {
      "partNumber": "8011038",
      "category": "Part"
  },
  {
      "partNumber": "8011062-100",
      "category": "Part"
  },
  {
      "partNumber": "8011062-48",
      "category": "Part"
  },
  {
      "partNumber": "8011123",
      "category": "Part"
  },
  {
      "partNumber": "8080154",
      "category": "Part"
  },
  {
      "partNumber": "8080155",
      "category": "Part"
  },
  {
      "partNumber": "8080156",
      "category": "Part"
  },
  {
      "partNumber": "8080157",
      "category": "Part"
  },
  {
      "partNumber": "8080182",
      "category": "Part"
  },
  {
      "partNumber": "808018201",
      "category": "Part"
  },
  {
      "partNumber": "C203110",
      "category": "Part"
  },
  {
      "partNumber": "C400020",
      "category": "Part"
  },
  {
      "partNumber": "CM00003",
      "category": "Part"
  },
  {
      "partNumber": "CM00006",
      "category": "Part"
  },
  {
      "partNumber": "CM00007",
      "category": "Part"
  },
  {
      "partNumber": "CM00011",
      "category": "Part"
  },
  {
      "partNumber": "CM00043",
      "category": "Part"
  },
  {
      "partNumber": "T000200",
      "category": "Part"
  },
  {
      "partNumber": "T000287",
      "category": "Part"
  },
  {
      "partNumber": "T000305",
      "category": "Part"
  },
  {
      "partNumber": "T000312",
      "category": "Part"
  },
  {
      "partNumber": "T000355",
      "category": "Part"
  },
  {
      "partNumber": "T000360",
      "category": "Part"
  },
  {
      "partNumber": "2400279",
      "category": "Part"
  },
  {
      "partNumber": "CM00004",
      "category": "Part"
  },
  {
      "partNumber": "T411110",
      "category": "Part"
  },
  {
      "partNumber": "T411111",
      "category": "Part"
  },
  {
      "partNumber": "T412000",
      "category": "Part"
  },
  {
      "partNumber": "T412001",
      "category": "Part"
  },
  {
      "partNumber": "T412014",
      "category": "Part"
  },
  {
      "partNumber": "T412025",
      "category": "Part"
  },
  {
      "partNumber": "T412026",
      "category": "Part"
  },
  {
      "partNumber": "T412065",
      "category": "Part"
  },
  {
      "partNumber": "T412066",
      "category": "Part"
  },
  {
      "partNumber": "T412070",
      "category": "Part"
  },
  {
      "partNumber": "T412071",
      "category": "Part"
  },
  {
      "partNumber": "3000060-1",
      "category": "Part"
  },
  {
      "partNumber": "3620320",
      "category": "Part"
  },
  {
      "partNumber": "3620802",
      "category": "Part"
  },
  {
      "partNumber": "4200015-1",
      "category": "Part"
  },
  {
      "partNumber": "4200015-2",
      "category": "Part"
  },
  {
      "partNumber": "8011100-11",
      "category": "Part"
  },
  {
      "partNumber": "8011100-22",
      "category": "Part"
  },
  {
      "partNumber": "8011100-24",
      "category": "Part"
  },
  {
      "partNumber": "8011100-54",
      "category": "Part"
  },
  {
      "partNumber": "8011100-55",
      "category": "Part"
  },
  {
      "partNumber": "8011035-21",
      "category": "Part"
  },
  {
      "partNumber": "2400089",
      "category": "Part"
  },
  {
      "partNumber": "2400120",
      "category": "Part"
  },
  {
      "partNumber": "2400121",
      "category": "Part"
  },
  {
      "partNumber": "2400122",
      "category": "Part"
  },
  {
      "partNumber": "2400156",
      "category": "Part"
  },
  {
      "partNumber": "2400160",
      "category": "Part"
  },
  {
      "partNumber": "3600159",
      "category": "Part"
  },
  {
      "partNumber": "3600160",
      "category": "Part"
  },
  {
      "partNumber": "4800360",
      "category": "Part"
  },
  {
      "partNumber": "8011101",
      "category": "Part"
  },
  {
      "partNumber": "8011102",
      "category": "Part"
  },
  {
      "partNumber": "8011103",
      "category": "Part"
  },
  {
      "partNumber": "HM00126",
      "category": "Part"
  },
  {
      "partNumber": "HM00128",
      "category": "Part"
  },
  {
      "partNumber": "HM00130",
      "category": "Part"
  },
  {
      "partNumber": "P260110",
      "category": "Part"
  },
  {
      "partNumber": "P260210",
      "category": "Part"
  },
  {
      "partNumber": "P330000",
      "category": "Part"
  },
  {
      "partNumber": "2050088",
      "category": "Part"
  },
  {
      "partNumber": "2050150B",
      "category": "Part"
  },
  {
      "partNumber": "2400256",
      "category": "Part"
  },
  {
      "partNumber": "2400257",
      "category": "Part"
  },
  {
      "partNumber": "2400258",
      "category": "Part"
  },
  {
      "partNumber": "T260006",
      "category": "Part"
  },
  {
      "partNumber": "T260007",
      "category": "Part"
  },
  {
      "partNumber": "T260008",
      "category": "Part"
  },
  {
      "partNumber": "P400000",
      "category": "Part"
  },
  {
      "partNumber": "P600200",
      "category": "Part"
  },
  {
      "partNumber": "P600210",
      "category": "Part"
  },
  {
      "partNumber": "P360001",
      "category": "Part"
  },
  {
      "partNumber": "P360005",
      "category": "Part"
  },
  {
      "partNumber": "P360006",
      "category": "Part"
  },
  {
      "partNumber": "P300010",
      "category": "Part"
  },
  {
      "partNumber": "P310009",
      "category": "Part"
  },
  {
      "partNumber": "P310010",
      "category": "Part"
  },
  {
      "partNumber": "P310020",
      "category": "Part"
  },
  {
      "partNumber": "P310024",
      "category": "Part"
  },
  {
      "partNumber": "P310027",
      "category": "Part"
  },
  {
      "partNumber": "P310035",
      "category": "Part"
  },
  {
      "partNumber": "P320003",
      "category": "Part"
  },
  {
      "partNumber": "P320009",
      "category": "Part"
  },
  {
      "partNumber": "P320023",
      "category": "Part"
  },
  {
      "partNumber": "P320048",
      "category": "Part"
  },
  {
      "partNumber": "2400081B",
      "category": "Part"
  },
  {
      "partNumber": "240062B",
      "category": "Part"
  },
  {
      "partNumber": "24-A300800B",
      "category": "Part"
  },
  {
      "partNumber": "2400187",
      "category": "Part"
  },
  {
      "partNumber": "2400190",
      "category": "Part"
  },
  {
      "partNumber": "2400193",
      "category": "Part"
  },
  {
      "partNumber": "2400200",
      "category": "Part"
  },
  {
      "partNumber": "2400201",
      "category": "Part"
  },
  {
      "partNumber": "2400202",
      "category": "Part"
  },
  {
      "partNumber": "2400204",
      "category": "Part"
  },
  {
      "partNumber": "2400213",
      "category": "Part"
  },
  {
      "partNumber": "2400214",
      "category": "Part"
  },
  {
      "partNumber": "2400215",
      "category": "Part"
  },
  {
      "partNumber": "2400218",
      "category": "Part"
  },
  {
      "partNumber": "2400221",
      "category": "Part"
  },
  {
      "partNumber": "135005350",
      "category": "Part"
  },
  {
      "partNumber": "2400377",
      "category": "Part"
  },
  {
      "partNumber": "2400460-02",
      "category": "Part"
  },
  {
      "partNumber": "3600117",
      "category": "Part"
  },
  {
      "partNumber": "3600133",
      "category": "Part"
  },
  {
      "partNumber": "480034601",
      "category": "Part"
  },
  {
      "partNumber": "480034606",
      "category": "Part"
  },
  {
      "partNumber": "4800370",
      "category": "Part"
  },
  {
      "partNumber": "360011406",
      "category": "Part"
  },
  {
      "partNumber": "3600141",
      "category": "Part"
  },
  {
      "partNumber": "3600232",
      "category": "Part"
  },
  {
      "partNumber": "P120110",
      "category": "Part"
  },
  {
      "partNumber": "P12011001",
      "category": "Part"
  },
  {
      "partNumber": "P12011002",
      "category": "Part"
  },
  {
      "partNumber": "P12011003",
      "category": "Part"
  },
  {
      "partNumber": "P12011004",
      "category": "Part"
  },
  {
      "partNumber": "P130062",
      "category": "Part"
  },
  {
      "partNumber": "P130073",
      "category": "Part"
  },
  {
      "partNumber": "4800784",
      "category": "Part"
  },
  {
      "partNumber": "4810723",
      "category": "Part"
  },
  {
      "partNumber": "8010006",
      "category": "Part"
  },
  {
      "partNumber": "HM00092",
      "category": "Part"
  },
  {
      "partNumber": "T720008",
      "category": "Part"
  },
  {
      "partNumber": "T720010",
      "category": "Part"
  },
  {
      "partNumber": "T720012",
      "category": "Part"
  },
  {
      "partNumber": "T720015",
      "category": "Part"
  },
  {
      "partNumber": "T720020",
      "category": "Part"
  },
  {
      "partNumber": "T720060",
      "category": "Part"
  },
  {
      "partNumber": "T720100",
      "category": "Part"
  },
  {
      "partNumber": "T720115",
      "category": "Part"
  },
  {
      "partNumber": "W000021",
      "category": "Part"
  },
  {
      "partNumber": "W000025",
      "category": "Part"
  },
  {
      "partNumber": "W000070",
      "category": "Part"
  },
  {
      "partNumber": "W000080",
      "category": "Part"
  },
  {
      "partNumber": "W000081",
      "category": "Part"
  },
  {
      "partNumber": "W000100",
      "category": "Part"
  },
  {
      "partNumber": "W030050",
      "category": "Part"
  },
  {
      "partNumber": "W030060",
      "category": "Part"
  },
  {
      "partNumber": "W030065",
      "category": "Part"
  },
  {
      "partNumber": "W030085",
      "category": "Part"
  },
  {
      "partNumber": "8080214",
      "category": "Part"
  },
  {
      "partNumber": "8080217",
      "category": "Part"
  },
  {
      "partNumber": "8080218",
      "category": "Part"
  },
  {
      "partNumber": "8080219",
      "category": "Part"
  },
  {
      "partNumber": "8080222",
      "category": "Part"
  },
  {
      "partNumber": "8080223",
      "category": "Part"
  },
  {
      "partNumber": "8080225",
      "category": "Part"
  },
  {
      "partNumber": "8080003",
      "category": "Part"
  },
  {
      "partNumber": "8080014",
      "category": "Part"
  },
  {
      "partNumber": "8080043",
      "category": "Part"
  },
  {
      "partNumber": "8080044",
      "category": "Part"
  },
  {
      "partNumber": "6000333-2",
      "category": "Part"
  },
  {
      "partNumber": "6000317",
      "category": "Part"
  },
  {
      "partNumber": "6000322",
      "category": "Part"
  },
  {
      "partNumber": "4811343",
      "category": "Part"
  },
  {
      "partNumber": "4811344",
      "category": "Part"
  },
  {
      "partNumber": "480030205",
      "category": "Part"
  },
  {
      "partNumber": "8080228",
      "category": "Part"
  },
  {
      "partNumber": "8080231",
      "category": "Part"
  },
  {
      "partNumber": "X200050",
      "category": "Part"
  },
  {
      "partNumber": "X200085",
      "category": "Part"
  },
  {
      "partNumber": "X200086",
      "category": "Part"
  },
  {
      "partNumber": "X200087",
      "category": "Part"
  },
  {
      "partNumber": "X200088",
      "category": "Part"
  },
  {
      "partNumber": "X200118",
      "category": "Part"
  },
  {
      "partNumber": "X200274",
      "category": "Part"
  },
  {
      "partNumber": "X200275",
      "category": "Part"
  },
  {
      "partNumber": "X200276",
      "category": "Part"
  },
  {
      "partNumber": "X200277",
      "category": "Part"
  },
  {
      "partNumber": "8080182-06",
      "category": "Part"
  },
  {
      "partNumber": "8080182-07",
      "category": "Part"
  },
  {
      "partNumber": "8080182-08",
      "category": "Part"
  },
  {
      "partNumber": "8098820",
      "category": "Part"
  },
  {
      "partNumber": "8098822",
      "category": "Part"
  },
  {
      "partNumber": "C501019-1",
      "category": "Part"
  },
  {
      "partNumber": "2400157",
      "category": "Part"
  },
  {
      "partNumber": "3600157",
      "category": "Part"
  },
  {
      "partNumber": "3600221",
      "category": "Part"
  },
  {
      "partNumber": "360034802",
      "category": "Part"
  },
  {
      "partNumber": "360037001",
      "category": "Part"
  },
  {
      "partNumber": "480072906",
      "category": "Part"
  },
  {
      "partNumber": "4800729-20",
      "category": "Part"
  },
  {
      "partNumber": "8080226",
      "category": "Part"
  },
  {
      "partNumber": "8080227",
      "category": "Part"
  },
  {
      "partNumber": "8080244",
      "category": "Part"
  },
  {
      "partNumber": "8080245",
      "category": "Part"
  },
  {
      "partNumber": "C501019-01",
      "category": "Part"
  },
  {
      "partNumber": "CM00002-01",
      "category": "Part"
  },
  {
      "partNumber": "HD30590-01",
      "category": "Part"
  },
  {
      "partNumber": "2400696",
      "category": "Part"
  },
  {
      "partNumber": "2400750",
      "category": "Part"
  },
  {
      "partNumber": "3620750",
      "category": "Part"
  },
  {
      "partNumber": "4810770",
      "category": "Part"
  },
  {
      "partNumber": "4810780",
      "category": "Part"
  },
  {
      "partNumber": "HD00750",
      "category": "Part"
  },
  {
      "partNumber": "8011059",
      "category": "Part"
  },
  {
      "partNumber": "2400325",
      "category": "Part"
  },
  {
      "partNumber": "2400326",
      "category": "Part"
  },
  {
      "partNumber": "2400327",
      "category": "Part"
  },
  {
      "partNumber": "2400343",
      "category": "Part"
  },
  {
      "partNumber": "2400345",
      "category": "Part"
  },
  {
      "partNumber": "2400119",
      "category": "Part"
  },
  {
      "partNumber": "2400131",
      "category": "Part"
  },
  {
      "partNumber": "240002301",
      "category": "Part"
  },
  {
      "partNumber": "2400083",
      "category": "Part"
  },
  {
      "partNumber": "2400084",
      "category": "Part"
  },
  {
      "partNumber": "2400324",
      "category": "Part"
  },
  {
      "partNumber": "2400280",
      "category": "Part"
  },
  {
      "partNumber": "2400299",
      "category": "Part"
  },
  {
      "partNumber": "2400303",
      "category": "Part"
  },
  {
      "partNumber": "2400501",
      "category": "Part"
  },
  {
      "partNumber": "2400630",
      "category": "Part"
  },
  {
      "partNumber": "2400631",
      "category": "Part"
  },
  {
      "partNumber": "2400479-23",
      "category": "Part"
  },
  {
      "partNumber": "2400479-24",
      "category": "Part"
  },
  {
      "partNumber": "2400651",
      "category": "Part"
  },
  {
      "partNumber": "480030310",
      "category": "Part"
  },
  {
      "partNumber": "480030311",
      "category": "Part"
  },
  {
      "partNumber": "4800803",
      "category": "Part"
  },
  {
      "partNumber": "4800808",
      "category": "Part"
  },
  {
      "partNumber": "4800810",
      "category": "Part"
  },
  {
      "partNumber": "4800817",
      "category": "Part"
  },
  {
      "partNumber": "4800828",
      "category": "Part"
  },
  {
      "partNumber": "4800838",
      "category": "Part"
  },
  {
      "partNumber": "4812000-03",
      "category": "Part"
  },
  {
      "partNumber": "6000351",
      "category": "Part"
  },
  {
      "partNumber": "3600116",
      "category": "Part"
  },
  {
      "partNumber": "3600131",
      "category": "Part"
  },
  {
      "partNumber": "4200041",
      "category": "Part"
  },
  {
      "partNumber": "4200042",
      "category": "Part"
  },
  {
      "partNumber": "4200047-1",
      "category": "Part"
  },
  {
      "partNumber": "480030321",
      "category": "Part"
  },
  {
      "partNumber": "4800362",
      "category": "Part"
  },
  {
      "partNumber": "4800363",
      "category": "Part"
  },
  {
      "partNumber": "4801715",
      "category": "Part"
  },
  {
      "partNumber": "C501982",
      "category": "Part"
  },
  {
      "partNumber": "CM00008-01",
      "category": "Part"
  },
  {
      "partNumber": "CM00367",
      "category": "Part"
  },
  {
      "partNumber": "CM00396",
      "category": "Part"
  },
  {
      "partNumber": "X000013",
      "category": "Part"
  },
  {
      "partNumber": "X000016",
      "category": "Part"
  },
  {
      "partNumber": "X000017",
      "category": "Part"
  },
  {
      "partNumber": "X000018",
      "category": "Part"
  },
  {
      "partNumber": "X000023",
      "category": "Part"
  },
  {
      "partNumber": "X000094",
      "category": "Part"
  },
  {
      "partNumber": "X000095",
      "category": "Part"
  },
  {
      "partNumber": "X000247",
      "category": "Part"
  },
  {
      "partNumber": "X000387",
      "category": "Part"
  },
  {
      "partNumber": "X000400",
      "category": "Part"
  },
  {
      "partNumber": "T840040",
      "category": "Part"
  },
  {
      "partNumber": "2020020",
      "category": "Part"
  },
  {
      "partNumber": "2050068",
      "category": "Part"
  },
  {
      "partNumber": "2400091",
      "category": "Part"
  },
  {
      "partNumber": "2400110",
      "category": "Part"
  },
  {
      "partNumber": "2400380",
      "category": "Part"
  },
  {
      "partNumber": "2400385",
      "category": "Part"
  },
  {
      "partNumber": "2400479-05",
      "category": "Part"
  },
  {
      "partNumber": "2400479-17",
      "category": "Part"
  },
  {
      "partNumber": "2400479-21",
      "category": "Part"
  },
  {
      "partNumber": "2400584",
      "category": "Part"
  },
  {
      "partNumber": "3600086",
      "category": "Part"
  },
  {
      "partNumber": "3600087",
      "category": "Part"
  },
  {
      "partNumber": "3600089",
      "category": "Part"
  },
  {
      "partNumber": "2400623",
      "category": "Part"
  },
  {
      "partNumber": "2400624",
      "category": "Part"
  },
  {
      "partNumber": "2400628",
      "category": "Part"
  },
  {
      "partNumber": "3620144",
      "category": "Part"
  },
  {
      "partNumber": "3620144-1",
      "category": "Part"
  },
  {
      "partNumber": "4800277",
      "category": "Part"
  },
  {
      "partNumber": "4800287",
      "category": "Part"
  },
  {
      "partNumber": "4800290",
      "category": "Part"
  },
  {
      "partNumber": "4800299",
      "category": "Part"
  },
  {
      "partNumber": "4800735",
      "category": "Part"
  },
  {
      "partNumber": "4800758",
      "category": "Part"
  },
  {
      "partNumber": "4800759",
      "category": "Part"
  },
  {
      "partNumber": "4800761",
      "category": "Part"
  },
  {
      "partNumber": "4800762",
      "category": "Part"
  },
  {
      "partNumber": "U500018",
      "category": "Part"
  },
  {
      "partNumber": "U500020",
      "category": "Part"
  },
  {
      "partNumber": "U500090",
      "category": "Part"
  },
  {
      "partNumber": "U600040",
      "category": "Part"
  },
  {
      "partNumber": "U600050",
      "category": "Part"
  },
  {
      "partNumber": "U600060",
      "category": "Part"
  },
  {
      "partNumber": "U610050",
      "category": "Part"
  },
  {
      "partNumber": "U370050",
      "category": "Part"
  },
  {
      "partNumber": "U300025",
      "category": "Part"
  },
  {
      "partNumber": "U340045",
      "category": "Part"
  },
  {
      "partNumber": "U340065",
      "category": "Part"
  },
  {
      "partNumber": "T840080",
      "category": "Part"
  },
  {
      "partNumber": "T410450",
      "category": "Part"
  },
  {
      "partNumber": "J700012",
      "category": "Part"
  },
  {
      "partNumber": "J700013",
      "category": "Part"
  },
  {
      "partNumber": "J700014",
      "category": "Part"
  },
  {
      "partNumber": "J700020",
      "category": "Part"
  },
  {
      "partNumber": "HD30590-02",
      "category": "Part"
  },
  {
      "partNumber": "HD10052",
      "category": "Part"
  },
  {
      "partNumber": "HD10097",
      "category": "Part"
  },
  {
      "partNumber": "HD10129",
      "category": "Part"
  },
  {
      "partNumber": "HD11012",
      "category": "Part"
  },
  {
      "partNumber": "CM00122",
      "category": "Part"
  },
  {
      "partNumber": "CM00152",
      "category": "Part"
  },
  {
      "partNumber": "CM00218",
      "category": "Part"
  },
  {
      "partNumber": "CM00264",
      "category": "Part"
  },
  {
      "partNumber": "CM00265",
      "category": "Part"
  },
  {
      "partNumber": "CM00300",
      "category": "Part"
  },
  {
      "partNumber": "C502783",
      "category": "Part"
  },
  {
      "partNumber": "CM00001",
      "category": "Part"
  },
  {
      "partNumber": "CM00005",
      "category": "Part"
  },
  {
      "partNumber": "8080334",
      "category": "Part"
  },
  {
      "partNumber": "8080072",
      "category": "Part"
  },
  {
      "partNumber": "6000017",
      "category": "Part"
  },
  {
      "partNumber": "4810334",
      "category": "Part"
  },
  {
      "partNumber": "4800767",
      "category": "Part"
  },
  {
      "partNumber": "4800776",
      "category": "Part"
  },
  {
      "partNumber": "4800723",
      "category": "Part"
  },
  {
      "partNumber": "4800348",
      "category": "Part"
  },
  {
      "partNumber": "4800352-1",
      "category": "Part"
  },
  {
      "partNumber": "4800345",
      "category": "Part"
  },
  {
      "partNumber": "3620306",
      "category": "Part"
  },
  {
      "partNumber": "3620306-1",
      "category": "Part"
  },
  {
      "partNumber": "3620343",
      "category": "Part"
  },
  {
      "partNumber": "3620377",
      "category": "Part"
  },
  {
      "partNumber": "2400706",
      "category": "Part"
  },
  {
      "partNumber": "2400650",
      "category": "Part"
  },
  {
      "partNumber": "2400679",
      "category": "Part"
  },
  {
      "partNumber": "6000008-1",
      "category": "Part"
  },
  {
      "partNumber": "480030301",
      "category": "Part"
  },
  {
      "partNumber": "480030302",
      "category": "Part"
  },
  {
      "partNumber": "480033801",
      "category": "Part"
  },
  {
      "partNumber": "480033802",
      "category": "Part"
  },
  {
      "partNumber": "4801708",
      "category": "Part"
  },
  {
      "partNumber": "4812000-01",
      "category": "Part"
  },
  {
      "partNumber": "4812000-28",
      "category": "Part"
  },
  {
      "partNumber": "8011039",
      "category": "Part"
  },
  {
      "partNumber": "8011058",
      "category": "Part"
  },
  {
      "partNumber": "6000101",
      "category": "Part"
  },
  {
      "partNumber": "8080189",
      "category": "Part"
  },
  {
      "partNumber": "8080232",
      "category": "Part"
  },
  {
      "partNumber": "8080251",
      "category": "Part"
  },
  {
      "partNumber": "8080252",
      "category": "Part"
  },
  {
      "partNumber": "8080253",
      "category": "Part"
  },
  {
      "partNumber": "8080254",
      "category": "Part"
  },
  {
      "partNumber": "8080256",
      "category": "Part"
  },
  {
      "partNumber": "8080350",
      "category": "Part"
  },
  {
      "partNumber": "8080351-1",
      "category": "Part"
  },
  {
      "partNumber": "8080352",
      "category": "Part"
  },
  {
      "partNumber": "HD41050",
      "category": "Part"
  },
  {
      "partNumber": "2400491",
      "category": "Part"
  },
  {
      "partNumber": "2400597",
      "category": "Part"
  },
  {
      "partNumber": "2400599",
      "category": "Part"
  },
  {
      "partNumber": "2400601",
      "category": "Part"
  },
  {
      "partNumber": "1-1208x2.25",
      "category": "Steering Head"
  },
  {
      "partNumber": "1-1612x2.25",
      "category": "Steering Head"
  },
  {
      "partNumber": "1-1812x2.25",
      "category": "Steering Head"
  },
  {
      "partNumber": "1-2016x2.25",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-2416x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-3018x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-3624x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-4230x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-4836x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-5442x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "2-6048x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "3-2420x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "3-3024x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "3-3630x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-54TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-60TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-66TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-72TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-84TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-96TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "5-CS600",
      "category": "Steering Station"
  },
  {
      "partNumber": "5-CS600ES",
      "category": "Steering Station"
  },
  {
      "partNumber": "6-ES",
      "category": "Steering System"
  },
  {
      "partNumber": "6-ES ADV",
      "category": "Steering System"
  },
  {
      "partNumber": "6-ESPO",
      "category": "Steering System"
  },
  {
      "partNumber": "16-08COMBOx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-08COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-10COMBOx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-10COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-12COMBOx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-12COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-12COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-14COMBOx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-14COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-14COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-16COMBOx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-16COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-16COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-18COMBOx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-18COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-18COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-20COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-20COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-24COMBOx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-24COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-24COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-30COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-30COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-36COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-36COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-42COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-42COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-48COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-48COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "16-1836W/Cx3/4",
      "category": "Part"
  },
  {
      "partNumber": "17-12ROCKx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-12ROCKx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-12ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-14ROCKx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-14ROKx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-14ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-16ROCKx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-16ROCKx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-16ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-18ROCKx1.625",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-18ROCKx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-18ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-20ROCKx2.25",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-20ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-24ROCKx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-24ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-30ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-30ROCKx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-36ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-36ROCKx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-42ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-42ROCKx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-48ROCKx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-48ROCKx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-24XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-30XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-36XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-42XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-48XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-60XTRMx5",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-2016COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-2416COMBOx3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-2416COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-3018COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-3624COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-4230COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-4836COMBOx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-2416XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-3018XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-3624XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-4230XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-4836XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "18-6048XTRMx5",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-2420XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-3024XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-3630XTRMx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "24-AP2412",
      "category": "Part"
  },
  {
      "partNumber": "24-AP2416",
      "category": "Part"
  },
  {
      "partNumber": "24-AP2418",
      "category": "Part"
  },
  {
      "partNumber": "24-AP2420",
      "category": "Part"
  },
  {
      "partNumber": "80-0810x1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-0804x1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-0805x1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-0810X2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-0804x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-0805X2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1205X1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-1210x1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-1205x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1210x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1605X1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-1610X1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-1605X2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1610x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1605X3",
      "category": "Auger"
  },
  {
      "partNumber": "80-1610X3",
      "category": "Auger"
  },
  {
      "partNumber": "80-1805X1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-1810X1.625",
      "category": "Auger"
  },
  {
      "partNumber": "80-1805X2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1810x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-1805X3",
      "category": "Auger"
  },
  {
      "partNumber": "80-1810X3",
      "category": "Auger"
  },
  {
      "partNumber": "80-2005X2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-2010x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-2010X3",
      "category": "Auger"
  },
  {
      "partNumber": "80-2010X4",
      "category": "Auger"
  },
  {
      "partNumber": "80-2410x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "80-2410x3",
      "category": "Auger"
  },
  {
      "partNumber": "80-2410X4",
      "category": "Auger"
  },
  {
      "partNumber": "80-4810x4",
      "category": "Auger"
  },
  {
      "partNumber": "83-1-120830x2.25",
      "category": "Auger"
  },
  {
      "partNumber": "83-2-241660x4",
      "category": "Auger"
  },
  {
      "partNumber": "83-2-301872x4",
      "category": "Auger"
  },
  {
      "partNumber": "83-3-242072x4",
      "category": "Auger"
  },
  {
      "partNumber": "19-2420ROLLx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-3024ROLLx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-3630ROLLx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-3024DISCx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "19-3630DISCx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "test2",
      "category": "Auger"
  },
  {
      "partNumber": "tester",
      "category": "Part"
  },
  {
      "partNumber": "3620510B",
      "category": "Part"
  },
  {
      "partNumber": "10-8080070",
      "category": "part"
  },
  {
      "partNumber": "10-8080232",
      "category": "part"
  },
  {
      "partNumber": "10-8080337",
      "category": "part"
  },
  {
      "partNumber": "10-8080339",
      "category": "part"
  },
  {
      "partNumber": "U000900",
      "category": "part"
  },
  {
      "partNumber": "U100120",
      "category": "part"
  },
  {
      "partNumber": "U200100",
      "category": "part"
  },
  {
      "partNumber": "U210100",
      "category": "part"
  },
  {
      "partNumber": "S600570",
      "category": "Part"
  },
  {
      "partNumber": "S600580",
      "category": "Part"
  },
  {
      "partNumber": "C501127",
      "category": "Part"
  },
  {
      "partNumber": "8-C502318",
      "category": "Part"
  },
  {
      "partNumber": "3620601",
      "category": "Part"
  },
  {
      "partNumber": "3620615 ",
      "category": "Part"
  },
  {
      "partNumber": "3620611B  ",
      "category": "Part"
  },
  {
      "partNumber": "S923506",
      "category": "Part"
  },
  {
      "partNumber": "U320120",
      "category": "Part"
  },
  {
      "partNumber": "P340000",
      "category": "Part"
  },
  {
      "partNumber": "83-C501655",
      "category": "Part"
  },
  {
      "partNumber": "15-S600800",
      "category": "Part"
  },
  {
      "partNumber": "83-C502679",
      "category": "Part"
  },
  {
      "partNumber": "83-C502363",
      "category": "Part"
  },
  {
      "partNumber": "15-S600801",
      "category": "Part"
  },
  {
      "partNumber": "15-BTE1001",
      "category": "Part"
  },
  {
      "partNumber": "15-BTE1002",
      "category": "Part"
  },
  {
      "partNumber": "8080042",
      "category": "Part"
  },
  {
      "partNumber": "A300120",
      "category": "Part"
  },
  {
      "partNumber": "A300125",
      "category": "Part"
  },
  {
      "partNumber": "BTE1003",
      "category": "Part"
  },
  {
      "partNumber": "3-BTE1201",
      "category": "Part"
  },
  {
      "partNumber": "3-BTE1202",
      "category": "Part"
  },
  {
      "partNumber": "36-BTE1300",
      "category": "Part"
  },
  {
      "partNumber": "S600765",
      "category": "Part"
  },
  {
      "partNumber": "6BTE1004",
      "category": "Part"
  },
  {
      "partNumber": "6BTE1005",
      "category": "Part"
  },
  {
      "partNumber": "36BTE1301",
      "category": "Part"
  },
  {
      "partNumber": "4200011B",
      "category": "Part"
  },
  {
      "partNumber": "2400008B",
      "category": "Part"
  },
  {
      "partNumber": "YDP420E",
      "category": "Part"
  },
  {
      "partNumber": "YN14E110",
      "category": "Part"
  },
  {
      "partNumber": "YDP314C",
      "category": "Part"
  },
  {
      "partNumber": "C502270",
      "category": "Part"
  },
  {
      "partNumber": "C502057",
      "category": "Part"
  },
  {
      "partNumber": "T402164",
      "category": "Part"
  },
  {
      "partNumber": "C501011",
      "category": "Part"
  },
  {
      "partNumber": "40051080",
      "category": "Part"
  },
  {
      "partNumber": "40051081",
      "category": "Part"
  },
  {
      "partNumber": "A301590",
      "category": "Part"
  },
  {
      "partNumber": "R800560",
      "category": "Part"
  },
  {
      "partNumber": "8BTE1008",
      "category": "Part"
  },
  {
      "partNumber": "8080066",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1190",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1191",
      "category": "Part"
  },
  {
      "partNumber": "BTE1192",
      "category": "Part"
  },
  {
      "partNumber": "18-2416COMBO-WC-1A",
      "category": "Part"
  },
  {
      "partNumber": "S600110",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1193",
      "category": "Part"
  },
  {
      "partNumber": "S600130",
      "category": "Part"
  },
  {
      "partNumber": "S600100",
      "category": "Part"
  },
  {
      "partNumber": "X000020",
      "category": "Part"
  },
  {
      "partNumber": "S600320",
      "category": "Part"
  },
  {
      "partNumber": "A200100",
      "category": "Part"
  },
  {
      "partNumber": "A200120",
      "category": "Part"
  },
  {
      "partNumber": "R800540",
      "category": "Part"
  },
  {
      "partNumber": "2090140",
      "category": "Part"
  },
  {
      "partNumber": "2090150",
      "category": "Part"
  },
  {
      "partNumber": "tester9",
      "category": "Part"
  },
  {
      "partNumber": "N500262-1",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500320-1",
      "category": "Raw Material"
  },
  {
      "partNumber": "H0001",
      "category": "Part"
  },
  {
      "partNumber": "H0002",
      "category": "Part"
  },
  {
      "partNumber": "BTE1010",
      "category": "Part"
  },
  {
      "partNumber": "Y8Y3120",
      "category": "Part"
  },
  {
      "partNumber": "X000300",
      "category": "Part"
  },
  {
      "partNumber": "S170CQ2WC",
      "category": "Part"
  },
  {
      "partNumber": "S130DQWC",
      "category": "Part"
  },
  {
      "partNumber": "S170RQ2WC",
      "category": "Part"
  },
  {
      "partNumber": "H-5000",
      "category": "Part"
  },
  {
      "partNumber": "H5001",
      "category": "Part"
  },
  {
      "partNumber": "H5000",
      "category": "Part"
  },
  {
      "partNumber": "2400266B",
      "category": "Part"
  },
  {
      "partNumber": "5BTE1102",
      "category": "Part"
  },
  {
      "partNumber": "5BTE1104",
      "category": "Part"
  },
  {
      "partNumber": "5BTE1110",
      "category": "Part"
  },
  {
      "partNumber": "5BTE1114",
      "category": "Part"
  },
  {
      "partNumber": "16-1201",
      "category": "Part"
  },
  {
      "partNumber": "16-1202",
      "category": "Part"
  },
  {
      "partNumber": "16-1601",
      "category": "Part"
  },
  {
      "partNumber": "16-1602",
      "category": "Part"
  },
  {
      "partNumber": "N500320-01",
      "category": "Raw Material"
  },
  {
      "partNumber": "H1001",
      "category": "Part"
  },
  {
      "partNumber": "H1000",
      "category": "Part"
  },
  {
      "partNumber": "H1002",
      "category": "Part"
  },
  {
      "partNumber": "C501902",
      "category": "Part"
  },
  {
      "partNumber": "C501903",
      "category": "Part"
  },
  {
      "partNumber": "C501904",
      "category": "Part"
  },
  {
      "partNumber": "C501905",
      "category": "Part"
  },
  {
      "partNumber": "C501975",
      "category": "Part"
  },
  {
      "partNumber": "C501906",
      "category": "Part"
  },
  {
      "partNumber": "C501957",
      "category": "Part"
  },
  {
      "partNumber": "C501953",
      "category": "Part"
  },
  {
      "partNumber": "C501954",
      "category": "Part"
  },
  {
      "partNumber": "C501907",
      "category": "Part"
  },
  {
      "partNumber": "C501652",
      "category": "Part"
  },
  {
      "partNumber": "C501874",
      "category": "Part"
  },
  {
      "partNumber": "C501873",
      "category": "Part"
  },
  {
      "partNumber": "C501820",
      "category": "Part"
  },
  {
      "partNumber": "C501835",
      "category": "Part"
  },
  {
      "partNumber": "C501836",
      "category": "Part"
  },
  {
      "partNumber": "C801836",
      "category": "Part"
  },
  {
      "partNumber": "C501832",
      "category": "Part"
  },
  {
      "partNumber": "C501861",
      "category": "Part"
  },
  {
      "partNumber": "C501833",
      "category": "Part"
  },
  {
      "partNumber": "C201822",
      "category": "Part"
  },
  {
      "partNumber": "C501822",
      "category": "Part"
  },
  {
      "partNumber": "C801822",
      "category": "Part"
  },
  {
      "partNumber": "C501852",
      "category": "Part"
  },
  {
      "partNumber": "C501853",
      "category": "Part"
  },
  {
      "partNumber": "C501823",
      "category": "Part"
  },
  {
      "partNumber": "C501829",
      "category": "Part"
  },
  {
      "partNumber": "C501821",
      "category": "Part"
  },
  {
      "partNumber": "C501840",
      "category": "Part"
  },
  {
      "partNumber": "C501280",
      "category": "Part"
  },
  {
      "partNumber": "CM00380",
      "category": "Part"
  },
  {
      "partNumber": "C501800",
      "category": "Part"
  },
  {
      "partNumber": "C501478-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501544",
      "category": "Part"
  },
  {
      "partNumber": "C501291",
      "category": "Part"
  },
  {
      "partNumber": "C501551",
      "category": "Part"
  },
  {
      "partNumber": "C501374",
      "category": "Part"
  },
  {
      "partNumber": "C501353",
      "category": "Part"
  },
  {
      "partNumber": "C501495",
      "category": "Part"
  },
  {
      "partNumber": "C501782",
      "category": "Part"
  },
  {
      "partNumber": "C501689",
      "category": "Part"
  },
  {
      "partNumber": "C501795",
      "category": "Part"
  },
  {
      "partNumber": "C501387",
      "category": "Part"
  },
  {
      "partNumber": "C501447",
      "category": "Part"
  },
  {
      "partNumber": "C501277",
      "category": "Part"
  },
  {
      "partNumber": "C501483",
      "category": "Part"
  },
  {
      "partNumber": "C501944",
      "category": "Part"
  },
  {
      "partNumber": "C501246",
      "category": "Part"
  },
  {
      "partNumber": "C501617",
      "category": "Part"
  },
  {
      "partNumber": "C501322",
      "category": "Part"
  },
  {
      "partNumber": "C501171",
      "category": "Part"
  },
  {
      "partNumber": "C501856",
      "category": "Part"
  },
  {
      "partNumber": "C501608",
      "category": "Part"
  },
  {
      "partNumber": "C501952",
      "category": "Part"
  },
  {
      "partNumber": "C501882",
      "category": "Part"
  },
  {
      "partNumber": "CM00409",
      "category": "Part"
  },
  {
      "partNumber": "C501914",
      "category": "Part"
  },
  {
      "partNumber": "C501320",
      "category": "Part"
  },
  {
      "partNumber": "C501775",
      "category": "Part"
  },
  {
      "partNumber": "C501465",
      "category": "Part"
  },
  {
      "partNumber": "C501773",
      "category": "Part"
  },
  {
      "partNumber": "C501774",
      "category": "Part"
  },
  {
      "partNumber": "C501772",
      "category": "Part"
  },
  {
      "partNumber": "C501338",
      "category": "Part"
  },
  {
      "partNumber": "C501693",
      "category": "Part"
  },
  {
      "partNumber": "C501421",
      "category": "Part"
  },
  {
      "partNumber": "C501420",
      "category": "Part"
  },
  {
      "partNumber": "C501380",
      "category": "Part"
  },
  {
      "partNumber": "C501062",
      "category": "Part"
  },
  {
      "partNumber": "C501323",
      "category": "Part"
  },
  {
      "partNumber": "C501249",
      "category": "Part"
  },
  {
      "partNumber": "C501254",
      "category": "Part"
  },
  {
      "partNumber": "C501341",
      "category": "Part"
  },
  {
      "partNumber": "C501337",
      "category": "Part"
  },
  {
      "partNumber": "C501378",
      "category": "Part"
  },
  {
      "partNumber": "C501618",
      "category": "Part"
  },
  {
      "partNumber": "C501601",
      "category": "Part"
  },
  {
      "partNumber": "C501411",
      "category": "Part"
  },
  {
      "partNumber": "C501179",
      "category": "Part"
  },
  {
      "partNumber": "C501245",
      "category": "Part"
  },
  {
      "partNumber": "C501282",
      "category": "Part"
  },
  {
      "partNumber": "C501288",
      "category": "Part"
  },
  {
      "partNumber": "C501287",
      "category": "Part"
  },
  {
      "partNumber": "C501289",
      "category": "Part"
  },
  {
      "partNumber": "C501342",
      "category": "Part"
  },
  {
      "partNumber": "C501339",
      "category": "Part"
  },
  {
      "partNumber": "C501257",
      "category": "Part"
  },
  {
      "partNumber": "C501238",
      "category": "Part"
  },
  {
      "partNumber": "C501547",
      "category": "Part"
  },
  {
      "partNumber": "C501193",
      "category": "Part"
  },
  {
      "partNumber": "C501191",
      "category": "Part"
  },
  {
      "partNumber": "C501194",
      "category": "Part"
  },
  {
      "partNumber": "C501552",
      "category": "Part"
  },
  {
      "partNumber": "C501278",
      "category": "Part"
  },
  {
      "partNumber": "C501383",
      "category": "Part"
  },
  {
      "partNumber": "CM00261",
      "category": "Part"
  },
  {
      "partNumber": "C501605",
      "category": "Part"
  },
  {
      "partNumber": "C501414",
      "category": "Part"
  },
  {
      "partNumber": "C501880",
      "category": "Part"
  },
  {
      "partNumber": "C501239",
      "category": "Part"
  },
  {
      "partNumber": "C501242",
      "category": "Part"
  },
  {
      "partNumber": "C501702",
      "category": "Part"
  },
  {
      "partNumber": "C501703",
      "category": "Part"
  },
  {
      "partNumber": "CM00287",
      "category": "Part"
  },
  {
      "partNumber": "C501779",
      "category": "Part"
  },
  {
      "partNumber": "C501793",
      "category": "Part"
  },
  {
      "partNumber": "C501790",
      "category": "Part"
  },
  {
      "partNumber": "C501789",
      "category": "Part"
  },
  {
      "partNumber": "C501616",
      "category": "Part"
  },
  {
      "partNumber": "CM00061",
      "category": "Part"
  },
  {
      "partNumber": "C501792",
      "category": "Part"
  },
  {
      "partNumber": "C501791",
      "category": "Part"
  },
  {
      "partNumber": "C501023",
      "category": "Part"
  },
  {
      "partNumber": "C501424",
      "category": "Part"
  },
  {
      "partNumber": "C501423",
      "category": "Part"
  },
  {
      "partNumber": "C501422",
      "category": "Part"
  },
  {
      "partNumber": "C501371",
      "category": "Part"
  },
  {
      "partNumber": "C501407",
      "category": "Part"
  },
  {
      "partNumber": "C501332",
      "category": "Part"
  },
  {
      "partNumber": "C501330",
      "category": "Part"
  },
  {
      "partNumber": "C501264",
      "category": "Part"
  },
  {
      "partNumber": "C501492",
      "category": "Part"
  },
  {
      "partNumber": "C501489",
      "category": "Part"
  },
  {
      "partNumber": "C501491",
      "category": "Part"
  },
  {
      "partNumber": "C501490",
      "category": "Part"
  },
  {
      "partNumber": "C501328",
      "category": "Part"
  },
  {
      "partNumber": "C501329",
      "category": "Part"
  },
  {
      "partNumber": "C501493",
      "category": "Part"
  },
  {
      "partNumber": "C501336",
      "category": "Part"
  },
  {
      "partNumber": "C501266",
      "category": "Part"
  },
  {
      "partNumber": "C501481",
      "category": "Part"
  },
  {
      "partNumber": "C501482",
      "category": "Part"
  },
  {
      "partNumber": "C501331",
      "category": "Part"
  },
  {
      "partNumber": "C501553",
      "category": "Part"
  },
  {
      "partNumber": "C501958",
      "category": "Part"
  },
  {
      "partNumber": "C501417",
      "category": "Part"
  },
  {
      "partNumber": "C501273",
      "category": "Part"
  },
  {
      "partNumber": "C501345",
      "category": "Part"
  },
  {
      "partNumber": "C501416",
      "category": "Part"
  },
  {
      "partNumber": "C501346",
      "category": "Part"
  },
  {
      "partNumber": "C501348",
      "category": "Part"
  },
  {
      "partNumber": "C501347",
      "category": "Part"
  },
  {
      "partNumber": "C501185",
      "category": "Part"
  },
  {
      "partNumber": "C501188",
      "category": "Part"
  },
  {
      "partNumber": "C501183",
      "category": "Part"
  },
  {
      "partNumber": "C501182",
      "category": "Part"
  },
  {
      "partNumber": "C501186",
      "category": "Part"
  },
  {
      "partNumber": "C501376",
      "category": "Part"
  },
  {
      "partNumber": "C501443",
      "category": "Part"
  },
  {
      "partNumber": "C501696",
      "category": "Part"
  },
  {
      "partNumber": "C501262",
      "category": "Part"
  },
  {
      "partNumber": "C501410",
      "category": "Part"
  },
  {
      "partNumber": "C501261",
      "category": "Part"
  },
  {
      "partNumber": "C501333",
      "category": "Part"
  },
  {
      "partNumber": "C501334",
      "category": "Part"
  },
  {
      "partNumber": "C501263",
      "category": "Part"
  },
  {
      "partNumber": "C501253",
      "category": "Part"
  },
  {
      "partNumber": "C501395",
      "category": "Part"
  },
  {
      "partNumber": "C501379",
      "category": "Part"
  },
  {
      "partNumber": "C501279",
      "category": "Part"
  },
  {
      "partNumber": "C501355",
      "category": "Part"
  },
  {
      "partNumber": "C501270",
      "category": "Part"
  },
  {
      "partNumber": "C501870",
      "category": "Part"
  },
  {
      "partNumber": "C501550",
      "category": "Part"
  },
  {
      "partNumber": "C501200",
      "category": "Part"
  },
  {
      "partNumber": "C501375",
      "category": "Part"
  },
  {
      "partNumber": "C501382",
      "category": "Part"
  },
  {
      "partNumber": "C501285",
      "category": "Part"
  },
  {
      "partNumber": "C501286",
      "category": "Part"
  },
  {
      "partNumber": "C501761",
      "category": "Part"
  },
  {
      "partNumber": "CM00260",
      "category": "Part"
  },
  {
      "partNumber": "C501412",
      "category": "Part"
  },
  {
      "partNumber": "C501281",
      "category": "Part"
  },
  {
      "partNumber": "C501613",
      "category": "Part"
  },
  {
      "partNumber": "C501240",
      "category": "Part"
  },
  {
      "partNumber": "C501344",
      "category": "Part"
  },
  {
      "partNumber": "C501247",
      "category": "Part"
  },
  {
      "partNumber": "C501413",
      "category": "Part"
  },
  {
      "partNumber": "C501415",
      "category": "Part"
  },
  {
      "partNumber": "C501377",
      "category": "Part"
  },
  {
      "partNumber": "C501519",
      "category": "Part"
  },
  {
      "partNumber": "C501704",
      "category": "Part"
  },
  {
      "partNumber": "C501729",
      "category": "Part"
  },
  {
      "partNumber": "C501418",
      "category": "Part"
  },
  {
      "partNumber": "C501255",
      "category": "Part"
  },
  {
      "partNumber": "C501274",
      "category": "Part"
  },
  {
      "partNumber": "C501343",
      "category": "Part"
  },
  {
      "partNumber": "C500202",
      "category": "Part"
  },
  {
      "partNumber": "CM00327",
      "category": "Part"
  },
  {
      "partNumber": "C400151",
      "category": "Part"
  },
  {
      "partNumber": "C400150",
      "category": "Part"
  },
  {
      "partNumber": "C502255",
      "category": "Part"
  },
  {
      "partNumber": "C502778",
      "category": "Part"
  },
  {
      "partNumber": "C502779",
      "category": "Part"
  },
  {
      "partNumber": "C502784",
      "category": "Part"
  },
  {
      "partNumber": "C502785",
      "category": "Part"
  },
  {
      "partNumber": "C502315",
      "category": "Part"
  },
  {
      "partNumber": "C502437",
      "category": "Part"
  },
  {
      "partNumber": "C502293",
      "category": "Part"
  },
  {
      "partNumber": "C502738",
      "category": "Part"
  },
  {
      "partNumber": "C502732",
      "category": "Part"
  },
  {
      "partNumber": "C502725",
      "category": "Part"
  },
  {
      "partNumber": "C502737",
      "category": "Part"
  },
  {
      "partNumber": "C502401",
      "category": "Part"
  },
  {
      "partNumber": "C502740",
      "category": "Part"
  },
  {
      "partNumber": "C502316",
      "category": "Part"
  },
  {
      "partNumber": "C502768",
      "category": "Part"
  },
  {
      "partNumber": "C502770",
      "category": "Part"
  },
  {
      "partNumber": "C502781",
      "category": "Part"
  },
  {
      "partNumber": "C502767",
      "category": "Part"
  },
  {
      "partNumber": "C502726",
      "category": "Part"
  },
  {
      "partNumber": "C502342",
      "category": "Part"
  },
  {
      "partNumber": "CM00114",
      "category": "Part"
  },
  {
      "partNumber": "C502332",
      "category": "Part"
  },
  {
      "partNumber": "C502341",
      "category": "Part"
  },
  {
      "partNumber": "C502421",
      "category": "Part"
  },
  {
      "partNumber": "C502420",
      "category": "Part"
  },
  {
      "partNumber": "C502358",
      "category": "Part"
  },
  {
      "partNumber": "C502419",
      "category": "Part"
  },
  {
      "partNumber": "C501955",
      "category": "Part"
  },
  {
      "partNumber": "C501933",
      "category": "Part"
  },
  {
      "partNumber": "C501221-1",
      "category": "Part"
  },
  {
      "partNumber": "C502555",
      "category": "Part"
  },
  {
      "partNumber": "C502685",
      "category": "Part"
  },
  {
      "partNumber": "C502556",
      "category": "Part"
  },
  {
      "partNumber": "C501419",
      "category": "Part"
  },
  {
      "partNumber": "C502418",
      "category": "Part"
  },
  {
      "partNumber": "C502536",
      "category": "Part"
  },
  {
      "partNumber": "C502417",
      "category": "Part"
  },
  {
      "partNumber": "C502416",
      "category": "Part"
  },
  {
      "partNumber": "C501399",
      "category": "Part"
  },
  {
      "partNumber": "C501872",
      "category": "Part"
  },
  {
      "partNumber": "C501838",
      "category": "Part"
  },
  {
      "partNumber": "C501854",
      "category": "Part"
  },
  {
      "partNumber": "C501828",
      "category": "Part"
  },
  {
      "partNumber": "CM00323",
      "category": "Part"
  },
  {
      "partNumber": "CM00246",
      "category": "Part"
  },
  {
      "partNumber": "C502376",
      "category": "Part"
  },
  {
      "partNumber": "C501730",
      "category": "Part"
  },
  {
      "partNumber": "C501731",
      "category": "Part"
  },
  {
      "partNumber": "C501661",
      "category": "Part"
  },
  {
      "partNumber": "C501685",
      "category": "Part"
  },
  {
      "partNumber": "C501966",
      "category": "Part"
  },
  {
      "partNumber": "C501655",
      "category": "Part"
  },
  {
      "partNumber": "C502498",
      "category": "Part"
  },
  {
      "partNumber": "C502400",
      "category": "Part"
  },
  {
      "partNumber": "C502363",
      "category": "Part"
  },
  {
      "partNumber": "C501384",
      "category": "Part"
  },
  {
      "partNumber": "C501276",
      "category": "Part"
  },
  {
      "partNumber": "C502019",
      "category": "Part"
  },
  {
      "partNumber": "C100019",
      "category": "Part"
  },
  {
      "partNumber": "C200019",
      "category": "Part"
  },
  {
      "partNumber": "C501515",
      "category": "Part"
  },
  {
      "partNumber": "C501305",
      "category": "Part"
  },
  {
      "partNumber": "C501306",
      "category": "Part"
  },
  {
      "partNumber": "C501307",
      "category": "Part"
  },
  {
      "partNumber": "C501308",
      "category": "Part"
  },
  {
      "partNumber": "C501292",
      "category": "Part"
  },
  {
      "partNumber": "C501292-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501309",
      "category": "Part"
  },
  {
      "partNumber": "C501309-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501310",
      "category": "Part"
  },
  {
      "partNumber": "C501310-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501311",
      "category": "Part"
  },
  {
      "partNumber": "C501311-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501528-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501516",
      "category": "Part"
  },
  {
      "partNumber": "C501477",
      "category": "Part"
  },
  {
      "partNumber": "C502217",
      "category": "Part"
  },
  {
      "partNumber": "C502510",
      "category": "Part"
  },
  {
      "partNumber": "C502700",
      "category": "Part"
  },
  {
      "partNumber": "C501719",
      "category": "Part"
  },
  {
      "partNumber": "C501819",
      "category": "Part"
  },
  {
      "partNumber": "C502157",
      "category": "Part"
  },
  {
      "partNumber": "CM00049",
      "category": "Part"
  },
  {
      "partNumber": "CM00048",
      "category": "Part"
  },
  {
      "partNumber": "C501604",
      "category": "Part"
  },
  {
      "partNumber": "C502239",
      "category": "Part"
  },
  {
      "partNumber": "C501467",
      "category": "Part"
  },
  {
      "partNumber": "C501780",
      "category": "Part"
  },
  {
      "partNumber": "C501763",
      "category": "Part"
  },
  {
      "partNumber": "C502076",
      "category": "Part"
  },
  {
      "partNumber": "C502249",
      "category": "Part"
  },
  {
      "partNumber": "C501484",
      "category": "Part"
  },
  {
      "partNumber": "CM00442",
      "category": "Part"
  },
  {
      "partNumber": "CM00250",
      "category": "Part"
  },
  {
      "partNumber": "C501632",
      "category": "Part"
  },
  {
      "partNumber": "C501631",
      "category": "Part"
  },
  {
      "partNumber": "C501322-PUR",
      "category": "Part"
  },
  {
      "partNumber": "C501778",
      "category": "Part"
  },
  {
      "partNumber": "C501801",
      "category": "Part"
  },
  {
      "partNumber": "C502170",
      "category": "Part"
  },
  {
      "partNumber": "C501603",
      "category": "Part"
  },
  {
      "partNumber": "C501401",
      "category": "Part"
  },
  {
      "partNumber": "C501235",
      "category": "Part"
  },
  {
      "partNumber": "C501701",
      "category": "Part"
  },
  {
      "partNumber": "C501846",
      "category": "Part"
  },
  {
      "partNumber": "C501867",
      "category": "Part"
  },
  {
      "partNumber": "C501847",
      "category": "Part"
  },
  {
      "partNumber": "C501676",
      "category": "Part"
  },
  {
      "partNumber": "C501671",
      "category": "Part"
  },
  {
      "partNumber": "C501681",
      "category": "Part"
  },
  {
      "partNumber": "C501926",
      "category": "Part"
  },
  {
      "partNumber": "C501697",
      "category": "Part"
  },
  {
      "partNumber": "C501878",
      "category": "Part"
  },
  {
      "partNumber": "C502516",
      "category": "Part"
  },
  {
      "partNumber": "CM00135",
      "category": "Part"
  },
  {
      "partNumber": "C501510",
      "category": "Part"
  },
  {
      "partNumber": "C501607",
      "category": "Part"
  },
  {
      "partNumber": "C501809",
      "category": "Part"
  },
  {
      "partNumber": "C501808",
      "category": "Part"
  },
  {
      "partNumber": "C502699",
      "category": "Part"
  },
  {
      "partNumber": "C502693",
      "category": "Part"
  },
  {
      "partNumber": "C501806",
      "category": "Part"
  },
  {
      "partNumber": "C100095",
      "category": "Part"
  },
  {
      "partNumber": "C500804",
      "category": "Part"
  },
  {
      "partNumber": "C500802",
      "category": "Part"
  },
  {
      "partNumber": "C500803",
      "category": "Part"
  },
  {
      "partNumber": "C500811",
      "category": "Part"
  },
  {
      "partNumber": "CM00041-01",
      "category": "Part"
  },
  {
      "partNumber": "C500806",
      "category": "Part"
  },
  {
      "partNumber": "C500810",
      "category": "Part"
  },
  {
      "partNumber": "C500800",
      "category": "Part"
  },
  {
      "partNumber": "C501008-35",
      "category": "Part"
  },
  {
      "partNumber": "C502486",
      "category": "Part"
  },
  {
      "partNumber": "C501722",
      "category": "Part"
  },
  {
      "partNumber": "C502522",
      "category": "Part"
  },
  {
      "partNumber": "C502527",
      "category": "Part"
  },
  {
      "partNumber": "C502351",
      "category": "Part"
  },
  {
      "partNumber": "C501781",
      "category": "Part"
  },
  {
      "partNumber": "C501776",
      "category": "Part"
  },
  {
      "partNumber": "C502114",
      "category": "Part"
  },
  {
      "partNumber": "C502352",
      "category": "Part"
  },
  {
      "partNumber": "C502482",
      "category": "Part"
  },
  {
      "partNumber": "C502203",
      "category": "Part"
  },
  {
      "partNumber": "C502480",
      "category": "Part"
  },
  {
      "partNumber": "C502481",
      "category": "Part"
  },
  {
      "partNumber": "C501545",
      "category": "Part"
  },
  {
      "partNumber": "C501546",
      "category": "Part"
  },
  {
      "partNumber": "C501543",
      "category": "Part"
  },
  {
      "partNumber": "C502260FB",
      "category": "Part"
  },
  {
      "partNumber": "C502141",
      "category": "Part"
  },
  {
      "partNumber": "C502399",
      "category": "Part"
  },
  {
      "partNumber": "C501686",
      "category": "Part"
  },
  {
      "partNumber": "C501762",
      "category": "Part"
  },
  {
      "partNumber": "C501237",
      "category": "Part"
  },
  {
      "partNumber": "C501340",
      "category": "Part"
  },
  {
      "partNumber": "C502337",
      "category": "Part"
  },
  {
      "partNumber": "C501250",
      "category": "Part"
  },
  {
      "partNumber": "C501244",
      "category": "Part"
  },
  {
      "partNumber": "C502153",
      "category": "Part"
  },
  {
      "partNumber": "C502495",
      "category": "Part"
  },
  {
      "partNumber": "C502138",
      "category": "Part"
  },
  {
      "partNumber": "C502187",
      "category": "Part"
  },
  {
      "partNumber": "C502137",
      "category": "Part"
  },
  {
      "partNumber": "CM00259",
      "category": "Part"
  },
  {
      "partNumber": "CM00216",
      "category": "Part"
  },
  {
      "partNumber": "C501373",
      "category": "Part"
  },
  {
      "partNumber": "C500805",
      "category": "Part"
  },
  {
      "partNumber": "C502530",
      "category": "Part"
  },
  {
      "partNumber": "C502189",
      "category": "Part"
  },
  {
      "partNumber": "C500004",
      "category": "Part"
  },
  {
      "partNumber": "C501798",
      "category": "Part"
  },
  {
      "partNumber": "C500110",
      "category": "Part"
  },
  {
      "partNumber": "C500112",
      "category": "Part"
  },
  {
      "partNumber": "C500114",
      "category": "Part"
  },
  {
      "partNumber": "C500116",
      "category": "Part"
  },
  {
      "partNumber": "C500118",
      "category": "Part"
  },
  {
      "partNumber": "C500120",
      "category": "Part"
  },
  {
      "partNumber": "C500124",
      "category": "Part"
  },
  {
      "partNumber": "C500130",
      "category": "Part"
  },
  {
      "partNumber": "C500136",
      "category": "Part"
  },
  {
      "partNumber": "C500142",
      "category": "Part"
  },
  {
      "partNumber": "C500143",
      "category": "Part"
  },
  {
      "partNumber": "C500106",
      "category": "Part"
  },
  {
      "partNumber": "C500108",
      "category": "Part"
  },
  {
      "partNumber": "C501391-018.00",
      "category": "Part"
  },
  {
      "partNumber": "C501502",
      "category": "Part"
  },
  {
      "partNumber": "C502381",
      "category": "Part"
  },
  {
      "partNumber": "C502380",
      "category": "Part"
  },
  {
      "partNumber": "C502383",
      "category": "Part"
  },
  {
      "partNumber": "C501487",
      "category": "Part"
  },
  {
      "partNumber": "C501486",
      "category": "Part"
  },
  {
      "partNumber": "C501485",
      "category": "Part"
  },
  {
      "partNumber": "C502854",
      "category": "Part"
  },
  {
      "partNumber": "C502744",
      "category": "Part"
  },
  {
      "partNumber": "C502855",
      "category": "Part"
  },
  {
      "partNumber": "C502743",
      "category": "Part"
  },
  {
      "partNumber": "C502853",
      "category": "Part"
  },
  {
      "partNumber": "C501991",
      "category": "Part"
  },
  {
      "partNumber": "C501993",
      "category": "Part"
  },
  {
      "partNumber": "C502300",
      "category": "Part"
  },
  {
      "partNumber": "C502301",
      "category": "Part"
  },
  {
      "partNumber": "C501996",
      "category": "Part"
  },
  {
      "partNumber": "C501997",
      "category": "Part"
  },
  {
      "partNumber": "C502302",
      "category": "Part"
  },
  {
      "partNumber": "C501989",
      "category": "Part"
  },
  {
      "partNumber": "C501879",
      "category": "Part"
  },
  {
      "partNumber": "C501859",
      "category": "Part"
  },
  {
      "partNumber": "C501370",
      "category": "Part"
  },
  {
      "partNumber": "C501248",
      "category": "Part"
  },
  {
      "partNumber": "C502692",
      "category": "Part"
  },
  {
      "partNumber": "C502691",
      "category": "Part"
  },
  {
      "partNumber": "C501283",
      "category": "Part"
  },
  {
      "partNumber": "C502041",
      "category": "Part"
  },
  {
      "partNumber": "C502683",
      "category": "Part"
  },
  {
      "partNumber": "CM00175",
      "category": "Part"
  },
  {
      "partNumber": "CM00174",
      "category": "Part"
  },
  {
      "partNumber": "C501805",
      "category": "Part"
  },
  {
      "partNumber": "C501760",
      "category": "Part"
  },
  {
      "partNumber": "C501640",
      "category": "Part"
  },
  {
      "partNumber": "C501764",
      "category": "Part"
  },
  {
      "partNumber": "C501777",
      "category": "Part"
  },
  {
      "partNumber": "C502346",
      "category": "Part"
  },
  {
      "partNumber": "C502524",
      "category": "Part"
  },
  {
      "partNumber": "C10001901",
      "category": "Part"
  },
  {
      "partNumber": "C502349",
      "category": "Part"
  },
  {
      "partNumber": "C501192",
      "category": "Part"
  },
  {
      "partNumber": "C501599",
      "category": "Part"
  },
  {
      "partNumber": "C501783",
      "category": "Part"
  },
  {
      "partNumber": "C502405",
      "category": "Part"
  },
  {
      "partNumber": "C501496",
      "category": "Part"
  },
  {
      "partNumber": "CM00284",
      "category": "Part"
  },
  {
      "partNumber": "C501635",
      "category": "Part"
  },
  {
      "partNumber": "C501634",
      "category": "Part"
  },
  {
      "partNumber": "CM00750",
      "category": "Part"
  },
  {
      "partNumber": "CM00751",
      "category": "Part"
  },
  {
      "partNumber": "CM00752",
      "category": "Part"
  },
  {
      "partNumber": "C502064-1",
      "category": "Part"
  },
  {
      "partNumber": "C502064-2",
      "category": "Part"
  },
  {
      "partNumber": "C502064-3",
      "category": "Part"
  },
  {
      "partNumber": "C501636",
      "category": "Part"
  },
  {
      "partNumber": "C502491",
      "category": "Part"
  },
  {
      "partNumber": "C501295",
      "category": "Part"
  },
  {
      "partNumber": "C501718",
      "category": "Part"
  },
  {
      "partNumber": "C501817",
      "category": "Part"
  },
  {
      "partNumber": "C502156",
      "category": "Part"
  },
  {
      "partNumber": "C501196",
      "category": "Part"
  },
  {
      "partNumber": "C501593",
      "category": "Part"
  },
  {
      "partNumber": "C502595",
      "category": "Part"
  },
  {
      "partNumber": "C501002",
      "category": "Part"
  },
  {
      "partNumber": "C502355",
      "category": "Part"
  },
  {
      "partNumber": "C400127",
      "category": "Part"
  },
  {
      "partNumber": "C502294",
      "category": "Part"
  },
  {
      "partNumber": "C502488",
      "category": "Part"
  },
  {
      "partNumber": "C500807",
      "category": "Part"
  },
  {
      "partNumber": "C500809",
      "category": "Part"
  },
  {
      "partNumber": "C500801",
      "category": "Part"
  },
  {
      "partNumber": "C500808",
      "category": "Part"
  },
  {
      "partNumber": "C501532",
      "category": "Part"
  },
  {
      "partNumber": "C501536",
      "category": "Part"
  },
  {
      "partNumber": "C200251",
      "category": "Part"
  },
  {
      "partNumber": "C200041",
      "category": "Part"
  },
  {
      "partNumber": "C501008-36",
      "category": "Part"
  },
  {
      "partNumber": "C501664",
      "category": "Part"
  },
  {
      "partNumber": "C501016",
      "category": "Part"
  },
  {
      "partNumber": "C501020",
      "category": "Part"
  },
  {
      "partNumber": "C501679",
      "category": "Part"
  },
  {
      "partNumber": "C501024",
      "category": "Part"
  },
  {
      "partNumber": "C502339",
      "category": "Part"
  },
  {
      "partNumber": "C501030",
      "category": "Part"
  },
  {
      "partNumber": "C502314",
      "category": "Part"
  },
  {
      "partNumber": "C501032",
      "category": "Part"
  },
  {
      "partNumber": "C501036",
      "category": "Part"
  },
  {
      "partNumber": "C502330",
      "category": "Part"
  },
  {
      "partNumber": "C501042",
      "category": "Part"
  },
  {
      "partNumber": "C502675",
      "category": "Part"
  },
  {
      "partNumber": "C501048",
      "category": "Part"
  },
  {
      "partNumber": "C501054",
      "category": "Part"
  },
  {
      "partNumber": "C501056",
      "category": "Part"
  },
  {
      "partNumber": "C501060",
      "category": "Part"
  },
  {
      "partNumber": "C501505",
      "category": "Part"
  },
  {
      "partNumber": "C501922",
      "category": "Part"
  },
  {
      "partNumber": "C502373",
      "category": "Part"
  },
  {
      "partNumber": "C502645",
      "category": "Part"
  },
  {
      "partNumber": "C502164",
      "category": "Part"
  },
  {
      "partNumber": "C501558",
      "category": "Part"
  },
  {
      "partNumber": "C501471 FLAT",
      "category": "Part"
  },
  {
      "partNumber": "C501927 FLAT",
      "category": "Part"
  },
  {
      "partNumber": "C502502",
      "category": "Part"
  },
  {
      "partNumber": "C502124",
      "category": "Part"
  },
  {
      "partNumber": "C501598",
      "category": "Part"
  },
  {
      "partNumber": "C502152",
      "category": "Part"
  },
  {
      "partNumber": "C501771",
      "category": "Part"
  },
  {
      "partNumber": "CM00151",
      "category": "Part"
  },
  {
      "partNumber": "C400126",
      "category": "Part"
  },
  {
      "partNumber": "C501021",
      "category": "Part"
  },
  {
      "partNumber": "C501442",
      "category": "Part"
  },
  {
      "partNumber": "C501406",
      "category": "Part"
  },
  {
      "partNumber": "CM00220",
      "category": "Part"
  },
  {
      "partNumber": "C501251",
      "category": "Part"
  },
  {
      "partNumber": "C502494",
      "category": "Part"
  },
  {
      "partNumber": "C502139",
      "category": "Part"
  },
  {
      "partNumber": "C502186",
      "category": "Part"
  },
  {
      "partNumber": "C501445",
      "category": "Part"
  },
  {
      "partNumber": "C501717",
      "category": "Part"
  },
  {
      "partNumber": "C501818",
      "category": "Part"
  },
  {
      "partNumber": "C502155",
      "category": "Part"
  },
  {
      "partNumber": "C501197",
      "category": "Part"
  },
  {
      "partNumber": "C200010",
      "category": "Part"
  },
  {
      "partNumber": "C502297",
      "category": "Part"
  },
  {
      "partNumber": "C502171",
      "category": "Part"
  },
  {
      "partNumber": "C502208",
      "category": "Part"
  },
  {
      "partNumber": "CM00333",
      "category": "Part"
  },
  {
      "partNumber": "CM00326",
      "category": "Part"
  },
  {
      "partNumber": "CM00324",
      "category": "Part"
  },
  {
      "partNumber": "CM00245",
      "category": "Part"
  },
  {
      "partNumber": "CM00317",
      "category": "Part"
  },
  {
      "partNumber": "C400011",
      "category": "Part"
  },
  {
      "partNumber": "C501937",
      "category": "Part"
  },
  {
      "partNumber": "CM00138",
      "category": "Part"
  },
  {
      "partNumber": "C501967",
      "category": "Part"
  },
  {
      "partNumber": "C501650",
      "category": "Part"
  },
  {
      "partNumber": "C501945",
      "category": "Part"
  },
  {
      "partNumber": "C501297",
      "category": "Part"
  },
  {
      "partNumber": "C501298",
      "category": "Part"
  },
  {
      "partNumber": "C502204",
      "category": "Part"
  },
  {
      "partNumber": "C501189",
      "category": "Part"
  },
  {
      "partNumber": "C501620",
      "category": "Part"
  },
  {
      "partNumber": "C501497",
      "category": "Part"
  },
  {
      "partNumber": "C501796",
      "category": "Part"
  },
  {
      "partNumber": "C501498",
      "category": "Part"
  },
  {
      "partNumber": "C501794",
      "category": "Part"
  },
  {
      "partNumber": "C502484",
      "category": "Part"
  },
  {
      "partNumber": "C502485",
      "category": "Part"
  },
  {
      "partNumber": "C500048",
      "category": "Part"
  },
  {
      "partNumber": "C502404",
      "category": "Part"
  },
  {
      "partNumber": "C502545",
      "category": "Part"
  },
  {
      "partNumber": "C501623",
      "category": "Part"
  },
  {
      "partNumber": "C501160",
      "category": "Part"
  },
  {
      "partNumber": "C501154",
      "category": "Part"
  },
  {
      "partNumber": "C502120",
      "category": "Part"
  },
  {
      "partNumber": "C501037",
      "category": "Part"
  },
  {
      "partNumber": "C501695",
      "category": "Part"
  },
  {
      "partNumber": "C501726",
      "category": "Part"
  },
  {
      "partNumber": "C502210",
      "category": "Part"
  },
  {
      "partNumber": "C501031",
      "category": "Part"
  },
  {
      "partNumber": "C501964",
      "category": "Part"
  },
  {
      "partNumber": "C501049",
      "category": "Part"
  },
  {
      "partNumber": "C502126",
      "category": "Part"
  },
  {
      "partNumber": "C500010",
      "category": "Part"
  },
  {
      "partNumber": "C500012",
      "category": "Part"
  },
  {
      "partNumber": "C500014",
      "category": "Part"
  },
  {
      "partNumber": "C500016",
      "category": "Part"
  },
  {
      "partNumber": "C500018",
      "category": "Part"
  },
  {
      "partNumber": "C500020",
      "category": "Part"
  },
  {
      "partNumber": "C500024",
      "category": "Part"
  },
  {
      "partNumber": "C500030",
      "category": "Part"
  },
  {
      "partNumber": "C500032",
      "category": "Part"
  },
  {
      "partNumber": "C500036",
      "category": "Part"
  },
  {
      "partNumber": "C500040",
      "category": "Part"
  },
  {
      "partNumber": "C500042",
      "category": "Part"
  },
  {
      "partNumber": "C500043",
      "category": "Part"
  },
  {
      "partNumber": "C500056",
      "category": "Part"
  },
  {
      "partNumber": "C500058",
      "category": "Part"
  },
  {
      "partNumber": "C500006",
      "category": "Part"
  },
  {
      "partNumber": "C500060",
      "category": "Part"
  },
  {
      "partNumber": "C500064",
      "category": "Part"
  },
  {
      "partNumber": "C500008",
      "category": "Part"
  },
  {
      "partNumber": "C500216",
      "category": "Part"
  },
  {
      "partNumber": "C502570",
      "category": "Part"
  },
  {
      "partNumber": "C502572",
      "category": "Part"
  },
  {
      "partNumber": "C501241",
      "category": "Part"
  },
  {
      "partNumber": "C501108",
      "category": "Part"
  },
  {
      "partNumber": "C502519",
      "category": "Part"
  },
  {
      "partNumber": "C502688",
      "category": "Part"
  },
  {
      "partNumber": "C502231",
      "category": "Part"
  },
  {
      "partNumber": "C502518",
      "category": "Part"
  },
  {
      "partNumber": "C200080",
      "category": "Part"
  },
  {
      "partNumber": "C100080",
      "category": "Part"
  },
  {
      "partNumber": "CM00215",
      "category": "Part"
  },
  {
      "partNumber": "C400121",
      "category": "Part"
  },
  {
      "partNumber": "C502722",
      "category": "Part"
  },
  {
      "partNumber": "C501260",
      "category": "Part"
  },
  {
      "partNumber": "C501243",
      "category": "Part"
  },
  {
      "partNumber": "C501533",
      "category": "Part"
  },
  {
      "partNumber": "C501284",
      "category": "Part"
  },
  {
      "partNumber": "C502377",
      "category": "Part"
  },
  {
      "partNumber": "C501720",
      "category": "Part"
  },
  {
      "partNumber": "C501812",
      "category": "Part"
  },
  {
      "partNumber": "C501252",
      "category": "Part"
  },
  {
      "partNumber": "C502690",
      "category": "Part"
  },
  {
      "partNumber": "C502158",
      "category": "Part"
  },
  {
      "partNumber": "C500201",
      "category": "Part"
  },
  {
      "partNumber": "C502140",
      "category": "Part"
  },
  {
      "partNumber": "C501946",
      "category": "Part"
  },
  {
      "partNumber": "C501810",
      "category": "Part"
  },
  {
      "partNumber": "C900925",
      "category": "Part"
  },
  {
      "partNumber": "C501176",
      "category": "Part"
  },
  {
      "partNumber": "C501504",
      "category": "Part"
  },
  {
      "partNumber": "C501503",
      "category": "Part"
  },
  {
      "partNumber": "C501440",
      "category": "Part"
  },
  {
      "partNumber": "C400000",
      "category": "Part"
  },
  {
      "partNumber": "C501327",
      "category": "Part"
  },
  {
      "partNumber": "C501369",
      "category": "Part"
  },
  {
      "partNumber": "C502492",
      "category": "Part"
  },
  {
      "partNumber": "C502509",
      "category": "Part"
  },
  {
      "partNumber": "C502534",
      "category": "Part"
  },
  {
      "partNumber": "CM00239",
      "category": "Part"
  },
  {
      "partNumber": "C502540",
      "category": "Part"
  },
  {
      "partNumber": "C502539",
      "category": "Part"
  },
  {
      "partNumber": "C502538",
      "category": "Part"
  },
  {
      "partNumber": "C502252",
      "category": "Part"
  },
  {
      "partNumber": "CM00276",
      "category": "Part"
  },
  {
      "partNumber": "C502040",
      "category": "Part"
  },
  {
      "partNumber": "H1003",
      "category": "Part"
  },
  {
      "partNumber": "H1004",
      "category": "Part"
  },
  {
      "partNumber": "R800244",
      "category": "Part"
  },
  {
      "partNumber": "R600182",
      "category": "Part"
  },
  {
      "partNumber": "R800242",
      "category": "Part"
  },
  {
      "partNumber": "R03620W205",
      "category": "Auger"
  },
  {
      "partNumber": "R800500",
      "category": "Part"
  },
  {
      "partNumber": "R800505",
      "category": "Part"
  },
  {
      "partNumber": "R800510",
      "category": "Part"
  },
  {
      "partNumber": "R800520",
      "category": "Part"
  },
  {
      "partNumber": "R800525",
      "category": "Part"
  },
  {
      "partNumber": "R800545",
      "category": "Part"
  },
  {
      "partNumber": "R800530",
      "category": "Part"
  },
  {
      "partNumber": "R800547",
      "category": "Part"
  },
  {
      "partNumber": "R800550",
      "category": "Part"
  },
  {
      "partNumber": "R800555",
      "category": "Part"
  },
  {
      "partNumber": "R800566",
      "category": "Part"
  },
  {
      "partNumber": "R800565",
      "category": "Part"
  },
  {
      "partNumber": "R800570",
      "category": "Part"
  },
  {
      "partNumber": "R800575",
      "category": "Part"
  },
  {
      "partNumber": "R800580",
      "category": "Part"
  },
  {
      "partNumber": "R800579",
      "category": "Part"
  },
  {
      "partNumber": "W200152",
      "category": "Part"
  },
  {
      "partNumber": "W200225",
      "category": "Part"
  },
  {
      "partNumber": "W200020",
      "category": "Part"
  },
  {
      "partNumber": "W200075",
      "category": "Part"
  },
  {
      "partNumber": "W200100",
      "category": "Part"
  },
  {
      "partNumber": "W200110",
      "category": "Part"
  },
  {
      "partNumber": "W200062",
      "category": "Part"
  },
  {
      "partNumber": "W200165",
      "category": "Part"
  },
  {
      "partNumber": "W200040",
      "category": "Part"
  },
  {
      "partNumber": "W200060",
      "category": "Part"
  },
  {
      "partNumber": "W200114",
      "category": "Part"
  },
  {
      "partNumber": "W200163",
      "category": "Part"
  },
  {
      "partNumber": "W200120",
      "category": "Part"
  },
  {
      "partNumber": "W200210",
      "category": "Part"
  },
  {
      "partNumber": "W300040",
      "category": "Part"
  },
  {
      "partNumber": "W200220",
      "category": "Part"
  },
  {
      "partNumber": "W200230",
      "category": "Part"
  },
  {
      "partNumber": "W200185",
      "category": "Part"
  },
  {
      "partNumber": "W200160",
      "category": "Part"
  },
  {
      "partNumber": "W200180",
      "category": "Part"
  },
  {
      "partNumber": "W200130",
      "category": "Part"
  },
  {
      "partNumber": "W300045",
      "category": "Part"
  },
  {
      "partNumber": "W040020",
      "category": "Part"
  },
  {
      "partNumber": "W200101",
      "category": "Part"
  },
  {
      "partNumber": "W200170",
      "category": "Part"
  },
  {
      "partNumber": "W200115",
      "category": "Part"
  },
  {
      "partNumber": "W200175",
      "category": "Part"
  },
  {
      "partNumber": "W200215",
      "category": "Part"
  },
  {
      "partNumber": "W200140",
      "category": "Part"
  },
  {
      "partNumber": "W200080",
      "category": "Part"
  },
  {
      "partNumber": "W100020",
      "category": "Part"
  },
  {
      "partNumber": "W200235",
      "category": "Part"
  },
  {
      "partNumber": "W100040",
      "category": "Part"
  },
  {
      "partNumber": "W100080",
      "category": "Part"
  },
  {
      "partNumber": "W100120",
      "category": "Part"
  },
  {
      "partNumber": "W100100",
      "category": "Part"
  },
  {
      "partNumber": "W100140",
      "category": "Part"
  },
  {
      "partNumber": "WD00020",
      "category": "Part"
  },
  {
      "partNumber": "WD00021",
      "category": "Part"
  },
  {
      "partNumber": "W030015",
      "category": "Part"
  },
  {
      "partNumber": "X200101",
      "category": "Part"
  },
  {
      "partNumber": "X600027",
      "category": "Part"
  },
  {
      "partNumber": "X600085",
      "category": "Part"
  },
  {
      "partNumber": "X600090",
      "category": "Part"
  },
  {
      "partNumber": "X600023",
      "category": "Part"
  },
  {
      "partNumber": "X600095",
      "category": "Part"
  },
  {
      "partNumber": "X600024",
      "category": "Part"
  },
  {
      "partNumber": "XX00305",
      "category": "Part"
  },
  {
      "partNumber": "X300253",
      "category": "Part"
  },
  {
      "partNumber": "X600026",
      "category": "Part"
  },
  {
      "partNumber": "X500060",
      "category": "Part"
  },
  {
      "partNumber": "X700020",
      "category": "Part"
  },
  {
      "partNumber": "X600411",
      "category": "Part"
  },
  {
      "partNumber": "X000070",
      "category": "Part"
  },
  {
      "partNumber": "X000114",
      "category": "Part"
  },
  {
      "partNumber": "X000116",
      "category": "Part"
  },
  {
      "partNumber": "X600410",
      "category": "Part"
  },
  {
      "partNumber": "X000119",
      "category": "Part"
  },
  {
      "partNumber": "X500010",
      "category": "Part"
  },
  {
      "partNumber": "X000113",
      "category": "Part"
  },
  {
      "partNumber": "X000107",
      "category": "Part"
  },
  {
      "partNumber": "X500015",
      "category": "Part"
  },
  {
      "partNumber": "X800040",
      "category": "Part"
  },
  {
      "partNumber": "X500001",
      "category": "Part"
  },
  {
      "partNumber": "X000120",
      "category": "Part"
  },
  {
      "partNumber": "X200020",
      "category": "Part"
  },
  {
      "partNumber": "X200017",
      "category": "Part"
  },
  {
      "partNumber": "S900706",
      "category": "Part"
  },
  {
      "partNumber": "S500131",
      "category": "Part"
  },
  {
      "partNumber": "S500211",
      "category": "Part"
  },
  {
      "partNumber": "S500231",
      "category": "Part"
  },
  {
      "partNumber": "S910900",
      "category": "Part"
  },
  {
      "partNumber": "S911100",
      "category": "Part"
  },
  {
      "partNumber": "S110RF2LA",
      "category": "Part"
  },
  {
      "partNumber": "S911300",
      "category": "Part"
  },
  {
      "partNumber": "S913861",
      "category": "Part"
  },
  {
      "partNumber": "S911700",
      "category": "Part"
  },
  {
      "partNumber": "S500281",
      "category": "Part"
  },
  {
      "partNumber": "S601501",
      "category": "Part"
  },
  {
      "partNumber": "S911900",
      "category": "Part"
  },
  {
      "partNumber": "S912102",
      "category": "Part"
  },
  {
      "partNumber": "S912300",
      "category": "Part"
  },
  {
      "partNumber": "S912902",
      "category": "Part"
  },
  {
      "partNumber": "S912900",
      "category": "Part"
  },
  {
      "partNumber": "S913502",
      "category": "Part"
  },
  {
      "partNumber": "S913611",
      "category": "Part"
  },
  {
      "partNumber": "S913655",
      "category": "Part"
  },
  {
      "partNumber": "S913855",
      "category": "Part"
  },
  {
      "partNumber": "S913602",
      "category": "Part"
  },
  {
      "partNumber": "S600775",
      "category": "Part"
  },
  {
      "partNumber": "S600200",
      "category": "Part"
  },
  {
      "partNumber": "S600122",
      "category": "Part"
  },
  {
      "partNumber": "S923561",
      "category": "Part"
  },
  {
      "partNumber": "S923560",
      "category": "Part"
  },
  {
      "partNumber": "S933802",
      "category": "Part"
  },
  {
      "partNumber": "S933805",
      "category": "Part"
  },
  {
      "partNumber": "S933803",
      "category": "Part"
  },
  {
      "partNumber": "S933804",
      "category": "Part"
  },
  {
      "partNumber": "S933702",
      "category": "Part"
  },
  {
      "partNumber": "S933703",
      "category": "Part"
  },
  {
      "partNumber": "S933704",
      "category": "Part"
  },
  {
      "partNumber": "S500150",
      "category": "Part"
  },
  {
      "partNumber": "S600126",
      "category": "Part"
  },
  {
      "partNumber": "S500280",
      "category": "Part"
  },
  {
      "partNumber": "S500285",
      "category": "Part"
  },
  {
      "partNumber": "S500115",
      "category": "Part"
  },
  {
      "partNumber": "S500135",
      "category": "Part"
  },
  {
      "partNumber": "S500155",
      "category": "Part"
  },
  {
      "partNumber": "S500175",
      "category": "Part"
  },
  {
      "partNumber": "S500195",
      "category": "Part"
  },
  {
      "partNumber": "S500215",
      "category": "Part"
  },
  {
      "partNumber": "S500235",
      "category": "Part"
  },
  {
      "partNumber": "S500255",
      "category": "Part"
  },
  {
      "partNumber": "S500275",
      "category": "Part"
  },
  {
      "partNumber": "S500110",
      "category": "Part"
  },
  {
      "partNumber": "S500130",
      "category": "Part"
  },
  {
      "partNumber": "S500170",
      "category": "Part"
  },
  {
      "partNumber": "S500190",
      "category": "Part"
  },
  {
      "partNumber": "S500210",
      "category": "Part"
  },
  {
      "partNumber": "S500230",
      "category": "Part"
  },
  {
      "partNumber": "S500250",
      "category": "Part"
  },
  {
      "partNumber": "S500270",
      "category": "Part"
  },
  {
      "partNumber": "S600180",
      "category": "Part"
  },
  {
      "partNumber": "S600101B",
      "category": "Part"
  },
  {
      "partNumber": "S600195",
      "category": "Part"
  },
  {
      "partNumber": "S600190",
      "category": "Part"
  },
  {
      "partNumber": "S600102B",
      "category": "Part"
  },
  {
      "partNumber": "S600112",
      "category": "Part"
  },
  {
      "partNumber": "N500100",
      "category": "Raw Material"
  },
  {
      "partNumber": "P260100",
      "category": "Part"
  },
  {
      "partNumber": "U000920",
      "category": "Part"
  },
  {
      "partNumber": "U000930",
      "category": "Part"
  },
  {
      "partNumber": "U800120",
      "category": "Part"
  },
  {
      "partNumber": "2400480",
      "category": "Part"
  },
  {
      "partNumber": "2400357-01",
      "category": "Part"
  },
  {
      "partNumber": "2400357-02",
      "category": "Part"
  },
  {
      "partNumber": "2400357-03",
      "category": "Part"
  },
  {
      "partNumber": "2400359-01",
      "category": "Part"
  },
  {
      "partNumber": "R800105",
      "category": "Part"
  },
  {
      "partNumber": "TH00093",
      "category": "Part"
  },
  {
      "partNumber": "5-CS600-01",
      "category": "Part"
  },
  {
      "partNumber": "A301590A",
      "category": "Part"
  },
  {
      "partNumber": "A301590B",
      "category": "Part"
  },
  {
      "partNumber": "A301590C",
      "category": "Part"
  },
  {
      "partNumber": "4811342",
      "category": "Part"
  },
  {
      "partNumber": "2400158",
      "category": "Part"
  },
  {
      "partNumber": "S600520",
      "category": "Part"
  },
  {
      "partNumber": "2050092",
      "category": "Part"
  },
  {
      "partNumber": "S600560",
      "category": "Part"
  },
  {
      "partNumber": "S600590",
      "category": "Part"
  },
  {
      "partNumber": "E0003",
      "category": "Part"
  },
  {
      "partNumber": "16-1016W/Cx5/8",
      "category": "Part"
  },
  {
      "partNumber": "HYD0021",
      "category": "Part"
  },
  {
      "partNumber": "2400356-01",
      "category": "Part"
  },
  {
      "partNumber": "C501381",
      "category": "Part"
  },
  {
      "partNumber": "C501019",
      "category": "Part"
  },
  {
      "partNumber": "200R262-66N",
      "category": "Part"
  },
  {
      "partNumber": "A800360B",
      "category": "Part"
  },
  {
      "partNumber": "M157510",
      "category": "Part"
  },
  {
      "partNumber": "S923520",
      "category": "Part"
  },
  {
      "partNumber": "S923522",
      "category": "Part"
  },
  {
      "partNumber": "S923509",
      "category": "Part"
  },
  {
      "partNumber": "S923508",
      "category": "Part"
  },
  {
      "partNumber": "200R401-66N",
      "category": "Part"
  },
  {
      "partNumber": "P260100-1",
      "category": "Part"
  },
  {
      "partNumber": "80-3610x4",
      "category": "Auger"
  },
  {
      "partNumber": "362061",
      "category": "Part"
  },
  {
      "partNumber": "808028",
      "category": "Part"
  },
  {
      "partNumber": "808048",
      "category": "Part"
  },
  {
      "partNumber": "H1005",
      "category": "Part"
  },
  {
      "partNumber": "H1006",
      "category": "Part"
  },
  {
      "partNumber": "3620512B",
      "category": "Part"
  },
  {
      "partNumber": "3620586B",
      "category": "Part"
  },
  {
      "partNumber": "C501667",
      "category": "Cutting Head"
  },
  {
      "partNumber": "006120",
      "category": "Part"
  },
  {
      "partNumber": "C500200-1",
      "category": "Part"
  },
  {
      "partNumber": "M156109",
      "category": "Part"
  },
  {
      "partNumber": "E0001",
      "category": "Part"
  },
  {
      "partNumber": "C501949",
      "category": "Part"
  },
  {
      "partNumber": "C501636-02",
      "category": "Part"
  },
  {
      "partNumber": "H1007",
      "category": "Part"
  },
  {
      "partNumber": "H9999",
      "category": "Part"
  },
  {
      "partNumber": "H9998",
      "category": "Part"
  },
  {
      "partNumber": "H9997",
      "category": "Part"
  },
  {
      "partNumber": "H9996",
      "category": "Part"
  },
  {
      "partNumber": "H9995",
      "category": "Part"
  },
  {
      "partNumber": "R800470",
      "category": "Part"
  },
  {
      "partNumber": "R800475",
      "category": "Part"
  },
  {
      "partNumber": "R800480",
      "category": "Part"
  },
  {
      "partNumber": "r800495",
      "category": "Part"
  },
  {
      "partNumber": "R800485",
      "category": "Part"
  },
  {
      "partNumber": "S601280",
      "category": "Part"
  },
  {
      "partNumber": "C501979",
      "category": "Part"
  },
  {
      "partNumber": "C502271",
      "category": "Part"
  },
  {
      "partNumber": "C502410",
      "category": "Part"
  },
  {
      "partNumber": "C502275",
      "category": "Part"
  },
  {
      "partNumber": "C501013",
      "category": "Part"
  },
  {
      "partNumber": "C501335",
      "category": "Part"
  },
  {
      "partNumber": "C501636-11",
      "category": "Part"
  },
  {
      "partNumber": "C502447",
      "category": "Part"
  },
  {
      "partNumber": "C502429",
      "category": "Part"
  },
  {
      "partNumber": "C502065",
      "category": "Part"
  },
  {
      "partNumber": "C502017",
      "category": "Part"
  },
  {
      "partNumber": "C502047",
      "category": "Part"
  },
  {
      "partNumber": "C501014",
      "category": "Part"
  },
  {
      "partNumber": "C502065-01",
      "category": "Part"
  },
  {
      "partNumber": "C502427",
      "category": "Part"
  },
  {
      "partNumber": "C501386",
      "category": "Part"
  },
  {
      "partNumber": "N500101",
      "category": "Raw Material"
  },
  {
      "partNumber": "C501636-13",
      "category": "Part"
  },
  {
      "partNumber": "N500102",
      "category": "Raw Material"
  },
  {
      "partNumber": "C501449",
      "category": "Part"
  },
  {
      "partNumber": "U000940",
      "category": "Part"
  },
  {
      "partNumber": "T402161",
      "category": "Part"
  },
  {
      "partNumber": "T402163",
      "category": "Part"
  },
  {
      "partNumber": "U000950",
      "category": "Part"
  },
  {
      "partNumber": "U000960",
      "category": "Part"
  },
  {
      "partNumber": "T422011",
      "category": "Part"
  },
  {
      "partNumber": "T422041",
      "category": "Part"
  },
  {
      "partNumber": "X000445",
      "category": "Part"
  },
  {
      "partNumber": "H1008",
      "category": "Part"
  },
  {
      "partNumber": "80-3610x3",
      "category": "Auger"
  },
  {
      "partNumber": "T320299",
      "category": "Part"
  },
  {
      "partNumber": "X300190",
      "category": "Part"
  },
  {
      "partNumber": "H1009",
      "category": "Part"
  },
  {
      "partNumber": "X300191",
      "category": "Part"
  },
  {
      "partNumber": "X300192",
      "category": "Part"
  },
  {
      "partNumber": "X000448",
      "category": "Part"
  },
  {
      "partNumber": "C501372",
      "category": "Part"
  },
  {
      "partNumber": "C502070",
      "category": "Part"
  },
  {
      "partNumber": "NA00100",
      "category": "Raw Material"
  },
  {
      "partNumber": "S601120-1",
      "category": "Part"
  },
  {
      "partNumber": "S601130-1",
      "category": "Part"
  },
  {
      "partNumber": "S601140-1",
      "category": "Part"
  },
  {
      "partNumber": "H1010",
      "category": "Part"
  },
  {
      "partNumber": "R800210",
      "category": "Part"
  },
  {
      "partNumber": "3642D000",
      "category": "Boring Machine"
  },
  {
      "partNumber": "3642D001",
      "category": "Part"
  },
  {
      "partNumber": "R800215",
      "category": "Part"
  },
  {
      "partNumber": "N500321",
      "category": "Raw Material"
  },
  {
      "partNumber": "3642D002",
      "category": "Part"
  },
  {
      "partNumber": "3606x4RUSH",
      "category": "Part"
  },
  {
      "partNumber": "80-1210x3",
      "category": "Auger"
  },
  {
      "partNumber": "C501920",
      "category": "Part"
  },
  {
      "partNumber": "C502786",
      "category": "Part"
  },
  {
      "partNumber": "C502761",
      "category": "Part"
  },
  {
      "partNumber": "C501112",
      "category": "Part"
  },
  {
      "partNumber": "C501017",
      "category": "Part"
  },
  {
      "partNumber": "C501405",
      "category": "Part"
  },
  {
      "partNumber": "C501015",
      "category": "Part"
  },
  {
      "partNumber": "60000011",
      "category": "Part"
  },
  {
      "partNumber": "3642D003",
      "category": "Part"
  },
  {
      "partNumber": "C501153",
      "category": "Part"
  },
  {
      "partNumber": "80-0810X1-5/8",
      "category": "Auger"
  },
  {
      "partNumber": "C501106",
      "category": "Part"
  },
  {
      "partNumber": "C501381-0",
      "category": "Part"
  },
  {
      "partNumber": "C501381-Y",
      "category": "Part"
  },
  {
      "partNumber": "8094179",
      "category": "Part"
  },
  {
      "partNumber": "H1011",
      "category": "Part"
  },
  {
      "partNumber": "H1012",
      "category": "Part"
  },
  {
      "partNumber": "H1013",
      "category": "Part"
  },
  {
      "partNumber": "H1014",
      "category": "Part"
  },
  {
      "partNumber": "H1015",
      "category": "Part"
  },
  {
      "partNumber": "H1016",
      "category": "Part"
  },
  {
      "partNumber": "3642D005",
      "category": "Part"
  },
  {
      "partNumber": "3642D006",
      "category": "Part"
  },
  {
      "partNumber": "3642D008",
      "category": "Part"
  },
  {
      "partNumber": "3642D007",
      "category": "Part"
  },
  {
      "partNumber": "16-36UPW/CX1",
      "category": "Part"
  },
  {
      "partNumber": "C501009",
      "category": "Part"
  },
  {
      "partNumber": "19-2420PDCX4W/C",
      "category": "Part"
  },
  {
      "partNumber": "HYD0022",
      "category": "Part"
  },
  {
      "partNumber": "HYD0023",
      "category": "Part"
  },
  {
      "partNumber": "HYD0024",
      "category": "Part"
  },
  {
      "partNumber": "HYD0025",
      "category": "Part"
  },
  {
      "partNumber": "HYD0026",
      "category": "Part"
  },
  {
      "partNumber": "HYD0027",
      "category": "Part"
  },
  {
      "partNumber": "HYD0028",
      "category": "Part"
  },
  {
      "partNumber": "Shop Labor",
      "category": "Part"
  },
  {
      "partNumber": "NA00090",
      "category": "Part"
  },
  {
      "partNumber": "17-30ROLLERX4W/C",
      "category": "Cutting Head"
  },
  {
      "partNumber": "N500400",
      "category": "Part"
  },
  {
      "partNumber": "17-24rollerx4",
      "category": "Cutting Head"
  },
  {
      "partNumber": "17-24ROLLERX4NOW/C",
      "category": "Cutting Head"
  },
  {
      "partNumber": "N500500",
      "category": "Raw Material"
  },
  {
      "partNumber": "3000060",
      "category": "Part"
  },
  {
      "partNumber": "7-35TONLP-C502460",
      "category": "Part"
  },
  {
      "partNumber": "S050RF200",
      "category": "Cutting Head"
  },
  {
      "partNumber": "N500600",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500601",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500602",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500604",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500605",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500606",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500607",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500608",
      "category": "Raw Material"
  },
  {
      "partNumber": "S923503",
      "category": "Part"
  },
  {
      "partNumber": "OUTSIDE LABOR",
      "category": "Part"
  },
  {
      "partNumber": "R500175",
      "category": "Part"
  },
  {
      "partNumber": "17-16ROLLERX3",
      "category": "Cutting Head"
  },
  {
      "partNumber": "N500185",
      "category": "Part"
  },
  {
      "partNumber": "HD7000",
      "category": "Part"
  },
  {
      "partNumber": "HD6005",
      "category": "Part"
  },
  {
      "partNumber": "S600111",
      "category": "Part"
  },
  {
      "partNumber": "16-1836w/cx3/4-01",
      "category": "Part"
  },
  {
      "partNumber": "16-1836W/Cx3/4-02",
      "category": "Part"
  },
  {
      "partNumber": "83-3-302472x4",
      "category": "Part"
  },
  {
      "partNumber": "2400357B",
      "category": "Part"
  },
  {
      "partNumber": "N500420",
      "category": "Part"
  },
  {
      "partNumber": "N500421",
      "category": "Part"
  },
  {
      "partNumber": "18-362401",
      "category": "Part"
  },
  {
      "partNumber": "18-362402",
      "category": "Part"
  },
  {
      "partNumber": "18-362403",
      "category": "Part"
  },
  {
      "partNumber": "18-X01",
      "category": "Part"
  },
  {
      "partNumber": "18-X4",
      "category": "Part"
  },
  {
      "partNumber": "2400357C",
      "category": "Part"
  },
  {
      "partNumber": "S923520-01",
      "category": "Part"
  },
  {
      "partNumber": "Freight Shipping and Handling",
      "category": "Part"
  },
  {
      "partNumber": "N500501",
      "category": "Raw Material"
  },
  {
      "partNumber": "16-1836w/cx3/4B",
      "category": "Part"
  },
  {
      "partNumber": "16-1836w/cx3/4-01B",
      "category": "Part"
  },
  {
      "partNumber": "16-1836W/Cx3/4-02B",
      "category": "Part"
  },
  {
      "partNumber": "R600125",
      "category": "Part"
  },
  {
      "partNumber": "18-2016COMBOx2.25",
      "category": "Part"
  },
  {
      "partNumber": "N500102-01",
      "category": "Part"
  },
  {
      "partNumber": "19-2420TTX4W/C",
      "category": "Part"
  },
  {
      "partNumber": "19-2420ROLLX4W\\C",
      "category": "Part"
  },
  {
      "partNumber": "19-3024XTRMTTX4W/C",
      "category": "Part"
  },
  {
      "partNumber": "R500171",
      "category": "Part"
  },
  {
      "partNumber": "N500101-01",
      "category": "Part"
  },
  {
      "partNumber": "19-302401",
      "category": "Part"
  },
  {
      "partNumber": "19-302402",
      "category": "Part"
  },
  {
      "partNumber": "C502275B",
      "category": "Part"
  },
  {
      "partNumber": "E0004",
      "category": "Part"
  },
  {
      "partNumber": "W000020",
      "category": "Part"
  },
  {
      "partNumber": "8080229",
      "category": "Part"
  },
  {
      "partNumber": "HD11010",
      "category": "Part"
  },
  {
      "partNumber": "HD40050",
      "category": "Part"
  },
  {
      "partNumber": "HD14010",
      "category": "Part"
  },
  {
      "partNumber": "HD50000B",
      "category": "Part"
  },
  {
      "partNumber": "HD14008",
      "category": "Part"
  },
  {
      "partNumber": "5-CS601",
      "category": "Part"
  },
  {
      "partNumber": "CS602",
      "category": "Part"
  },
  {
      "partNumber": "CS603",
      "category": "Part"
  },
  {
      "partNumber": "5bte1103",
      "category": "Part"
  },
  {
      "partNumber": "CS604",
      "category": "Part"
  },
  {
      "partNumber": "E0002",
      "category": "Part"
  },
  {
      "partNumber": "CSW001",
      "category": "Part"
  },
  {
      "partNumber": "CSW002",
      "category": "Part"
  },
  {
      "partNumber": "CS602-01",
      "category": "Part"
  },
  {
      "partNumber": "CS603-01",
      "category": "Part"
  },
  {
      "partNumber": "CS604-01",
      "category": "Part"
  },
  {
      "partNumber": "CS605",
      "category": "Part"
  },
  {
      "partNumber": "CS606",
      "category": "Part"
  },
  {
      "partNumber": "CS607",
      "category": "Part"
  },
  {
      "partNumber": "CS608",
      "category": "Part"
  },
  {
      "partNumber": "CS609",
      "category": "Part"
  },
  {
      "partNumber": "CS610",
      "category": "Part"
  },
  {
      "partNumber": "CS611",
      "category": "Part"
  },
  {
      "partNumber": "CS612",
      "category": "Part"
  },
  {
      "partNumber": "CS613",
      "category": "Part"
  },
  {
      "partNumber": "CS614",
      "category": "Part"
  },
  {
      "partNumber": "CS615",
      "category": "Part"
  },
  {
      "partNumber": "CS116",
      "category": "Part"
  },
  {
      "partNumber": "CS616",
      "category": "Part"
  },
  {
      "partNumber": "CS617",
      "category": "Part"
  },
  {
      "partNumber": "CS618",
      "category": "Part"
  },
  {
      "partNumber": "CS619",
      "category": "Part"
  },
  {
      "partNumber": "CS620",
      "category": "Part"
  },
  {
      "partNumber": "CS621",
      "category": "Part"
  },
  {
      "partNumber": "CS622",
      "category": "Part"
  },
  {
      "partNumber": "CSW003",
      "category": "Part"
  },
  {
      "partNumber": "CSW004",
      "category": "Part"
  },
  {
      "partNumber": "CSW005",
      "category": "Part"
  },
  {
      "partNumber": "CSW006",
      "category": "Part"
  },
  {
      "partNumber": "CSW007",
      "category": "Part"
  },
  {
      "partNumber": "CSW008",
      "category": "Part"
  },
  {
      "partNumber": "CSW009",
      "category": "Part"
  },
  {
      "partNumber": "CSW010",
      "category": "Part"
  },
  {
      "partNumber": "CSW011",
      "category": "Part"
  },
  {
      "partNumber": "CSW012",
      "category": "Part"
  },
  {
      "partNumber": "CSW013",
      "category": "Part"
  },
  {
      "partNumber": "CSW014",
      "category": "Part"
  },
  {
      "partNumber": "CSW015",
      "category": "Part"
  },
  {
      "partNumber": "CSW016",
      "category": "Part"
  },
  {
      "partNumber": "CSW017",
      "category": "Part"
  },
  {
      "partNumber": "CSW018",
      "category": "Part"
  },
  {
      "partNumber": "CSW019",
      "category": "Part"
  },
  {
      "partNumber": "CSW020",
      "category": "Part"
  },
  {
      "partNumber": "E0005",
      "category": "Part"
  },
  {
      "partNumber": "CSW001-01",
      "category": "Part"
  },
  {
      "partNumber": "E0006",
      "category": "Part"
  },
  {
      "partNumber": "E0007",
      "category": "Part"
  },
  {
      "partNumber": "E0008",
      "category": "Part"
  },
  {
      "partNumber": "E0009",
      "category": "Part"
  },
  {
      "partNumber": "E0010",
      "category": "Part"
  },
  {
      "partNumber": "E0011",
      "category": "Part"
  },
  {
      "partNumber": "E0012",
      "category": "Part"
  },
  {
      "partNumber": "E0013",
      "category": "Part"
  },
  {
      "partNumber": "E0014",
      "category": "Part"
  },
  {
      "partNumber": "E0002-01",
      "category": "Part"
  },
  {
      "partNumber": "5HYD600",
      "category": "Part"
  },
  {
      "partNumber": "5HYD601",
      "category": "Part"
  },
  {
      "partNumber": "5HYD602",
      "category": "Part"
  },
  {
      "partNumber": "5HYD603",
      "category": "Part"
  },
  {
      "partNumber": "5HYD604",
      "category": "Part"
  },
  {
      "partNumber": "5HYD605",
      "category": "Part"
  },
  {
      "partNumber": "5HYD606",
      "category": "Part"
  },
  {
      "partNumber": "5HYD610",
      "category": "Part"
  },
  {
      "partNumber": "5HYD611",
      "category": "Part"
  },
  {
      "partNumber": "5HYD612",
      "category": "Part"
  },
  {
      "partNumber": "5HYD613",
      "category": "Part"
  },
  {
      "partNumber": "5HYD614",
      "category": "Part"
  },
  {
      "partNumber": "5HYD615",
      "category": "Part"
  },
  {
      "partNumber": "5HYD616",
      "category": "Part"
  },
  {
      "partNumber": "5HYD617",
      "category": "Part"
  },
  {
      "partNumber": "16-1016W/Cx5/8-01",
      "category": "Part"
  },
  {
      "partNumber": "16-1016W/Cx5/8-02",
      "category": "Part"
  },
  {
      "partNumber": "N500650",
      "category": "Part"
  },
  {
      "partNumber": "N500606-1",
      "category": "Part"
  },
  {
      "partNumber": "N500600-1",
      "category": "Part"
  },
  {
      "partNumber": "R800260",
      "category": "Part"
  },
  {
      "partNumber": "8080188",
      "category": "Part"
  },
  {
      "partNumber": "17-16rollerx3-01",
      "category": "Part"
  },
  {
      "partNumber": "17-16rollerx3-02",
      "category": "Part"
  },
  {
      "partNumber": "17-16ROLLERx3-03",
      "category": "Part"
  },
  {
      "partNumber": "17-16rollerx3-04",
      "category": "Part"
  },
  {
      "partNumber": "17-16rollerx3-05",
      "category": "Part"
  },
  {
      "partNumber": "17-16ROLLERx3-06",
      "category": "Part"
  },
  {
      "partNumber": "17-16ROLLERx3-07",
      "category": "Part"
  },
  {
      "partNumber": "17-16ROLLERx3-08",
      "category": "Part"
  },
  {
      "partNumber": "R800540-01",
      "category": "Part"
  },
  {
      "partNumber": "R800185",
      "category": "Part"
  },
  {
      "partNumber": "3620601B",
      "category": "Part"
  },
  {
      "partNumber": "15-BTE1003",
      "category": "Part"
  },
  {
      "partNumber": "15-BTE1004",
      "category": "Part"
  },
  {
      "partNumber": "H1017",
      "category": "Part"
  },
  {
      "partNumber": "H1018",
      "category": "Part"
  },
  {
      "partNumber": "H1019",
      "category": "Part"
  },
  {
      "partNumber": "H1020",
      "category": "Part"
  },
  {
      "partNumber": "H2000",
      "category": "Part"
  },
  {
      "partNumber": "H2001",
      "category": "Part"
  },
  {
      "partNumber": "4-54-TBM-1",
      "category": "Part"
  },
  {
      "partNumber": "3642D010",
      "category": "Part"
  },
  {
      "partNumber": "3642D009",
      "category": "Boring Machine"
  },
  {
      "partNumber": "246758001",
      "category": "Part"
  },
  {
      "partNumber": "19-X01",
      "category": "Part"
  },
  {
      "partNumber": "N510100",
      "category": "Part"
  },
  {
      "partNumber": "N510100-01",
      "category": "Part"
  },
  {
      "partNumber": "BTE0001",
      "category": "Part"
  },
  {
      "partNumber": "19-X05",
      "category": "Part"
  },
  {
      "partNumber": "19-X02",
      "category": "Part"
  },
  {
      "partNumber": "19-X04",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1194",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1195",
      "category": "Part"
  },
  {
      "partNumber": "E00071",
      "category": "Part"
  },
  {
      "partNumber": "E00081",
      "category": "Part"
  },
  {
      "partNumber": "80-0810x1-5/8x1/4",
      "category": "Part"
  },
  {
      "partNumber": "H1021",
      "category": "Part"
  },
  {
      "partNumber": "4-60TBM-DISC",
      "category": "Cutting Head"
  },
  {
      "partNumber": "n500403",
      "category": "Part"
  },
  {
      "partNumber": "N500403-1",
      "category": "Part"
  },
  {
      "partNumber": "3600023-1",
      "category": "Part"
  },
  {
      "partNumber": "N500410",
      "category": "Part"
  },
  {
      "partNumber": "N500430",
      "category": "Part"
  },
  {
      "partNumber": "N500440",
      "category": "Part"
  },
  {
      "partNumber": "N500450",
      "category": "Part"
  },
  {
      "partNumber": "N500462",
      "category": "Part"
  },
  {
      "partNumber": "5CSD01",
      "category": "Part"
  },
  {
      "partNumber": "5CSD02",
      "category": "Part"
  },
  {
      "partNumber": "5CSD03",
      "category": "Part"
  },
  {
      "partNumber": "5CSD04",
      "category": "Part"
  },
  {
      "partNumber": "C501636-09",
      "category": "Part"
  },
  {
      "partNumber": "C501636-01",
      "category": "Part"
  },
  {
      "partNumber": "80-1210x3x3/8",
      "category": "Auger"
  },
  {
      "partNumber": "C501636-03",
      "category": "Part"
  },
  {
      "partNumber": "80-4810x3x1/2",
      "category": "Auger"
  },
  {
      "partNumber": "80-3010x3x1/2",
      "category": "Auger"
  },
  {
      "partNumber": "80-3010x4x1/2",
      "category": "Auger"
  },
  {
      "partNumber": "80-4210x3x1/2",
      "category": "Auger"
  },
  {
      "partNumber": "80-4210x4x1/2",
      "category": "Auger"
  },
  {
      "partNumber": "H600",
      "category": "Part"
  },
  {
      "partNumber": "8496001",
      "category": "Part"
  },
  {
      "partNumber": "5472001",
      "category": "Part"
  },
  {
      "partNumber": "5496001",
      "category": "Part"
  },
  {
      "partNumber": "E00072",
      "category": "Part"
  },
  {
      "partNumber": "5HYD618",
      "category": "Part"
  },
  {
      "partNumber": "2400323",
      "category": "Part"
  },
  {
      "partNumber": "15-BTE1005",
      "category": "Part"
  },
  {
      "partNumber": "15-BTE1006",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1190-01",
      "category": "Part"
  },
  {
      "partNumber": "N500652",
      "category": "Part"
  },
  {
      "partNumber": "H1023",
      "category": "Part"
  },
  {
      "partNumber": "H1022",
      "category": "Part"
  },
  {
      "partNumber": "N710260-01",
      "category": "Part"
  },
  {
      "partNumber": "N500654",
      "category": "Raw Material"
  },
  {
      "partNumber": "N500654-01",
      "category": "Part"
  },
  {
      "partNumber": "5-CS600PB",
      "category": "Part"
  },
  {
      "partNumber": "24D001",
      "category": "Boring Machine"
  },
  {
      "partNumber": "24D002",
      "category": "Boring Machine"
  },
  {
      "partNumber": "24D003",
      "category": "Part"
  },
  {
      "partNumber": "80-1204x1-5/8x1/4",
      "category": "Part"
  },
  {
      "partNumber": "R800254",
      "category": "Part"
  },
  {
      "partNumber": "3600132",
      "category": "Part"
  },
  {
      "partNumber": "3600070",
      "category": "Part"
  },
  {
      "partNumber": "H1024",
      "category": "Part"
  },
  {
      "partNumber": "H1025",
      "category": "Part"
  },
  {
      "partNumber": "H1026",
      "category": "Part"
  },
  {
      "partNumber": "H1027",
      "category": "Part"
  },
  {
      "partNumber": "H1028",
      "category": "Part"
  },
  {
      "partNumber": "H1029",
      "category": "Part"
  },
  {
      "partNumber": "H1030",
      "category": "Part"
  },
  {
      "partNumber": "N7101625",
      "category": "Raw Material"
  },
  {
      "partNumber": "80-0605X15/8X1/4",
      "category": "Auger"
  },
  {
      "partNumber": "12HB000",
      "category": "Boring Machine"
  },
  {
      "partNumber": "16-06combox15/8",
      "category": "Cutting Head"
  },
  {
      "partNumber": "n5005104",
      "category": "Raw Material"
  },
  {
      "partNumber": "3642D011",
      "category": "Part"
  },
  {
      "partNumber": "3642D012",
      "category": "Part"
  },
  {
      "partNumber": "3642D013",
      "category": "Part"
  },
  {
      "partNumber": "3642D014",
      "category": "Part"
  },
  {
      "partNumber": "8496020",
      "category": "Part"
  },
  {
      "partNumber": "8496021",
      "category": "Part"
  },
  {
      "partNumber": "8496022",
      "category": "Part"
  },
  {
      "partNumber": "3496023",
      "category": "Part"
  },
  {
      "partNumber": "8496023",
      "category": "Part"
  },
  {
      "partNumber": "N500605-1",
      "category": "Raw Material"
  },
  {
      "partNumber": "16-18COMBOx3-01",
      "category": "Part"
  },
  {
      "partNumber": "5496020",
      "category": "Part"
  },
  {
      "partNumber": "5496021",
      "category": "Part"
  },
  {
      "partNumber": "5496022",
      "category": "Part"
  },
  {
      "partNumber": "5496023",
      "category": "Part"
  },
  {
      "partNumber": "5496026",
      "category": "Part"
  },
  {
      "partNumber": "5496030",
      "category": "Part"
  },
  {
      "partNumber": "3-2418x4",
      "category": "Steering Head"
  },
  {
      "partNumber": "83-3-241860x4",
      "category": "Auger"
  },
  {
      "partNumber": "4-48TBM",
      "category": "Steering Head"
  },
  {
      "partNumber": "4-48TBM-DISC",
      "category": "Cutting Head"
  },
  {
      "partNumber": "R500155",
      "category": "Auger"
  },
  {
      "partNumber": "R800180",
      "category": "Part"
  },
  {
      "partNumber": "N500200",
      "category": "Raw Material"
  },
  {
      "partNumber": "5496024",
      "category": "Part"
  },
  {
      "partNumber": "hyd0001",
      "category": "Part"
  },
  {
      "partNumber": "HYD0002",
      "category": "Part"
  },
  {
      "partNumber": "HYD0003",
      "category": "Part"
  },
  {
      "partNumber": "H1031",
      "category": "Part"
  },
  {
      "partNumber": "H1032",
      "category": "Part"
  },
  {
      "partNumber": "H1033",
      "category": "Part"
  },
  {
      "partNumber": "H1034",
      "category": "Part"
  },
  {
      "partNumber": "H1035",
      "category": "Part"
  },
  {
      "partNumber": "H1036",
      "category": "Part"
  },
  {
      "partNumber": "H1038",
      "category": "Part"
  },
  {
      "partNumber": "H1037",
      "category": "Part"
  },
  {
      "partNumber": "16-10combo-01",
      "category": "Part"
  },
  {
      "partNumber": "16-60COMBOx5",
      "category": "Cutting Head"
  },
  {
      "partNumber": "R600140",
      "category": "Part"
  },
  {
      "partNumber": "8080125",
      "category": "Part"
  },
  {
      "partNumber": "3-30SRS",
      "category": "Steering Head"
  },
  {
      "partNumber": "3-36SRS",
      "category": "Steering Head"
  },
  {
      "partNumber": "16-1016W/Cx5/8B",
      "category": "Part"
  },
  {
      "partNumber": "H1039",
      "category": "Part"
  },
  {
      "partNumber": "2-2416000",
      "category": "Steering Head"
  },
  {
      "partNumber": "h1040",
      "category": "Part"
  },
  {
      "partNumber": "H1041",
      "category": "Part"
  },
  {
      "partNumber": "H1042",
      "category": "Part"
  },
  {
      "partNumber": "H1043",
      "category": "Part"
  },
  {
      "partNumber": "N500655",
      "category": "Raw Material"
  },
  {
      "partNumber": "R800212",
      "category": "Part"
  },
  {
      "partNumber": "n510101",
      "category": "Part"
  },
  {
      "partNumber": "5472002",
      "category": "Part"
  },
  {
      "partNumber": "5HYD619",
      "category": "Part"
  },
  {
      "partNumber": "N500604-1",
      "category": "Part"
  },
  {
      "partNumber": "BTE-1196",
      "category": "Part"
  },
  {
      "partNumber": "H1044",
      "category": "Part"
  },
  {
      "partNumber": "H1045",
      "category": "Part"
  },
  {
      "partNumber": "R800205-1",
      "category": "Part"
  },
  {
      "partNumber": "R800545-1",
      "category": "Part"
  },
  {
      "partNumber": "N500542",
      "category": "Part"
  }
];
    
const allItems:Item[] = [
  {
      "transactionType": "Expense",
      "idNumber": "9359",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"payday 5.17.2024\",\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123651",
      "amount": 509.510009765625,
      "vendor": "BTE",
      "customer": "Vermeer Southeast  Florida/Georgia/Alabama",
      "dateCreated": "2024-06-20 13:03:34",
      "dateFulfilled": "2024-06-24T10:57:12.781Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"C500142\",\"description\":\"HINGE SET - 42\\\" CASING\",\"unitPrice\":600.88,\"lineAmount\":600.88}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123648",
      "amount": 147.1300048828125,
      "vendor": "BTE",
      "customer": "Vermeer Southeast  Florida/Georgia/Alabama",
      "dateCreated": "2024-06-20 12:35:40",
      "dateFulfilled": "2024-06-24T10:57:10.896Z",
      "origin": "lois",
      "items": "[{\"quantity\":10,\"partNumber\":\"R700150\",\"description\":\"PIN SHOULDER, auger pin for 3\\\" HEX augers\",\"unitPrice\":15.02,\"lineAmount\":150.2},{\"quantity\":10,\"partNumber\":\"R700175\",\"description\":\"AUGER CLIP, cotter pin 1-5/8\\\" and 2-1/4\\\" SHANK\",\"unitPrice\":0.95,\"lineAmount\":9.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9464",
      "amount": 1643.93994140625,
      "vendor": "Pressure Washers Direct",
      "customer": "BTE",
      "dateCreated": "2024-06-19T14:52:05.427Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"6\",\"partNumber\":\"CS602\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123647",
      "amount": 4980.68994140625,
      "vendor": "BTE",
      "customer": "Vermeer Mountain West",
      "dateCreated": "2024-06-19 20:18:08",
      "dateFulfilled": "2024-06-20T19:12:37.413Z",
      "origin": "lois",
      "items": "[{\"quantity\":8,\"partNumber\":\"C501381\",\"description\":\"HYD. CYLINDER 50mm 50 Ton\",\"unitPrice\":700,\"lineAmount\":5600}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123646",
      "amount": 4311.72998046875,
      "vendor": "BTE",
      "customer": "Vermeer Texas/Louisana",
      "dateCreated": "2024-06-19 15:26:39",
      "dateFulfilled": "2024-06-24T10:57:53.821Z",
      "origin": "lois",
      "items": "[{\"quantity\":5,\"partNumber\":\"CS602\",\"description\":\"CONTROL STATION HOSE REEL (UNMODIFIED- PAINTED ONLY IF NECESSARY) WITHOUT HYD. HOSE\",\"unitPrice\":958.97,\"lineAmount\":4794.85}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123645",
      "amount": 6684.43994140625,
      "vendor": "BTE",
      "customer": "Vermeer Southeast  Florida/Georgia/Alabama",
      "dateCreated": "2024-06-18 20:15:48",
      "dateFulfilled": "2024-06-19T14:49:55.093Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"4800810\",\"description\":\"GRIP W/ SWITCH MCL48/54 N.O. SWITCH\",\"unitPrice\":277.48,\"lineAmount\":277.48},{\"quantity\":1,\"partNumber\":\"T720100\",\"description\":\"GAUGE SIGHT HYD.OIL 24-42T 3 1/4\\\" (BOLT CENTERS) L\",\"unitPrice\":84.98,\"lineAmount\":84.98},{\"quantity\":1,\"partNumber\":\"P260210\",\"description\":\"COUPLING CENTER MEMBER 30/42B\",\"unitPrice\":2405.34,\"lineAmount\":2405.34},{\"quantity\":1,\"partNumber\":\"3600210\",\"description\":\"CLUTCH HYD MCL-30 - 42t L600 \",\"unitPrice\":5544.2,\"lineAmount\":5544.2}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123643",
      "amount": 13107.5,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-06-18 17:24:49",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":5,\"partNumber\":\"80-2410x2.25\",\"description\":\"24\\\" auger 10' long on 2-1/4\\\" hex with 3/8\\\" flights\",\"unitPrice\":2450,\"lineAmount\":12250}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123642",
      "amount": 1337.5,
      "vendor": "BTE",
      "customer": "NC Services LLC",
      "dateCreated": "2024-06-18 14:31:34",
      "dateFulfilled": "2024-06-19T10:08:54.406Z",
      "origin": "lois",
      "items": "[{\"quantity\":5,\"partNumber\":\"R600160\",\"description\":\"2-1/4 HEX BOX/BUSHING/SOCKET\",\"unitPrice\":250,\"lineAmount\":1250}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123641",
      "amount": 8827.5,
      "vendor": "BTE",
      "customer": "Father and Son Ent.",
      "dateCreated": "2024-06-17 19:09:18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-12COMBOx1.625\",\"description\":\"12\\\" combo cutting head with wing cutters on 1-5/8\\\" hex with carbide teeth\",\"unitPrice\":1450,\"lineAmount\":1450},{\"quantity\":4,\"partNumber\":\"80-1210x1.625\",\"description\":\"12\\\" auger 10' long on 1-5/8\\\" hex with 1/4\\\" flights\",\"unitPrice\":1700,\"lineAmount\":6800}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123640",
      "amount": 805.010009765625,
      "vendor": "BTE",
      "customer": "Cox-Edwards Company",
      "dateCreated": "2024-06-17 18:41:39",
      "dateFulfilled": "2024-06-24T10:58:06.433Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2050170\",\"description\":\"VLV CNTRL 20B 1200 M-427 SGL SPOOL 3KPSI 15 GP\",\"unitPrice\":784,\"lineAmount\":784}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123582",
      "amount": 171.85000610351562,
      "vendor": "BTE",
      "customer": "Shockley Plumbing",
      "dateCreated": "2024-06-17 18:15:06",
      "dateFulfilled": "2024-06-18T14:11:13.178Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400266B\",\"description\":\"CLUTCH RESTRAINT CLIP (McL 36/42C and BTE 24D)\",\"unitPrice\":42.5,\"lineAmount\":42.5},{\"quantity\":1,\"partNumber\":\"TH00093\",\"description\":\"HYD CLUTCH HOSE McL36/42C\",\"unitPrice\":76,\"lineAmount\":76}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123580",
      "amount": 1177,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-06-17 18:14:15",
      "dateFulfilled": "2024-06-18T14:11:01.501Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"R800212\",\"description\":\"2-1/4\\\" MALE X 4\\\" FEMALE ADAPTER\",\"unitPrice\":1100,\"lineAmount\":1100}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123624",
      "amount": 416.6400146484375,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-06-17 18:07:41",
      "dateFulfilled": "2024-06-24T14:46:18.394Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"R500170\",\"description\":\"3\\\" HEX BAR/SHANK REPAIR \",\"unitPrice\":389.38,\"lineAmount\":389.38}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123636",
      "amount": 5528,
      "vendor": "BTE",
      "customer": "Vermeer Midsouth",
      "dateCreated": "2024-06-17 17:53:31",
      "dateFulfilled": "2024-06-24T14:47:38.942Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-10COMBOx1.625\",\"description\":\"10\\\" combo cutting head with wing cutters on 1-5/8\\\" hex with carbide teeth\",\"unitPrice\":1450,\"lineAmount\":1450},{\"quantity\":4,\"partNumber\":\"80-1605X1.625\",\"description\":\"16\\\" auger 5' long on 1-5/8\\\" hex with 1/4” flights\",\"unitPrice\":1365,\"lineAmount\":5460}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123626",
      "amount": 21423.060546875,
      "vendor": "BTE",
      "customer": "Brad Atkinson",
      "dateCreated": "2024-06-17 17:53:20",
      "dateFulfilled": "2024-06-24T14:25:51.045Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"4800348\",\"description\":\"SHAFT INPUT ADAPTER\",\"unitPrice\":2769.48,\"lineAmount\":2769.48},{\"quantity\":1,\"partNumber\":\"18-4836XTRMx4\",\"description\":\"48\\\" xtrm OTS cuttting head on 4\\\" hex w/King Kong teeth\",\"unitPrice\":18500,\"lineAmount\":18500},{\"quantity\":6,\"partNumber\":\"15-BTE1003\",\"description\":\"King Kong Teeth - flat face\",\"unitPrice\":340,\"lineAmount\":2040}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123632",
      "amount": 1534.75,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-06-17 17:53:19",
      "dateFulfilled": "2024-06-24T14:47:13.513Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"P330000\",\"description\":\"SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED \",\"unitPrice\":980,\"lineAmount\":980},{\"quantity\":1,\"partNumber\":\"3600132\",\"description\":\"Companion flange for 24D, Mcl 30 thru 42, 3642D\",\"unitPrice\":525,\"lineAmount\":525}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123625",
      "amount": 0,
      "vendor": "BTE",
      "customer": "DRS Enterprises",
      "dateCreated": "2024-06-17 17:53:16",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"19-3024XTRMTTX4W/C\",\"description\":\"30\\\" RUSH XTRM CUTTING HEAD WITH LARGE TRENCHER TEETH ON 4\\\" HEX WITH WING CUTTERS. CUTS 33\\\".\",\"unitPrice\":12500,\"lineAmount\":12500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123627",
      "amount": 727.9600219726562,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2024-06-17 17:53:15",
      "dateFulfilled": "2024-06-24T14:46:57.760Z",
      "origin": "dan",
      "items": "[{\"quantity\":30,\"partNumber\":\"15-S600801\",\"description\":\"NON ROTATING FLAT CARBIDE TEETH FOR COMBO CUTTING HEADS AND STD OTS CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":750}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123621",
      "amount": 1498.8399658203125,
      "vendor": "BTE",
      "customer": "Vermeer All Roads-Roanoke",
      "dateCreated": "2024-06-17 17:49:24",
      "dateFulfilled": "2024-06-24T14:24:26.041Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"X000095\",\"description\":\"SWITCH TOGGLE MCL36/42-48/54\",\"unitPrice\":24.89,\"lineAmount\":24.89},{\"quantity\":1,\"partNumber\":\"X000020\",\"description\":\"E STOP COMPLETE\",\"unitPrice\":346.3,\"lineAmount\":346.3},{\"quantity\":1,\"partNumber\":\"4800810\",\"description\":\"GRIP W/ SWITCH MCL48/54 N.O. SWITCH\",\"unitPrice\":277.48,\"lineAmount\":277.48},{\"quantity\":4,\"partNumber\":\"S600210\",\"description\":\"CONICAL WC 2 BIT 1\\\" SPACER for 18-48\\\" McL ROCK CUTTERS\",\"unitPrice\":295,\"lineAmount\":1180}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123623",
      "amount": 2592.110107421875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-06-17 17:49:22",
      "dateFulfilled": "2024-06-24T14:24:58.542Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"P330000\",\"description\":\"SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED \",\"unitPrice\":980,\"lineAmount\":980},{\"quantity\":5,\"partNumber\":\"2400121\",\"description\":\"COUP PUMP NYLON SLEEVE 24-42B\",\"unitPrice\":70.35,\"lineAmount\":351.75},{\"quantity\":1,\"partNumber\":\"P260100\",\"description\":\"HALF COUPLING MCL24B 602-R 1-3/8 FB\",\"unitPrice\":554.75,\"lineAmount\":554.75},{\"quantity\":1,\"partNumber\":\"P260110\",\"description\":\"COUPLING CENTER MEMBER MCL24B\",\"unitPrice\":1278.3,\"lineAmount\":1278.3},{\"quantity\":1,\"partNumber\":\"2400089\",\"description\":\"COUPLING ADAPTER PLATE MCL-24B\",\"unitPrice\":37.42,\"lineAmount\":37.42}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123619",
      "amount": 12284.099609375,
      "vendor": "BTE",
      "customer": "City Albemarle",
      "dateCreated": "2024-06-17 17:49:18",
      "dateFulfilled": "2024-06-24T14:24:05.980Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"HD30620\",\"description\":\"CYL HYD.MCL-12H ALTERNATIVE TO HD30610 287021 - WI\",\"unitPrice\":970.76,\"lineAmount\":1941.52},{\"quantity\":1,\"partNumber\":\"8080227\",\"description\":\"SEAL FRONT SHAFT MCL10H/12HB\",\"unitPrice\":195.16,\"lineAmount\":195.16},{\"quantity\":2,\"partNumber\":\"8080229\",\"description\":\"THRUST BEARING FOR 12HB CARRIAGE ASSEMBLY\",\"unitPrice\":207.34,\"lineAmount\":414.68},{\"quantity\":2,\"partNumber\":\"8080228\",\"description\":\"BEARING CUP MCL10H/12HB REAR DRIVE SHAFT\",\"unitPrice\":124.01,\"lineAmount\":248.02},{\"quantity\":1,\"partNumber\":\"HD11010\",\"description\":\"DRIVE SHAFT FOR MCL 12HB CARRAIGE ASSEMBLY\",\"unitPrice\":784.16,\"lineAmount\":784.16},{\"quantity\":1,\"partNumber\":\"HD40050\",\"description\":\"1-5/8\\\" CHUCK ASSEMBLY FOR McL 12HB\",\"unitPrice\":407.4,\"lineAmount\":407.4},{\"quantity\":1,\"partNumber\":\"8080231\",\"description\":\"LOCK NUT C-CLAMP MCL-12HB/10H DRIVE SHAFT\",\"unitPrice\":88.27,\"lineAmount\":88.27},{\"quantity\":4,\"partNumber\":\"W000020\",\"description\":\"CAM ROLLER BEARING FOR 12HB CARRIAGE ASSEMBLY\",\"unitPrice\":74,\"lineAmount\":296},{\"quantity\":1,\"partNumber\":\"HD14008\",\"description\":\"8\\\" ADAPTER KIT / PUSH PLATE FOR McL 12HB\",\"unitPrice\":640.52,\"lineAmount\":640.52},{\"quantity\":1,\"partNumber\":\"HD50000B\",\"description\":\"SADDLE (MODIFIED BY US TO WORK FOR 8\\\" ONLY) FOR McL 12HB\",\"unitPrice\":64.9,\"lineAmount\":64.9},{\"quantity\":1,\"partNumber\":\"HD7000\",\"description\":\"MASTER TRACK FOR 12H ZIPPER 84\\\" LONG\",\"unitPrice\":3950,\"lineAmount\":3950},{\"quantity\":1,\"partNumber\":\"HD6005\",\"description\":\"EXTENSION TRACK FOR 12H ZIPPER FOR RUNNING 10' AUGERS\",\"unitPrice\":3900,\"lineAmount\":3900}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123618",
      "amount": 2494.800048828125,
      "vendor": "BTE",
      "customer": "Vermeer Midsouth",
      "dateCreated": "2024-06-17 17:49:16",
      "dateFulfilled": "2024-06-24T14:23:40.664Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2050091\",\"description\":\"CLUTCH HYD MCL-20B (L)\",\"unitPrice\":2525.25,\"lineAmount\":2525.25},{\"quantity\":1,\"partNumber\":\"2050092\",\"description\":\"drive cup\",\"unitPrice\":553,\"lineAmount\":553}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123612",
      "amount": 1765.1700439453125,
      "vendor": "BTE",
      "customer": "Vermeer Mountain West",
      "dateCreated": "2024-06-17 17:49:10",
      "dateFulfilled": "2024-06-24T14:23:26.165Z",
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"8080189\",\"description\":\"CLAMPING JAW\",\"unitPrice\":242.73,\"lineAmount\":970.92},{\"quantity\":1,\"partNumber\":\"8080232\",\"description\":\"WATER SWIVEL MCL10H\",\"unitPrice\":620.52,\"lineAmount\":620.52},{\"quantity\":1,\"partNumber\":\"8080350\",\"description\":\"SUB SAVER FIRESTICK MCL-10H\",\"unitPrice\":365,\"lineAmount\":365}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123598",
      "amount": 307.8599853515625,
      "vendor": "BTE",
      "customer": "Vermeer Southeast  Florida/Georgia/Alabama",
      "dateCreated": "2024-06-17 17:45:52",
      "dateFulfilled": "2024-06-24T14:45:56.271Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400262\",\"description\":\"VALVE RELIEF ADJ. MCL24/30B\",\"unitPrice\":325.3,\"lineAmount\":325.3},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":59.52,\"lineAmount\":59.52}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123595",
      "amount": 1965.77001953125,
      "vendor": "BTE",
      "customer": "Keener Construction CO.",
      "dateCreated": "2024-06-17 17:42:46",
      "dateFulfilled": "2024-06-24T14:45:38.619Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-12COMBOx2.25\",\"description\":\"12\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":1500,\"lineAmount\":1500},{\"quantity\":1,\"partNumber\":\"2400371\",\"description\":\"VALVE SOLENOID (HF)20 24 42T\",\"unitPrice\":253.61,\"lineAmount\":253.61},{\"quantity\":8,\"partNumber\":\"S600765\",\"description\":\"Tooth CONICAL HEAVY DUTY LARGE 4\\\"-36\\\" HEADS for McL rock heads\",\"unitPrice\":30,\"lineAmount\":240},{\"quantity\":-1,\"partNumber\":\"4801702\",\"description\":\"COIL SOLENOID VALVE 48/54\",\"unitPrice\":65.94,\"lineAmount\":-65.94},{\"quantity\":-1,\"partNumber\":\"2400357\",\"description\":\"VALVE HNDL W/LINK V20 24B/30B PISTOL GRIP HDL\",\"unitPrice\":528,\"lineAmount\":-528},{\"quantity\":1,\"partNumber\":\"2400357B\",\"description\":\"VALVE HNDL W/LINK V20 24B/30B PISTOL GRIP HDL\",\"unitPrice\":527.9,\"lineAmount\":527.9}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123592",
      "amount": 701.8699951171875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Colfax NC",
      "dateCreated": "2024-06-17 17:42:43",
      "dateFulfilled": "2024-06-24T14:22:19.926Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"S600510\",\"description\":\"BIT CARBIDE 1/2\\\" X 1/2\\\"\",\"unitPrice\":20.2,\"lineAmount\":40.4},{\"quantity\":2,\"partNumber\":\"S600530\",\"description\":\"BIT CARBIDE 1/2\\\" X 1-1/2\\\"\",\"unitPrice\":70.18,\"lineAmount\":140.36},{\"quantity\":1,\"partNumber\":\"S050RF200\",\"description\":\"HEAD, COMBO 6'' OD 1/58' HEX\",\"unitPrice\":677.39,\"lineAmount\":677.39},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":19.19,\"lineAmount\":19.19}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123591",
      "amount": 12749.650390625,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-06-17 17:42:42",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"17-16ROLLERX3\",\"description\":\"16\\\" rollerbit cutting head with NO wing cutters on 3\\\" hex, cuts 17-17.5\\\"\",\"unitPrice\":12500,\"lineAmount\":12500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123587",
      "amount": 124040.25,
      "vendor": "BTE",
      "customer": "Bore Company LLC",
      "dateCreated": "2024-06-17 17:42:35",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"3-2420x4\",\"description\":\"24\\\" RUSH OTS w/20\\\" auger on 4\\\" Hex\",\"unitPrice\":52500,\"lineAmount\":52500},{\"quantity\":1,\"partNumber\":\"19-2420PDCX4W/C\",\"description\":\"24\\\" RUSH CUTTING HEAD WITH PDC CUTTERS WITH WING CUTTERS\",\"unitPrice\":35000,\"lineAmount\":35000},{\"quantity\":1,\"partNumber\":\"5-CS600\",\"description\":\"600' Control Station\",\"unitPrice\":49000,\"lineAmount\":49000},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":1322.5,\"lineAmount\":1322.5}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123578",
      "amount": 1397.1400146484375,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-06-13 15:04:45",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"P330000\",\"description\":\"SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED \",\"unitPrice\":980,\"lineAmount\":980},{\"quantity\":1,\"partNumber\":\"P260100\",\"description\":\"HALF COUPLING MCL24B 602-R 1-3/8 FB\",\"unitPrice\":554.75,\"lineAmount\":554.75},{\"quantity\":1,\"partNumber\":\"2400089\",\"description\":\"COUPLING ADAPTER PLATE MCL-24B\",\"unitPrice\":211.68,\"lineAmount\":211.68}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9344",
      "amount": 4611.52001953125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Coyote Logistics WEB PYMNT ***** Coyote Logistics WEB PYMNT *****3372\",\"unitPrice\":4611.52,\"lineAmount\":4611.52}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123577",
      "amount": 772.9000244140625,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-06-12 19:03:20",
      "dateFulfilled": "2024-06-13T15:05:30.095Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"16-1016W/Cx5/8B\",\"description\":\"WING CUTTER FOR 10”-16” ROCK CUTTING HEAD. REQUIRES 2 PER CUTTING HEAD (USES 5/8” X 3-3/4” bolt)\",\"unitPrice\":345,\"lineAmount\":690},{\"quantity\":8,\"partNumber\":\"Teeth:BTE1003\",\"description\":\"CONICAL ROCK TEETH FOR COMBO CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":200}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123570",
      "amount": 5940,
      "vendor": "BTE",
      "customer": "Vermeer Corporation",
      "dateCreated": "2024-06-12 16:13:24",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123576",
      "amount": 54325,
      "vendor": "BTE",
      "customer": "H & H Road Boring Company",
      "dateCreated": "2024-06-12 13:25:17",
      "dateFulfilled": "2024-06-19T20:21:34.995Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"OTS - Vermeer patent:2-2416x4\",\"description\":\"24\\\" std OTS w/16\\\" auger and combo cutting head on 4\\\" hex\",\"unitPrice\":53750,\"lineAmount\":53750}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9313",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9350",
      "amount": 1835.22998046875,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ULINE SHIP SUPPLIES XXX-XXX-5510 WI XXXX-XXXXXX-X1019\",\"unitPrice\":1835.23,\"lineAmount\":1835.23}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9315",
      "amount": 22.479999542236328,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPINX #292               INMAN SPINX #292 INMAN SC\",\"unitPrice\":22.48,\"lineAmount\":22.48}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9314",
      "amount": 21.530000686645508,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPINX #292               INMAN SPINX #292 INMAN SC\",\"unitPrice\":21.53,\"lineAmount\":21.53}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123575",
      "amount": 393.1199951171875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads-Roanoke",
      "dateCreated": "2024-06-11 14:51:03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400303\",\"description\":\"SWITCH IGNITION DEUTZ 2 KEYS INCLUDED 2400501\",\"unitPrice\":491.4,\"lineAmount\":491.4}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123572",
      "amount": 200,
      "vendor": "BTE",
      "customer": "Vermeer Mountain West",
      "dateCreated": "2024-06-11 13:18:04",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"8080125\",\"description\":\"Hammer Stake for 12H, 14\\\" total. 12\\\" effective length\",\"unitPrice\":125,\"lineAmount\":250}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123571",
      "amount": 6056.14990234375,
      "vendor": "BTE",
      "customer": "Shockley Plumbing",
      "dateCreated": "2024-06-11 09:30:27",
      "dateFulfilled": "2024-06-11T13:25:21.070Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"3600210\",\"description\":\"CLUTCH HYD MCL-30 - 42t L600 \",\"unitPrice\":5544.2,\"lineAmount\":5544.2},{\"quantity\":1,\"partNumber\":\"TH00093\",\"description\":\"HYD CLUTCH HOSE McL36/42C\",\"unitPrice\":76,\"lineAmount\":76},{\"quantity\":1,\"partNumber\":\"W030060\",\"description\":\"BEARING MCL30/36 AND MCL36/42C\",\"unitPrice\":105.95,\"lineAmount\":105.95},{\"quantity\":1,\"partNumber\":\"2400266B\",\"description\":\"CLUTCH RESTRAINT CLIP (McL 36/42C and BTE 24D)\",\"unitPrice\":42.5,\"lineAmount\":42.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9300",
      "amount": 18.040000915527344,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":18.04,\"lineAmount\":18.04}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9303",
      "amount": 11.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.99,\"lineAmount\":11.99}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123569",
      "amount": 5215.89990234375,
      "vendor": "BTE",
      "customer": "B & D Developers LLC",
      "dateCreated": "2024-06-10 18:59:36",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-06combox15/8\",\"description\":\"6\\\" combo cutting head on 1-5/8\\\" hex\",\"unitPrice\":750,\"lineAmount\":750},{\"quantity\":1,\"partNumber\":\"16-12COMBOx2.25\",\"description\":\"12\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":1500,\"lineAmount\":1500},{\"quantity\":5,\"partNumber\":\"R05026F204\",\"description\":\"6\\\" Auger x 1-5/8 Hex x 4'\",\"unitPrice\":460.18,\"lineAmount\":2300.9},{\"quantity\":2,\"partNumber\":\"16-1016W/Cx5/8\",\"description\":\"WING CUTTER FOR 10”-16” COMBO CUTTING HEAD includes carbid tooth\",\"unitPrice\":245,\"lineAmount\":490},{\"quantity\":1,\"partNumber\":\"S600120\",\"description\":\"BIT WING CUTTER COMBO 10\\\"-16\\\" (OBSOLETE)\",\"unitPrice\":175,\"lineAmount\":175}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123568",
      "amount": 1521.0999755859375,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-06-10 18:29:56",
      "dateFulfilled": "2024-06-11T19:20:06.784Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-16COMBOx2.25\",\"description\":\"16\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with #150 carbide teeth\",\"unitPrice\":1750,\"lineAmount\":1750}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123567",
      "amount": 1722.4000244140625,
      "vendor": "BTE",
      "customer": "JITOW LLC",
      "dateCreated": "2024-06-10 14:58:11",
      "dateFulfilled": "2024-06-11T13:20:28.547Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"S601280\",\"description\":\"Lead Head Combo/Rock\",\"unitPrice\":575,\"lineAmount\":575},{\"quantity\":6,\"partNumber\":\"S600320\",\"description\":\"1x1 square hard surface replacement tooth\",\"unitPrice\":62.12,\"lineAmount\":372.72},{\"quantity\":2,\"partNumber\":\"S600340\",\"description\":\"BIT HARD SURFACE 1\\\" X 2\\\"\",\"unitPrice\":75,\"lineAmount\":150},{\"quantity\":8,\"partNumber\":\"U320120\",\"description\":\"COTTER PIN CLIP FOR MCL TYPE B TEETH INSERTS\",\"unitPrice\":1.5,\"lineAmount\":12},{\"quantity\":2,\"partNumber\":\"S600110\",\"description\":\"WING CUTTER (18”-60” DIRT HEAD) new part number\",\"unitPrice\":250,\"lineAmount\":500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123566",
      "amount": 2140,
      "vendor": "BTE",
      "customer": "JITOW LLC",
      "dateCreated": "2024-06-10 13:42:02",
      "dateFulfilled": "2024-06-11T13:20:56.103Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-18COMBOx2.25\",\"description\":\"18\\\" combo old style Mcl cutting head with wing cutters on 2-1/4\\\" hex \",\"unitPrice\":2000,\"lineAmount\":2000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123565",
      "amount": 2300,
      "vendor": "BTE",
      "customer": "Jeffers Contruction",
      "dateCreated": "2024-06-10 12:44:25",
      "dateFulfilled": "2024-06-11T19:22:25.942Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"17-12ROCKx2.25\",\"description\":\"12\\\" rock cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":2300,\"lineAmount\":2300}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9204",
      "amount": 266.8500061035156,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-B NEXT Insur (AP I NEXT Insur ST-B*L*Z0I6G1F8\",\"unitPrice\":266.85,\"lineAmount\":266.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9203",
      "amount": 44.150001525878906,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2501     WOODRUF TRACTOR SUPPLY #2501 WOODRUFF SC\",\"unitPrice\":44.15,\"lineAmount\":44.15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9185",
      "amount": 103.91999816894531,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT #1129     SPARTAN THE HOME DEPOT #1129 SPARTANBURG SC\",\"unitPrice\":103.92,\"lineAmount\":103.92}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9186",
      "amount": 317.25,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ASHEVILLE HWY RENTAL     864-583 ASHEVILLE HWY RENTAL 864-5836393 SC\",\"unitPrice\":317.25,\"lineAmount\":317.25}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9188",
      "amount": 39.27000045776367,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":39.27,\"lineAmount\":39.27}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9193",
      "amount": 43.11000061035156,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPINX #292               INMAN SPINX #292 INMAN SC\",\"unitPrice\":43.11,\"lineAmount\":43.11}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9205",
      "amount": 34.2400016784668,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON.COM AMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":34.24,\"lineAmount\":34.24}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9351",
      "amount": 63.36000061035156,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":63.36,\"lineAmount\":63.36}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9167",
      "amount": 208.39999389648438,
      "vendor": "King Kong Tools",
      "customer": "BTE",
      "dateCreated": "2024-06-07T15:36:58.863Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"2\",\"partNumber\":\"15-BTE1004\"}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9036",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"payday 5.17.2024\",\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9184",
      "amount": 15,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CIRCLE K # 21503         WOODRUF CIRCLE K # 21503 WOODRUFF SC\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9194",
      "amount": 11.760000228881836,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.76,\"lineAmount\":11.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9352",
      "amount": 3204.8701171875,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"POWER EQUIPMENT DIREBOLINGBROOK IL XXXX-XXXXXX-X1001\",\"unitPrice\":3204.87,\"lineAmount\":3204.87}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123564",
      "amount": 788.6500244140625,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-06-06 15:16:04",
      "dateFulfilled": "2024-06-07T15:41:05.281Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400081B\",\"description\":\"SPOIL EJECTOR PADDLE ASSY 24B 2 pieces, includes bolts and nuts\",\"unitPrice\":650,\"lineAmount\":650}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9160",
      "amount": 12.260000228881836,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":12.26,\"lineAmount\":12.26}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123563",
      "amount": 256690,
      "vendor": "BTE",
      "customer": "Bore Company LLC",
      "dateCreated": "2024-06-06",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"4-48TBM\",\"description\":\"48\\\" TBM SUPER OTS electric - man entry - auger asst\",\"unitPrice\":235000,\"lineAmount\":235000},{\"quantity\":1,\"partNumber\":\"4-48TBM-DISC\",\"description\":\"9\\\" DISC CUTTER FOR 48\\\" TBM\",\"unitPrice\":35200,\"lineAmount\":35200}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123562",
      "amount": 610,
      "vendor": "BTE",
      "customer": "City Albemarle",
      "dateCreated": "2024-06-05 16:56:25",
      "dateFulfilled": "2024-06-11T19:20:46.214Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"R800180\",\"description\":\"1-5/8\\\" M x 1-3/8\\\" FM adapter\",\"unitPrice\":610,\"lineAmount\":610}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123561",
      "amount": 8352,
      "vendor": "BTE",
      "customer": "Vermeer Southeast Florida/ Georgia",
      "dateCreated": "2024-06-05 12:23:22",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-24COMBOx2.25\",\"description\":\"24\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":3200,\"lineAmount\":3200},{\"quantity\":4,\"partNumber\":\"80-1210x2.25\",\"description\":\"12\\\" auger 10' long on 2-1/4\\\" hex with 3/8\\\" flights\",\"unitPrice\":1810,\"lineAmount\":7240}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9187",
      "amount": 50.189998626708984,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":50.19,\"lineAmount\":50.19}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9137",
      "amount": 611,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ASHEVILLE HWY RENTAL     864-583 ASHEVILLE HWY RENTAL 864-5836393 SC\",\"unitPrice\":611,\"lineAmount\":611}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123560",
      "amount": 2960.050048828125,
      "vendor": "BTE",
      "customer": "Lawrimore Construction",
      "dateCreated": "2024-06-04 13:27:52",
      "dateFulfilled": "2024-06-18T14:10:09.862Z",
      "origin": "lois",
      "items": "[{\"quantity\":20,\"partNumber\":\"M157510\",\"description\":\"Steering Head Drill Rod\",\"unitPrice\":130.32,\"lineAmount\":2606.4},{\"quantity\":2,\"partNumber\":\"C400010\",\"description\":\"SENSING HEAD\",\"unitPrice\":80,\"lineAmount\":160}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9207",
      "amount": 24.739999771118164,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":24.74,\"lineAmount\":24.74}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9206",
      "amount": 25.030000686645508,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":25.03,\"lineAmount\":25.03}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9136",
      "amount": 17.989999771118164,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":17.99,\"lineAmount\":17.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9039",
      "amount": 358.55999755859375,
      "vendor": "Bailey Hydraulics",
      "customer": "BTE",
      "dateCreated": "2024-06-03T11:21:17.125Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"2\",\"partNumber\":\"2400357-02\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123559",
      "amount": 865.7000122070312,
      "vendor": "BTE",
      "customer": "Carolina Tap & Bore INC",
      "dateCreated": "2024-06-03 17:16:25",
      "dateFulfilled": "2024-06-03T20:06:58.082Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"3600124\",\"description\":\"HYD DOG PLATE CYL ASSY 24-42T\",\"unitPrice\":751.35,\"lineAmount\":751.35}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123558",
      "amount": 11488,
      "vendor": "BTE",
      "customer": "Shockley Plumbing",
      "dateCreated": "2024-06-03 13:57:34",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"80-1810X3\",\"description\":\"18\\\" auger 10' long on 3\\\" hex with 3/8\\\" flights\",\"unitPrice\":2872,\"lineAmount\":11488}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9231",
      "amount": 35.25,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":35.25,\"lineAmount\":35.25}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9128",
      "amount": 21.579999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Amazon web services      aws.ama Amazon web services aws.amazon.coWA\",\"unitPrice\":21.58,\"lineAmount\":21.58}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9134",
      "amount": 181.42999267578125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":181.43,\"lineAmount\":181.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9127",
      "amount": 10.6899995803833,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":10.69,\"lineAmount\":10.69}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9096",
      "amount": 63.65999984741211,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-G NEXT Insur (AP I NEXT Insur ST-G*Q*M9J3K8Z7\",\"unitPrice\":63.66,\"lineAmount\":63.66}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9063",
      "amount": 6.849999904632568,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-06-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":6.85,\"lineAmount\":6.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9076",
      "amount": 12.829999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":12.83,\"lineAmount\":12.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9077",
      "amount": 118.0999984741211,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TST* CITYRANGE STEAKHOUSESpartan TST* CITYRANGE STEAKHOUSESpartanburg SC\",\"unitPrice\":118.1,\"lineAmount\":118.1}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9078",
      "amount": 13.149999618530273,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1061                  ROCK HI QT 1061 ROCK HILL SC\",\"unitPrice\":13.15,\"lineAmount\":13.15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9083",
      "amount": 52.93000030517578,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Indeed 93549525          800-462 Indeed 93549525 800-4625842 TX\",\"unitPrice\":52.93,\"lineAmount\":52.93}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9084",
      "amount": 50.43000030517578,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1061                  ROCK HI QT 1061 ROCK HILL SC\",\"unitPrice\":50.43,\"lineAmount\":50.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9129",
      "amount": 171.3000030517578,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"MISCELLANEOUS ADJUSTMENT\",\"unitPrice\":171.3,\"lineAmount\":171.3}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9044",
      "amount": 26.1299991607666,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-06-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":26.13,\"lineAmount\":26.13}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9232",
      "amount": 3.190000057220459,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-06-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":3.19,\"lineAmount\":3.19}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123557",
      "amount": 2574.25,
      "vendor": "BTE",
      "customer": "Shockley Plumbing",
      "dateCreated": "2024-05-31 16:31:36",
      "dateFulfilled": "2024-06-04T20:01:21.437Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-18COMBOx3\",\"description\":\"18\\\" combo cutting head with wing cutters on 3\\\" hex with carbide teeth\",\"unitPrice\":2350,\"lineAmount\":2350}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9057",
      "amount": 30,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Digital Banking Fees\",\"unitPrice\":30,\"lineAmount\":30}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9075",
      "amount": 743.9400024414062,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COSTCO WHSE #1008        SPARTAN COSTCO WHSE #1008 SPARTANBURG SC\",\"unitPrice\":743.94,\"lineAmount\":743.94}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9079",
      "amount": 70.48999786376953,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":70.49,\"lineAmount\":70.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9080",
      "amount": 267.5,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTUIT *QBooks Online    CL.INTU INTUIT *QBooks Online CL.INTUIT.COMCA\",\"unitPrice\":267.5,\"lineAmount\":267.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9085",
      "amount": 389,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ANSWERFORCE              800-461 ANSWERFORCE 800-461-8520 OR\",\"unitPrice\":389,\"lineAmount\":389}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9032",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123555",
      "amount": 2942.580078125,
      "vendor": "BTE",
      "customer": "Gordy Construction",
      "dateCreated": "2024-05-30 17:27:49",
      "dateFulfilled": "2024-06-01T17:43:02.328Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"2400359\",\"description\":\"VLV HNDL W/LINK V20 24B/30B DOG PLATE HANDLE W/ L\",\"unitPrice\":86.35,\"lineAmount\":172.7},{\"quantity\":1,\"partNumber\":\"2400356\",\"description\":\"VALVE HYD.CONTROL 24/30\",\"unitPrice\":2735.09,\"lineAmount\":2735.09}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123554",
      "amount": 1050.4100341796875,
      "vendor": "BTE",
      "customer": "Avritt Contracting",
      "dateCreated": "2024-05-30 15:43:19",
      "dateFulfilled": "2024-06-01T17:43:09.719Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"S601130\",\"description\":\"LEAD SCREW DIRT 1\\\" V\",\"unitPrice\":175,\"lineAmount\":175},{\"quantity\":4,\"partNumber\":\"S600320\",\"description\":\"1x1 square hard surface replacement tooth\",\"unitPrice\":62.12,\"lineAmount\":248.48},{\"quantity\":2,\"partNumber\":\"S600340\",\"description\":\"BIT HARD SURFACE 1\\\" X 2\\\"\",\"unitPrice\":75,\"lineAmount\":150},{\"quantity\":2,\"partNumber\":\"S600100\",\"description\":\"WING CUTTER (10”-16” DIRT HEAD)\",\"unitPrice\":225,\"lineAmount\":450}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9043",
      "amount": 102.58999633789062,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-30",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"GODADDY.COM XXX-XXX-8855 AZ XXXX-XXXXXX-X1019\",\"unitPrice\":102.59,\"lineAmount\":102.59}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9025",
      "amount": 154.75,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-30",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":154.75,\"lineAmount\":154.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9081",
      "amount": 26.190000534057617,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-30",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CAROLINA HOSE AND HYDRAULSPARTAN CAROLINA HOSE AND HYDRAULSPARTANBURG SC\",\"unitPrice\":26.19,\"lineAmount\":26.19}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1067",
      "amount": 97.19999694824219,
      "vendor": "EMJ",
      "customer": "BTE",
      "dateCreated": "2024-05-29T09:00:45.102Z",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":\"60\",\"partNumber\":\"N7101625\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123552",
      "amount": 5528,
      "vendor": "BTE",
      "customer": "Vermeer Midsouth",
      "dateCreated": "2024-05-29 19:11:33",
      "dateFulfilled": "2024-06-24T14:38:11.628Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-10COMBOx1.625\",\"description\":\"10\\\" combo cutting head with wing cutters on 1-5/8\\\" hex with carbide teeth\",\"unitPrice\":1450,\"lineAmount\":1450},{\"quantity\":4,\"partNumber\":\"80-1605X1.625\",\"description\":\"16\\\" auger 5' long on 1-5/8\\\" hex with 1/4” flights\",\"unitPrice\":1365,\"lineAmount\":5460}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9208",
      "amount": 28.420000076293945,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":28.42,\"lineAmount\":28.42}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9209",
      "amount": 13.979999542236328,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":13.98,\"lineAmount\":13.98}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123549",
      "amount": 8667,
      "vendor": "BTE",
      "customer": "HRH Engineering Services",
      "dateCreated": "2024-05-28 18:28:15",
      "dateFulfilled": "2024-06-11T13:22:01.345Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"80-2410x2.25\",\"description\":\"24\\\" auger 10' long on 2-1/4\\\" hex with 3/8\\\" flights\",\"unitPrice\":2450,\"lineAmount\":4900},{\"quantity\":1,\"partNumber\":\"16-24COMBOx2.25\",\"description\":\"24\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":3200,\"lineAmount\":3200}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9082",
      "amount": 7050,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTERNATIONAL WIRE TRANSFER-0000 INTERNATIONAL WIRE TRANSFER-0000320 2 REF#320\",\"unitPrice\":7050,\"lineAmount\":7050}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8985",
      "amount": 251.63999938964844,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-I NEXT Insur (AP I NEXT Insur ST-I*M*T4J0P0B0\",\"unitPrice\":251.64,\"lineAmount\":251.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8966",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8986",
      "amount": 25,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#320\",\"unitPrice\":25,\"lineAmount\":25}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8977",
      "amount": 37.33000183105469,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":37.33,\"lineAmount\":37.33}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9019",
      "amount": 52.77000045776367,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":52.77,\"lineAmount\":52.77}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8978",
      "amount": 27.049999237060547,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIRECT TOOLS FACTORY O   MYRTLE DIRECT TOOLS FACTORY O MYRTLE BEACH SC\",\"unitPrice\":27.05,\"lineAmount\":27.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8979",
      "amount": 26.34000015258789,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"EXXON TIGER MART #11     AYNOR EXXON TIGER MART #11 AYNOR SC\",\"unitPrice\":26.34,\"lineAmount\":26.34}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8976",
      "amount": 54.41999816894531,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX9106    COLUMBI SHELL OIL XXXXXXX9106 COLUMBIA SC\",\"unitPrice\":54.42,\"lineAmount\":54.42}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9020",
      "amount": 13.729999542236328,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":13.73,\"lineAmount\":13.73}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123547",
      "amount": 150.5500030517578,
      "vendor": "BTE",
      "customer": "Dale Construction Company",
      "dateCreated": "2024-05-24 14:28:59",
      "dateFulfilled": "2024-05-28T12:33:18.935Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"2400121\",\"description\":\"COUP PUMP NYLON SLEEVE 24-42B\",\"unitPrice\":70.35,\"lineAmount\":140.7}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123546",
      "amount": 4980.31982421875,
      "vendor": "BTE",
      "customer": "Spinnaker General Contractors Inc",
      "dateCreated": "2024-05-24 11:08:54",
      "dateFulfilled": "2024-05-30T19:11:43.277Z",
      "origin": "dan",
      "items": "[{\"quantity\":2,\"partNumber\":\"80-1610x2.25\",\"description\":\"16\\\" auger 10' long on 2-1/4\\\" hex with 3/8\\\" flights\",\"unitPrice\":2250,\"lineAmount\":4500},{\"quantity\":10,\"partNumber\":\"R700140\",\"description\":\"PIN SHOULDER 2-1/4HEX CONN\",\"unitPrice\":14.5,\"lineAmount\":145},{\"quantity\":10,\"partNumber\":\"R700180\",\"description\":\"1/8\\\" cotter pin for 2-1/4\\\", 3\\\", 4\\\" and 5\\\" hex pins\",\"unitPrice\":0.95,\"lineAmount\":9.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8683",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8944",
      "amount": 5.269999980926514,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":5.27,\"lineAmount\":5.27}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123545",
      "amount": 1534.75,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-05-23 20:04:07",
      "dateFulfilled": "2024-05-30T19:32:23.413Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"P330000\",\"description\":\"SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED \",\"unitPrice\":980,\"lineAmount\":980},{\"quantity\":1,\"partNumber\":\"3600132\",\"description\":\"Companion flange for 24D, Mcl 30 thru 42, 3642D\",\"unitPrice\":525,\"lineAmount\":525}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123544",
      "amount": 29400,
      "vendor": "BTE",
      "customer": "Bore Company LLC",
      "dateCreated": "2024-05-23 19:05:57",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"5-CS600PB\",\"description\":\"PIGGY-BACK FOR CONTROL STATION, one reel 400' 1/2 hose, one reel 400' 3/8\\\" hose\",\"unitPrice\":4500,\"lineAmount\":4500},{\"quantity\":1,\"partNumber\":\"19-2420ROLLX4W\\\\C\",\"description\":\"RUSH 24\\\" XTRM ROLLER CUTTER WITH WING CUTTERS ON 4\\\" HEX. CUTS 27\\\".\",\"unitPrice\":24900,\"lineAmount\":24900}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123542",
      "amount": 21423.060546875,
      "vendor": "BTE",
      "customer": "Brad Atkinson",
      "dateCreated": "2024-05-23 11:25:44",
      "dateFulfilled": "2024-05-30T10:24:55.864Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"4800348\",\"description\":\"SHAFT INPUT ADAPTER\",\"unitPrice\":2769.48,\"lineAmount\":2769.48},{\"quantity\":1,\"partNumber\":\"18-4836XTRMx4\",\"description\":\"48\\\" xtrm OTS cuttting head on 4\\\" hex w/King Kong teeth\",\"unitPrice\":18500,\"lineAmount\":18500},{\"quantity\":6,\"partNumber\":\"15-BTE1003\",\"description\":\"King Kong Teeth - flat face\",\"unitPrice\":340,\"lineAmount\":2040}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8961",
      "amount": 2.990000009536743,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":2.99,\"lineAmount\":2.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8960",
      "amount": 8,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CIRCLE K # 23416         ROEBUCK CIRCLE K # 23416 ROEBUCK SC\",\"unitPrice\":8,\"lineAmount\":8}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1065",
      "amount": 8568,
      "vendor": "Hydraulics Direct",
      "customer": "BTE",
      "dateCreated": "2024-05-22T12:22:54.257Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"4\",\"partNumber\":\"5HYD614\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123541",
      "amount": 3442,
      "vendor": "BTE",
      "customer": "Singleton Excavating",
      "dateCreated": "2024-05-22 20:11:28",
      "dateFulfilled": "2024-05-30T19:33:17.822Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-12COMBOx1.625\",\"description\":\"12\\\" combo cutting head with wing cutters on 1-5/8\\\" hex with carbide teeth\",\"unitPrice\":1800,\"lineAmount\":1800},{\"quantity\":1,\"partNumber\":\"S601130\",\"description\":\"LEAD SCREW DIRT 1\\\" V\",\"unitPrice\":175,\"lineAmount\":175},{\"quantity\":1,\"partNumber\":\"S900703\",\"description\":\"LEAD SCREW PAD 1\\\"-8 FM\",\"unitPrice\":5,\"lineAmount\":5},{\"quantity\":1,\"partNumber\":\"80-1204x1-5/8x1/4\",\"description\":\"12” auger x 4’ long on 1-5/8” hex with 1/4 flights\",\"unitPrice\":1462,\"lineAmount\":1462}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123540",
      "amount": 727.9600219726562,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2024-05-22 18:49:29",
      "dateFulfilled": "2024-05-23T14:59:14.292Z",
      "origin": "dan",
      "items": "[{\"quantity\":30,\"partNumber\":\"15-S600801\",\"description\":\"NON ROTATING FLAT CARBIDE TEETH FOR COMBO CUTTING HEADS AND STD OTS CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":750}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123539",
      "amount": 0,
      "vendor": "BTE",
      "customer": "DRS Enterprises",
      "dateCreated": "2024-05-22 14:58:46",
      "dateFulfilled": "2024-05-23T09:37:55.998Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"19-3024XTRMTTX4W/C\",\"description\":\"30\\\" RUSH XTRM CUTTING HEAD WITH LARGE TRENCHER TEETH ON 4\\\" HEX WITH WING CUTTERS. CUTS 33\\\".\",\"unitPrice\":12500,\"lineAmount\":12500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123538",
      "amount": 214,
      "vendor": "BTE",
      "customer": "Keener Construction CO.",
      "dateCreated": "2024-05-22 14:49:15",
      "dateFulfilled": "2024-05-22T17:12:03.871Z",
      "origin": "lois",
      "items": "[{\"quantity\":8,\"partNumber\":\"Teeth:BTE1003\",\"description\":\"CONICAL ROCK TEETH FOR COMBO CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":200}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123537",
      "amount": 2592.110107421875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-05-22 13:53:51",
      "dateFulfilled": "2024-05-23T14:59:25.466Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"P330000\",\"description\":\"SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED \",\"unitPrice\":980,\"lineAmount\":980},{\"quantity\":5,\"partNumber\":\"2400121\",\"description\":\"COUP PUMP NYLON SLEEVE 24-42B\",\"unitPrice\":70.35,\"lineAmount\":351.75},{\"quantity\":1,\"partNumber\":\"P260100\",\"description\":\"HALF COUPLING MCL24B 602-R 1-3/8 FB\",\"unitPrice\":554.75,\"lineAmount\":554.75},{\"quantity\":1,\"partNumber\":\"P260110\",\"description\":\"COUPLING CENTER MEMBER MCL24B\",\"unitPrice\":1278.3,\"lineAmount\":1278.3},{\"quantity\":1,\"partNumber\":\"2400089\",\"description\":\"COUPLING ADAPTER PLATE MCL-24B\",\"unitPrice\":37.42,\"lineAmount\":37.42}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8984",
      "amount": 634.4299926757812,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DUKEENERGY dan PAY ********8409\",\"unitPrice\":634.43,\"lineAmount\":634.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8962",
      "amount": 23.049999237060547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SXM*SIRIUSXM.COM/ACCT    888-635 SXM*SIRIUSXM.COM/ACCT 888-635-5144 NY\",\"unitPrice\":23.05,\"lineAmount\":23.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8959",
      "amount": 167.07000732421875,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Spectrum                 855-707 Spectrum 855-707-7328 MO\",\"unitPrice\":167.07,\"lineAmount\":167.07}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8888",
      "amount": 120.47000122070312,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":120.47,\"lineAmount\":120.47}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1066",
      "amount": 2472,
      "vendor": "Hydraulics Direct",
      "customer": "BTE",
      "dateCreated": "2024-05-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"partNumber\":\"5HYD613\",\"description\":\"3/8\\\" HYDRAULIC HOSE 600' LONG FOR CONTROL STATION (GOES ON HOSE REEL)\",\"unitPrice\":2472,\"lineAmount\":2472}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9254",
      "amount": 63.33000183105469,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIGI-KEY (XXX)XXX-4539 MN XXXX-XXXXXX-X1019\",\"unitPrice\":63.33,\"lineAmount\":63.33}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8931",
      "amount": 58.68000030517578,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING TRACTOR SUPPLY #2516 BOILING SPRINSC\",\"unitPrice\":58.68,\"lineAmount\":58.68}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9264",
      "amount": 3433.35009765625,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INFINITE ELECTRONICSIRVINE CA XXXX-XXXXXX-X1019\",\"unitPrice\":3433.35,\"lineAmount\":3433.35}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8963",
      "amount": 1,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ZAPIER.COM/CHARGE        ZAPIER. ZAPIER.COM/CHARGE ZAPIER.COM CA\",\"unitPrice\":1,\"lineAmount\":1}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8409",
      "amount": 634.4299926757812,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":634.43,\"lineAmount\":634.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8933",
      "amount": 98.43000030517578,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":98.43,\"lineAmount\":98.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8932",
      "amount": 923.3200073242188,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"GODADDY.COM XXX-XXX-8855 AZ XXXX-XXXXXX-X1019\",\"unitPrice\":923.32,\"lineAmount\":923.32}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8930",
      "amount": 18.850000381469727,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":18.85,\"lineAmount\":18.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8983",
      "amount": 68.61000061035156,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-O NEXT Insur (AP I NEXT Insur ST-O*Z*V6R1I5G7\",\"unitPrice\":68.61,\"lineAmount\":68.61}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8929",
      "amount": 17.110000610351562,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":17.11,\"lineAmount\":17.11}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8703",
      "amount": 18.75,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":18.75,\"lineAmount\":18.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8716",
      "amount": 35.13999938964844,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":35.14,\"lineAmount\":35.14}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1064",
      "amount": 26.399999618530273,
      "vendor": "Hydraulics Direct",
      "customer": "BTE",
      "dateCreated": "2024-05-18T11:55:08.935Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"12\",\"partNumber\":\"5HYD618\"}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1063",
      "amount": 36.720001220703125,
      "vendor": "Hydraulics Direct",
      "customer": "BTE",
      "dateCreated": "2024-05-18T11:49:31.772Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"12\",\"partNumber\":\"5HYD605\"}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8928",
      "amount": 51.72999954223633,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":51.73,\"lineAmount\":51.73}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9210",
      "amount": 19.5,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":19.5,\"lineAmount\":19.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8938",
      "amount": 16.56999969482422,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON.COM AMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":16.57,\"lineAmount\":16.57}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1061",
      "amount": 18.239999771118164,
      "vendor": "Hydraulics Direct",
      "customer": "BTE",
      "dateCreated": "2024-05-17T19:24:44.402Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"12\",\"partNumber\":\"5HYD603\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123536",
      "amount": 256.79998779296875,
      "vendor": "BTE",
      "customer": "Shockley Plumbing",
      "dateCreated": "2024-05-17 18:24:45",
      "dateFulfilled": "2024-05-29T12:46:30.418Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"3600023-1\",\"description\":\"HOLD DOWN ASSEMBLY FOR MCL 36/42B\",\"unitPrice\":120,\"lineAmount\":240}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8717",
      "amount": 223.91000366210938,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"WWW.SUPERBRIGHTLEDS.CO   HTTPSWW WWW.SUPERBRIGHTLEDS.CO HTTPSWWW.SUPEMO\",\"unitPrice\":223.91,\"lineAmount\":223.91}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8937",
      "amount": 75.87999725341797,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":75.88,\"lineAmount\":75.88}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8934",
      "amount": 12.84000015258789,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KENNAMETAL INC XXX-XXX-7738 PA XXXX-XXXXXX-X1019\",\"unitPrice\":12.84,\"lineAmount\":12.84}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8650",
      "amount": 50,
      "vendor": "Amazon",
      "customer": "BTE",
      "dateCreated": "2024-05-16T19:43:39.180Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"10\",\"partNumber\":\"E0010\"}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1058",
      "amount": 624,
      "vendor": "Sherwin Williams",
      "customer": "BTE",
      "dateCreated": "2024-05-15T11:57:43.685Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"13\",\"partNumber\":\"BTE-1194\"}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1057",
      "amount": 480,
      "vendor": "Sherwin Williams",
      "customer": "BTE",
      "dateCreated": "2024-05-15T11:47:25.172Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"10\",\"partNumber\":\"BTE-1190\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123535",
      "amount": 1498.8399658203125,
      "vendor": "BTE",
      "customer": "Vermeer All Roads-Roanoke",
      "dateCreated": "2024-05-15 13:29:41",
      "dateFulfilled": "2024-05-15T16:33:26.676Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"X000095\",\"description\":\"SWITCH TOGGLE MCL36/42-48/54\",\"unitPrice\":24.89,\"lineAmount\":24.89},{\"quantity\":1,\"partNumber\":\"X000020\",\"description\":\"E STOP COMPLETE\",\"unitPrice\":346.3,\"lineAmount\":346.3},{\"quantity\":1,\"partNumber\":\"4800810\",\"description\":\"GRIP W/ SWITCH MCL48/54 N.O. SWITCH\",\"unitPrice\":277.48,\"lineAmount\":277.48},{\"quantity\":4,\"partNumber\":\"S600210\",\"description\":\"CONICAL WC 2 BIT 1\\\" SPACER for 18-48\\\" McL ROCK CUTTERS\",\"unitPrice\":295,\"lineAmount\":1180}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8718",
      "amount": 15.930000305175781,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":15.93,\"lineAmount\":15.93}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8936",
      "amount": 16.899999618530273,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019\",\"unitPrice\":16.9,\"lineAmount\":16.9}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8618",
      "amount": 143.3699951171875,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"WATER PUMP FOR CONTROL STATION (24V/5.5GPM)\",\"unitPrice\":133.99,\"lineAmount\":133.99},{\"quantity\":1,\"unitPrice\":9.38,\"lineAmount\":9.38}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8720",
      "amount": 42.04999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"EL MEX                   INMAN EL MEX INMAN SC\",\"unitPrice\":42.05,\"lineAmount\":42.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8719",
      "amount": 5.550000190734863,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CIRCLE K # 23416         ROEBUCK CIRCLE K # 23416 ROEBUCK SC\",\"unitPrice\":5.55,\"lineAmount\":5.55}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1059",
      "amount": 118.4800033569336,
      "vendor": "Carolina Bearing & Belting",
      "customer": "BTE",
      "dateCreated": "2024-05-14T17:24:20.294Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"2\",\"partNumber\":\"8080229\"}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8517",
      "amount": 149.9499969482422,
      "vendor": "Amazon",
      "customer": "BTE",
      "dateCreated": "2024-05-14T11:05:01.822Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"1\",\"partNumber\":\"E0001\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123534",
      "amount": 12284.099609375,
      "vendor": "BTE",
      "customer": "City Albemarle",
      "dateCreated": "2024-05-14 17:24:19",
      "dateFulfilled": "2024-06-10T11:05:08.743Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"HD30620\",\"description\":\"CYL HYD.MCL-12H ALTERNATIVE TO HD30610 287021 - WI\",\"unitPrice\":970.76,\"lineAmount\":1941.52},{\"quantity\":1,\"partNumber\":\"8080227\",\"description\":\"SEAL FRONT SHAFT MCL10H/12HB\",\"unitPrice\":195.16,\"lineAmount\":195.16},{\"quantity\":2,\"partNumber\":\"8080229\",\"description\":\"THRUST BEARING FOR 12HB CARRIAGE ASSEMBLY\",\"unitPrice\":207.34,\"lineAmount\":414.68},{\"quantity\":2,\"partNumber\":\"8080228\",\"description\":\"BEARING CUP MCL10H/12HB REAR DRIVE SHAFT\",\"unitPrice\":124.01,\"lineAmount\":248.02},{\"quantity\":1,\"partNumber\":\"HD11010\",\"description\":\"DRIVE SHAFT FOR MCL 12HB CARRAIGE ASSEMBLY\",\"unitPrice\":784.16,\"lineAmount\":784.16},{\"quantity\":1,\"partNumber\":\"HD40050\",\"description\":\"1-5/8\\\" CHUCK ASSEMBLY FOR McL 12HB\",\"unitPrice\":407.4,\"lineAmount\":407.4},{\"quantity\":1,\"partNumber\":\"8080231\",\"description\":\"LOCK NUT C-CLAMP MCL-12HB/10H DRIVE SHAFT\",\"unitPrice\":88.27,\"lineAmount\":88.27},{\"quantity\":4,\"partNumber\":\"W000020\",\"description\":\"CAM ROLLER BEARING FOR 12HB CARRIAGE ASSEMBLY\",\"unitPrice\":74,\"lineAmount\":296},{\"quantity\":1,\"partNumber\":\"HD14008\",\"description\":\"8\\\" ADAPTER KIT / PUSH PLATE FOR McL 12HB\",\"unitPrice\":640.52,\"lineAmount\":640.52},{\"quantity\":1,\"partNumber\":\"HD50000B\",\"description\":\"SADDLE (MODIFIED BY US TO WORK FOR 8\\\" ONLY) FOR McL 12HB\",\"unitPrice\":64.9,\"lineAmount\":64.9},{\"quantity\":1,\"partNumber\":\"HD7000\",\"description\":\"MASTER TRACK FOR 12H ZIPPER 84\\\" LONG\",\"unitPrice\":3950,\"lineAmount\":3950},{\"quantity\":1,\"partNumber\":\"HD6005\",\"description\":\"EXTENSION TRACK FOR 12H ZIPPER FOR RUNNING 10' AUGERS\",\"unitPrice\":3900,\"lineAmount\":3900}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123533",
      "amount": 2494.800048828125,
      "vendor": "BTE",
      "customer": "Vermeer Midsouth",
      "dateCreated": "2024-05-14 17:21:02",
      "dateFulfilled": "2024-05-15T16:33:33.167Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2050091\",\"description\":\"CLUTCH HYD MCL-20B (L)\",\"unitPrice\":2525.25,\"lineAmount\":2525.25},{\"quantity\":1,\"partNumber\":\"2050092\",\"description\":\"drive cup\",\"unitPrice\":553,\"lineAmount\":553}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123532",
      "amount": 906.489990234375,
      "vendor": "BTE",
      "customer": "LW INC",
      "dateCreated": "2024-05-14 14:20:53",
      "dateFulfilled": "2024-05-15T13:05:38.328Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400121\",\"description\":\"COUP PUMP NYLON SLEEVE 24-42B\",\"unitPrice\":70.35,\"lineAmount\":70.35},{\"quantity\":1,\"partNumber\":\"2400158\",\"description\":\"COUP PUMP HUB PUMP SIDE 24B 9-TOOTH W/CROSS CL\",\"unitPrice\":277.2,\"lineAmount\":277.2},{\"quantity\":1,\"partNumber\":\"2400156\",\"description\":\"COUP PUMP HUB ENG SIDE 24-42B\",\"unitPrice\":265.58,\"lineAmount\":265.58},{\"quantity\":3,\"partNumber\":\"2400277\",\"description\":\"BRACKET VALVE HANDLE 24B (H)\",\"unitPrice\":78.02,\"lineAmount\":234.06}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1053",
      "amount": 158,
      "vendor": "Logan Clutch Corporation",
      "customer": "BTE",
      "dateCreated": "2024-05-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"partNumber\":\"2050091\",\"unitPrice\":158,\"lineAmount\":158}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8565",
      "amount": 188.8800048828125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES BRC PAYMENT ***********991 LOWES BRC PAYMENT ***********9919\",\"unitPrice\":188.88,\"lineAmount\":188.88}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8533",
      "amount": 70.37000274658203,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":70.37,\"lineAmount\":70.37}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8465",
      "amount": 273.6199951171875,
      "vendor": "BARNDOOR AG",
      "customer": "BTE",
      "dateCreated": "2024-05-13T10:50:10.144Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"2\",\"partNumber\":\"2400167\"}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8464",
      "amount": 535.9600219726562,
      "vendor": "Amazon",
      "customer": "BTE",
      "dateCreated": "2024-05-13T10:49:58.791Z",
      "dateFulfilled": '',
      "origin": "chad",
      "items": "[{\"quantity\":\"4\",\"partNumber\":\"CSW001\"}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123531",
      "amount": 859.25,
      "vendor": "BTE",
      "customer": "Alabama Grading",
      "dateCreated": "2024-05-13 17:30:35",
      "dateFulfilled": "2024-05-14T20:07:58.847Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400160\",\"description\":\"COUPLING HYD PUMP MCL24/30B\",\"unitPrice\":838.22,\"lineAmount\":838.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8585",
      "amount": 135.22000122070312,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT #1129     SPARTAN THE HOME DEPOT #1129 SPARTANBURG SC\",\"unitPrice\":135.22,\"lineAmount\":135.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8509",
      "amount": 46.09000015258789,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":46.09,\"lineAmount\":46.09}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8567",
      "amount": 242.00999450683594,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-E NEXT Insur (AP I NEXT Insur ST-E*S*F1K4L3W4\",\"unitPrice\":242.01,\"lineAmount\":242.01}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9018",
      "amount": 9522.8798828125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FCB FUNDS TRANSFER TO  X2286\",\"unitPrice\":9522.88,\"lineAmount\":9522.88}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8742",
      "amount": 90.0199966430664,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":90.02,\"lineAmount\":90.02}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8591",
      "amount": 29.280000686645508,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":29.28,\"lineAmount\":29.28}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8592",
      "amount": 11.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.99,\"lineAmount\":11.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8743",
      "amount": 88.51000213623047,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEXXXXXX7942 FedExMEMPHIS TN XXXX-XXXXXX-X1019\",\"unitPrice\":88.51,\"lineAmount\":88.51}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8745",
      "amount": 22.75,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":22.75,\"lineAmount\":22.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8744",
      "amount": 424.9800109863281,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":424.98,\"lineAmount\":424.98}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8622",
      "amount": 681.0800170898438,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":681.08,\"lineAmount\":681.08}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8302",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"payday 5.17.2024\",\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123530",
      "amount": 133.89999389648438,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-05-09 16:44:16",
      "dateFulfilled": "2024-05-13T15:47:17.110Z",
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"3600086\",\"description\":\"ISOLATOR FRONT MCL24B FRONT & REAR ISOLATOR MCL30-\",\"unitPrice\":46.73,\"lineAmount\":93.46}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123529",
      "amount": 495,
      "vendor": "BTE",
      "customer": "Keener Construction CO.",
      "dateCreated": "2024-05-09 14:34:23",
      "dateFulfilled": "2024-05-14T20:08:06.084Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"R800185\",\"description\":\"2-1/4” FM x 1-5/8” M adapter\",\"unitPrice\":495,\"lineAmount\":495}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123528",
      "amount": 174.25,
      "vendor": "BTE",
      "customer": "Dan's Marine Service, Inc",
      "dateCreated": "2024-05-09 13:49:18",
      "dateFulfilled": "2024-05-09T14:45:09.248Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400121\",\"description\":\"COUP PUMP NYLON SLEEVE 24-42B\",\"unitPrice\":70.35,\"lineAmount\":70.35}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123527",
      "amount": 1765.1700439453125,
      "vendor": "BTE",
      "customer": "Vermeer Mountain West",
      "dateCreated": "2024-05-09 13:43:22",
      "dateFulfilled": "2024-05-09T14:45:16.790Z",
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"8080189\",\"description\":\"CLAMPING JAW\",\"unitPrice\":242.73,\"lineAmount\":970.92},{\"quantity\":1,\"partNumber\":\"8080232\",\"description\":\"WATER SWIVEL MCL10H\",\"unitPrice\":620.52,\"lineAmount\":620.52},{\"quantity\":1,\"partNumber\":\"8080350\",\"description\":\"SUB SAVER FIRESTICK MCL-10H\",\"unitPrice\":365,\"lineAmount\":365}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8623",
      "amount": 890,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"STV MOTORSPORTS LAS VEGAS NV XXXX-XXXXXX-X1019\",\"unitPrice\":890,\"lineAmount\":890}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8586",
      "amount": 611,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ASHEVILLE HWY RENTAL     864-583 ASHEVILLE HWY RENTAL 864-5836393 SC\",\"unitPrice\":611,\"lineAmount\":611}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123516",
      "amount": 269.5299987792969,
      "vendor": "BTE",
      "customer": "Claude H Nix Construction",
      "dateCreated": "2024-05-08 17:47:29",
      "dateFulfilled": "2024-05-09T02:08:47.348Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"E0005\",\"description\":\"COLLECTOR RING 6 WIRE 15 AMP FOR CONTROL STATION\",\"unitPrice\":156,\"lineAmount\":156},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":113.53,\"lineAmount\":113.53}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123515",
      "amount": 962.0800170898438,
      "vendor": "BTE",
      "customer": "dan Sullivan Excavation",
      "dateCreated": "2024-05-08 15:03:27",
      "dateFulfilled": "2024-05-09T14:02:36.609Z",
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":962.08,\"lineAmount\":962.08}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8746",
      "amount": 5,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BT*TURBOSQUID NEW ORLEANS LA XXXX-XXXXXX-X1019\",\"unitPrice\":5,\"lineAmount\":5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8593",
      "amount": 21.90999984741211,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":21.91,\"lineAmount\":21.91}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8447",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8617",
      "amount": 34.439998626708984,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":34.44,\"lineAmount\":34.44}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8594",
      "amount": 11.760000228881836,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.76,\"lineAmount\":11.76}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123512",
      "amount": 1799.47998046875,
      "vendor": "BTE",
      "customer": "Dan's Marine Service",
      "dateCreated": "2024-05-06 17:12:36",
      "dateFulfilled": "2024-05-07T21:25:23.469Z",
      "origin": "lois",
      "items": "[{\"quantity\":50,\"partNumber\":\"Teeth:BTE1003\",\"description\":\"CONICAL ROCK TEETH FOR COMBO CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":1250},{\"quantity\":2,\"partNumber\":\"16-1016W/Cx5/8\",\"description\":\"WING CUTTER FOR 10”-16” COMBO CUTTING HEAD includes carbid tooth\",\"unitPrice\":245,\"lineAmount\":490},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":59.48,\"lineAmount\":59.48}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123511",
      "amount": 160.6999969482422,
      "vendor": "BTE",
      "customer": "Ballantyne Infrastructure Equipment",
      "dateCreated": "2024-05-06 14:39:42",
      "dateFulfilled": "2024-05-08T10:01:31.523Z",
      "origin": "dan",
      "items": "[{\"quantity\":10,\"partNumber\":\"R700140\",\"description\":\"PIN SHOULDER 2-1/4HEX CONN\",\"unitPrice\":15.12,\"lineAmount\":151.2},{\"quantity\":10,\"partNumber\":\"R700180\",\"description\":\"1/8\\\" cotter pin for 2-1/4\\\", 3\\\", 4\\\" and 5\\\" hex pins\",\"unitPrice\":0.95,\"lineAmount\":9.5}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123510",
      "amount": 1605,
      "vendor": "BTE",
      "customer": "Ballantyne Infrastructure Equipment",
      "dateCreated": "2024-05-06 14:15:56",
      "dateFulfilled": "2024-05-08T10:01:01.640Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-12COMBOx2.25\",\"description\":\"12\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":1500,\"lineAmount\":1500}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8368",
      "amount": 121.43000030517578,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":121.43,\"lineAmount\":121.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8420",
      "amount": 25,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#295\",\"unitPrice\":25,\"lineAmount\":25}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8747",
      "amount": 77.48999786376953,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":77.49,\"lineAmount\":77.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8595",
      "amount": 46.2400016784668,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":46.24,\"lineAmount\":46.24}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8748",
      "amount": 12.5,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":12.5,\"lineAmount\":12.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8749",
      "amount": 198.30999755859375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":198.31,\"lineAmount\":198.31}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8721",
      "amount": 34.33000183105469,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Amazon web services      aws.ama Amazon web services aws.amazon.coWA\",\"unitPrice\":34.33,\"lineAmount\":34.33}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8596",
      "amount": 10.6899995803833,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":10.69,\"lineAmount\":10.69}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123509",
      "amount": 477.7200012207031,
      "vendor": "BTE",
      "customer": "H & H Road Boring Company",
      "dateCreated": "2024-05-02 20:43:24",
      "dateFulfilled": "2024-05-03T11:11:33.426Z",
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"CSW001\",\"description\":\"WATER PUMP FOR CONTROL STATION (24V/5.5GPM)\",\"unitPrice\":454.97,\"lineAmount\":454.97},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":22.75,\"lineAmount\":22.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8620",
      "amount": 9.569999694824219,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL SERVICE STATIOINMAN SC XXXX-XXXXXX-X1019\",\"unitPrice\":9.57,\"lineAmount\":9.57}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8619",
      "amount": 117.87999725341797,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"EL MEX XXXXXXXXX4485INMAN SC XXXX-XXXXXX-X1019\",\"unitPrice\":117.88,\"lineAmount\":117.88}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8598",
      "amount": 223.1999969482422,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"WOODRUFF FAMILY DENTISTRYWOODRUF WOODRUFF FAMILY DENTISTRYWOODRUFF SC\",\"unitPrice\":223.2,\"lineAmount\":223.2}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8597",
      "amount": 12.829999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":12.83,\"lineAmount\":12.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8573",
      "amount": 118.05000305175781,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Indeed 92294177          800-462 Indeed 92294177 800-4625842 TX\",\"unitPrice\":118.05,\"lineAmount\":118.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8608",
      "amount": 267.5,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTUIT *QBooks Online    CL.INTU INTUIT *QBooks Online CL.INTUIT.COMCA\",\"unitPrice\":267.5,\"lineAmount\":267.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8574",
      "amount": 389,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ANSWERFORCE              800-461 ANSWERFORCE 800-461-8520 OR\",\"unitPrice\":389,\"lineAmount\":389}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8107",
      "amount": 22.360000610351562,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-05-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":22.36,\"lineAmount\":22.36}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8599",
      "amount": 49.59000015258789,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-05-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":49.59,\"lineAmount\":49.59}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8750",
      "amount": 59.52000045776367,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-05-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":59.52,\"lineAmount\":59.52}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8338",
      "amount": 148.75999450683594,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-04-30",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":148.76,\"lineAmount\":148.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8751",
      "amount": 41.2599983215332,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":41.26,\"lineAmount\":41.26}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8236",
      "amount": 225.07000732421875,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-D NEXT Insur (AP I NEXT Insur ST-D*K*A2U5W1U5\",\"unitPrice\":225.07,\"lineAmount\":225.07}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8752",
      "amount": 32.36000061035156,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":32.36,\"lineAmount\":32.36}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8753",
      "amount": 75.27999877929688,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":75.28,\"lineAmount\":75.28}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8607",
      "amount": 976.5700073242188,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QUALITY HAULERS OF SC LLC864-969 QUALITY HAULERS OF SC LLC864-9692329 SC\",\"unitPrice\":976.57,\"lineAmount\":976.57}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8078",
      "amount": 45.61000061035156,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":45.61,\"lineAmount\":45.61}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7973",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"payday 5/3/24\",\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8238",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#196\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8237",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#191\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8424",
      "amount": 4766.85986328125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KK ROCK TEETH\",\"unitPrice\":514.8,\"lineAmount\":514.8},{\"quantity\":1,\"description\":\"King Kong Teeth - flat face\",\"unitPrice\":3600,\"lineAmount\":3600},{\"quantity\":1,\"description\":\"KING KONG Teeth weld-on\",\"unitPrice\":521,\"lineAmount\":521},{\"quantity\":1,\"unitPrice\":131.06,\"lineAmount\":131.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8072",
      "amount": 11.420000076293945,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":11.42,\"lineAmount\":11.42}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8425",
      "amount": 800,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"STV MOTORSPORTS LAS VEGAS NV XXXX-XXXXXX-X1019\",\"unitPrice\":800,\"lineAmount\":800}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8621",
      "amount": 128.27000427246094,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DROPBOX*6Z3TDXCXRYYJSAN FRANCISCO CA XXXX-XXXXXX-X1019\",\"unitPrice\":128.27,\"lineAmount\":128.27}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8235",
      "amount": 582.0399780273438,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DUKEENERGY dan PAY ********8409\",\"unitPrice\":582.04,\"lineAmount\":582.04}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8600",
      "amount": 78.41999816894531,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"VISTAPRINT               866-207 VISTAPRINT 866-207-4955 MA\",\"unitPrice\":78.42,\"lineAmount\":78.42}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8062",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123499",
      "amount": 12749.650390625,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-04-23",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"17-16ROLLERX3\",\"description\":\"16\\\" rollerbit cutting head with NO wing cutters on 3\\\" hex, cuts 17-17.5\\\"\",\"unitPrice\":12500,\"lineAmount\":12500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123498",
      "amount": 326.2300109863281,
      "vendor": "BTE",
      "customer": "Dan's Marine Service, Inc",
      "dateCreated": "2024-04-23",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":326.23,\"lineAmount\":326.23}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123497",
      "amount": 701.8699951171875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Colfax NC",
      "dateCreated": "2024-04-23",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":2,\"partNumber\":\"S600510\",\"description\":\"BIT CARBIDE 1/2\\\" X 1/2\\\"\",\"unitPrice\":20.2,\"lineAmount\":40.4},{\"quantity\":2,\"partNumber\":\"S600530\",\"description\":\"BIT CARBIDE 1/2\\\" X 1-1/2\\\"\",\"unitPrice\":70.18,\"lineAmount\":140.36},{\"quantity\":1,\"partNumber\":\"S050RF200\",\"description\":\"HEAD, COMBO 6'' OD 1/58' HEX\",\"unitPrice\":677.39,\"lineAmount\":677.39},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":19.19,\"lineAmount\":19.19}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8601",
      "amount": 2.990000009536743,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":2.99,\"lineAmount\":2.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8233",
      "amount": 27.3700008392334,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPARTANBURG WATE danPAY SPARTAN SPARTANBURG WATE danPAY SPARTANBURG WAT\",\"unitPrice\":27.37,\"lineAmount\":27.37}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8242",
      "amount": 17025.640625,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FCB FUNDS TRANSFER TO  X9424 - Customer Deposit - Trust check, accidentally deposit in Boretec\",\"unitPrice\":17025.64,\"lineAmount\":17025.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8030",
      "amount": 30.260000228881836,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":30.26,\"lineAmount\":30.26}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7984",
      "amount": 582.0399780273438,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Feb 23- Mar 22, 2024\",\"unitPrice\":582.04,\"lineAmount\":582.04}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8575",
      "amount": 167.07000732421875,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Spectrum                 855-707 Spectrum 855-707-7328 MO\",\"unitPrice\":167.07,\"lineAmount\":167.07}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123495",
      "amount": 1012.1199951171875,
      "vendor": "BTE",
      "customer": "Long & Sons Utility Company",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400357\",\"description\":\"VALVE HNDL W/LINK V20 24B/30B PISTOL GRIP HDL\",\"unitPrice\":528,\"lineAmount\":528},{\"quantity\":1,\"partNumber\":\"X000020\",\"description\":\"E STOP COMPLETE\",\"unitPrice\":346.3,\"lineAmount\":346.3},{\"quantity\":1,\"partNumber\":\"X000300\",\"description\":\"HOUR METER\",\"unitPrice\":55,\"lineAmount\":55},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":17.77,\"lineAmount\":17.77}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8754",
      "amount": 119.81999969482422,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":119.82,\"lineAmount\":119.82}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8602",
      "amount": 23.049999237060547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SXM*SIRIUSXM.COM/ACCT    888-635 SXM*SIRIUSXM.COM/ACCT 888-635-5144 NY\",\"unitPrice\":23.05,\"lineAmount\":23.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8603",
      "amount": 34.7400016784668,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"7-ELEVEN 38816           SPARTAN 7-ELEVEN 38816 SPARTANSBURG SC\",\"unitPrice\":34.74,\"lineAmount\":34.74}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8756",
      "amount": 1062.2099609375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KENNAMETAL INC XXX-XXX-7738 PA XXXX-XXXXXX-X1019\",\"unitPrice\":1062.21,\"lineAmount\":1062.21}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8755",
      "amount": 5.010000228881836,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KENNAMETAL INC XXX-XXX-7738 PA XXXX-XXXXXX-X1019\",\"unitPrice\":5.01,\"lineAmount\":5.01}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8866",
      "amount": 661.3200073242188,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BLT*PCLiquidations.com   888-654 BLT*PCLiquidations.com 888-6543376 FL\",\"unitPrice\":661.32,\"lineAmount\":661.32}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8757",
      "amount": 193.6999969482422,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIGI-KEY (XXX)XXX-4539 MN XXXX-XXXXXX-X1019\",\"unitPrice\":193.7,\"lineAmount\":193.7}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123494",
      "amount": 124040.25,
      "vendor": "BTE",
      "customer": "Bore Company LLC",
      "dateCreated": "2024-04-18",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"3-2420x4\",\"description\":\"24\\\" RUSH OTS w/20\\\" auger on 4\\\" Hex\\nBTERUSH241801\",\"unitPrice\":52500,\"lineAmount\":52500},{\"quantity\":1,\"partNumber\":\"19-2420PDCX4W/C\",\"description\":\"24\\\" RUSH CUTTING HEAD WITH PDC CUTTERS WITH WING CUTTERS\",\"unitPrice\":35000,\"lineAmount\":35000},{\"quantity\":1,\"partNumber\":\"5-CS600\",\"description\":\"600' Control Station\\nBTE600SS10\",\"unitPrice\":49000,\"lineAmount\":49000},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":1322.5,\"lineAmount\":1322.5}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123493",
      "amount": 2096,
      "vendor": "BTE",
      "customer": "Dan's Marine Service, Inc",
      "dateCreated": "2024-04-18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-12COMBOx2.25\",\"description\":\"12\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":1500,\"lineAmount\":1500},{\"quantity\":8,\"partNumber\":\"Teeth:BTE1003\",\"description\":\"CONICAL ROCK TEETH FOR COMBO CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":200},{\"quantity\":8,\"partNumber\":\"15-S600801\",\"description\":\"NON ROTATING FLAT CARBIDE TEETH FOR COMBO CUTTING HEADS AND STD OTS CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":200},{\"quantity\":2,\"partNumber\":\"S600120\",\"description\":\"BIT WING CUTTER COMBO 10\\\"-16\\\"\",\"unitPrice\":98,\"lineAmount\":196}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7977",
      "amount": 27.579999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":27.58,\"lineAmount\":27.58}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7969",
      "amount": 73.36000061035156,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":73.36,\"lineAmount\":73.36}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123491",
      "amount": 127.11000061035156,
      "vendor": "BTE",
      "customer": "Fortis Siteworks",
      "dateCreated": "2024-04-17",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"S601130\",\"description\":\"LEAD SCREW DIRT 1\\\" V\",\"unitPrice\":98.67,\"lineAmount\":98.67},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":28.44,\"lineAmount\":28.44}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8002",
      "amount": 16,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Wire Transfer Fee\",\"unitPrice\":16,\"lineAmount\":16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7935",
      "amount": 9.699999809265137,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":9.7,\"lineAmount\":9.7}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123490",
      "amount": 1750,
      "vendor": "BTE",
      "customer": "McLeRoy Equipment LLC",
      "dateCreated": "2024-04-17",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-16COMBOx2.25\",\"description\":\"16\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with #150 carbide teeth\",\"unitPrice\":1750,\"lineAmount\":1750}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123492",
      "amount": 89760,
      "vendor": "BTE",
      "customer": "J & M Construction Inc",
      "dateCreated": "2024-04-17",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"OTS - Vermeer patent:1-2016x2.25\",\"description\":\"20\\\" mini OTS w/ 16\\\" auger and combo cutting head on 2-1/4\\\" hex\\nBTE162012\",\"unitPrice\":39000,\"lineAmount\":39000},{\"quantity\":1,\"partNumber\":\"5-CS600\",\"description\":\"600' Control Station\\nBTE600SS09\",\"unitPrice\":49000,\"lineAmount\":49000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123486",
      "amount": 1872.5,
      "vendor": "BTE",
      "customer": "Carolina's Contracting LLC",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"16-16COMBOx2.25\",\"description\":\"16\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with #150 carbide teeth\",\"unitPrice\":1750,\"lineAmount\":1750},{\"quantity\":-10,\"partNumber\":\"15-S600801\",\"description\":\"NON ROTATING FLAT CARBIDE TEETH FOR COMBO CUTTING HEADS AND STD OTS CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":-250},{\"quantity\":10,\"partNumber\":\"Teeth:BTE1003\",\"description\":\"CONICAL ROCK TEETH FOR COMBO CUTTING HEADS\",\"unitPrice\":25,\"lineAmount\":250}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123485",
      "amount": 197.47999572753906,
      "vendor": "BTE",
      "customer": "Dawn Development",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"T260008\",\"description\":\"CHAIN DELRIN MCL20B/M1800 10 1/2LG/13 PITCHES\",\"unitPrice\":182.88,\"lineAmount\":182.88},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":14.6,\"lineAmount\":14.6}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123487",
      "amount": 1081.56005859375,
      "vendor": "BTE",
      "customer": "Stone Grading",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"S600590\",\"description\":\"BIT CARBIDE 1” X 2”\",\"unitPrice\":108.68,\"lineAmount\":434.72},{\"quantity\":2,\"partNumber\":\"S601130\",\"description\":\"LEAD SCREW DIRT 1\\\" V\",\"unitPrice\":98.67,\"lineAmount\":197.34},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":29.26,\"lineAmount\":29.26},{\"quantity\":4,\"partNumber\":\"S600510\",\"description\":\"BIT CARBIDE 1/2\\\" X 1/2\\\"\",\"unitPrice\":20.2,\"lineAmount\":80.8},{\"quantity\":3,\"partNumber\":\"S600520\",\"description\":\"BIT CARBIDE 1/2” X 1”\",\"unitPrice\":58.67,\"lineAmount\":176.01},{\"quantity\":1,\"partNumber\":\"S601120\",\"description\":\"LEAD SCREW DIRT 7/8\\\" V\",\"unitPrice\":92.67,\"lineAmount\":92.67}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8587",
      "amount": 317.25,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ASHEVILLE HWY RENTAL     864-583 ASHEVILLE HWY RENTAL 864-5836393 SC\",\"unitPrice\":317.25,\"lineAmount\":317.25}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7922",
      "amount": 52.849998474121094,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":52.85,\"lineAmount\":52.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8604",
      "amount": 11.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.99,\"lineAmount\":11.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8605",
      "amount": 888.7899780273438,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QUALITY HAULERS OF SC LLC864-969 QUALITY HAULERS OF SC LLC864-9692329 SC\",\"unitPrice\":888.79,\"lineAmount\":888.79}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8606",
      "amount": 1061.97998046875,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QUALITY HAULERS OF SC LLC864-969 QUALITY HAULERS OF SC LLC864-9692329 SC\",\"unitPrice\":1061.98,\"lineAmount\":1061.98}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8609",
      "amount": 1375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"MOREY CPA                WWW.CPA MOREY CPA WWW.CPAMOREY.CA\",\"unitPrice\":1375,\"lineAmount\":1375}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123484",
      "amount": 4884.169921875,
      "vendor": "BTE",
      "customer": "Vermeer High Plains",
      "dateCreated": "2024-04-15",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":12,\"partNumber\":\"C501106\",\"description\":\"1/2'' X 1-1/2'' HINGE BOLTS\",\"unitPrice\":16.87,\"lineAmount\":202.44},{\"quantity\":1,\"partNumber\":\"3600210\",\"description\":\"CLUTCH HYD MCL-30 - 42t L600 (P60-0011)\",\"unitPrice\":5544.2,\"lineAmount\":5544.2},{\"quantity\":1,\"partNumber\":\"W030060\",\"description\":\"BEARING MCL30/36 AND MCL36/42C\",\"unitPrice\":105.95,\"lineAmount\":105.95},{\"quantity\":2,\"partNumber\":\"C501127\",\"description\":\"2\\\" OLD SYLE HINDGE FOR OTS\",\"unitPrice\":98,\"lineAmount\":196},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees\",\"unitPrice\":56.62,\"lineAmount\":56.62}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8758",
      "amount": 6.940000057220459,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":6.94,\"lineAmount\":6.94}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7903",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8228",
      "amount": 300.1099853515625,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-J NEXT Insur (AP I NEXT Insur ST-J*H*C6O5W8K5\",\"unitPrice\":300.11,\"lineAmount\":300.11}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8234",
      "amount": 304.92999267578125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES BRC PAYMENT ***********991 LOWES BRC PAYMENT ***********9919\",\"unitPrice\":304.93,\"lineAmount\":304.93}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7846",
      "amount": 33.189998626708984,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":33.19,\"lineAmount\":33.19}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8759",
      "amount": 71.30999755859375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":71.31,\"lineAmount\":71.31}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7828",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "6083",
      "amount": 252,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"payday 4/19/24\",\"unitPrice\":126,\"lineAmount\":126},{\"quantity\":1,\"description\":\"payday 4/5/24\",\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8610",
      "amount": 304.7099914550781,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"MISCELLANEOUS ADJUSTMENT\",\"unitPrice\":304.71,\"lineAmount\":304.71}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8760",
      "amount": 73.37000274658203,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":73.37,\"lineAmount\":73.37}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123478",
      "amount": 14474.98046875,
      "vendor": "BTE",
      "customer": "Diehl Contracting LLC",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":48,\"partNumber\":\"Parts\",\"description\":\"808028\\nDrill rod for 10H Firestick 1-3/4\\\" x 28\\\"\",\"unitPrice\":345,\"lineAmount\":16560}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123479",
      "amount": 3726.199951171875,
      "vendor": "BTE",
      "customer": "Double R Utilities Inc",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"S601280\\nLead Head ,Combo/Rock 1-1/2 for\",\"unitPrice\":451.88,\"lineAmount\":451.88},{\"quantity\":10,\"partNumber\":\"Parts\",\"description\":\"S600590\\nBIT CARBIDE 1” X 2”\",\"unitPrice\":108.68,\"lineAmount\":1086.8},{\"quantity\":25,\"partNumber\":\"Parts\",\"description\":\"S600570\\n1x1 internal combo tooth with carbide\",\"unitPrice\":75.95,\"lineAmount\":1898.75},{\"quantity\":30,\"partNumber\":\"Parts\",\"description\":\"U320120\\nCOTTER PIN CLIP FOR MCL TYPE B TEETH INSERTS\",\"unitPrice\":1.5,\"lineAmount\":45}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123480",
      "amount": 6901.5,
      "vendor": "BTE",
      "customer": "Dimmer's Precision Grading",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"80-1810x2.25\\n18\\\" auger 10' long on 2-1/4\\\" hex with 3/8\\\" flights\",\"unitPrice\":2200,\"lineAmount\":4400},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"16-18COMBOx2.25\\n18\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with #150 carbide teeth\",\"unitPrice\":2050,\"lineAmount\":2050}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8239",
      "amount": 16,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Wire Transfer Fee\",\"unitPrice\":16,\"lineAmount\":16}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123477",
      "amount": 13600,
      "vendor": "BTE",
      "customer": "dan Sullivan Excavation",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"TRACK SECTION 9FT - 36/42C\",\"unitPrice\":6800,\"lineAmount\":13600}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7825",
      "amount": 652.0399780273438,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":652.04,\"lineAmount\":652.04}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8611",
      "amount": 24.530000686645508,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":24.53,\"lineAmount\":24.53}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7815",
      "amount": 214.49000549316406,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":214.49,\"lineAmount\":214.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8761",
      "amount": 372.4800109863281,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":372.48,\"lineAmount\":372.48}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123475",
      "amount": 12844.3701171875,
      "vendor": "BTE",
      "customer": "JSRae LLC",
      "dateCreated": "2024-04-10",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"80-3610x3. \\n36\\\" auger 10' long on 3\\\" hex\",\"unitPrice\":3400,\"lineAmount\":6800},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"16-36COMBOx3\\n36\\\" combo cutting head with wing cutters on 3\\\" hex with carbide teeth\",\"unitPrice\":4200,\"lineAmount\":4200},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"R800215\\n3\\\" M HEX X 1-5/8\\\"FM HEX\",\"unitPrice\":552,\"lineAmount\":552}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "BTE123470",
      "amount": 1128.4100341796875,
      "vendor": "BTE",
      "customer": "Carolina's Contracting LLC",
      "dateCreated": "2024-04-10",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"S600100 \\nWING CUTTER (10-16 MCL TYPE B DIRT\",\"unitPrice\":141.43,\"lineAmount\":282.86},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"S601130\\nLEAD SCREW DIRT 1\\\"\",\"unitPrice\":98.67,\"lineAmount\":98.67},{\"quantity\":6,\"partNumber\":\"Parts\",\"description\":\"S600570\\n1x1 internal combo tooth with carbide\",\"unitPrice\":75.95,\"lineAmount\":455.7},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"S600590\\nBIT CARBIDE 1” X 2”\",\"unitPrice\":108.68,\"lineAmount\":217.36}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1243",
      "amount": 106450,
      "vendor": "BTE",
      "customer": "RLW/Clyde",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2-4836x4 \\n48\\\" std OTS w/36\\\" auger and combo cutting head on 4\\\" hex\\nBTE4836206\",\"unitPrice\":97250,\"lineAmount\":97250},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"C501655\\n3606X4 WITH 3/4\\\" FLIGHT 34.25\\\"OD FOR 48\\\" STANDARD OTS,\",\"unitPrice\":-6800,\"lineAmount\":-6800},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"3606X4 WITH 1-1/2 contact FLIGHT 34.25\\\"OD FOR 48\\\" STANDARD OTS,\",\"unitPrice\":11800,\"lineAmount\":11800}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1216",
      "amount": 4900,
      "vendor": "BTE",
      "customer": "Techint saccade Ingenera y Construction",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"36\\\" combo cutting head with wing cutters on 4\\\" hex with carbide teeth\",\"unitPrice\":4500,\"lineAmount\":4500},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"Wing cutter for 36\\\" head\",\"unitPrice\":200,\"lineAmount\":400}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1047",
      "amount": 129040,
      "vendor": "Eskridge, Inc",
      "customer": "BTE",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":10,\"partNumber\":\"3642D001\",\"description\":\"440LAS1C4-28 - TORQUE HUB\",\"unitPrice\":12904,\"lineAmount\":129040}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1242",
      "amount": 9842.1396484375,
      "vendor": "BTE",
      "customer": "Techint Engineering & Construction-Uruguay",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"4810399  CLUTCH HYD MCL \\n(Weight 75lbs)  \\nDimensions 15x15x11\",\"unitPrice\":9842.14,\"lineAmount\":9842.14}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7789",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1046",
      "amount": 1480,
      "vendor": "Industrial Tube and Steel",
      "customer": "BTE",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":4,\"partNumber\":\"N500320-01\",\"description\":\"DOM-A513 Type 5 - 6.5 OD x 0.5 W x 59.5\\\"\",\"unitPrice\":370,\"lineAmount\":1480}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8588",
      "amount": 293.75,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ASHEVILLE HWY RENTAL     864-583 ASHEVILLE HWY RENTAL 864-5836393 SC\",\"unitPrice\":293.75,\"lineAmount\":293.75}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1241",
      "amount": 2097.199951171875,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"P330000\\nSHAFT INPUT ADAPTER 20-24B SAE\",\"unitPrice\":980,\"lineAmount\":1960}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1044",
      "amount": 994.8900146484375,
      "vendor": "Carolina Bearing & Belting",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":3,\"partNumber\":\"P330000\",\"description\":\"D0159.10 - SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED \",\"unitPrice\":331.63,\"lineAmount\":994.89}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1240",
      "amount": 684.3800048828125,
      "vendor": "BTE",
      "customer": "Keener Construction CO.",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"4801702 .COIL SOLENOID VALVE\",\"unitPrice\":65.94,\"lineAmount\":65.94},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2400357.VALVE HNDL W/LINK  TRIGGER\",\"unitPrice\":528,\"lineAmount\":528}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1237",
      "amount": 547.6799926757812,
      "vendor": "BTE",
      "customer": "UIT Builds",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"Parts\",\"description\":\"H1010\\nWing cutter welded 2-1/4” x 7-1/4” long,\",\"unitPrice\":34.5,\"lineAmount\":138}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "7770",
      "amount": 23.950000762939453,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":23.95,\"lineAmount\":23.95}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1239",
      "amount": 1811.06005859375,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"R8004954” hex to 3” hex bar male to male for augers\",\"unitPrice\":905.53,\"lineAmount\":1811.06}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1043",
      "amount": 37.31999969482422,
      "vendor": "Carolina Bearing & Belting",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":2,\"partNumber\":\"X000300\",\"description\":\"DC 6-80V MECHANICAL HOUR METER\",\"unitPrice\":18.66,\"lineAmount\":37.32}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1238",
      "amount": 1114.969970703125,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"R8003210. 2-1/4\\\" M X 3\\\" FM ADAPTER\",\"unitPrice\":1042.03,\"lineAmount\":1042.03}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8864",
      "amount": 67.9800033569336,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIRECT TOOLS FACTORY O   MYRTLE DIRECT TOOLS FACTORY O MYRTLE BEACH SC\",\"unitPrice\":67.98,\"lineAmount\":67.98}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1035",
      "amount": 11135,
      "vendor": "Industrial Tube and Steel",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":10,\"partNumber\":\"N710250\",\"description\":\"HEX 4000 4140 CD AN HEX BARS/SHANKS\",\"unitPrice\":195,\"lineAmount\":1950},{\"quantity\":20,\"partNumber\":\"N500100\",\"description\":\"1026 CD ANN TUBE - 6.5\\\" OD x 4.04\\\" HEX ID x 7\\\"\",\"unitPrice\":275,\"lineAmount\":5500},{\"quantity\":10,\"partNumber\":\"N500320-01\",\"description\":\"6.50 x 5.50 1026 DOM Tube for 4\\\" hex auger for OTS steering heads, CUT TO 59.5\\\" WITH 45 DEG CHAMFER BE\",\"unitPrice\":368.5,\"lineAmount\":3685}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1045",
      "amount": 18765,
      "vendor": "Industrial Tube and Steel",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":20,\"partNumber\":\"N710260\",\"description\":\"4140 CD HT BAR 3.0 HEX X 11-3/4\\\"\",\"unitPrice\":111.25,\"lineAmount\":2225},{\"quantity\":40,\"partNumber\":\"R600170\",\"description\":\"1026 CD ANN TUBE 5.0 OD X 3.03\\\" HEX ID X 7\\\"\",\"unitPrice\":120,\"lineAmount\":4800},{\"quantity\":20,\"partNumber\":\"N500321\",\"description\":\"DOM TUBE 5.0 OD X 0.5 W X 107-1/2\\\"\",\"unitPrice\":550,\"lineAmount\":11000},{\"quantity\":1,\"unitPrice\":740,\"lineAmount\":740}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8625",
      "amount": 94.2699966430664,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":17.53,\"lineAmount\":17.53},{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":15.86,\"lineAmount\":15.86},{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":32.27,\"lineAmount\":32.27},{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":28.61,\"lineAmount\":28.61}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8613",
      "amount": 11.760000228881836,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.76,\"lineAmount\":11.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8612",
      "amount": 21.399999618530273,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CHATGPT SUBSCRIPTION     HTTPSOP CHATGPT SUBSCRIPTION HTTPSOPENAI.CCA\",\"unitPrice\":21.4,\"lineAmount\":21.4}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8762",
      "amount": 46.34000015258789,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":46.34,\"lineAmount\":46.34}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8614",
      "amount": 30.639999389648438,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CIRCLE K # 23416         ROEBUCK CIRCLE K # 23416 ROEBUCK SC\",\"unitPrice\":30.64,\"lineAmount\":30.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "6080",
      "amount": 132.07000732421875,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":132.07,\"lineAmount\":132.07}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1236",
      "amount": 3773.52001953125,
      "vendor": "BTE",
      "customer": "Guymann Construction",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"W030050\",\"description\":\"BEARING MCL24B 6202-2RS16MM\",\"unitPrice\":25.06,\"lineAmount\":25.06},{\"quantity\":1,\"partNumber\":\"Boring Machines:2400252 Clutch for 24C\",\"description\":\"Hyd clutch for 24B, C\",\"unitPrice\":3731,\"lineAmount\":3731}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8763",
      "amount": 445.95001220703125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEXXXXXX7386 FedExMEMPHIS TN XXXX-XXXXXX-X1019\",\"unitPrice\":445.95,\"lineAmount\":445.95}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1042",
      "amount": 8098.89990234375,
      "vendor": "EMJ",
      "customer": "BTE",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":30,\"partNumber\":\"N500101\",\"description\":\"107547 - 1026 DOM SRA A513/5 3.750 OD x 3.250 ID S/C 9'6\\\" (114\\\")\",\"unitPrice\":187.21,\"lineAmount\":5616.3},{\"quantity\":20,\"partNumber\":\"N500102\",\"description\":\"108553 - 1026 DOM SRA A513/5 2.750 OD X 2.250 ID S/C 9'6.5\\\" (114.5\\\")\",\"unitPrice\":124.13,\"lineAmount\":2482.6}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1235",
      "amount": 241.47000122070312,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":12,\"partNumber\":\"H1009\",\"description\":\"zinc yellow - chromate plated hex head screw, grade 8 steel, 3/4\\\"-16 thread size, 3\\\" long, partially threaded\",\"unitPrice\":17.33,\"lineAmount\":207.96}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8722",
      "amount": 41.56999969482422,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Amazon web services      aws.ama Amazon web services aws.amazon.coWA\",\"unitPrice\":41.57,\"lineAmount\":41.57}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1041",
      "amount": 3198,
      "vendor": "Logan Clutch Corporation",
      "customer": "BTE",
      "dateCreated": "2024-04-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":3,\"partNumber\":\"Boring Machines:2400252 Clutch for 24C\",\"description\":\"P45-0019 clutch assembly\",\"unitPrice\":1066,\"lineAmount\":3198}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1227",
      "amount": 3033.85009765625,
      "vendor": "BTE",
      "customer": "Vermeer Southeast  Florida/Georgia/Alabama",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2400252. \\nCLUTCH HYD MCL-24B (L) COMPLETE\",\"unitPrice\":3731,\"lineAmount\":3731},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"W030050 \\nBEARING MCL24B 6202-2RS16MM\",\"unitPrice\":25.06,\"lineAmount\":25.06}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1230",
      "amount": 29960,
      "vendor": "BTE",
      "customer": "Shockley Plumbing",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":3,\"partNumber\":\"Parts\",\"description\":\"10' HEAVY DUTY TRACK FOR 36/42D\",\"unitPrice\":8000,\"lineAmount\":24000},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"3' FRONT TRACK 36/42D\",\"unitPrice\":2400,\"lineAmount\":2400},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"1 1/2\\\" BACKING PLATE FOR 36/42D\",\"unitPrice\":1600,\"lineAmount\":1600}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1229",
      "amount": 85.9800033569336,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"GAUGE SIGHT HYD.OIL T720100\",\"unitPrice\":84.98,\"lineAmount\":84.98}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "6074",
      "amount": 8.789999961853027,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":8.79,\"lineAmount\":8.79}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1232",
      "amount": 25745,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Rental\",\"description\":\"One Month rental of 36\\\" McLaughlin OTS steering head, with 4\\\" hex auger and combo carbide teeth cutting head. Has 4 steering flaps. 6' long and weighs 3500 LBs. Use 5 or 6\\\" channel. Serial #BTE3624203\\nStart Date of Rental: 4.16.2024\\nDue on or before 5.15.2024\",\"unitPrice\":22050,\"lineAmount\":22050}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1234",
      "amount": 572.4500122070312,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"16-1836W/Cx3/4 Wing Cutter for 18\\\" to 36\\\" combo cutting\",\"unitPrice\":260,\"lineAmount\":520},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"bolt and nut for wing cutter\",\"unitPrice\":7.5,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8723",
      "amount": 10.6899995803833,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":10.69,\"lineAmount\":10.69}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "6067",
      "amount": 53.54999923706055,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":53.55,\"lineAmount\":53.55}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1231",
      "amount": 1094.5400390625,
      "vendor": "BTE",
      "customer": "Walter C Via Enterprises INC",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":10,\"partNumber\":\"Parts\",\"description\":\"S600570\\t1x1 internal combo tooth with carbide\",\"unitPrice\":75.95,\"lineAmount\":759.5},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"S600580\\t1x1 side 1-1/2\\\" combo carbide tooth\",\"unitPrice\":98.25,\"lineAmount\":98.25},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"S600590\\tBIT CARBIDE 1” X 2”\",\"unitPrice\":108.68,\"lineAmount\":108.68},{\"quantity\":12,\"partNumber\":\"Parts\",\"description\":\"U320120\\tCOTTER PIN CLIP FOR MCL TYPE B TEETH INSERTS\",\"unitPrice\":1.5,\"lineAmount\":18},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"S601260\\tLEAD SCREW COMBO/ROCK 1-1/2\\\" PUD 7/8-9V THREAD\",\"unitPrice\":80.15,\"lineAmount\":80.15}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1233",
      "amount": 221.00999450683594,
      "vendor": "BTE",
      "customer": "Lamb Construction",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"S601130 LEAD SCREW DIRT 1\\\" V\",\"unitPrice\":98.67,\"lineAmount\":197.34}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1228",
      "amount": 7490,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-04-03",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"24-P360000.TORQUE HUB S3B MCL-24B SERIAL NO.\",\"unitPrice\":7000,\"lineAmount\":7000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1224",
      "amount": 535,
      "vendor": "BTE",
      "customer": "HRH Engineering Services",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"Parts\",\"description\":\"BTE1003\",\"unitPrice\":20,\"lineAmount\":80},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"16-1016W/Cx5/8\\nWING CUTTER FOR 10”-16” COMBO CUTTING HEAD.\",\"unitPrice\":210,\"lineAmount\":420}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8576",
      "amount": 58.22999954223633,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Indeed 90782499          800-462 Indeed 90782499 800-4625842 TX\",\"unitPrice\":58.23,\"lineAmount\":58.23}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8577",
      "amount": 18,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Parking-GREENVILLE-SPARTANBURG AI213-378 GREENVILLE-SPARTANBURG AI213-3781190 SC\",\"unitPrice\":18,\"lineAmount\":18}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5982",
      "amount": 40.369998931884766,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":40.37,\"lineAmount\":40.37}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1225",
      "amount": 63660.94921875,
      "vendor": "BTE",
      "customer": "Claude H Nix Construction",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2-3018x4\\n30\\\" std OTS w/18\\\" auger and combo cutting head on 4\\\" hex    Serial #BTE3018202\",\"unitPrice\":62500,\"lineAmount\":62500},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"6-ES ADV. \\nAdvanced electronic steering - large touch screen - data log - includes grade - line and roll.\",\"unitPrice\":9995,\"lineAmount\":9995}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5980",
      "amount": 16.68000030517578,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":16.68,\"lineAmount\":16.68}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8724",
      "amount": 12.829999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":12.83,\"lineAmount\":12.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8935",
      "amount": 481.3500061035156,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1019 battery tender 5 amp selectable 12 volt/ 24v battery charger maintainer. switchable 5amp/2.5amp onboard marine for boat watercraft - screw mountable heavy duty weather resistant - 022-0258-DL-WH\",\"unitPrice\":481.35,\"lineAmount\":481.35}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1226",
      "amount": 1421.030029296875,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2024-04-02",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Various/Misc:1-Low ProHydraulic cylinder - (35 ton @ 6800) 50Ton, 25mm Stroke, 1/4 NPT. Used on 24\\\" Steering Head\",\"description\":\"Low ProHydraulic cylinder - 35 ton @ 6800, 1\\\" Stroke, 1/4 NPT, used on 24\\\"OTS and RUSH heads.\",\"unitPrice\":490,\"lineAmount\":980},{\"quantity\":8,\"partNumber\":\"Parts\",\"description\":\"3/4x1 1/2 henge bolt\",\"unitPrice\":9.5,\"lineAmount\":76}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1222",
      "amount": 2097.199951171875,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-04-01",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"P330000\\nSHAFT INPUT ADAPTER 20-24B\",\"unitPrice\":980,\"lineAmount\":1960}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1223",
      "amount": 342.3999938964844,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-04-01",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":16,\"partNumber\":\"Parts\",\"description\":\"BTE1003 \\nSM02 KENAMENTAL STYLE CONICAL TEETH,\",\"unitPrice\":20,\"lineAmount\":320}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1221",
      "amount": 2025,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-04-01",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"3620510B\\nChuck assembly for 36/42C with 3\\\" hex for S10H gearbox\",\"unitPrice\":1875,\"lineAmount\":1875}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5984",
      "amount": 252.3800048828125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-H NEXT Insur (AP I NEXT Insur ST-H*D*Y0O6X2Y3\",\"unitPrice\":252.38,\"lineAmount\":252.38}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8764",
      "amount": 35.04999923706055,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-04-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":35.05,\"lineAmount\":35.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5970",
      "amount": 83.63999938964844,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-04-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":83.64,\"lineAmount\":83.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8583",
      "amount": 73.0199966430664,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DOLLAR  #0056523         CLEVELA DOLLAR #0056523 CLEVELAND OH\",\"unitPrice\":73.02,\"lineAmount\":73.02}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8725",
      "amount": 34.20000076293945,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":34.2,\"lineAmount\":34.2}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8726",
      "amount": 276.05999755859375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTUIT *QBooks Online    CL.INTU INTUIT *QBooks Online CL.INTUIT.COMCA\",\"unitPrice\":276.06,\"lineAmount\":276.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8582",
      "amount": 389,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ANSWERFORCE              800-461 ANSWERFORCE 800-461-8520 OR\",\"unitPrice\":389,\"lineAmount\":389}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8581",
      "amount": 14.220000267028809,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TST* STBC CLEVELAND - 220CLEVELA TST* STBC CLEVELAND - 220CLEVELAND OH\",\"unitPrice\":14.22,\"lineAmount\":14.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8580",
      "amount": 121.44000244140625,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KIMPTON SCHOFIELD HOTEL  CLEVELA KIMPTON SCHOFIELD HOTEL CLEVELAND OH\",\"unitPrice\":121.44,\"lineAmount\":121.44}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8578",
      "amount": 35,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DELTA AIR   Baggage Fee  CLEVELA DELTA AIR Baggage Fee CLEVELAND OH\",\"unitPrice\":35,\"lineAmount\":35}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9213",
      "amount": 16.959999084472656,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-30",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":16.96,\"lineAmount\":16.96}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9353",
      "amount": 137.97000122070312,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"PRICELN*KIMPTON SCHONORWALK CT XXXX-XXXXXX-X1019\",\"unitPrice\":137.97,\"lineAmount\":137.97}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8589",
      "amount": 1923.1099853515625,
      "vendor": "jose (4904) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ROCK FOR BACK YARD-ADAMS 30M IN MAN         INMAN ADAMS 30M IN MAN INMAN SC\",\"unitPrice\":1923.11,\"lineAmount\":1923.11}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8240",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#161\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1218",
      "amount": 1709.0999755859375,
      "vendor": "BTE",
      "customer": "White Cloud Pipeline Corp",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"Parts\",\"description\":\"S600340\\nBIT HARD SURFACE 1\\\" X 2\\\"\",\"unitPrice\":75,\"lineAmount\":300},{\"quantity\":18,\"partNumber\":\"Parts\",\"description\":\"S600570\\n1x1 internal combo tooth with carbide\",\"unitPrice\":75.95,\"lineAmount\":1367.1}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8579",
      "amount": 35,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DELTA AIR   Baggage Fee  800-221 DELTA AIR Baggage Fee 800-2211212 SC\",\"unitPrice\":35,\"lineAmount\":35}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8584",
      "amount": 210.00999450683594,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"GENERAL SPRING           913-829 GENERAL SPRING 913-829-0619 KS\",\"unitPrice\":210.01,\"lineAmount\":210.01}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1217",
      "amount": 2827,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"P260110\\nCOUPLING CENTER MEMBER MCL24B\",\"unitPrice\":1278.31,\"lineAmount\":1278.31},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"P260100\\nHALF COUPLING MORFLEX\",\"unitPrice\":383.75,\"lineAmount\":383.75},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"P330000\\nSHAFT INPUT ADAPTER 20-24B\",\"unitPrice\":980,\"lineAmount\":980}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9214",
      "amount": 475.20001220703125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DELTA AIR LINES ATLANTA XXXX-XXXXXX-X1019\",\"unitPrice\":475.2,\"lineAmount\":475.2}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8590",
      "amount": 30,
      "vendor": "jose (4904) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SKINNER TIRE             INMAN SKINNER TIRE INMAN SC\",\"unitPrice\":30,\"lineAmount\":30}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5954",
      "amount": 74.81999969482422,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":74.82,\"lineAmount\":74.82}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8858",
      "amount": 750,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Teller Cashed Check/Withdrawal\",\"unitPrice\":750,\"lineAmount\":750}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8863",
      "amount": 2000,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTERNATIONAL WIRE TRANSFER-0000 INTERNATIONAL WIRE TRANSFER-0000161 2 REF#161\",\"unitPrice\":2000,\"lineAmount\":2000}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9215",
      "amount": 49.68000030517578,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":49.68,\"lineAmount\":49.68}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1040",
      "amount": 74.97000122070312,
      "vendor": "Carolina Bearing & Belting",
      "customer": "BTE",
      "dateCreated": "2024-03-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":3,\"partNumber\":\"W000025\",\"description\":\"cam follower regular stud sealed hex drive\",\"unitPrice\":24.99,\"lineAmount\":74.97}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8727",
      "amount": 14.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":14.99,\"lineAmount\":14.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8728",
      "amount": 6.369999885559082,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DOLLAR GENERAL #10284    INMAN DOLLAR GENERAL #10284 INMAN SC\",\"unitPrice\":6.37,\"lineAmount\":6.37}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1214",
      "amount": 291.0400085449219,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Simpsonville",
      "dateCreated": "2024-03-26",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"X000020\\nE STOP ASSYMBLED\",\"unitPrice\":346.3,\"lineAmount\":346.3}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1215",
      "amount": 281.07000732421875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Simpsonville",
      "dateCreated": "2024-03-26",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2400167\\nRELIEF VALVE PILOT OP\",\"unitPrice\":229.64,\"lineAmount\":229.64}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1213",
      "amount": 3131.219970703125,
      "vendor": "BTE",
      "customer": "Dawn Development",
      "dateCreated": "2024-03-25",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2050091\\nCLUTCH HYD MCL-20B (L)\",\"unitPrice\":2525.25,\"lineAmount\":2525.25},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2050092\\nDRIVE CUP\",\"unitPrice\":553,\"lineAmount\":553}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1212",
      "amount": 3627.0400390625,
      "vendor": "BTE",
      "customer": "DSC Construction",
      "dateCreated": "2024-03-25",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"2400252\\nCLUTCH HYD MCL-24B (L) COMPLETE\",\"unitPrice\":3579.98,\"lineAmount\":3579.98},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"W030050\\nBEARING MCL24B\",\"unitPrice\":25.06,\"lineAmount\":25.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9217",
      "amount": 113.79000091552734,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":113.79,\"lineAmount\":113.79}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5877",
      "amount": 220.57000732421875,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":220.57,\"lineAmount\":220.57}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5888",
      "amount": 632.0800170898438,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DUKEENERGY dan PAY ********8409\",\"unitPrice\":632.08,\"lineAmount\":632.08}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5889",
      "amount": 25.15999984741211,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPARTANBURG WATE danPAY SPARTAN SPARTANBURG WATE danPAY SPARTANBURG WAT\",\"unitPrice\":25.16,\"lineAmount\":25.16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5887",
      "amount": 426.92999267578125,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"HOMEDEPOT.COM            800-430 HOMEDEPOT.COM 800-430-3376 GA\",\"unitPrice\":426.93,\"lineAmount\":426.93}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8731",
      "amount": 2.990000009536743,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":2.99,\"lineAmount\":2.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8737",
      "amount": 111.9000015258789,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DONATOS #0422 OLO        BOILING SPRINSC\",\"unitPrice\":111.9,\"lineAmount\":111.9}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8730",
      "amount": 48.630001068115234,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CIRCLE K # 21503         WOODRUF CIRCLE K # 21503 WOODRUFF SC\",\"unitPrice\":48.63,\"lineAmount\":48.63}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8729",
      "amount": 16.049999237060547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COSTCO WHSE #1008        SPARTAN COSTCO WHSE #1008 SPARTANBURG SC\",\"unitPrice\":16.05,\"lineAmount\":16.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8867",
      "amount": 149.0399932861328,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":149.04,\"lineAmount\":149.04}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9216",
      "amount": 34.90999984741211,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":34.91,\"lineAmount\":34.91}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8732",
      "amount": 23.049999237060547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SXM*SIRIUSXM.COM/ACCT    888-635 SXM*SIRIUSXM.COM/ACCT 888-635-5144 NY\",\"unitPrice\":23.05,\"lineAmount\":23.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9218",
      "amount": 347.32000732421875,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ULINE SHIP SUPPLIES XXX-XXX-5510 WI XXXX-XXXXXX-X1019\",\"unitPrice\":347.32,\"lineAmount\":347.32}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5911",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5937",
      "amount": 167.07000732421875,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Spectrum                 855-707 Spectrum 855-707-7328 MO\",\"unitPrice\":167.07,\"lineAmount\":167.07}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8733",
      "amount": 200,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CIRCLE K # 23416         ROEBUCK CIRCLE K # 23416 ROEBUCK SC\",\"unitPrice\":200,\"lineAmount\":200}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1037",
      "amount": 2380,
      "vendor": "SHC, Inc.",
      "customer": "BTE",
      "dateCreated": "2024-03-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":10,\"partNumber\":\"1007040-3600124\",\"description\":\"1\\\" Bore 3/4\\\" Rod 4\\\" Stroke\\nC1045 Chrome Rod\\nSlot Cover Plates\",\"unitPrice\":238,\"lineAmount\":2380}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1038",
      "amount": 251.8800048828125,
      "vendor": "Carolina Bearing & Belting",
      "customer": "BTE",
      "dateCreated": "2024-03-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"partNumber\":\"2400356-01\",\"description\":\"metal handle bracket for 24B, 10H and 12H greason valves, does not included handle\",\"unitPrice\":251.88,\"lineAmount\":251.88}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1211",
      "amount": 973.4099731445312,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-03-21",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"CLUTCH DISK KIT (L) MCL-24B\\n2400256\",\"unitPrice\":1193.19,\"lineAmount\":1193.19}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5886",
      "amount": 200,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":200,\"lineAmount\":200}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1210",
      "amount": 809.97998046875,
      "vendor": "BTE",
      "customer": "CK Contractors & Development",
      "dateCreated": "2024-03-21",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"VALVE HNDL W/LINK V20 24B/30B (WILL WORK ON 54/60 WITH 90)PISTOL GRIP/JOYSTICK/TRIGGER\\n2400357\",\"unitPrice\":528,\"lineAmount\":528},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"VLV HNDL W/LINK V20 24B/30B DOG PLATE HANDLE W/ Link\\n2400359\",\"unitPrice\":86.35,\"lineAmount\":86.35},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"Metal handle bracket for 24B,\",\"unitPrice\":88.38,\"lineAmount\":176.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5836",
      "amount": 16.010000228881836,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":16.01,\"lineAmount\":16.01}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1209",
      "amount": 152.16000366210938,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-03-20",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"ISOLATOR FRONT MCL24B FRONT & REAR ISOLATOR 3600086\",\"unitPrice\":46.73,\"lineAmount\":46.73},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"ISOLATOR REAR MCL24B\\n2400083\",\"unitPrice\":47.74,\"lineAmount\":95.48}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9219",
      "amount": 64.83000183105469,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":64.83,\"lineAmount\":64.83}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1208",
      "amount": 5487.81982421875,
      "vendor": "BTE",
      "customer": "DRS Enterprises",
      "dateCreated": "2024-03-20",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":14,\"partNumber\":\"Parts\",\"description\":\"KING KONG 1160 ROCK TEETH\",\"unitPrice\":346,\"lineAmount\":4844},{\"quantity\":4,\"partNumber\":\"Parts\",\"description\":\"KING KONG BOLT \\nH1001\",\"unitPrice\":2.5,\"lineAmount\":10},{\"quantity\":3,\"partNumber\":\"Parts\",\"description\":\"LED light for OTS rush head steering head\\nE0003\",\"unitPrice\":73.5,\"lineAmount\":220.5},{\"quantity\":1,\"partNumber\":\"misc\",\"description\":\"finance charge 1%\",\"unitPrice\":54.3,\"lineAmount\":54.3}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8734",
      "amount": 32.02000045776367,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":32.02,\"lineAmount\":32.02}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1206",
      "amount": 776.3200073242188,
      "vendor": "BTE",
      "customer": "Strathcona Excavating INC",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"Parts\",\"description\":\"BRNG CAMROLL 1.25 HEX DR BRNG WITH SHAFT SEALED\\nW000025\",\"unitPrice\":158.41,\"lineAmount\":633.64}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1207",
      "amount": 2462.429931640625,
      "vendor": "BTE",
      "customer": "KM Davis Contracting",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"paddle assy.\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"Chuck Assymbly for 2-1/4\\\" hex-no extension required\\n240062B\",\"unitPrice\":1325,\"lineAmount\":1325},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"24B Saddle Assembly-includes 12 tru 20 inserts\\n24-A300800B\",\"unitPrice\":890,\"lineAmount\":890}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8738",
      "amount": 4.840000152587891,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"WM SUPERCENTER #2806     BOILING SPRINSC\",\"unitPrice\":4.84,\"lineAmount\":4.84}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5894",
      "amount": 16,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Wire Transfer Fee\",\"unitPrice\":16,\"lineAmount\":16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9220",
      "amount": 118.83000183105469,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DHL Bonn DE XXXX-XXXXXX-X1019\",\"unitPrice\":118.83,\"lineAmount\":118.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5830",
      "amount": 27.170000076293945,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":27.17,\"lineAmount\":27.17}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8865",
      "amount": 990.780029296875,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QUALITY HAULERS OF SC LLC864-969 QUALITY HAULERS OF SC LLC864-9692329 SC\",\"unitPrice\":990.78,\"lineAmount\":990.78}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1205",
      "amount": 3964.330078125,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-03-18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"CLUTCH HYD MCL-24B (L)COMPLETE\\n2400252\",\"unitPrice\":3579.98,\"lineAmount\":3579.98},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"RETAINER MAIN DRIVE\\n2400221\",\"unitPrice\":125,\"lineAmount\":125}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5819",
      "amount": 7126.81005859375,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"inventory influx - will reconcile with inventory audit\",\"unitPrice\":7126.81,\"lineAmount\":7126.81}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5892",
      "amount": 272.3599853515625,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-M NEXT Insur (AP I NEXT Insur ST-M*C*W1B9W6L7\",\"unitPrice\":272.36,\"lineAmount\":272.36}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9266",
      "amount": 19.31999969482422,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON.COM AMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":19.32,\"lineAmount\":19.32}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1203",
      "amount": 50.31999969482422,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Simpsonville",
      "dateCreated": "2024-03-18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"BIT CARBIDE 1/2\\\" X 1/2\\\"\\nS600510\",\"unitPrice\":20.2,\"lineAmount\":40.4}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1204",
      "amount": 811.7999877929688,
      "vendor": "BTE",
      "customer": "Ditch Witch of the Carolinas Simpsonville",
      "dateCreated": "2024-03-18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":3,\"partNumber\":\"Parts\",\"description\":\"R800105\\n13/16\\\" male hex. x 1 1/8 famale hex\",\"unitPrice\":210,\"lineAmount\":630},{\"quantity\":20,\"partNumber\":\"Parts\",\"description\":\"R700760\\nDRIVE PIN 1-1/8 HEX\",\"unitPrice\":5.5,\"lineAmount\":110}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8765",
      "amount": 13.739999771118164,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":13.74,\"lineAmount\":13.74}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5893",
      "amount": 93.72000122070312,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES BRC PAYMENT ***********991 LOWES BRC PAYMENT ***********9919\",\"unitPrice\":93.72,\"lineAmount\":93.72}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5802",
      "amount": 39.790000915527344,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":39.79,\"lineAmount\":39.79}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5811",
      "amount": 149.83999633789062,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-03-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":149.84,\"lineAmount\":149.84}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5895",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#102\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5768",
      "amount": 126,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":126,\"lineAmount\":126}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5896",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#110\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1036",
      "amount": 4111.5,
      "vendor": "Industrial Tube and Steel",
      "customer": "BTE",
      "dateCreated": "2024-03-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"partNumber\":\"N500262\",\"description\":\"DOM-1513 TYPE 5 (3.250  ID) 3.750 OD X 0.250 W X SRL\\n*CUT EACH LENGTH TWICE AT 114\\\" AND SHIP DROPS*\\n*SRL'S ARE RUNNING AROUND 22'10\\\" LONG*\",\"unitPrice\":3706.5,\"lineAmount\":3706.5},{\"quantity\":1,\"unitPrice\":405,\"lineAmount\":405}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5753",
      "amount": 60.7400016784668,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":60.74,\"lineAmount\":60.74}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1201",
      "amount": 1735.489990234375,
      "vendor": "BTE",
      "customer": "Paul Ellard & Company",
      "dateCreated": "2024-03-14",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"24\\\" x 12.75\\\" push plate for McL 24B and C. 1-1/2\\\" Plate w 11.75\\\" hole with 6ea 1/2\\\" plate holders\",\"unitPrice\":1499,\"lineAmount\":1499},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling\",\"unitPrice\":236.49,\"lineAmount\":236.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8766",
      "amount": 115.22000122070312,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DHL Bonn DE XXXX-XXXXXX-X1001\",\"unitPrice\":115.22,\"lineAmount\":115.22}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1199",
      "amount": 548,
      "vendor": "BTE",
      "customer": "McLeRoy Equipment LLC",
      "dateCreated": "2024-03-13",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"PISTOL GRIP/JOYSTICK/TRIGGER\\n2400357\",\"unitPrice\":528,\"lineAmount\":528}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8739",
      "amount": 78.06999969482422,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL 53941600024    INMAN        SC\",\"unitPrice\":78.07,\"lineAmount\":78.07}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1198",
      "amount": 2391.10009765625,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-03-13",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"COUPLINHALF\\nP260100\",\"unitPrice\":383.75,\"lineAmount\":383.75},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"COUPLING CENTER\\nP260110\",\"unitPrice\":1278.31,\"lineAmount\":1278.31},{\"quantity\":3,\"partNumber\":\"Parts\",\"description\":\"SCREW\\nU000930\",\"unitPrice\":2,\"lineAmount\":6},{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"SCREW\\nU000920\",\"unitPrice\":4,\"lineAmount\":8},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"CLAMP U-BOLT\\nU800120\",\"unitPrice\":11.31,\"lineAmount\":11.31},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"MUFFLER MCL 24C\\n2400479-05\",\"unitPrice\":1041.01,\"lineAmount\":1041.01},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"ADAPTER PLATE\\n2400480\",\"unitPrice\":231.75,\"lineAmount\":231.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5806",
      "amount": 16,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Wire Transfer Fee\",\"unitPrice\":16,\"lineAmount\":16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8767",
      "amount": 46.599998474121094,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":46.6,\"lineAmount\":46.6}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1197",
      "amount": 1366.969970703125,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-03-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":20,\"partNumber\":\"Parts\",\"description\":\"CONICAL TEETH, WILL FIT IN SAME BLOCK \\nBTE1003\",\"unitPrice\":20,\"lineAmount\":400},{\"quantity\":12,\"partNumber\":\"Parts\",\"description\":\"AR150-87 FLAT NON ROTATING TEETH FOR COMBO AND STD OTS CUTTING HEADS. 15-S600801\",\"unitPrice\":25,\"lineAmount\":300},{\"quantity\":10,\"partNumber\":\"Parts\",\"description\":\"BIT CARBIDE 1/2\\\" X 1 1/2\\\"\\nS600530\",\"unitPrice\":7.018,\"lineAmount\":70.18},{\"quantity\":4,\"partNumber\":\"Parts\",\"description\":\"BIT WING CUTTER COMBO\\nS600150B\",\"unitPrice\":114.34,\"lineAmount\":457.36},{\"quantity\":50,\"partNumber\":\"Parts\",\"description\":\"COTTER PINS\\nU320120\",\"unitPrice\":1,\"lineAmount\":50}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8735",
      "amount": 87.02999877929688,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CONNER FLYNNS AT WOODR   864-386 CONNER FLYNNS AT WOODR 864-3860911 SC\",\"unitPrice\":87.03,\"lineAmount\":87.03}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8736",
      "amount": 11.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.99,\"lineAmount\":11.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5803",
      "amount": 21.100000381469727,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":21.1,\"lineAmount\":21.1}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5805",
      "amount": 7.389999866485596,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":7.39,\"lineAmount\":7.39}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5804",
      "amount": 20.399999618530273,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ROBERTSON'S ACE HARDWARE WOODRUF ROBERTSON'S ACE HARDWARE WOODRUFF SC\",\"unitPrice\":20.4,\"lineAmount\":20.4}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5687",
      "amount": 668.6400146484375,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":668.64,\"lineAmount\":668.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8768",
      "amount": 71.77999877929688,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":71.78,\"lineAmount\":71.78}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8769",
      "amount": 64.33000183105469,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":64.33,\"lineAmount\":64.33}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5682",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#162\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5702",
      "amount": 30.239999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":30.24,\"lineAmount\":30.24}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5703",
      "amount": 76.73999786376953,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Subway 14566             186-458 Subway 14566 186-45852784 SC\",\"unitPrice\":76.74,\"lineAmount\":76.74}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5659",
      "amount": 9.050000190734863,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"USPS.COM CLICKNSHIP      800-344 USPS.COM CLICKNSHIP 800-344-7779 DC\",\"unitPrice\":9.05,\"lineAmount\":9.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5655",
      "amount": 2.390000104904175,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":2.39,\"lineAmount\":2.39}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1194",
      "amount": 2904.89990234375,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"white steel control station control box\",\"unitPrice\":2362.5,\"lineAmount\":2362.5},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"CONTROL BOX HARNESS\",\"unitPrice\":472.5,\"lineAmount\":472.5},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"FM 120V flush mount electrical inlet\",\"unitPrice\":59.4,\"lineAmount\":59.4},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"2 pin male connector for hyd. pump & lights\",\"unitPrice\":10.5,\"lineAmount\":10.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5704",
      "amount": 11.760000228881836,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.76,\"lineAmount\":11.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5652",
      "amount": 45.58000183105469,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":45.58,\"lineAmount\":45.58}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1196",
      "amount": 198.8800048828125,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Simpsonville",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Lead Screw 1\\\"\\nS601130\",\"unitPrice\":98.67,\"lineAmount\":98.67},{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"12\\\" wing cutter\\ns600120\",\"unitPrice\":64.34,\"lineAmount\":128.68}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9143",
      "amount": 14.369999885559082,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":14.37,\"lineAmount\":14.37}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9142",
      "amount": 15.789999961853027,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":15.79,\"lineAmount\":15.79}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9141",
      "amount": 14.369999885559082,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":14.37,\"lineAmount\":14.37}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9140",
      "amount": 15.789999961853027,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-03-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":15.79,\"lineAmount\":15.79}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5706",
      "amount": 21.399999618530273,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CHATGPT SUBSCRIPTION     HTTPSOP CHATGPT SUBSCRIPTION HTTPSOPENAI.CCA\",\"unitPrice\":21.4,\"lineAmount\":21.4}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5705",
      "amount": 27.729999542236328,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":27.73,\"lineAmount\":27.73}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1191",
      "amount": 333.510009765625,
      "vendor": "BTE",
      "customer": "Vermeer Italia",
      "dateCreated": "2024-03-06",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"CLUTCH SEAL KIT MCL-24B\\n2400257\",\"unitPrice\":131.57,\"lineAmount\":263.14}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5941",
      "amount": 100.29000091552734,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-03-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT 1129      SPARTANBURG  SC\",\"unitPrice\":100.29,\"lineAmount\":100.29}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1192",
      "amount": 780.5,
      "vendor": "BTE",
      "customer": "Ditch Witch Sales of North Carolina",
      "dateCreated": "2024-03-06",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":10,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"1x1 internal combo tooth with carbide.  S600570\",\"unitPrice\":75.95,\"lineAmount\":759.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5707",
      "amount": 1638.280029296875,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QUALITY HAULERS OF SC LLC864-969 QUALITY HAULERS OF SC LLC864-9692329 SC\",\"unitPrice\":1638.28,\"lineAmount\":1638.28}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5708",
      "amount": 1707.9000244140625,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QUALITY HAULERS OF SC LLC864-969 QUALITY HAULERS OF SC LLC864-9692329 SC\",\"unitPrice\":1707.9,\"lineAmount\":1707.9}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1033",
      "amount": 23260,
      "vendor": "Eskridge, Inc",
      "customer": "BTE",
      "dateCreated": "2024-03-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"partNumber\":\"Boring Machines:Gear Box for 84 and 96\\\" TBMs\",\"unitPrice\":23260,\"lineAmount\":23260}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1190",
      "amount": 9706,
      "vendor": "BTE",
      "customer": "UIT Builds",
      "dateCreated": "2024-03-05",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"3606X4 WITH 3/4\\\" FLIGHT 34.25\\\"OD FOR 48\\\" \\n83-C501655\",\"unitPrice\":6706,\"lineAmount\":6706}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8770",
      "amount": 41.869998931884766,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":41.87,\"lineAmount\":41.87}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5701",
      "amount": 952.9600219726562,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"D0159.10\",\"unitPrice\":907.68,\"lineAmount\":907.68},{\"quantity\":1,\"unitPrice\":45.28,\"lineAmount\":45.28}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1185",
      "amount": 3477.5,
      "vendor": "BTE",
      "customer": "Payne McGinn & Cummins Inc",
      "dateCreated": "2024-03-04",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"12\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":1500,\"lineAmount\":1500},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"16\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with #150 carbide teeth\",\"unitPrice\":1750,\"lineAmount\":1750}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5683",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#96\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5685",
      "amount": 269.1700134277344,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-U NEXT Insur (AP I NEXT Insur ST-U*L*J3W4B7Z0\",\"unitPrice\":269.17,\"lineAmount\":269.17}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5577",
      "amount": 121.2300033569336,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-03-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":121.23,\"lineAmount\":121.23}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8771",
      "amount": 48.13999938964844,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-03-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":48.14,\"lineAmount\":48.14}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5713",
      "amount": 10.6899995803833,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":10.69,\"lineAmount\":10.69}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5712",
      "amount": 5.429999828338623,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Google ADSXXXXXX2580     650-253 Google ADSXXXXXX2580 650-2530000 CA\",\"unitPrice\":5.43,\"lineAmount\":5.43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5711",
      "amount": 276.05999755859375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTUIT *QBooks Online    CL.INTU INTUIT *QBooks Online CL.INTUIT.COMCA\",\"unitPrice\":276.06,\"lineAmount\":276.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5710",
      "amount": 29.6299991607666,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":29.63,\"lineAmount\":29.63}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5709",
      "amount": 39.5,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Amazon web services      aws.ama Amazon web services aws.amazon.coWA\",\"unitPrice\":39.5,\"lineAmount\":39.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5714",
      "amount": 12.829999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":12.83,\"lineAmount\":12.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5658",
      "amount": 107.55000305175781,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ROBERTSON'S ACE HARDWARE WOODRUF ROBERTSON'S ACE HARDWARE WOODRUFF SC\",\"unitPrice\":107.55,\"lineAmount\":107.55}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5725",
      "amount": 640.9299926757812,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Mac mini purchase for parts dept. APPLE.COM/US             800-676 APPLE.COM/US 800-676-2775 CA\",\"unitPrice\":640.93,\"lineAmount\":640.93}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5943",
      "amount": 43.290000915527344,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-03-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":43.29,\"lineAmount\":43.29}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5697",
      "amount": 381.3900146484375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-03-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":29.63,\"lineAmount\":29.63},{\"quantity\":1,\"description\":\"4 ea 30643428 Hub_gdxm048_PM_14cspln_tr_1CC\",\"unitPrice\":351.76,\"lineAmount\":351.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9139",
      "amount": 11.979999542236328,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES #02595*            SPARTANBURG  SC\",\"unitPrice\":11.98,\"lineAmount\":11.98}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5678",
      "amount": 55.91999816894531,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station THE HOME DEPOT #1129     SPARTANBURG  SC\",\"unitPrice\":55.92,\"lineAmount\":55.92}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5546",
      "amount": 31.059999465942383,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":31.06,\"lineAmount\":31.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5939",
      "amount": 11.979999542236328,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES #02595*            SPARTANBURG  SC\",\"unitPrice\":11.98,\"lineAmount\":11.98}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1184",
      "amount": 80,
      "vendor": "BTE",
      "customer": "Town of Chilhowie",
      "dateCreated": "2024-02-29",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"METER,HOUR\",\"unitPrice\":56,\"lineAmount\":56}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1180",
      "amount": 359.8599853515625,
      "vendor": "BTE",
      "customer": "Vermeer Great Plains",
      "dateCreated": "2024-02-28",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":6,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"BIT CARBIDE 1/2\\\" X 1-1/2\\\" S600530\",\"unitPrice\":70.18,\"lineAmount\":421.08}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5715",
      "amount": 49.720001220703125,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":49.72,\"lineAmount\":49.72}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1179",
      "amount": 947.4600219726562,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-02-28",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":12,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"BIT HARD SURFACE 1”x1” S600320\",\"unitPrice\":62.12,\"lineAmount\":745.44},{\"quantity\":6,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"BIT HARD SURFACE 1”x2” S600340\",\"unitPrice\":68.98,\"lineAmount\":413.88}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1177",
      "amount": 730,
      "vendor": "BTE",
      "customer": "Eslinger Contracting Co Inc",
      "dateCreated": "2024-02-27",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Wing Cutters for 20\\\" OTS\",\"unitPrice\":350,\"lineAmount\":700}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5684",
      "amount": 16,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Wire Transfer Fee\",\"unitPrice\":16,\"lineAmount\":16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5686",
      "amount": 633.75,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Check 1313\",\"unitPrice\":633.75,\"lineAmount\":633.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9229",
      "amount": 53.09000015258789,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":53.09,\"lineAmount\":53.09}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5942",
      "amount": 50.84000015258789,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-02-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":50.84,\"lineAmount\":50.84}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5677",
      "amount": 300.3299865722656,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station THE HOME DEPOT 1129      SPARTANBURG  SC\",\"unitPrice\":300.33,\"lineAmount\":300.33}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1176",
      "amount": 828,
      "vendor": "BTE",
      "customer": "California Auger Boring",
      "dateCreated": "2024-02-26",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":8,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"2\\\" HINGE\",\"unitPrice\":98,\"lineAmount\":784}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5442",
      "amount": 154.6999969482422,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"OFFICE DEPOT #200        SPARTAN OFFICE DEPOT #200 SPARTANBURG SC\",\"unitPrice\":154.7,\"lineAmount\":154.7}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9230",
      "amount": 100.76000213623047,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":100.76,\"lineAmount\":100.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5467",
      "amount": 2.990000009536743,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":2.99,\"lineAmount\":2.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5449",
      "amount": 20.260000228881836,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPARTANBURG WATE danPAY SPARTAN SPARTANBURG WATE danPAY SPARTANBURG WAT\",\"unitPrice\":20.26,\"lineAmount\":20.26}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5392",
      "amount": 17467.919921875,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DRS startup Jan 2024\",\"unitPrice\":17467.92,\"lineAmount\":17467.92}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5443",
      "amount": 43.459999084472656,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":43.46,\"lineAmount\":43.46}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5673",
      "amount": 17.1200008392334,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station LOWES #02595*            SPARTANBURG  SC\",\"unitPrice\":17.12,\"lineAmount\":17.12}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1175",
      "amount": 3495.02001953125,
      "vendor": "BTE",
      "customer": "HARDY UNDERGROUND UTILITIES LLC",
      "dateCreated": "2024-02-22",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"36\\\" SADDLE ASSEMBLY WITH 30\\\" INSERTS\",\"unitPrice\":3197.5,\"lineAmount\":3197.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5472",
      "amount": 159.7100067138672,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Spectrum                 855-707 Spectrum 855-707-7328 MO\",\"unitPrice\":159.71,\"lineAmount\":159.71}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5674",
      "amount": 13.149999618530273,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station THE HOME DEPOT #1129     SPARTANBURG  SC\",\"unitPrice\":13.15,\"lineAmount\":13.15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5532",
      "amount": 7169.52001953125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":7169.52,\"lineAmount\":7169.52}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5468",
      "amount": 23.049999237060547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SXM*SIRIUSXM.COM/ACCT    888-635 SXM*SIRIUSXM.COM/ACCT 888-635-5144 NY\",\"unitPrice\":23.05,\"lineAmount\":23.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5454",
      "amount": 65.45999908447266,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"VISTAPRINT               866-207 VISTAPRINT 866-207-4955 MA - Business cards\",\"unitPrice\":65.46,\"lineAmount\":65.46}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5446",
      "amount": 7169.52001953125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FIRST CITIZENS PAYMENT **********2922 - interest only for first 3 months.\",\"unitPrice\":7169.52,\"lineAmount\":7169.52}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5676",
      "amount": 251.17999267578125,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station THE HOME DEPOT 1129      SPARTANBURG  SC\",\"unitPrice\":251.18,\"lineAmount\":251.18}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1166",
      "amount": 4678.31982421875,
      "vendor": "BTE",
      "customer": "DRS Enterprises",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":12,\"partNumber\":\"Teeth:King Kong 1160 teeth\",\"description\":\"King Kong teeth\",\"unitPrice\":386,\"lineAmount\":4632},{\"quantity\":1,\"partNumber\":\"misc\",\"description\":\"finance charge\",\"unitPrice\":46.32,\"lineAmount\":46.32}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1172",
      "amount": 921.1300048828125,
      "vendor": "BTE",
      "customer": "Vermeer All Roads -Charlotte",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"SHAFT INPUT ADAPTER 20-24B SAE B - SPL. SPLINED S\\nP330000\",\"unitPrice\":1101.35,\"lineAmount\":1101.35},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"BEARING MCL24B 6202-2RS16MM\\nW030050\",\"unitPrice\":25.06,\"lineAmount\":25.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5475",
      "amount": 55.279998779296875,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1178 OUTSIDE          CLINTON      SC\",\"unitPrice\":55.28,\"lineAmount\":55.28}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5453",
      "amount": 49.029998779296875,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"MARATHON PETRO221572     GREENSB MARATHON PETRO221572 GREENSBORO NC\",\"unitPrice\":49.03,\"lineAmount\":49.03}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5679",
      "amount": 202.6300048828125,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMZN Mktp US*RI10M6RS2   Amzn.com/danWA\",\"unitPrice\":202.63,\"lineAmount\":202.63}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5408",
      "amount": 33.04999923706055,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":33.05,\"lineAmount\":33.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5466",
      "amount": 239.8800048828125,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Yearly QB/inventory  integration fee. ZAPIER.COM/CHARGE        ZAPIER. ZAPIER.COM/CHARGE ZAPIER.COM CA\",\"unitPrice\":239.88,\"lineAmount\":239.88}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1167",
      "amount": 20456.630859375,
      "vendor": "BTE",
      "customer": "551 - 30\" Head and CS",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":17.5,\"partNumber\":\"Onsite Start-up support\",\"description\":\"Daily charge for on-site start up support with new equipment - Kurt Missen on site Jan 24 - Feb 15 2024.\",\"unitPrice\":1500,\"lineAmount\":26250}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5444",
      "amount": 276.5299987792969,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-S NEXT Insur (AP I NEXT Insur ST-S*V*Z9I2N8T2\",\"unitPrice\":276.53,\"lineAmount\":276.53}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1169",
      "amount": 3424,
      "vendor": "BTE",
      "customer": "Dallas 1 Construction, LLC",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"24\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with carbide teeth\",\"unitPrice\":3200,\"lineAmount\":3200}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1170",
      "amount": 209,
      "vendor": "BTE",
      "customer": "McLeRoy Equipment LLC",
      "dateCreated": "2024-02-20",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"THROTTLE CNTRL SET MCL24/42B CNTRL HEAD AND CABLE\",\"unitPrice\":185,\"lineAmount\":185}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9221",
      "amount": 43.0099983215332,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":43.01,\"lineAmount\":43.01}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9265",
      "amount": 4635.990234375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INFINITE ELECTRONICSIRVINE CA XXXX-XXXXXX-X1019\",\"unitPrice\":4635.99,\"lineAmount\":4635.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9255",
      "amount": 12.15999984741211,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIGI-KEY (XXX)XXX-4539 MN XXXX-XXXXXX-X1019\",\"unitPrice\":12.16,\"lineAmount\":12.16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9222",
      "amount": 69.02999877929688,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":69.03,\"lineAmount\":69.03}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5452",
      "amount": 27.219999313354492,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"EXXON SHARPE SHOP        BLYTHEW EXXON SHARPE SHOP BLYTHEWOOD SC\",\"unitPrice\":27.22,\"lineAmount\":27.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5675",
      "amount": 902.8099975585938,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station THE HOME DEPOT 1129      SPARTANBURG  SC\",\"unitPrice\":902.81,\"lineAmount\":902.81}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5441",
      "amount": 72.1500015258789,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT #1129     SPARTAN THE HOME DEPOT #1129 SPARTANBURG SC\",\"unitPrice\":72.15,\"lineAmount\":72.15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5451",
      "amount": 46.189998626708984,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1061                  ROCK HI QT 1061 ROCK HILL SC\",\"unitPrice\":46.19,\"lineAmount\":46.19}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9223",
      "amount": 48.459999084472656,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":48.46,\"lineAmount\":48.46}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5349",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5672",
      "amount": 266.6000061035156,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"auger station LOWES #02595*            SPARTANBURG  SC\",\"unitPrice\":266.6,\"lineAmount\":266.6}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1165",
      "amount": 896.8499755859375,
      "vendor": "BTE",
      "customer": "RDO Equipment Co. CA",
      "dateCreated": "2024-02-15",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"CHAIN TURNBUCKLE 8080042\",\"unitPrice\":225,\"lineAmount\":450},{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"THD CHAIN (L H) MCL-10H REV   8080044\",\"unitPrice\":116.06,\"lineAmount\":232.12},{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"THD CHAIN (R H) MCL-10H REV   8080043\",\"unitPrice\":116.06,\"lineAmount\":232.12},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"FLOAT INDICATOR ASSY, MCL-10H 8080066\",\"unitPrice\":75,\"lineAmount\":75},{\"quantity\":4,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"WASHER,SPHERICAL SET 5/80 BORE\",\"unitPrice\":11.87,\"lineAmount\":47.48},{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"NUT,HEX .625-11\",\"unitPrice\":0.92,\"lineAmount\":1.84}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1164",
      "amount": 457.3999938964844,
      "vendor": "BTE",
      "customer": "Vermeer Heartland",
      "dateCreated": "2024-02-15",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"VALVE HNDL W/LINK V20 24B/30B (WILL WORK ON 54/60 WITH 90)PISTOL GRIP/JOYSTICK HDL.     2400357\",\"unitPrice\":528,\"lineAmount\":528}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9224",
      "amount": 60.09000015258789,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":60.09,\"lineAmount\":60.09}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9225",
      "amount": 283.260009765625,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"UPS* XXX-XXX-1648 GA XXXX-XXXXXX-X1019\",\"unitPrice\":283.26,\"lineAmount\":283.26}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5351",
      "amount": 22.440000534057617,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":22.44,\"lineAmount\":22.44}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9226",
      "amount": 1487.9100341796875,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ULINE SHIP SUPPLIES XXX-XXX-5510 WI XXXX-XXXXXX-X1019\",\"unitPrice\":1487.91,\"lineAmount\":1487.91}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1163",
      "amount": 42999.94140625,
      "vendor": "BTE",
      "customer": "Vermeer Chile",
      "dateCreated": "2024-02-14",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"WATER LEVEL C400000\",\"unitPrice\":2612.8,\"lineAmount\":2612.8},{\"quantity\":2,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"SENSING HEAD  C400010\",\"unitPrice\":62.35,\"lineAmount\":124.7},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"36\\\" MANUAL STEERING HEAD KIT. C500036\",\"unitPrice\":938.3,\"lineAmount\":938.3},{\"quantity\":9,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"36' AUGERX4\\\"HEX X10' R35064X310\",\"unitPrice\":3162.41,\"lineAmount\":28461.69},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"36\\\" CUTTING HEAD 4\\\" HEX\",\"unitPrice\":3984.99,\"lineAmount\":3984.99},{\"quantity\":50,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"CONICAL BULLET DRILL ROCK TOOTH\",\"unitPrice\":16,\"lineAmount\":800},{\"quantity\":10,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"STEERING HEAD DRILL ROD\",\"unitPrice\":130.32,\"lineAmount\":1303.2},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"ADAPTER KIT 900 MM PIPE (36\\\")\",\"unitPrice\":4774.26,\"lineAmount\":4774.26}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9228",
      "amount": 142.8000030517578,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"M & R MACHINE & TOOLBLOOMINGDALE IL XXXX-XXXXXX-X1019\",\"unitPrice\":142.8,\"lineAmount\":142.8}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5334",
      "amount": 116.13999938964844,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":116.14,\"lineAmount\":116.14}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5350",
      "amount": 114.30999755859375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT #1129     SPARTAN THE HOME DEPOT #1129 SPARTANBURG SC\",\"unitPrice\":114.31,\"lineAmount\":114.31}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1159",
      "amount": 74.27999877929688,
      "vendor": "BTE",
      "customer": "Vermeer Southeast  Florida/Georgia/Alabama",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"COUP PUMP NYLON SLEEVE 24-42B\\n2400121\",\"unitPrice\":70.35,\"lineAmount\":70.35}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5289",
      "amount": 14.699999809265137,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2501     WOODRUF TRACTOR SUPPLY #2501 WOODRUFF SC\",\"unitPrice\":14.7,\"lineAmount\":14.7}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5294",
      "amount": 574.1099853515625,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES BRC PAYMENT ***********991 LOWES BRC PAYMENT ***********9919\",\"unitPrice\":574.11,\"lineAmount\":574.11}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1160",
      "amount": 515.47998046875,
      "vendor": "BTE",
      "customer": "Vermeer All Roads - Garner",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"VALVE HNDL W/LINK V20 24B/30B PISTOL GRIP HDL.      2400357\",\"unitPrice\":528,\"lineAmount\":528},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"VLV HNDL W/LINK V20 24B/30B DOG PLATE HANDLE W/ L.  2400359\",\"unitPrice\":86.35,\"lineAmount\":86.35}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5283",
      "amount": 77.87999725341797,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":77.88,\"lineAmount\":77.88}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5668",
      "amount": 111.86000061035156,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"PROPANE TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":111.86,\"lineAmount\":111.86}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9227",
      "amount": 347.32000732421875,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"ULINE SHIP SUPPLIES XXX-XXX-5510 WI XXXX-XXXXXX-X1019\",\"unitPrice\":347.32,\"lineAmount\":347.32}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1154",
      "amount": 560,
      "vendor": "BTE",
      "customer": "General Hydronics Utilities LLC",
      "dateCreated": "2024-02-12",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"S600210 Wing cutter for 18-30\\\" Mcl rock cutter\",\"unitPrice\":265,\"lineAmount\":530}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9256",
      "amount": 480.1400146484375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIGI-KEY (XXX)XXX-4539 MN XXXX-XXXXXX-X1019\",\"unitPrice\":480.14,\"lineAmount\":480.14}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8772",
      "amount": 50.560001373291016,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":50.56,\"lineAmount\":50.56}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1155",
      "amount": 12022.2197265625,
      "vendor": "BTE",
      "customer": "Techint saccade Ingenera y Construction",
      "dateCreated": "2024-02-12",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"S230CX2WC 24\\\" ROCK HEAD WITH WC 4\\\"hex\",\"unitPrice\":3111.55,\"lineAmount\":3111.55},{\"quantity\":100,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"S600765 CONICAL BITS\",\"unitPrice\":20,\"lineAmount\":2000},{\"quantity\":12,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"WING CUTTERS\",\"unitPrice\":212.53,\"lineAmount\":2550.36},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"S290CX2WC 30\\\" ROCK HEAD WC 4\\\" hex\",\"unitPrice\":4360.31,\"lineAmount\":4360.31}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5670",
      "amount": 57.22999954223633,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"WM SUPERCENTER #1035     SPARTANBURG  SC\",\"unitPrice\":57.23,\"lineAmount\":57.23}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5321",
      "amount": 10,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"GOOGLE *ADSXXXXXX2580    cc@goog GOOGLE *ADSXXXXXX2580 cc@google.comCA\",\"unitPrice\":10,\"lineAmount\":10}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5316",
      "amount": 11.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.99,\"lineAmount\":11.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5671",
      "amount": 77.8499984741211,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL 53941600024    INMAN        SC\",\"unitPrice\":77.85,\"lineAmount\":77.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5669",
      "amount": 27.780000686645508,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT #1129     SPARTANBURG  SC\",\"unitPrice\":27.78,\"lineAmount\":27.78}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5477",
      "amount": 42.4900016784668,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-02-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COSTCO GAS #1008         SPARTANBURG  SC\",\"unitPrice\":42.49,\"lineAmount\":42.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5261",
      "amount": 11.0600004196167,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":11.06,\"lineAmount\":11.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5295",
      "amount": 500,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTERNATIONAL WIRE TRANSFER-0000284 2 REF#284 - To India for drawings (deposit)\",\"unitPrice\":500,\"lineAmount\":500}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5293",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#284\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5315",
      "amount": 39.65999984741211,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":39.66,\"lineAmount\":39.66}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5476",
      "amount": 284.2099914550781,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-02-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TMOBILE*AUTO PAY         800-937-8997 WA\",\"unitPrice\":284.21,\"lineAmount\":284.21}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5258",
      "amount": 8.15999984741211,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":8.16,\"lineAmount\":8.16}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5292",
      "amount": 100,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"International Wire Transfer Fee International Wire Transfer Fee REF#66\",\"unitPrice\":100,\"lineAmount\":100}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8773",
      "amount": 190.25,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEXXXXXX6479 FedExMEMPHIS TN XXXX-XXXXXX-X1019\",\"unitPrice\":190.25,\"lineAmount\":190.25}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5323",
      "amount": 878,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Polycarbonate Door, Bobcat R Series, 1/2\\\" AMERIRENT EQUIPMENT 765-2063057 IN\",\"unitPrice\":878,\"lineAmount\":878}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1031",
      "amount": 13352,
      "vendor": "Logan Clutch Corporation",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":4,\"partNumber\":\"Boring Machines:2400252 Clutch for 24C\",\"description\":\"P45-0019 clutch\",\"unitPrice\":1066,\"lineAmount\":4264},{\"quantity\":4,\"partNumber\":\"Boring Machines:3600210 36/42 hy clutch\",\"description\":\"P60-0011 had clutch\",\"unitPrice\":2272,\"lineAmount\":9088}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5290",
      "amount": 172.41000366210938,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":172.41,\"lineAmount\":172.41}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8740",
      "amount": 5.130000114440918,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL 53941600024    INMAN        SC\",\"unitPrice\":5.13,\"lineAmount\":5.13}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5300",
      "amount": 4031.639892578125,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"24B Flex coupling between transmission and planetary\",\"unitPrice\":3652.27,\"lineAmount\":3652.27},{\"quantity\":1,\"unitPrice\":115.61,\"lineAmount\":115.61},{\"quantity\":1,\"description\":\"should not have been charged - asking for a credit\",\"unitPrice\":263.76,\"lineAmount\":263.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5319",
      "amount": 173.05999755859375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KARS SC LLC              INMAN KARS SC LLC INMAN SC\",\"unitPrice\":173.06,\"lineAmount\":173.06}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5288",
      "amount": 175,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":175,\"lineAmount\":175}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8923",
      "amount": 919.6099853515625,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BT*BAILEY INTERNATIOKNOXVILLE TN XXXX-XXXXXX-X1019\",\"unitPrice\":919.61,\"lineAmount\":919.61}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5478",
      "amount": 22.1200008392334,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"OFFICE DEPOT #200        SPARTANBURG  SC\",\"unitPrice\":22.12,\"lineAmount\":22.12}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5314",
      "amount": 11.760000228881836,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.76,\"lineAmount\":11.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5313",
      "amount": 21.399999618530273,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"CHATGPT SUBSCRIPTION     HTTPSOP CHATGPT SUBSCRIPTION HTTPSOPENAI.CCA\",\"unitPrice\":21.4,\"lineAmount\":21.4}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8741",
      "amount": 96.38999938964844,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DOMINO'S 5637            SPARTANBURG  SC\",\"unitPrice\":96.39,\"lineAmount\":96.39}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5317",
      "amount": 713.219970703125,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-06",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"U SAV MOR STORES OF AMERISAN BER U SAV MOR STORES OF AMERISAN BERNARDINCA\",\"unitPrice\":713.22,\"lineAmount\":713.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5170",
      "amount": 2.0899999141693115,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":2.09,\"lineAmount\":2.09}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8774",
      "amount": 21.579999923706055,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":21.58,\"lineAmount\":21.58}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5231",
      "amount": 96.38999938964844,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":96.39,\"lineAmount\":96.39}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5287",
      "amount": 224.72000122070312,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-U NEXT Insur (AP I NEXT Insur ST-U*Z*S8B2A4R3\",\"unitPrice\":224.72,\"lineAmount\":224.72}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5232",
      "amount": 5.130000114440918,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":5.13,\"lineAmount\":5.13}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5230",
      "amount": 34.20000076293945,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":34.2,\"lineAmount\":34.2}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5312",
      "amount": 10.6899995803833,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":10.69,\"lineAmount\":10.69}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5310",
      "amount": 131.6699981689453,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"HAMPTON INNS             CANTON HAMPTON INNS CANTON OH\",\"unitPrice\":131.67,\"lineAmount\":131.67}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5236",
      "amount": 202.69000244140625,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":202.69,\"lineAmount\":202.69}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5233",
      "amount": 11.75,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":11.75,\"lineAmount\":11.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5234",
      "amount": 100.55999755859375,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":100.56,\"lineAmount\":100.56}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5235",
      "amount": 43.849998474121094,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":43.85,\"lineAmount\":43.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5237",
      "amount": 18.170000076293945,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":18.17,\"lineAmount\":18.17}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8776",
      "amount": 14.670000076293945,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":14.67,\"lineAmount\":14.67}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8775",
      "amount": 84.80999755859375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":84.81,\"lineAmount\":84.81}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5101",
      "amount": 8.479999542236328,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-02-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":8.48,\"lineAmount\":8.48}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5106",
      "amount": 40.939998626708984,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1177500PAR MAR 89     MINERAL BP#1177500PAR MAR 89 MINERAL WELLSWV\",\"unitPrice\":40.94,\"lineAmount\":40.94}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5308",
      "amount": 43.47999954223633,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"EXXON CIRCLE K #108      HILLSVI EXXON CIRCLE K #108 HILLSVILLE VA\",\"unitPrice\":43.48,\"lineAmount\":43.48}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5320",
      "amount": 186.24000549316406,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"MISCELLANEOUS ADJUSTMENT\",\"unitPrice\":186.24,\"lineAmount\":186.24}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5309",
      "amount": 12.829999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":12.83,\"lineAmount\":12.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5311",
      "amount": 36.47999954223633,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPEEDWAY 05120 2348 E WHECAMBRID SPEEDWAY 05120 2348 E WHECAMBRIDGE OH\",\"unitPrice\":36.48,\"lineAmount\":36.48}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5111",
      "amount": 52.2599983215332,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-02-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#2410314TIMES 51       STATESV BP#2410314TIMES 51 STATESVILLE NC\",\"unitPrice\":52.26,\"lineAmount\":52.26}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5461",
      "amount": 7906.490234375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-02-01",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KFS1160\",\"unitPrice\":7722,\"lineAmount\":7722},{\"quantity\":1,\"unitPrice\":184.49,\"lineAmount\":184.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5086",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5307",
      "amount": 241.82000732421875,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-31",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"INTUIT *QBooks Online    CL.INTU INTUIT *QBooks Online CL.INTUIT.COMCA\",\"unitPrice\":241.82,\"lineAmount\":241.82}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1143",
      "amount": 2119.5,
      "vendor": "BTE",
      "customer": "Vermeer Southeast Florida/ Georgia",
      "dateCreated": "2024-01-30",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5306",
      "amount": 23.270000457763672,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-30",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":23.27,\"lineAmount\":23.27}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8777",
      "amount": 65.83999633789062,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":65.84,\"lineAmount\":65.84}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5128",
      "amount": 6428.02001953125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Interest only for Mcl Parts Loan:  First payment\",\"unitPrice\":6428.02,\"lineAmount\":6428.02}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1141",
      "amount": 507,
      "vendor": "BTE",
      "customer": "CK Contractors & Development",
      "dateCreated": "2024-01-29",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":2,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"Boretec sale-isolator,rear MCL24B\",\"unitPrice\":68,\"lineAmount\":136},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"Coupling, Half Morflex MCL24B 602-R 1-3/8 FB\",\"unitPrice\":338,\"lineAmount\":338}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1142",
      "amount": 428.1600036621094,
      "vendor": "BTE",
      "customer": "CK Contractors & Development",
      "dateCreated": "2024-01-29",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Coupling Center member\",\"unitPrice\":400.15,\"lineAmount\":400.15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5245",
      "amount": 307.75,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-01-29",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":307.75,\"lineAmount\":307.75}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5305",
      "amount": 28.579999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KITCHEN 292              INMAN KITCHEN 292 INMAN SC\",\"unitPrice\":28.58,\"lineAmount\":28.58}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5465",
      "amount": 134.8000030517578,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-28",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON MARKETPLACE NAMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":134.8,\"lineAmount\":134.8}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8778",
      "amount": 179.24000549316406,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":179.24,\"lineAmount\":179.24}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8779",
      "amount": 211.6300048828125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-27",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":211.63,\"lineAmount\":211.63}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5304",
      "amount": 39.27000045776367,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-26",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":39.27,\"lineAmount\":39.27}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5197",
      "amount": 557.3400268554688,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Pump,Water, 115VAC High Press\",\"unitPrice\":557.34,\"lineAmount\":557.34}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5525",
      "amount": 207.8300018310547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-25",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"B&D INDUSTRIAL           478-746 B&D INDUSTRIAL 478-746-7623 GA\",\"unitPrice\":207.83,\"lineAmount\":207.83}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1140",
      "amount": 571,
      "vendor": "BTE",
      "customer": "West Texas Boring, LLC",
      "dateCreated": "2024-01-25",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1138",
      "amount": 97.44000244140625,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-24",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5044",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1138",
      "amount": 97.44000244140625,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-24",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1139",
      "amount": 8938.66015625,
      "vendor": "BTE",
      "customer": "KM Davis Contracting",
      "dateCreated": "2024-01-24",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"TORQUE HUB,S3B MCL-24B\",\"unitPrice\":6182.23,\"lineAmount\":6182.23},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"SHAFT,INPUT ADAPTER 20-24B SAE B - SPL. SPLINED S -P330000\",\"unitPrice\":421.66,\"lineAmount\":421.66},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"16\\\" combo cutting head with wing cutters on 2-1/4\\\" hex with #150 carbide teeth 16-16COMBOx2.25\",\"unitPrice\":1750,\"lineAmount\":1750}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5238",
      "amount": 12.850000381469727,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-24",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":12.85,\"lineAmount\":12.85}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1137",
      "amount": 100.87999725341797,
      "vendor": "BTE",
      "customer": "J & M Construction Inc",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1135",
      "amount": 4800,
      "vendor": "BTE",
      "customer": "Underground Utilities LLC",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5125",
      "amount": 27.190000534057617,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SPARTANBURG WATE danPAY SPARTAN SPARTANBURG WATE danPAY SPARTANBURG WAT\",\"unitPrice\":27.19,\"lineAmount\":27.19}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5301",
      "amount": 30.81999969482422,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"RACEWAY 6954  02369544   FLORENC RACEWAY 6954 02369544 FLORENCE SC\",\"unitPrice\":30.82,\"lineAmount\":30.82}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5302",
      "amount": 33.08000183105469,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":33.08,\"lineAmount\":33.08}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5303",
      "amount": 2.990000009536743,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":2.99,\"lineAmount\":2.99}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1134",
      "amount": 799,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1136",
      "amount": 327.20001220703125,
      "vendor": "BTE",
      "customer": "Vermeer Midsouth",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1134",
      "amount": 799,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5244",
      "amount": 95.88999938964844,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":95.89,\"lineAmount\":95.89}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5036",
      "amount": 1.0099999904632568,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":1.01,\"lineAmount\":1.01}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5318",
      "amount": 49.63999938964844,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-23",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIRECT TOOLS FACTORY O   MYRTLE DIRECT TOOLS FACTORY O MYRTLE BEACH SC\",\"unitPrice\":49.64,\"lineAmount\":49.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5124",
      "amount": 236.22000122070312,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-F NEXT Insur (AP I NEXT Insur ST-F*U*O0F7M7B1\",\"unitPrice\":236.22,\"lineAmount\":236.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5473",
      "amount": 159.7100067138672,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Spectrum                 855-707 Spectrum 855-707-7328 MO\",\"unitPrice\":159.71,\"lineAmount\":159.71}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8780",
      "amount": 54.220001220703125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-22",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":54.22,\"lineAmount\":54.22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5479",
      "amount": 49.68000030517578,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-01-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COSTCO GAS #1008         SPARTANBURG  SC\",\"unitPrice\":49.68,\"lineAmount\":49.68}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5246",
      "amount": 23.049999237060547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SXM*SIRIUSXM.COM/ACCT    888-635 SXM*SIRIUSXM.COM/ACCT 888-635-5144 NY\",\"unitPrice\":23.05,\"lineAmount\":23.05}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5247",
      "amount": 19.270000457763672,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"PILOT_00346              LUGOFF PILOT_00346 LUGOFF SC\",\"unitPrice\":19.27,\"lineAmount\":19.27}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8782",
      "amount": 22,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Fedex XXX-XXX-3339 TN XXXX-XXXXXX-X1019\",\"unitPrice\":22,\"lineAmount\":22}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5112",
      "amount": 32.63999938964844,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":32.64,\"lineAmount\":32.64}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8781",
      "amount": 688.4099731445312,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-21",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":688.41,\"lineAmount\":688.41}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1133",
      "amount": 7720,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2024-01-19",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":20,\"partNumber\":\"Teeth:King Kong 1160 teeth\",\"description\":\"King Kong teeth\",\"unitPrice\":386,\"lineAmount\":7720}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1133",
      "amount": 7720,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2024-01-19",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":20,\"partNumber\":\"Teeth:King Kong 1160 teeth\",\"description\":\"King Kong teeth\",\"unitPrice\":386,\"lineAmount\":7720}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5110",
      "amount": 366,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"THE HOME DEPOT 1129      SPARTAN THE HOME DEPOT 1129 SPARTANBURG SC\",\"unitPrice\":366,\"lineAmount\":366}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5520",
      "amount": 2632.72998046875,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-19",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KING KONG TOOLS          678-765 KING KONG TOOLS 678-7657930 GA\",\"unitPrice\":2632.73,\"lineAmount\":2632.73}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5497",
      "amount": 201.1199951171875,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NAPA OF BOILING SPRINGS  BOILING SPRINSC\",\"unitPrice\":201.12,\"lineAmount\":201.12}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1132",
      "amount": 2038.6700439453125,
      "vendor": "BTE",
      "customer": "Tom Brigman Contractors, Inc",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Boring Machines:24-Thrust cylinder 24B 5x42x2.5  2400437\",\"description\":\"Thrust cylinder 24B 5x42x2.5\",\"unitPrice\":2117,\"lineAmount\":2117}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5115",
      "amount": 831.77001953125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SC DEPT REVENUE DEBIT ****5430\",\"unitPrice\":831.77,\"lineAmount\":831.77}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5498",
      "amount": 38.66999816894531,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"WAL-MART #2806           BOILING SPRINSC\",\"unitPrice\":38.67,\"lineAmount\":38.67}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1130",
      "amount": 167.39999389648438,
      "vendor": "BTE",
      "customer": "Vermeer Southeast",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"6202-2RS/16mm\",\"description\":\"Pilot Bearing 24B\",\"unitPrice\":43,\"lineAmount\":43}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1131",
      "amount": 12790,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Heads:18-30\\\" XTRM bit for std OTS heads, with OD cutters (1-1/2\\\" bolts), 4\\\"hex, 2-1/2\\\" plate & KK Teeth\",\"description\":\"18-30\\\" XTRM bit for standard OTS heads, with OD cutters (1-1/2\\\" bolts), 4\\\"hex, 2-1/2\\\" plate & King Kong Teeth\",\"unitPrice\":12500,\"lineAmount\":12500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1129",
      "amount": 772.2000122070312,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":12,\"partNumber\":\"Teeth:BTE150\",\"description\":\"Boretec 150  Carbide Tooth\",\"unitPrice\":32,\"lineAmount\":384},{\"quantity\":12,\"partNumber\":\"Teeth:BTE87B for150 tooth\",\"description\":\"150 Tooth Block Holder\",\"unitPrice\":25,\"lineAmount\":300}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4990",
      "amount": 1.5499999523162842,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":1.55,\"lineAmount\":1.55}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1132",
      "amount": 2038.6700439453125,
      "vendor": "BTE",
      "customer": "Tom Brigman Contractors, Inc",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Boring Machines:24-Thrust cylinder 24B 5x42x2.5  2400437\",\"description\":\"Thrust cylinder 24B 5x42x2.5\",\"unitPrice\":2117,\"lineAmount\":2117}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1129",
      "amount": 772.2000122070312,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":12,\"partNumber\":\"Teeth:BTE150\",\"description\":\"Boretec 150  Carbide Tooth\",\"unitPrice\":32,\"lineAmount\":384},{\"quantity\":12,\"partNumber\":\"Teeth:BTE87B for150 tooth\",\"description\":\"150 Tooth Block Holder\",\"unitPrice\":25,\"lineAmount\":300}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5521",
      "amount": 3945.6201171875,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KING KONG TOOLS          678-765 KING KONG TOOLS 678-7657930 GA\",\"unitPrice\":3945.62,\"lineAmount\":3945.62}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1130",
      "amount": 167.39999389648438,
      "vendor": "BTE",
      "customer": "Vermeer Southeast",
      "dateCreated": "2024-01-18",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"6202-2RS/16mm\",\"description\":\"Pilot Bearing 24B\",\"unitPrice\":43,\"lineAmount\":43}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9268",
      "amount": 317.45001220703125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COYOTE LOGISTICS,LLCXXX-XXX-9683 GA XXXX-XXXXXX-X1001\",\"unitPrice\":317.45,\"lineAmount\":317.45}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1126",
      "amount": 6151,
      "vendor": "BTE",
      "customer": "RDO Equipment Co (MN)",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"70-drill stem:10H sub saver Firestick\",\"description\":\"10H sub saver Firestick\",\"unitPrice\":365,\"lineAmount\":365},{\"quantity\":20,\"partNumber\":\"10H firestick drill rod, 1.75OD 48\\\" long\",\"description\":\"10H firestick drill rod, 1.75OD 48\\\" long\",\"unitPrice\":345,\"lineAmount\":6900}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1128",
      "amount": 864.7999877929688,
      "vendor": "BTE",
      "customer": "Vermeer Southeast",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5243",
      "amount": 696.3200073242188,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TECH PRODUCTS CO         937-438 TECH PRODUCTS CO 937-438-1100 OH\",\"unitPrice\":696.32,\"lineAmount\":696.32}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8784",
      "amount": 1444.5,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BT*WIRE AND CABLE YOCHICAGO IL XXXX-XXXXXX-X1001\",\"unitPrice\":1444.5,\"lineAmount\":1444.5}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9270",
      "amount": 59.95000076293945,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BT*PAINTHOSE.COM MILACA MN XXXX-XXXXXX-X1019\",\"unitPrice\":59.95,\"lineAmount\":59.95}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9269",
      "amount": 292.7900085449219,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COYOTE LOGISTICS,LLCXXX-XXX-9683 GA XXXX-XXXXXX-X1019\",\"unitPrice\":292.79,\"lineAmount\":292.79}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8783",
      "amount": 3195,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KING KONG TOOLS 4616BUFORD GA XXXX-XXXXXX-X1019\",\"unitPrice\":3195,\"lineAmount\":3195}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9267",
      "amount": 1661.9300537109375,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"POWER EQUIPMENT DIREBOLINGBROOK IL XXXX-XXXXXX-X1019\",\"unitPrice\":1661.93,\"lineAmount\":1661.93}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5471",
      "amount": 393.1600036621094,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":367.96,\"lineAmount\":367.96},{\"quantity\":1,\"unitPrice\":25.2,\"lineAmount\":25.2}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5107",
      "amount": 26.1299991607666,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"SHELL OIL XXXXXXX0024    INMAN SHELL OIL XXXXXXX0024 INMAN SC\",\"unitPrice\":26.13,\"lineAmount\":26.13}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5109",
      "amount": 242.3300018310547,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"HOMEDEPOT.COM            800-430 HOMEDEPOT.COM 800-430-3376 GA\",\"unitPrice\":242.33,\"lineAmount\":242.33}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1127",
      "amount": 155,
      "vendor": "BTE",
      "customer": "KM Davis Contracting",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Boring Machines:36/42 and 24 motor mounts 51508-2\",\"description\":\"36/42 and rear 24 motor mounts\",\"unitPrice\":39,\"lineAmount\":39},{\"quantity\":2,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"ISOLATOR,REAR  MCL24B\",\"unitPrice\":33,\"lineAmount\":66}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4970",
      "amount": 2270.949951171875,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-17",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"P45-0019 Clutch Assembly\",\"unitPrice\":2132,\"lineAmount\":2132},{\"quantity\":1,\"unitPrice\":75,\"lineAmount\":75},{\"quantity\":1,\"description\":\"CC processing fees on vendor side\",\"unitPrice\":63.95,\"lineAmount\":63.95}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5122",
      "amount": 422.010009765625,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"LOWES BRC PAYMENT ***********991 LOWES BRC PAYMENT ***********9919\",\"unitPrice\":422.01,\"lineAmount\":422.01}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5121",
      "amount": 8500,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FCB FUNDS TRANSFER TO  X9424\",\"unitPrice\":8500,\"lineAmount\":8500}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5123",
      "amount": 1012.7100219726562,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Check 1233\",\"unitPrice\":1012.71,\"lineAmount\":1012.71}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5242",
      "amount": 40.54999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-16",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"Pipedrive INC            NY Pipedrive INC NY NY\",\"unitPrice\":40.55,\"lineAmount\":40.55}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5241",
      "amount": 11.989999771118164,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-15",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.99,\"lineAmount\":11.99}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4957",
      "amount": 118.11000061035156,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":118.11,\"lineAmount\":118.11}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5239",
      "amount": 31.950000762939453,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":31.95,\"lineAmount\":31.95}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5240",
      "amount": 849.3499755859375,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEXXXXXXXXX4710        Collier FEDEXXXXXXXXX4710 Collierville TN\",\"unitPrice\":849.35,\"lineAmount\":849.35}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5481",
      "amount": 55.630001068115234,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-01-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"EXXON ENERGY MART NO 1  8HENDERSONVILLNC\",\"unitPrice\":55.63,\"lineAmount\":55.63}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5108",
      "amount": 22.229999542236328,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2501     WOODRUF TRACTOR SUPPLY #2501 WOODRUFF SC\",\"unitPrice\":22.23,\"lineAmount\":22.23}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5524",
      "amount": 64.70999908447266,
      "vendor": "Joyce (7036) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-14",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"OFFICE DEPOT #200        SPARTAN OFFICE DEPOT #200 SPARTANBURG SC\",\"unitPrice\":64.71,\"lineAmount\":64.71}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8785",
      "amount": 63.970001220703125,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-13",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"FEDEX - EXPRESS XXX-XXX-1147 TN XXXX-XXXXXX-X1019\",\"unitPrice\":63.97,\"lineAmount\":63.97}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4917",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1125",
      "amount": 22316,
      "vendor": "BTE",
      "customer": "UIT Builds",
      "dateCreated": "2024-01-12",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Heads:36-48\\\" XTRM bit for std OTS heads, with OD cutters (2.25 pins), 5\\\"hex, 3\\\" plate & KK Teeth\",\"description\":\"36-48\\\" XTRM bit for standard OTS heads, with OD cutters (2.25 pins), 5\\\"hex, 3\\\" plate & King Kong Teeth\",\"unitPrice\":18500,\"lineAmount\":18500},{\"quantity\":6,\"partNumber\":\"Teeth:King Kong 1160 teeth\",\"description\":\"King Kong teeth\",\"unitPrice\":386,\"lineAmount\":2316}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5105",
      "amount": 16,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":16,\"lineAmount\":16}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1124",
      "amount": 2675,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2024-01-12",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Heads:Cutting Head 8\\\" rock cutting head with WC on 1-5/8\\\" hex\",\"description\":\"Cutting Head 8\\\" rock cutting head with WC on 1-5/8\\\" hex\",\"unitPrice\":2500,\"lineAmount\":2500}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5501",
      "amount": 55.619998931884766,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-01-12",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC\",\"unitPrice\":55.62,\"lineAmount\":55.62}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4907",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "8926",
      "amount": 15.710000038146973,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-11",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"AMAZON.COM AMZN.COM/dan WA XXXX-XXXXXX-X1001\",\"unitPrice\":15.71,\"lineAmount\":15.71}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1122",
      "amount": 380,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2024-01-11",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:Pull out for 24\\\" and up OTS\",\"description\":\"Pull Out with LED lights, no electronic sensor for 24\\\" and up OTS heads\",\"unitPrice\":350,\"lineAmount\":350}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1123",
      "amount": 2546.840087890625,
      "vendor": "BTE",
      "customer": "Vermeer Southeast",
      "dateCreated": "2024-01-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"2400252 CLUTCH,HYD MCL-24B (L)\",\"unitPrice\":2454.84,\"lineAmount\":2454.84}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1123",
      "amount": 2546.840087890625,
      "vendor": "BTE",
      "customer": "Vermeer Southeast",
      "dateCreated": "2024-01-11",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"2400252 CLUTCH,HYD MCL-24B (L)\",\"unitPrice\":2454.84,\"lineAmount\":2454.84}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4900",
      "amount": 13.199999809265137,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-10",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"USPS.COM CLICKNSHIP      800-344 USPS.COM CLICKNSHIP 800-344-7779 DC\",\"unitPrice\":13.2,\"lineAmount\":13.2}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1121",
      "amount": 4659.4501953125,
      "vendor": "BTE",
      "customer": "Vermeer Texas/Louisana",
      "dateCreated": "2024-01-10",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"power outlet, Accessory (light) weatherproof\",\"unitPrice\":120.809,\"lineAmount\":120.81},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"cord,GFCI, 20A for CNTRL Station\",\"unitPrice\":134.1517,\"lineAmount\":134.15},{\"quantity\":1,\"partNumber\":\"Steering (Control) Station:PTPE172\",\"description\":\"10K PSI Hydraulic Pump 110V\",\"unitPrice\":4216.1986,\"lineAmount\":4216.2},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Volt meter CNTRL STA. SRS Digital Summit Racing\",\"unitPrice\":172.7414,\"lineAmount\":172.74},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Timer Control Station SRS Light Timer\",\"unitPrice\":30.6386,\"lineAmount\":30.64},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Pump,Water, 115VAC High Press\",\"unitPrice\":696.6718,\"lineAmount\":696.67}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1119",
      "amount": 3950,
      "vendor": "BTE",
      "customer": "Brad Atkinson",
      "dateCreated": "2024-01-10",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"20\\\" OTS standard cutting Head on 3\\\" hex with carbide #150 teeth\",\"unitPrice\":3500,\"lineAmount\":3500}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4901",
      "amount": 29.889999389648438,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-09",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"BP#1979509WOODRUFF TRUCK ROEBUCK BP#1979509WOODRUFF TRUCK ROEBUCK SC\",\"unitPrice\":29.89,\"lineAmount\":29.89}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4912",
      "amount": 191.4499969482422,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-P NEXT Insur (AP I NEXT Insur ST-P*B*O2H3R4R9\",\"unitPrice\":191.45,\"lineAmount\":191.45}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4956",
      "amount": 221.8800048828125,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-01-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":221.88,\"lineAmount\":221.88}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1118",
      "amount": 9995,
      "vendor": "BTE",
      "customer": "DRS Enterprises",
      "dateCreated": "2024-01-08",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering (Control) Station:1-Pitch Indicator/Electronic Steering sold with our Steering Station. PRICE TIMES 1.5 IF SOLD ALONE\",\"description\":\"Electronic Steering, shows pitch of head and direction on line. Sold with Steering Station. Includes case and pull out. Sensor#HWT901B-485\",\"unitPrice\":9995,\"lineAmount\":9995}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1117",
      "amount": 35420,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2024-01-08",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Heads:36-48\\\" XTRM bit for std OTS heads, with OD cutters (2.25 pins), 5\\\"hex, 3\\\" plate & KK Teeth\",\"description\":\"36-48\\\" XTRM bit for standard OTS heads, with OD cutters (2.25 pins), 5\\\"hex, 3\\\" plate & King Kong Teeth\",\"unitPrice\":18500,\"lineAmount\":18500},{\"quantity\":8,\"partNumber\":\"Various/Misc:1-Standard 50 ton flap cylinder (35 ton at 6800 psi) for 30\\\" and above TBMs. 5\\\" tall and 4-7/8\\\" dia\",\"description\":\"Standard 35 ton flap cylinder at 6800 psi, 2\\\" stroke, 4-7/8\\\" dia for 30\\\" and above Steering Heads and TBMs\",\"unitPrice\":495,\"lineAmount\":3960},{\"quantity\":8,\"partNumber\":\"Various/Misc:Hinge for 30\\\" and larger steering heads. Takes 8ea 3/4 flat head Allen bolts 1-3/4 long. BTE4x1/2x12\",\"description\":\"New Style Hinge 4x1/2x12 long, 8ea 3/4\\\" Allen bolt holes. For OTS, Rush and Auger assist TBMs.\",\"unitPrice\":120,\"lineAmount\":960},{\"quantity\":1,\"partNumber\":\"PLR:PLR-Fabricated Parts\",\"description\":\"Replace left flap, install new style hinges\",\"unitPrice\":12000,\"lineAmount\":12000},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred\",\"unitPrice\":0,\"lineAmount\":0}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1117",
      "amount": 35420,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2024-01-08",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Heads:36-48\\\" XTRM bit for std OTS heads, with OD cutters (2.25 pins), 5\\\"hex, 3\\\" plate & KK Teeth\",\"description\":\"36-48\\\" XTRM bit for standard OTS heads, with OD cutters (2.25 pins), 5\\\"hex, 3\\\" plate & King Kong Teeth\",\"unitPrice\":18500,\"lineAmount\":18500},{\"quantity\":8,\"partNumber\":\"Various/Misc:1-Standard 50 ton flap cylinder (35 ton at 6800 psi) for 30\\\" and above TBMs. 5\\\" tall and 4-7/8\\\" dia\",\"description\":\"Standard 35 ton flap cylinder at 6800 psi, 2\\\" stroke, 4-7/8\\\" dia for 30\\\" and above Steering Heads and TBMs\",\"unitPrice\":495,\"lineAmount\":3960},{\"quantity\":8,\"partNumber\":\"Various/Misc:Hinge for 30\\\" and larger steering heads. Takes 8ea 3/4 flat head Allen bolts 1-3/4 long. BTE4x1/2x12\",\"description\":\"New Style Hinge 4x1/2x12 long, 8ea 3/4\\\" Allen bolt holes. For OTS, Rush and Auger assist TBMs.\",\"unitPrice\":120,\"lineAmount\":960},{\"quantity\":1,\"partNumber\":\"PLR:PLR-Fabricated Parts\",\"description\":\"Replace left flap, install new style hinges\",\"unitPrice\":12000,\"lineAmount\":12000},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred\",\"unitPrice\":0,\"lineAmount\":0}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4861",
      "amount": 15,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-08",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"System-recorded fee for QuickBooks Payments. Fee-name: DiscountRateFee, fee-type: Daily.\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5482",
      "amount": 47.40999984741211,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-01-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"COSTCO GAS #1008         SPARTANBURG  SC\",\"unitPrice\":47.41,\"lineAmount\":47.41}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5522",
      "amount": 2632.85009765625,
      "vendor": "Sam (5941) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"KING KONG TOOLS          678-765 KING KONG TOOLS 678-7657930 GA\",\"unitPrice\":2632.85,\"lineAmount\":2632.85}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4914",
      "amount": 11.760000228881836,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-07",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":11.76,\"lineAmount\":11.76}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "9257",
      "amount": 32.13999938964844,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"DIGI-KEY (XXX)XXX-4539 MN XXXX-XXXXXX-X1019\",\"unitPrice\":32.14,\"lineAmount\":32.14}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5102",
      "amount": 494.8800048828125,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"unitPrice\":494.88,\"lineAmount\":494.88}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4915",
      "amount": 20.200000762939453,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-05",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"QT 1128                  INMAN QT 1128 INMAN SC\",\"unitPrice\":20.2,\"lineAmount\":20.2}]"
  },
  {
      "transactionType": "PO",
      "idNumber": "1028",
      "amount": 653.760009765625,
      "vendor": "Tech Products",
      "customer": "BTE",
      "dateCreated": "2024-01-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":24,\"partNumber\":\"Boring Machines:24C and 36/42 motor mounts\",\"description\":\"51507-4\",\"unitPrice\":14.27,\"lineAmount\":342.48},{\"quantity\":24,\"partNumber\":\"Boring Machines:36/42 and 24 motor mounts 51508-2\",\"description\":\"51508-2\",\"unitPrice\":12.97,\"lineAmount\":311.28}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5483",
      "amount": 65.48999786376953,
      "vendor": "Rhonda (3833)",
      "customer": "BTE",
      "dateCreated": "2024-01-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"T-MOBILE STORE # 9399    SPARTANBURG  SC\",\"unitPrice\":65.49,\"lineAmount\":65.49}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4939",
      "amount": 4500,
      "vendor": "Amazon AMEX",
      "customer": "BTE",
      "dateCreated": "2024-01-04",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TQL- TRAX MILFORD OH XXXX-XXXXXX-X1019\",\"unitPrice\":4500,\"lineAmount\":4500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1115",
      "amount": 7830,
      "vendor": "BTE",
      "customer": "California Auger Boring",
      "dateCreated": "2024-01-04",
      "dateFulfilled": '',
      "origin": "lois",
      "items": "[{\"quantity\":4,\"partNumber\":\"Various/Misc:Hinge for 30\\\" and larger steering heads. Takes 8ea 3/4 flat head Allen bolts 1-3/4 long. BTE4x1/2x12\",\"description\":\"New Style Hinge 4x1/2x12 long, 8ea 3/4\\\" Allen bolt holes. For OTS, Rush and Auger assist TBMs.\",\"unitPrice\":120,\"lineAmount\":480},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred TBD\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":15,\"partNumber\":\"Various/Misc:1-Low ProHydraulic cylinder - (35 ton @ 6800) 50Ton, 25mm Stroke, 1/4 NPT. Used on 24\\\" Steering Head\",\"description\":\"Low ProHydraulic cylinder - 35 ton @ 6800, 1\\\" Stroke, 1/4 NPT, used on 24\\\"OTS and RUSH heads.\",\"unitPrice\":490,\"lineAmount\":7350}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1114",
      "amount": 135355,
      "vendor": "BTE",
      "customer": "DRS Enterprises",
      "dateCreated": "2024-01-04",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:30\\\"RUSH (rock utility steering head) NO CUTTING HEAD\",\"description\":\"30\\\" Rock Utility Steering Head (RUSH) for 30\\\" casing, cuts 32.5\\\" for rock. 4\\\" hex Auger with 1-1/2\\\" flights. Cutting Bits available, carbide teeth, King Kong teeth and Roller cone cutters. Has Water sensor, bentonite port and water to front port, all coming through 5\\\" channel. Boretec Pull out has 3 LED steering lights, ready for Electronic Steering. Uses 20 -24\\\" augers.  Must order bit separate. Ser#BTERUSH302401\",\"unitPrice\":62500,\"lineAmount\":62500},{\"quantity\":1,\"partNumber\":\"Steering (Control) Station:1-600 McLaughlin OTS Control Station, good for 570' crossings\",\"description\":\"600 Steering Station, good for 570' crossings. 34\\\" wide x 68\\\" high x 62\\\" long, weighs 2350 LBS. Controls small TBMs, RUSH, OTS and most steering heads, has water level controls, pump for steering flaps, 600' of 4ea hydraulic lines, 600' of water level hose, 600' of wire for left/right LEDs, work lights, ready for Electronic Guidance. Comes with pull out. Ser #BTE600SS08\",\"unitPrice\":48500,\"lineAmount\":48500},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Labor Hours\",\"description\":\"shipping TBD\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Cutting Heads:18-30\\\" XTRM bit for std OTS heads, with OD cutters (1-1/2\\\" bolts), 4\\\"hex, 2-1/2\\\" plate & KK Teeth\",\"description\":\"24-32\\\" XTRM bit for steering heads, with OD cutters (2-1/4 pins), 4\\\"hex, 2-1/2\\\" plate & King Kong Teeth\",\"unitPrice\":15500,\"lineAmount\":15500}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4916",
      "amount": 10.6899995803833,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":10.69,\"lineAmount\":10.69}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "5500",
      "amount": 33.9900016784668,
      "vendor": "Vitaly (9804)",
      "customer": "BTE",
      "dateCreated": "2024-01-03",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"TRACTOR SUPPLY #2516     BOILING SPRINSC - propane for shop forklifts\",\"unitPrice\":33.99,\"lineAmount\":33.99}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1112",
      "amount": 14937,
      "vendor": "BTE",
      "customer": "Global Underground Corp",
      "dateCreated": "2024-01-03",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"34\\\" OD auger with 1-1/2\\\" contact flights, on 4\\\" hex, 6' long for 48\\\" OTS\",\"unitPrice\":13385,\"lineAmount\":13385},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Freight\",\"unitPrice\":1552,\"lineAmount\":1552}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1113",
      "amount": 2262,
      "vendor": "BTE",
      "customer": "Vermeer MV Solutions",
      "dateCreated": "2024-01-03",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":2,\"partNumber\":\"Various/Misc:Parts\",\"description\":\"24C Valve assembly #2400713\",\"unitPrice\":1413.75,\"lineAmount\":2827.5}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1112",
      "amount": 14937,
      "vendor": "BTE",
      "customer": "Global Underground Corp",
      "dateCreated": "2024-01-03",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"34\\\" OD auger with 1-1/2\\\" contact flights, on 4\\\" hex, 6' long for 48\\\" OTS\",\"unitPrice\":13385,\"lineAmount\":13385},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Freight\",\"unitPrice\":1552,\"lineAmount\":1552}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4844",
      "amount": 419.79998779296875,
      "vendor": "FCB 9432",
      "customer": "BTE",
      "dateCreated": "2024-01-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"NEXT Insur (AP I NEXT Insur ST-X NEXT Insur (AP I NEXT Insur ST-X*Q*M8I8R5M9\",\"unitPrice\":419.8,\"lineAmount\":419.8}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4842",
      "amount": 12.829999923706055,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"APPLE.COM/dan           866-712 APPLE.COM/dan 866-712-7753 CA\",\"unitPrice\":12.83,\"lineAmount\":12.83}]"
  },
  {
      "transactionType": "Expense",
      "idNumber": "4841",
      "amount": 403.44000244140625,
      "vendor": "dan (5966) - 1",
      "customer": "BTE",
      "dateCreated": "2024-01-02",
      "dateFulfilled": '',
      "origin": "QBO",
      "items": "[{\"quantity\":1,\"description\":\"MISCELLANEOUS ADJUSTMENT\",\"unitPrice\":403.44,\"lineAmount\":403.44}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1110",
      "amount": 11258,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2023-12-13",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"14-3/4” x 5’ long on 4” hex with 1-1/2” contact flighting for 24” OTS\",\"unitPrice\":4908,\"lineAmount\":4908},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"16-3/4” x 6’ long on 4” hex with 1-1/2” contact flighting for 30” OTS\",\"unitPrice\":5350,\"lineAmount\":5350},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred, estimated, will be ajusted.\",\"unitPrice\":1000,\"lineAmount\":1000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1109",
      "amount": 2681,
      "vendor": "BTE",
      "customer": "Jim Robinson Contractors Inc",
      "dateCreated": "2023-12-11",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":4,\"partNumber\":\"Various/Misc:1-Low ProHydraulic cylinder - (35 ton @ 6800) 50Ton, 25mm Stroke, 1/4 NPT. Used on 24\\\" Steering Head\",\"description\":\"Low ProHydraulic cylinder - 35 ton @ 6800, 1\\\" Stroke, 1/4 NPT, used on 24\\\"OTS and RUSH heads.\",\"unitPrice\":490,\"lineAmount\":1960},{\"quantity\":6,\"partNumber\":\"Various/Misc:Hinge for 30\\\" and larger steering heads. Takes 8ea 3/4 flat head Allen bolts 1-3/4 long. BTE4x1/2x12\",\"description\":\"New Style Hinge 4x1/2x12 long, 8ea 3/4\\\" Allen bolt holes. For OTS, Rush and Auger assist TBMs.\",\"unitPrice\":120,\"lineAmount\":720},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred\",\"unitPrice\":1,\"lineAmount\":1}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1107",
      "amount": 350,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2023-12-08",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:Pull out for 24\\\" and up OTS\",\"description\":\"Pull Out with LED lights, no electronic sensor for 24\\\" and up OTS heads\",\"unitPrice\":350,\"lineAmount\":350}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1104",
      "amount": 214000,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-12-04",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:66\\\" McLaughlin Super OTS Auger assit, man entry 50HP Electric TBM\",\"description\":\"66\\\"Auger assit 50HP Electric TBM, with 24\\\" of adjustment for bit placement, with out pusher adapter. Includes 50' of electrical cord, with low voltage lighting at head, 4 steering flaps, set up for 20\\\" conveyor auger, LH half bit with King Kong teeth with 22,000 ft/lb torque. Customer furnish 20\\\" augers and extraction pipe, 150KW generator, 48-60\\\" auger boring machine.\",\"unitPrice\":200000,\"lineAmount\":200000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1103",
      "amount": 200000,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-12-04",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:70\\\"Auger assit 50HP Electric TBM for 60\\\" Hobas pipe.\",\"description\":\"70\\\"Auger assit 50HP Electric TBM for 60\\\" Hobas pipe, with 24\\\" of bit ajustment, with pusher adapter and 2 saddles. Includes 50' of electrical cord, with low voltage lighting at head, 4 steering flaps, set up for 24\\\" conveyor auger, LH half bit with King Kong teeth with 22,000 ft/lb torque. Customer furnish 24\\\" augers and extraction pipe, 75-100KW generator, 60to 72\\\" auger boring machine.\",\"unitPrice\":200000,\"lineAmount\":200000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1102",
      "amount": 139641.40625,
      "vendor": "BTE",
      "customer": "Favela Boring & Underground Utilities LLC",
      "dateCreated": "2023-12-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:24\\\" McLaughlin OTS steering head, with 4\\\" hex auger and combo carbide teeth cutting head.\",\"description\":\"24\\\" McL OTS, with auger on 4\\\" hex and cutting head. ser# BTE2416200\",\"unitPrice\":53732.35,\"lineAmount\":53732.35},{\"quantity\":1,\"partNumber\":\"Steering (Control) Station:1-600 McLaughlin OTS Control Station, good for 570' crossings\",\"description\":\"600 Steering Station, good for 570' crossings. 34\\\" wide x 68\\\" high x 62\\\" long, weighs 2350 LBS. Controls small TBMs, RUSH, OTS and most steering heads, has water level controls, pump for steering flaps, 600' of 4ea hydraulic lines, 600' of water level hose, 600' of wire for left/right LEDs, work lights, ready for Electronic Guidance. Comes with pull out. ser# BTE600SS207\",\"unitPrice\":65913.72,\"lineAmount\":65913.72},{\"quantity\":1,\"partNumber\":\"Steering (Control) Station:1-Pitch Indicator/Electronic Steering sold with our Steering Station. PRICE TIMES 1.5 IF SOLD ALONE\",\"description\":\"Electronic Steering, shows pitch of head and direction on line. Sold with Steering Station. Includes case and pull out. Sensor # WT42200026924\",\"unitPrice\":10859.91,\"lineAmount\":10859.91},{\"quantity\":1,\"partNumber\":\"misc\",\"description\":\"expedited fee\",\"unitPrice\":7635.42,\"lineAmount\":7635.42}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1101",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-12-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1100",
      "amount": 12925,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2023-11-02",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Cutting Heads:18-30\\\" XTRM bit for std OTS heads, with OD cutters (1-1/2\\\" bolts), 4\\\"hex, 2-1/2\\\" plate & KK Teeth\",\"description\":\"18-30\\\" XTRM bit for steering heads, with OD cutters (1-1/2\\\" pins), 4\\\"hex, 2-1/2\\\" plate & King Kong Teeth\",\"unitPrice\":12500,\"lineAmount\":12500},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred\",\"unitPrice\":425,\"lineAmount\":425}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1099",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-11-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1098",
      "amount": 135,
      "vendor": "BTE",
      "customer": "Eslinger Contracting Co Inc",
      "dateCreated": "2023-10-25",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:Water sensor\",\"description\":\"Water sensor for water level, works for uphill or down hill for 36\\\"\",\"unitPrice\":120,\"lineAmount\":120},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred\",\"unitPrice\":15,\"lineAmount\":15}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1097",
      "amount": 664.2000122070312,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2023-10-05",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":10,\"partNumber\":\"Teeth:BTE150\",\"description\":\"Boretec 150  Carbide Tooth\",\"unitPrice\":32,\"lineAmount\":320},{\"quantity\":10,\"partNumber\":\"Teeth:BTE87B for150 tooth\",\"description\":\"Carbide Tooth Block Holder\",\"unitPrice\":25,\"lineAmount\":250},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Freight\",\"unitPrice\":45,\"lineAmount\":45}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1096",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-10-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1093",
      "amount": 311.239990234375,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2023-09-19",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":4,\"partNumber\":\"Teeth:BTE150\",\"description\":\"Boretec 150  Carbide Tooth\",\"unitPrice\":32,\"lineAmount\":128},{\"quantity\":4,\"partNumber\":\"Teeth:BTE87B for150 tooth\",\"description\":\"Carbide Tooth Block Holder\",\"unitPrice\":25,\"lineAmount\":100},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping and handling fees incurred\",\"unitPrice\":65,\"lineAmount\":65}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1092",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-09-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1091",
      "amount": 2298.239990234375,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2023-08-16",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":38,\"partNumber\":\"Various/Misc:1-BTE4\\\"channel\",\"description\":\"4\\\" LITE Channel (10' long)\",\"unitPrice\":56,\"lineAmount\":2128}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1090",
      "amount": 29500,
      "vendor": "BTE",
      "customer": "DH Underground",
      "dateCreated": "2023-08-11",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:20\\\" McLaughlin OTS standard steering head, 48\\\" long with 16\\\" auger on 2-1/4\\\" hex, cuts 21\\\" OD.\",\"description\":\"20\\\"Auger asset TBM-48\\\" long with 16\\\" auger on 2-1/4\\\" hex, cuts 21\\\" OD. Has 4 steering flaps, bentonite outlet, left/right LEDs, Water Level ready, ready for Electronic Guidence. Good for most soils except solid rock. Ser# BTEH162011\",\"unitPrice\":29500,\"lineAmount\":29500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1089",
      "amount": 1572.47998046875,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2023-08-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":26,\"partNumber\":\"Various/Misc:1-BTE4\\\"channel\",\"description\":\"4\\\" LITE Channel (10' long)\",\"unitPrice\":56,\"lineAmount\":1456}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1088",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-08-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1087",
      "amount": 1405,
      "vendor": "BTE",
      "customer": "Mainline Contracting, Inc",
      "dateCreated": "2023-07-24",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":2,\"partNumber\":\"Parts\",\"description\":\"Hydraulic Cylinder to fit 24 inch OTS Head\",\"unitPrice\":490,\"lineAmount\":980},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Shipping and Handling\",\"description\":\"Shipping\",\"unitPrice\":425,\"lineAmount\":425}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1083",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-07-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1078",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-06-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1074",
      "amount": 2100,
      "vendor": "BTE",
      "customer": "540 Mini TBM 24",
      "dateCreated": "2023-05-17",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Freight\",\"description\":\"Pick up and delivered to job site\",\"unitPrice\":2100,\"lineAmount\":2100}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1075",
      "amount": 8100,
      "vendor": "BTE",
      "customer": "542 - 24 TBM refurb",
      "dateCreated": "2023-05-17",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":81,\"partNumber\":\"Refurbished Equipment\",\"description\":\"Replace, fit and paint 3 flaps on McL 24\\\" OTS. Test Head.\",\"unitPrice\":100,\"lineAmount\":8100},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Freight\",\"description\":\"TBD\",\"unitPrice\":0,\"lineAmount\":0}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1070",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-05-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1068",
      "amount": 1209.5999755859375,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2023-04-24",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":20,\"partNumber\":\"Various/Misc:1-BTE4\\\"channel\",\"description\":\"4\\\" LITE Channel (10' long)\",\"unitPrice\":56,\"lineAmount\":1120}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1063",
      "amount": 4980,
      "vendor": "BTE",
      "customer": "Jim Robinson Contractors Inc",
      "dateCreated": "2023-04-03",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":2,\"partNumber\":\"misc\",\"description\":\"small sensor\",\"unitPrice\":2490,\"lineAmount\":4980}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1061",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-04-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1056",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-03-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1054",
      "amount": 1209.5999755859375,
      "vendor": "BTE",
      "customer": "MH&S Directional",
      "dateCreated": "2023-02-14",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":20,\"partNumber\":\"Various/Misc:1-BTE4\\\"channel\",\"description\":\"4\\\" LITE Channel (10' long)\",\"unitPrice\":56,\"lineAmount\":1120}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1069",
      "amount": 200000,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-02-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Steering Head:60\\\" McLaughlin Super OTS Auger assist, Man entry, 50 HP Electric TBM\",\"description\":\"54-60\\\"Auger assit 40HP Electric TBM, with out pusher adapter. Includes 100' of electrical cord, with low voltage lighting at head, 4 steering flaps, set up for 16\\\" conveyor auger, LH half bit with King Kong teeth with 22,000 ft/lb torque. Customer furnish 16\\\" augers and extraction pipe, 75-100KW generator, 48-60\\\" auger boring machine.\",\"unitPrice\":200000,\"lineAmount\":200000}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1052",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-02-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1048",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2023-01-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1049",
      "amount": 37833.94140625,
      "vendor": "BTE",
      "customer": "529",
      "dateCreated": "2022-12-15",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":96.5,\"partNumber\":\"shop Labor:Shop labor\",\"description\":\"Shop Labor\",\"unitPrice\":125,\"lineAmount\":12062.5},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Services\",\"description\":\"Steel at cost\",\"unitPrice\":20735,\"lineAmount\":20735},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"Misc parts\",\"unitPrice\":1736.44,\"lineAmount\":1736.44},{\"quantity\":1,\"partNumber\":\"Freight/Shipping/Services costs:Freight\",\"description\":\"Shipping at cost\",\"unitPrice\":3300,\"lineAmount\":3300}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1039",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2022-12-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1034",
      "amount": 10785.26953125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2022-10-01",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"description\":\"Due on the 15th of the month\",\"unitPrice\":3472.22,\"lineAmount\":3472.22},{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"description\":\"Due on the 30th of the month\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1030",
      "amount": 7313.0498046875,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2022-09-30",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly Payment for McLaughlin WH-175 Boring Machine\",\"unitPrice\":7313.05,\"lineAmount\":7313.05}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1032",
      "amount": 12973.75,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2022-09-20",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Solid Rock System:Slewing Bearing - 30\\\"\",\"description\":\"Slewing Bearing - 30\\\"\",\"unitPrice\":7500,\"lineAmount\":7500},{\"quantity\":1,\"partNumber\":\"Cutting Bits:Fabricated Parts\",\"description\":\"Misc bolts for 30\\\" SRS\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"Misc parts, Bolts for 30\\\" SRS\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"24\\\" adapter plate for WH 175\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"shop Labor:Shop labor\",\"description\":\"Shop Labor for Jose\",\"unitPrice\":125,\"lineAmount\":125},{\"quantity\":1,\"partNumber\":\"Parts\",\"description\":\"water pump for control station\",\"unitPrice\":0,\"lineAmount\":0},{\"quantity\":1,\"partNumber\":\"Cutting Heads:Replacement auger and bit for 16\\\" TBM, cuts 17\\\" withnwiug cutters\",\"description\":\"Replacement auger and bit for 16\\\" TBM, cuts 17\\\" with wing cutters\",\"unitPrice\":4500,\"lineAmount\":4500}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1029",
      "amount": 3472.219970703125,
      "vendor": "BTE",
      "customer": "Best Tunneling and Boring LLC",
      "dateCreated": "2022-09-15",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":1,\"partNumber\":\"Monthly payment for 24\\\" McLaughlin Bore Machine\",\"unitPrice\":3472.22,\"lineAmount\":3472.22}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1014",
      "amount": 20,
      "vendor": "BTE",
      "customer": "Jim Robinson Contractors Inc",
      "dateCreated": "2022-05-16",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":24,\"partNumber\":\"Various/Misc:1-Standard 50 ton flap cylinder (35 ton at 6800 psi) for 30\\\" and above TBMs. 5\\\" tall and 4-7/8\\\" dia\",\"description\":\"Standard 35 ton flap cylnder at 6800 psi for 30\\\" and above\",\"unitPrice\":0.8333333,\"lineAmount\":20}]"
  },
  {
      "transactionType": "Invoice",
      "idNumber": "1014",
      "amount": 20,
      "vendor": "BTE",
      "customer": "Jim Robinson Contractors Inc",
      "dateCreated": "2022-05-16",
      "dateFulfilled": '',
      "origin": "dan",
      "items": "[{\"quantity\":24,\"partNumber\":\"Various/Misc:1-Standard 50 ton flap cylinder (35 ton at 6800 psi) for 30\\\" and above TBMs. 5\\\" tall and 4-7/8\\\" dia\",\"description\":\"Standard 35 ton flap cylnder at 6800 psi for 30\\\" and above\",\"unitPrice\":0.8333333,\"lineAmount\":20}]"
  }
];

const barColors = [
    '#a855f7', // Purple
    '#8b5cf6', // Violet
    '#6366f1', // Indigo
    '#3b82f6', // Blue
    '#0ea5e9', // Sky
    '#06b6d4', // Cyan
    '#14b8a6', // Teal
    '#10b981', // Emerald
    '#22c55e', // Green
  ];

const partToCategory: { [key: string]: string } = {};
allCategories.forEach(category => {
  partToCategory[category.partNumber] = category.category;
});

// Initialize sales data for each category by month
const categorySalesByMonth: { [key: string]: { [key: string]: number } } = {};

// Extract and count parts sold from invoices
allItems.forEach(transaction => {
    if (transaction.transactionType === 'Invoice') {
      const month = new Date(transaction.dateCreated).toISOString().substr(0, 7); // Get year-month

      // Parse the items JSON string into an array of TransactionItem
      const items: TransactionItem[] = JSON.parse(transaction.items);

      items.forEach((item: TransactionItem) => {
        const partNumber = item.partNumber;
        const category = partToCategory[partNumber];
        if (category) {
          if (!categorySalesByMonth[category]) {
            categorySalesByMonth[category] = {};
          }
          if (!categorySalesByMonth[category][month]) {
            categorySalesByMonth[category][month] = 0;
          }
          categorySalesByMonth[category][month] += item.quantity;
        }
      });
    }
  });

    // Calculate forecast for the next six months using linear regression
    const forecastData: { [key: string]: number[] } = {};
    const monthsLabels: string[] = [];
  
    Object.keys(categorySalesByMonth).forEach((category, index) => {
      const salesData = Object.entries(categorySalesByMonth[category]).map(([month, sales]) => {
        const monthIndex = new Date(month).getTime() / (1000 * 60 * 60 * 24 * 30); // Convert month to a numeric value
        return [monthIndex, sales] as [number, number];
      });
  
      const result = regression.linear(salesData);
      const nextSixMonths = Array.from({ length: 6 }, (_, i) => {
        const lastMonthIndex = Math.max(...salesData.map(dataPoint => dataPoint[0]));
        return lastMonthIndex + i + 1;
      });
  
      forecastData[category] = nextSixMonths.map(monthIndex => result.predict(monthIndex)[1]);
  
      // Populate monthsLabels with future months in 'YYYY-MM' format
      if (monthsLabels.length === 0) {
        const lastMonth = new Date(Math.max(...salesData.map(dataPoint => dataPoint[0])) * (1000 * 60 * 60 * 24 * 30));
        for (let i = 1; i <= 6; i++) {
          const nextMonth = new Date(lastMonth);
          nextMonth.setMonth(lastMonth.getMonth() + i);
          monthsLabels.push(nextMonth.toString().slice(4, 15));
        }
      }
    });
  
    // Prepare data for Chart.js
    const chartData = {
      labels: monthsLabels,
      datasets: Object.keys(forecastData).map((category, index) => ({
        label: `${category}s`,
        borderRadius: 5,
        data: forecastData[category],
        backgroundColor: barColors[index % barColors.length], // Use colors from the predefined array
      })),
    };

let options;
    if (isMobile) {
    options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        scales: {
          y: {
            stacked: false,
            ticks: {
              callback: (tickValue: string | number, index: number, ticks: any[]) => {
                if (typeof tickValue === 'number') {
                  return `${tickValue.toLocaleString()}`;
                }
                return tickValue;
              }
            }
          },
          x: {
            stacked: false
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                const value = typeof tooltipItem.raw === 'number' ? tooltipItem.raw : 0;
                return `${tooltipItem.dataset.label}: ${value.toLocaleString()}`;
              }
            }
          },
          legend: {
            position: 'bottom' as const,
            labels: {
              useBorderRadius: true,
              borderRadius: 5
            }
          },
          title: {
            display: false,
            text: 'Money Generated by Category Each Month',
          },
          
        },
      };
    } else {
        options = {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 4,
            scales: {
              y: {
                stacked: false,
                ticks: {
                  callback: (tickValue: string | number, index: number, ticks: any[]) => {
                    if (typeof tickValue === 'number') {
                      return `${tickValue.toLocaleString()}`;
                    }
                    return tickValue;
                  }
                }
              },
              x: {
                stacked: false
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem: TooltipItem<'bar'>) => {
                    const value = typeof tooltipItem.raw === 'number' ? tooltipItem.raw : 0;
                    return `${tooltipItem.dataset.label}: ${value.toLocaleString()}`;
                  }
                }
              },
              legend: {
                position: 'bottom' as const,
                labels: {
                  useBorderRadius: true,
                  borderRadius: 5
                }
              },
              title: {
                display: false,
                text: 'Money Generated by Category Each Month',
              },
              
            },
          }; 
    }
      


  const fetchItems = async () => {
    setIsLoading(true)

      setItems(allItems) // Assuming the payload contains the array of items
      setIsLoading(false) // Set loading to false after data is fetched
    
  }



  const fetchCategories = async () => {

      setItemCategories(allCategories) // Assuming the payload contains the array of items
    
  }

  useEffect(() => {
    fetchItems()
    fetchCategories()

  }, [])

  useEffect(() => {
    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    }
    setIsMobile(isMobile())
  }, [])

  useEffect(() => {
    if (items.length && itemCategories.length) {
      const invoiceItems = items.filter(
        (item) => item.transactionType === 'Invoice'
      )
      

      const categoryData = invoiceItems.reduce(
        (acc: Record<string, Record<string, number>>, item) => {
          const parsedItems = JSON.parse(item.items)
          parsedItems.forEach(
            (parsedItem: { partNumber: string; lineAmount: number }) => {
              const cleanedPartNumber = parsedItem.partNumber.includes(':')
                ? parsedItem.partNumber.split(':').pop()
                : parsedItem.partNumber

              const category = (
                itemCategories.find(
                  (cat) => cat.partNumber === cleanedPartNumber
                )?.category || 'Unknown'
              ).toLowerCase()
              const month = new Date(item.dateCreated).toLocaleString(
                'default',
                { month: 'short', year: 'numeric' }
              )
              if (!acc[month]) {
                acc[month] = {}
              }
              if (!acc[month][category]) {
                acc[month][category] = 0
              }
              acc[month][category] += parsedItem.lineAmount
            }
          )
          return acc
        },
        {}
      )

      const allMonths = Array.from({ length: 12 }, (_, i) =>
        new Date(2024, i).toLocaleString('default', {
          month: 'short',
          year: 'numeric',
        })
      )
      const labels = allMonths
      const categories = Array.from(
        new Set(itemCategories.map((cat) => cat.category.toLowerCase()))
      )



      const datasets = categories.map((category, index) => ({
        label: category + 's',
        data: allMonths.map((month) => categoryData[month]?.[category] || 0),
        backgroundColor: barColors[index % barColors.length], // Assign color based on index
        borderRadius: {
          topLeft: 10,
          topRight: 10,
        },
      }))

      setChartCategoriesData({
        labels,
        datasets,
      })
    }
  }, [items, itemCategories])

  useEffect(() => {
    if (items.length && itemCategories.length) {
      const invoiceItems = items
        .filter((item) => item.transactionType === 'Invoice')
        .flatMap((record) => {
          let itemsArray: ItemType[] = [];
          try {
            itemsArray = JSON.parse(record.items);
          } catch (error) {
            console.error('Error parsing items:', error);
            return [];
          }
          return itemsArray.map((item) => {
            const partNumber = item.partNumber?.includes(':')
              ? item.partNumber.split(':')[1]
              : item.partNumber;
            return {
              ...item,
              partNumber,
              dateCreated: record.dateCreated,
              invoiceNumber: record.idNumber,
              discount: record.discount || 0,
            };
          });
        });
  

  
      const allMonths = Array.from({ length: 12 }, (_, i) =>
        new Date(2024, i).toLocaleString('default', {
          month: 'short',
          year: 'numeric',
        })
      );
  
      const moneyIn: { [key: string]: { [origin: string]: number } } = {};
      const expenses: { [key: string]: number } = {};
      const pos: { [key: string]: number } = {};
      const royalties: { [key: string]: number } = {};
  
      items.forEach((item) => {
        if (item.dateCreated) {
          const month = new Date(item.dateCreated).toLocaleString('default', {
            month: 'short',
            year: 'numeric',
          });
  
          if (item.transactionType === 'Invoice') {
            if (!moneyIn[month]) {
              moneyIn[month] = {};
            }
            const origin = item.origin || 'Unknown';
            moneyIn[month][origin] = (moneyIn[month][origin] || 0) + item.amount;
          } else if (item.transactionType === 'Expense') {
            expenses[month] = (expenses[month] || 0) + item.amount;
          } else if (item.transactionType === 'PO') {
            pos[month] = (pos[month] || 0) + item.amount;
          }
        }
      });
  

  
      const moneyInToDate = items.reduce((acc, item) => {
        if (item.transactionType === 'Invoice' && new Date(item.dateCreated) > new Date('2024-01-01')) {
          return acc + item.amount;
        }
        return acc;
      }, 0);
  
      const moneyOutToDate = items.reduce((acc, item) => {
        if ((item.transactionType === 'Expense' || item.transactionType === 'PO') && new Date(item.dateCreated) > new Date('2024-01-01')) {
          return acc + item.amount;
        }
        return acc;
      }, 0) + Object.values(royalties).reduce((acc, value) => acc + value, 0);
  
      setChartMoneyInOut({
        labels: allMonths,
        moneyIn: allMonths.map((month) => moneyIn[month] || {}),
        moneyOut: allMonths.map((month) => ({
          expenses: expenses[month] || 0,
          pos: pos[month] || 0,
          royalties: royalties[month] || 0,
        })),
      });
  
      setInvoiceItems(invoiceItems);
      setMoneyInToDate(moneyInToDate);
      setMoneyOutToDate(moneyOutToDate);
    }
  }, [items, royaltiesItems]);

  useEffect(() => {
    // Function to detect if the device is mobile
    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    }
    setIsMobile(isMobile())
  }, [])

  const sortData = (field: keyof Item) => {
    const sorted = [...items].sort((a, b) => {
      // Use a non-'' assertion operator (!) if you are sure it won't be '',
      // or provide a fallback value to handle '' or undefined.
      const aValue = a[field] ?? '' // Fallback to empty string if ''
      const bValue = b[field] ?? '' // Fallback to empty string if ''

      // If the field values are strings, ensure they are compared correctly.
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue)
      }

      // If the field values are numbers, handle them accordingly.
      // Adjust this part if you have specific fields that are numbers.
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue
      }

      // Adjust comparisons as needed based on your data types.
      return 0
    })
    setItems(sorted)
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    setSortedBy(field)
  }

  return (
    <section className="my-5 space-y-4">

{!isLoading && (
      <div className="bg-white rounded-lg p-2 mt-4 ">
      <h1 className="font-semibold text-xl mb-5 text-center">Forecasted items sold by category</h1>
      <Bar data={chartData}  options={options}/>
      </div>

)}
      {!isLoading && (
        <div className="bg-white rounded-lg p-2">
          <h1 className="font-semibold text-xl mb-5 text-center">
            Money in by category
          </h1>
          <div className="h-72 flex justify-center w-full">
            <ChartCategories chartData={chartCategoriesData} />
          </div>

        </div>
      )}
      {!isLoading && (
        <div className="bg-white rounded-lg p-2 mb-4">
          <h1 className="font-semibold text-xl mb-2 text-center">
            Money in vs. Money out
          </h1>
          <div className="flex justify-center text-xs mb-5 text-center space-x-4">
  <p>Total money in: <span className="font-semibold">{moneyInToDate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></p> 
  <p>Total money out: <span className="font-semibold">{moneyOutToDate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></p>
</div>
          <div className="h-72 flex justify-center items-center w-full">
    
            <ChartMoneyInOut chartMoneyInOut={chartMoneyInOut} />
     

          </div>


        </div>
      )}
    </section>
  )
}

export default DataAndAnalytics
