import React, { useRef, useState } from 'react';


  
interface IgnoreConfirmationProps {
  dateCreated: string;
  quantity: number;
  partNumber: string;
  onHide: () => void;
}

  const IgnoreConfirmation: React.FC<IgnoreConfirmationProps> = ({ dateCreated, quantity, partNumber, onHide }) => {
    const [isVisible, setIsVisible] = useState(true); // Control the visibility of the component
    const [status, setStatus] = useState('confirmation'); // Control the display of confirmation or processing message
  
    const hideComponentConfirm = () => setIsVisible(false);
  

    
    const newTime = () => {
      const dateTimeArray = new Date().toString()
      return dateTimeArray
    }

    const hideComponent = () => {
      // Call the onHide function passed from the parent component
      onHide();
    };
    const ignore = async () => {
      setStatus("deleting...")
  
      
     
          hideComponentConfirm();
         
    };
  
    if (!isVisible) return null;
  
    return (
      <div  className="text-gray-700 bg-white border border-gray-200 rounded-lg overflow-hidden lg:text-sm flex items-center justify-center">
        <div className="w-full p-5">
          <p className={`confirmation text-center ${status !== 'confirmation' ? 'hidden' : ''}`}>
            Are you sure you want to ignore this build:&nbsp;<span className="font-bold">({quantity}) {partNumber}</span>?
          </p>
          <div className={`buttons flex justify-center items-center mt-5 ${status !== 'confirmation' ? 'hidden' : ''}`}>
            <button className="bg-gray-200 text-black hover:bg-gray-300 text-center m-1 py-1 flex-grow rounded-lg" onClick={hideComponent}>No</button>
            <button className="bg-gray-700 text-white hover:bg-black text-center py-1 m-1 flex-grow rounded-lg" onClick={ignore}>Yes</button>
          </div>
          <p className={`processing ${status !== 'deleting...' ? 'hidden' : ''} text-center`}>
            deleting...
          </p>
        </div>
      </div>
    );
  };

  export default IgnoreConfirmation