// src/pages/ContactPage.tsx

import React, { useEffect } from 'react';
import ContactForm from '../components/ContactForm';
import { Helmet } from "react-helmet-async";

const ContactPage: React.FC = () => {


    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  

  return (
    <div className="min-h-screen bg-gray-100 p-8">
                  <Helmet>
      <title>Optima Inventory - Contact</title>
      <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <title>Optima Inventory</title>


    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" />

 
    <meta property="og:title" content="Optima Inventory - Contact" />
    <meta property="og:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.optima-inventory.com" />
    <meta property="og:image" content="https://www.optima-inventory.com/logo.png" />

 
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Optima Inventory - Contact" />
    <meta name="twitter:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta name="twitter:image" content="https://www.optima-inventory.com/logo.png" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
      <header className="mb-10 text-center">
        <h1 className="text-4xl font-bold">Contact Us</h1>
      </header>
      <p className="px-2 text-center opacity-70 mb-10">Email, call, or schedule a meeting.</p>
      <div className="grid md:grid-cols-2">
      <div className="flex justify-center">
        <div>
        <div className="flex justify-center items-center space-x-2 mb-2">
        <div className="h-6 w-6 ml-1 rounded-sm overflow-hidden space-y-px">
          <div className="h-1 w-8 bg-emerald-500"></div>
          <div className="h-1 w-8 bg-teal-500"></div>
          <div className="h-1 w-8 bg-sky-500"></div>
          <div className="h-1 w-8 bg-violet-500"></div>
          <div className="h-1 w-8 bg-pink-500"></div>
        </div>
        <h1 className="text-3xl font-semibold text-center">
          Optima Inventory
        </h1>
      </div>
        <p>1309 Coffeen Avenue STE 1200</p>
        <p className="mb-5">Sheridan, Wyoming 82801</p>
        <div className="text-sm">
        <p><a href="tel:+19733817333">+1 (973) 381-7333</a></p>
        <p><a href="mailto:support@optima-holdings.com">support@optima-holdings.com</a></p>
        </div>
        </div>
        </div>  

      <ContactForm />

      </div>
      

    </div>
  );
};

export default ContactPage;