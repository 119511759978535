'use client';

//lazy load of the cards to reduce memory.

import React, { useEffect, useState } from 'react';

import InventoryCardEditable from './InventoryCardEditable';


//import Menu from '../buttons/menu';

interface InventoryItem {
  partNumber: string
  description: string
  quantity: number
  location: string
  cost: number
  retailPrice: number
  weight: number
  leadTime: number
  docFileName: string
  images: string
  upsell: string
  type: string
  parts: string
  vendors: { vName: string; qboID: number; isVisible: true; vUrl: string; vPartNumber: string; vLeadTime: number; vCost: number; vPaymentMethod: string; }[]
  manufacturer: string
  manufactPartNum: string
  parLevel: number
  partsUrl: string
  isVisible: boolean; 
  partsInStock: string
  name: string
  items:[]
  editor: string
  editDateTime: string
  publicFacing:string
  category: string
  dynamicPricing: string
  uploadedFiles: {name: string, url: string}[]
  allItems: InventoryItem[]
  quantityNeeded: number
  breakdownInProgress: boolean
  rAndD: boolean
  royalties: boolean
}

const InventoryCards = () => {
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [status, setStatus] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
//const [filteredItems, setFilteredItems] = useState<InventoryItem[]>([]);

  const [filterCategory, setFilterCategory] = useState('All')


const allInventoryItems:InventoryItem[] =     [
  {
    "partNumber": "4801702",
    "name": "COIL SOLENOID VALVE 48/54",
    "quantity": 9,
    "leadTime": 2,
    "partsUrl": "",
    "cost": 18.84,
    "retailPrice": 65.94,
    "location": "7-C-4 (Bin 3)",
    "weight": 0.75,
    "parLevel": 0,
    "type": "buy",
    "description": "COIL SOLENOID VALVE 48/54",
    "docFileName": "",
    "images": "https://boretec.com/images/4801702-2024-03-21T17:56:29-h-200.png",
    "category": "Part",
    "manufacturer": "",
    "manufactPartNum": "",
    "vendors": [],
    "upsell": "",
    "partsInStock": "",
    "publicFacing": "yes",
    "editor": "juliya",
    "editDateTime": "Mar 21 2024 13:56:31 (Eastern)",
    "dynamicPricing": "manual",
    "uploadedFiles": [],
    "items": [],
    "breakdownInProgress": false,
    "rAndD": false,
    "royalties": false,
    "isVisible": true,
    "quantityNeeded": 0,
    "parts": "",
    "allItems": []
  },
  {
    "partNumber": "80-3610x4",
    "name": "36” auger 10’ long on 4” hex",
    "quantity": 0,
    "leadTime": 21,
    "partsUrl": "",
    "cost": 3764.23,
    "retailPrice": 4189,
    "location": "34-5-6",
    "weight": 1100,
    "parLevel": 0,
    "type": "build",
    "description": "36” auger on 4”hex 10’ long true OD is 34.5” with 1/2\" flights",
    "docFileName": "",
    "images": "https://boretec.com/images/80-3610x4-2024-04-04T19:45:00-h-200.png",
    "category": "Auger",
    "manufacturer": "",
    "manufactPartNum": "",
    "vendors": [],
    "upsell": "",
    "partsInStock": "no",
    "publicFacing": "yes",
    "editor": "bill",
    "editDateTime": "May 16 2024 09:20:06 (Eastern)",
    "dynamicPricing": "manual",
    "uploadedFiles": [],
    "items": [],
    "breakdownInProgress": false,
    "rAndD": false,
    "royalties": false,
    "isVisible": true,
    "quantityNeeded": 0,
    "parts": "",
    "allItems": [],
  },
  {
    "partNumber": "5472002",
    "name": "HYDRAULIC TANK FOR 54\"-72\" TBM",
    "quantity": 50,
    "leadTime": 5,
    "partsUrl": "",
    "cost": 104.99,
    "retailPrice": 364.47,
    "location": "7-97-1",
    "weight": 0,
    "parLevel": 0,
    "type": "buy",
    "description": "HYDRAULIC TANK FOR 54\"-72\" TBM",
    "docFileName": "",
    "images": "https://boretec.com/images/5472002-2024-06-19T11:34:18-h-200.png",
    "category": "Part",
    "manufacturer": "FIELD TUFF",
    "manufactPartNum": "FTF-05GOR",
    "vendors": [
      {
        "vName": "Amazon",
        "qboID": 147,
        "isVisible": true,
        "vUrl": "https://www.amazon.com/s?k=FTF-05GOR&crid=5XEYDOWBRTH0&sprefix=ftf-05gor%2Caps%2C90&ref=nb_sb_noss_1",
        "vPartNumber": "FTF-05GOR",
        "vLeadTime": 5,
        "vCost": 14.99,
        "vPaymentMethod": ""
      }
    ],
    "upsell": "",
    "partsInStock": "",
    "publicFacing": "no",
    "editor": "chad",
    "editDateTime": "Jun 19 2024 07:34:22 (Eastern)",
    "dynamicPricing": "manual",
    "uploadedFiles": [],
    "items": [],
    "breakdownInProgress": false,
    "rAndD": false,
    "royalties": false,
    "isVisible": true,
    "quantityNeeded": 0,
    "parts": "",
    "allItems": []
  }
];



  const fetchInventoryItems = async () => {

  
        setInventoryItems(allInventoryItems);
        
      

  };
  
  useEffect(() => {

    
        fetchInventoryItems();
     
  }, []);


  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleClearSearch = () => {

    setSearchTerm('');
    // Reset all items to be visible when the search is cleared
    setInventoryItems(inventoryItems.map(item => ({ ...item, isVisible: true })));
    
  };



 const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    if (!newSearchTerm.trim()) {
      // If the search term is empty, set all items to visible
      setInventoryItems(inventoryItems.map(item => ({ ...item, isVisible: true })));
    } else {
      // Otherwise, filter based on the search term
      const searchWords = newSearchTerm.split(/\s+/);
      setInventoryItems(inventoryItems.map(item => {
        const itemText = `${item.description} ${item.partNumber} ${item.location}`.toLowerCase();
        const isVisible = searchWords.every(word => itemText.includes(word));
        return { ...item, isVisible };
      }));
    }
  };

  //filtering by category
  const filteredItems = inventoryItems.filter(
    (item) => filterCategory === 'All' || item.category === filterCategory
  )

  // Determine the count of visible items
  const visibleItemCount = filteredItems.filter((item) => item.isVisible).length

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterCategory(event.target.value)
  }



  return (
<section className="mt-5">

      <div className="opacity-95 py-1">

      <div className="mt-2 flex flex-col md:flex-row w-full space-y-2 md:space-y-0 md:space-x-2 items-center">
      <div className={`md:flex-grow w-full flex justify-between items-end ${isInputFocused ? 'border-gray-500' : 'border-gray-200 hover:border-gray-300'} border bg-white rounded-lg py-1 pl-3`}>
      <input
        type="text"
        id="searchInput"
        className="w-full outline-none focus:outline-none focus:ring-0 placeholder:text-gray-500 placeholder:font-light"
        placeholder="Search inventory"
        value={searchTerm} // Ensure this correctly references the state
        onChange={handleSearch}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        
      />
              {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="pr-2 border-r border-gray-500 text-xs text-bottom text-gray-500 font-light hover:text-gray-600"
            type="button"
          >
            clear {/* This is a Unicode 'X' character: &#x2715; */}
          </button>
        )}
      <div className="whitespace-nowrap text-bottom text-right text-xs text-gray-500 font-light ml-2 pr-1">
        {visibleItemCount} item(s)
      </div>
    </div>

    {/*{showMenu && (<div className="hidden md:block"><Menu /></div>)}*/}
  </div>
  </div>

      <div className="flex justify-end my-2">
        <p className="py-1 px-2">Select a category:</p>
        <select
          value={filterCategory}
          onChange={handleCategoryChange}
          className="border py-1 px-2 border-gray-200 rounded-md"
        >
          <option value="All">All</option>
          <option value="Auger">Augers</option>
          <option value="Boring Machine">Boring Machines</option>
          <option value="Cutting Head">Cutting Heads</option>
          <option value="Part">Parts</option>
          <option value="Steering Head">Steering Heads</option>
          <option value="Steering Station">Steering Stations</option>
          <option value="Steering System">Steering Systems</option>
          <option value="Raw Material">Raw Material</option>
          

        </select>
      </div>
     {status === "loading" && (      
     <div className="px-2 text-md font-thin h-screen w-full flex justify-center pt-5">
      <div className="text-center">
      <p className="font-bold">Double Click to edit.</p>
      <p className="font-bold">Tap the image to enlarge.</p>
      <p className="animate-pulse">Loading...</p>
      </div>
</div>
)}

      <div id="inventoryCards" className="flex flex-wrap -mx-2 my-2">
        {filteredItems.map((item) => (
          <div key={item.partNumber} className={`px-2 mb-4 w-full md:w-1/2 lg:w-1/3 ${item.isVisible ? '' : 'hidden'}`}>
            <InventoryCardEditable {...item} allItems={inventoryItems} />
          </div>
        ))}
      </div>

    </section>
  );
};

export default InventoryCards;
