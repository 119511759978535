import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";



const divsData = [
  { className: 'bg-emerald-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-teal-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-sky-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-violet-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-pink-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-emerald-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-teal-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-sky-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-violet-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-pink-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-emerald-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-teal-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-sky-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-violet-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-pink-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-emerald-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-teal-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-sky-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-violet-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-pink-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-emerald-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-teal-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-sky-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-violet-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-pink-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-emerald-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-teal-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-sky-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-violet-500 w-full rounded-sm overflow-hidden' },
  { className: 'bg-pink-500 w-full rounded-sm overflow-hidden' },
];

const animations = [ 'animate-pulse', 'animate-pulse-slow'];

const spans = [ 'col-span-2', 'col-span-4', 'col-span-6', 'col-span-3' ];

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const getRandomAnimation = () => {
  const randomIndex = Math.floor(Math.random() * animations.length);
  return animations[randomIndex];
};
const getRandomSpan = () => {
  const randomIndex = Math.floor(Math.random() * spans.length);
  return spans[randomIndex];
};

const SetupPage: React.FC = () => {
  const [scenarioDivs, setScenarioDivs] = useState<any[]>([]);
  const [scenarioDivs2, setScenarioDivs2] = useState<any[]>([]);
  const [integrationDivs, setIntegrationDivs] = useState<any[]>([]);
  const [displayedIntegrationDivs, setDisplayedIntegrationDivs] = useState<any[]>([]);



  useEffect(() => {
    const shuffledDivs = shuffleArray([...divsData]);
    const scenarioDivs = shuffledDivs.map((div, index) => {
      if (Math.random() < 0.5) {
        return {
          ...div,
          className: `${div.className} h-4 ${getRandomAnimation()}`,
        };
      }
      return div;
    });
    const scenarioDivs2 = shuffleArray([...divsData]).map((div, index) => {
      if (Math.random() < 0.5) {
        return {
          ...div,
          className: `${div.className} h-4 ${getRandomAnimation()}`,
        };
      }
      return div;
    });
    const integrationDivs = shuffledDivs.map((div, index) => {
      if (Math.random() < 0.5) {
        return {
          ...div,
          className: `${div.className} h-4 ${getRandomSpan()}`,
        };
      }
      return div;
    });
    setScenarioDivs(scenarioDivs);
    setScenarioDivs2(scenarioDivs2);
    setIntegrationDivs(integrationDivs);
  }, []);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < integrationDivs.length) {
        setDisplayedIntegrationDivs(prev => [...prev, integrationDivs[index]]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 500); // 500ms delay
    return () => clearInterval(interval);
  }, [integrationDivs]);

  return (
    <div className="min-h-screen p-2 md:p-6">
      <Helmet>
        <title>Optima Inventory - Setup</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <title>Optima Inventory</title>

        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />

        <meta property="og:title" content="Optima Inventory - Pricing" />
        <meta
          property="og:description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.optima-inventory.com" />
        <meta
          property="og:image"
          content="https://www.optima-inventory.com/logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Optima Inventory - Pricing" />
        <meta
          name="twitter:description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <meta
          name="twitter:image"
          content="https://www.optima-inventory.com/logo.png"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
      <div className="flex justify-center items-center space-x-2">
        <div className="h-6 w-6 rounded-sm overflow-hidden space-y-px mb-10 mt-px">
          <div className={`bg-emerald-500 h-1 w-8`}></div>
          <div className={`bg-teal-500 h-1 w-8`}></div>
          <div className={`bg-sky-500 h-1 w-8`}></div>
          <div className={`bg-violet-500 h-1 w-8`}></div>
          <div className={`bg-pink-500 h-1 w-8`}></div>
        </div>
        <h1 className="text-4xl font-bold mb-10">Setup</h1>
      </div>

      <div className="md:max-w-4xl mx-auto">
        <div className="md:grid md:grid-cols-2 gap-3 mb-5 mt-10">
          <div>
        <h1 className="mb-5 text-xl font-bold">Step 1: Road Map</h1>
        <ul className="space-y-2 list-inside text-left  ">
        <li>
            Outline your current solutions (software, processes, etc.).
          </li>
          <li>
            Run through the problems you're encountering and
            brainstorm solutions.
          </li>
          <li>Define the who, when, where, how and why of each solution.</li>
          <li>Ensure that each solution is able to adapt to potential changes (growth, aquisition, etc.).</li>
        </ul>
        <p className="font-light text-sm mt-5">Time needed: 1 day (then it's an on-going discussion).</p>
        </div>
        <div className="w-full grid grid-cols-10 gap-1 mt-5">
        {scenarioDivs.map((div, index) => (
              <div key={index} className={div.className}>

              </div>
            ))}
                    {scenarioDivs2.map((div, index) => (
              <div key={index} className={div.className}>

              </div>
            ))}
        </div>
        </div>

        <div className="md:grid md:grid-cols-2 gap-3 mb-5 mt-10">
          <div>
        <h1 className="text-xl font-bold mb-5">
          Step 2: Integration
        </h1>
        <ul className="space-y-2 list-inside text-left  ">
          <li>Make a list of all the data we need.</li>
          <li>Understand what data you have and where it is.</li>
          <li>Transfer your data into the app.</li>
          
        </ul>
        <p className="font-light text-sm mt-5">Time needed: 2-5 days.</p>
        </div>
        <div className="w-full grid grid-cols-10 gap-1 mt-5">
          {displayedIntegrationDivs.map((div, index) => (
            <div key={index} className={div?.className}></div>
          ))}
        </div>
        </div>

        <div className="md:grid md:grid-cols-2 gap-3 mb-5 mt-10">
          <div>
        <h1 className="mb-5 text-xl font-bold">
          Step 3: Train, Test, and Re-Test
        </h1>
        <ul className="space-y-2 list-inside text-left  ">
          <li>
            Get the app in the hands of your employees to get their
            feedback.
          </li>
          <li>Make all the changes needed.</li>
          <li>Validate that all needed training has been accomplished.</li>
        </ul>
        <p className="font-light text-sm mt-5">Time needed: 2-5 days.</p>
        </div>
        <div className="space-y-1 overflow-hidden mt-5">
          <div>
          <div className={`bg-emerald-500 h-4 w-full rounded-t-md overflow-hidden`}></div>
        <div className={`bg-emerald-500 h-4 w-full rounded-b-sm overflow-hidden`}></div>
        </div>
        <div className="animate-bounce-x">
          <div className={`bg-teal-500 w-full h-4 rounded-t-sm overflow-hidden`}></div>
          <div className={`bg-teal-500 w-full h-4 rounded-b-sm overflow-hidden`}></div>
          </div>
          <div>
            <div className={`bg-sky-500 w-full h-4 rounded-t-sm overflow-hidden`}></div>
            <div className={`bg-sky-500 w-full h-4 rounded-b-sm overflow-hidden`}></div>
          </div>
          <div>
          <div className={`bg-violet-500 w-full h-4 rounded-t-sm overflow-hidden`}></div>
          <div className={`bg-violet-500 w-full h-4 rounded-b-sm overflow-hidden animate-bounce-y-slow`}></div>
          </div>
          <div>
          <div className={`bg-pink-500 w-full h-4 rounded-t-sm overflow-hidden`}></div>
          <div className={`bg-pink-500 w-full h-4 rounded-b-md overflow-hidden`}></div>
          </div>
          </div>
          </div>

          <div className="md:grid md:grid-cols-2 gap-3 mb-5 mt-10">
            <div>
        <h1 className="mb-5 text-xl font-bold">Step 4: Go Live</h1>
        <ul className="space-y-2 list-inside text-left  ">
        
          <li>Set up a plan for updates, problems, and maintenance.</li>
          
        </ul>
        <p className="font-light text-sm mt-5">Time needed: 1 day or less.</p>
        </div>
        <div className="space-y-1 overflow-hidden w-1/2 mx-auto mt-5 animate-ping-slow">
        <div className={`bg-emerald-500 h-8 w-full rounded-b-sm rounded-t-md overflow-hidden`}></div>
          <div className={`bg-teal-500 h-8 w-full rounded-sm overflow-hidden`}></div>
          <div className={`bg-sky-500 h-8 w-full rounded-sm overflow-hidden`}></div>
          <div className={`bg-violet-500 h-8 w-full rounded-sm overflow-hidden`}></div>
          <div className={`bg-pink-500 h-8 w-full rounded-t-sm rounded-b-md overflow-hidden`}></div>
          </div>
          </div>
      </div>
    </div>
  );
};

export default SetupPage;