import React from 'react';
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
    return (
        <div className="container mx-auto p-6">
                        <Helmet>
      <title>Optima Inventory - Terms</title>
<meta name="description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />

<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta charSet="UTF-8" />
<meta name="robots" content="index, follow" />
<meta property="og:title" content="Optima Inventory - Terms" />
<meta property="og:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.optima-inventory.com" />
<meta property="og:image" content="/logo.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Optima Inventory - Terms" />
<meta name="twitter:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
<meta name="twitter:image" content="/logo.png" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
            <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
            <p className="mb-4">These terms and conditions outline the rules and regulations for the use of our website.</p>
            
            <h2 className="text-xl font-semibold mb-2">Data Collection</h2>
            <p className="mb-4">
                The only data we collect is through Google Analytics and the information you provide in the forms on our website. Google Analytics helps us understand how visitors interact with our website, such as the number of visitors, the pages they visit, and the duration of their stay. This information is anonymized and used for improving our website.
            </p>
            <p className="mb-4">
                Any personal information you provide through forms on our website, such as your name, email address, or other contact details, will be used solely for the purpose it was collected and will not be shared with third parties without your explicit consent.
            </p>
            
            <h2 className="text-xl font-semibold mb-2">GDPR Compliance</h2>
            <p className="mb-4">
                We are committed to ensuring the privacy and protection of your personal data. In accordance with the General Data Protection Regulation (GDPR), you have the right to access, rectify, or delete any personal data we have collected from you. You also have the right to restrict or object to the processing of your data, as well as the right to data portability.
            </p>
            <p className="mb-4">
                If you have any concerns or requests regarding your personal data, please contact us at <a href="mailto:support@optima-holdings.com">support@optima-holdings.com</a>.
            </p>
            
            <h2 className="text-xl font-semibold mb-2">Acceptance of Terms</h2>
            <p className="mb-4">
                By using our website, you hereby consent to our terms and conditions and agree to their terms. If you do not agree with any part of these terms and conditions, please do not use our website.
            </p>
            
            <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
            <p className="mb-4">
                We reserve the right to update or modify these terms and conditions at any time. Any changes will be posted on this page with an updated revision date. We encourage you to review these terms periodically to stay informed about how we are protecting your information.
            </p>
            
            <p className="mt-4">
                <strong>Last updated:</strong> June 21, 2024
            </p>
        </div>
    );
};

export default TermsAndConditions;