import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Apply custom styles after the script is loaded
    script.onload = () => {
      const style = document.createElement('style');
      style.innerHTML = `
        .calendly-inline-widget {
          box-shadow: none !important;
          border: none !important;
        }
      `;
      document.head.appendChild(style);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/randall-malcom/30min"
      style={{ minWidth: '320px', height: '700px', boxShadow: 'none', border: 'none' }}
    ></div>
  );
};

export default CalendlyWidget;