'use client'

import React, { useEffect, useState } from 'react'
import EstimateCard, { EstimateCards } from './EstimateCard'



const Estimates = () => {
  const [showAddEstimate, setShowAddEstimate] = useState(false)
const [estimates, setEstimates] = useState<EstimateCards[]>([])
const [searchTerm, setSearchTerm] = useState('')
const [isInputFocused, setIsInputFocused] = useState(false)
const [isLoading, setIsLoading] = useState(false)


useEffect(() => {
  fetchestimates();
}, []);

const allEstimates:EstimateCards[] = [
  {
      "urlKey": "tcace4ag4bitbitfatcd5hfb4",
      "estimateID": 53,
      "items": "[{\"partNumber\":\"3600159\",\"retailPrice\":237.37,\"image\":\"https://boretec.com/images/3600159-2024-03-21T18:54:09-h-200.png\",\"name\":\"CPLNG HUB PUMP MCL36-42B OR C (SAE C-C 14T SPLINE) U\",\"quantity\":1,\"qboID\":\"1567\"},{\"partNumber\":\"2400120\",\"retailPrice\":228.76,\"image\":\"https://boretec.com/images/2400120-2024-03-21T18:21:34-h-200.png\",\"name\":\"HUB COUPLING\",\"quantity\":1,\"qboID\":\"1562\"},{\"partNumber\":\"2400121\",\"retailPrice\":70.35,\"image\":\"https://boretec.com/images/2400121-2024-03-21T18:53:24-h-200.png\",\"name\":\"COUP PUMP NYLON SLEEVE 24-42B\",\"quantity\":3,\"qboID\":\"1563\"}]",
      "displayName": "",
      "familyName": "Smith",
      "givenName": "Nancy",
      "companyName": "NV Energy",
      "primaryEmail": "smith@nvenergy.com",
      "primaryPhone": "5555555555",
      "billAddressCity": "Reno",
      "billAddressLine1": "7 Electricity Dr.",
      "billAddressLine2": "",
      "billAddressState": "NV",
      "billAddressPostalCode": "89520",
      "country": "United States",
      "fullyQualifiedName": "",
      "shipAddressState": "NV",
      "shipAddressCity": "Reno",
      "shipAddressLine1": "7 Electricity",
      "shipAddressLine2": "",
      "shipAddressPostalCode": "89520",
      "customerType": "",
      "mobilePhone": "5555555555",
      "tax": 0,
      "subtotal": 677.1799926757812,
      "discount": 0,
      "message": "",
      "emails": "[]",
      "dateCreated": "2024-06-18T18:50:24.963Z",
      "origin": "web",
      "expirationDate": "2024-06-25T00:00:00.000Z",
      "converted": false,
      "selectedCustomer": "{\"mobilePhone\":\"4782789178\",\"displayName\":\"\",\"familyName\":\"toler\",\"givenName\":\"waylon\",\"companyName\":\"l&l utilities\",\"primaryEmail\":\"waylonllutilities@yahoo.com\",\"qboID\":0,\"primaryPhone\":\"4782740876\",\"billAddressCity\":\"east dublin\",\"billAddressLine1\":\"746 robert webb rd\",\"billAddressLine2\":\"\",\"billAddressState\":\"GA\",\"billAddressPostalCode\":\"31027\",\"country\":\"United States\",\"fullyQualifiedName\":\"\",\"shipAddressState\":\"GA\",\"shipAddressCity\":\"east dublin\",\"shipAddressLine1\":\"746 robert webb rd\",\"shipAddressLine2\":\"\",\"shipAddressPostalCode\":\"31027\",\"dateCreated\":\"\",\"balance\":0,\"taxable\":\"\",\"customerType\":\"\",\"taxResaleNumber\":null,\"isVisible\":true}",
      "notes": "Tue Jun 18 2024 (Casey): Parts not in stock part number needs to be updated. Spoke Waylon ",
      "isVisible": true
  },
  {
      "urlKey": "jcace7ag7bhjbijacjcc8hdg7",
      "estimateID": 52,
      "items": "[{\"images\":\"https://boretec.com/images/80-2410x2-h-200.png\",\"partNumber\":\"80-2410x2.25\",\"name\":\"24\\\" auger 10' long on 2-1/4\\\" hex with 3/8\\\" flights\",\"retailPrice\":2450,\"weight\":490,\"qboID\":\"616\",\"isVisible\":true,\"quantity\":5}]",
      "displayName": "Disney",
      "familyName": "Ocean",
      "givenName": "Frank",
      "companyName": "Disney",
      "primaryEmail": "corp@disney.com",
      "primaryPhone": "(555) 555-5555",
      "billAddressCity": "Anahiem",
      "billAddressLine1": "20 Happy Dr.",
      "billAddressLine2": '',
      "billAddressState": "CA",
      "billAddressPostalCode": "92752",
      "country": "USA",
      "fullyQualifiedName": "Disney",
      "shipAddressState": "CA",
      "shipAddressCity": "Anahiem",
      "shipAddressLine1": "20 Happy Dr.",
      "shipAddressLine2": '',
      "shipAddressPostalCode": "92752",
      "customerType": '',
      "mobilePhone": "555-555-5555",
      "tax": 857.5,
      "subtotal": 12250,
      "discount": 0,
      "message": "",
      "emails": "[]",
      "dateCreated": "2024-06-17T18:02:22.737Z",
      "origin": "lois",
      "expirationDate": "2024-06-22T00:00:00.000Z",
      "converted": true,
      "selectedCustomer": "{\"displayName\":\"Disney\",\"familyName\":\"Whitmoreland\",\"givenName\":\"Devin\",\"companyName\":\"Disney\",\"primaryEmail\":\"corp@pmandc.us\",\"qboID\":\"329\",\"primaryPhone\":\"(864) 834-8221\",\"billAddressCity\":\"Travelers Rest\",\"billAddressLine1\":\"2017 N Hwy 25\",\"billAddressLine2\":null,\"billAddressState\":\"SC\",\"billAddressPostalCode\":\"29690\",\"country\":\"USA\",\"fullyQualifiedName\":\"Disney\",\"shipAddressState\":\"SC\",\"shipAddressCity\":\"Travelers Rest\",\"shipAddressLine1\":\"2017 N Hwy 25\",\"shipAddressLine2\":null,\"shipAddressPostalCode\":\"29690\",\"dateCreated\":\"2024-02-29T10:58:07-08:00\",\"balance\":0,\"notes\":null,\"taxable\":0,\"customerType\":null,\"equipment\":null,\"taxResaleNumber\":null,\"mobilePhone\":\"864-483-0638\",\"shipAddressCompnayName\":null,\"isVisible\":true}",
      "notes": '',
      "isVisible": true
  }
];

const fetchestimates = async () => {
  setIsLoading(true);

      setEstimates(allEstimates)
      setIsLoading(false)

};

const handleInputFocus = () => {
  setIsInputFocused(true)
}

const handleInputBlur = () => {
  setIsInputFocused(false)
}

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newSearchTerm = event.target.value.toLowerCase()
  setSearchTerm(newSearchTerm)

  if (!newSearchTerm.trim()) {
    // If the search term is empty, set all items to visible
    setEstimates(
      estimates.map((item) => ({ ...item, isVisible: true }))
    )
  } else {
    // Otherwise, filter based on the search term
    const searchWords = newSearchTerm.split(/\s+/)
    setEstimates(
      estimates.map((item) => {
        const itemText =
          `${item.displayName} ${item.primaryEmail} ${item.estimateID}`.toLowerCase()
        const isVisible = searchWords.every((word) => itemText.includes(word))
        return { ...item, isVisible }
      })
    )
  }
}

// Determine the count of visible items
const visibleItemCount = estimates.filter(
  (estimate) => estimate.isVisible
).length
  
const handleClearSearch = () => {

  setSearchTerm('');
  // Reset all items to be visible when the search is cleared
  setEstimates(estimates.map(item => ({ ...item, isVisible: true })));
  
};



 

  return (
    <section className={`md:h-60`}>
    <div className={`rounded-lg overflow-hidden`}>
    {showAddEstimate && 
    <div className="rounded-lg overflow-hidden">
      <div className="grid grid-cols-3 border-b border-white">
      <div className="bg-blue-700 w-full text-white text-center py-1 px-2"></div>
      <p className="bg-blue-700 w-full text-white text-center py-1 px-2">New Estimate</p>
      <div className="bg-blue-700 w-full text-white text-center flex justify-end">
        <button className="bg-blue-700 text-left py-1 px-2" onClick={()=>setShowAddEstimate(false)}>Close</button>
        </div>
      </div >
    

      
      </div>}
<div>
    <div className="flex my-3">
      <div className={`md:flex-grow w-full flex justify-between items-end ${isInputFocused ? 'border-gray-500' : 'border-gray-200 hover:border-gray-300'} border bg-white rounded-lg py-1 pl-3`}>
      <input
        type="text"
        id="searchInput"
        className="w-full outline-none focus:outline-none focus:ring-0 placeholder:text-gray-500 placeholder:font-light"
        placeholder="Search estimates"
        value={searchTerm} // Ensure this correctly references the state
        onChange={handleSearch}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        
      />
              {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="pr-2 border-r border-gray-500 text-xs text-bottom text-gray-500 font-light hover:text-gray-600"
            type="button"
          >
            clear {/* This is a Unicode 'X' character: &#x2715; */}
          </button>
        )}
      <div className="whitespace-nowrap text-bottom text-right text-xs text-gray-500 font-light ml-2 pr-1">
        {visibleItemCount} item(s)
      </div>
    </div>

      </div>
      
      {isLoading && (
          <div className="flex w-full justify-center items-center">
            <div>
        <p className="text-center animate-pulse">Loading...</p>


            </div>
            </div>
        )}
      {estimates.map((estimate, index) => (
        <div key={index} className={`${
          estimate.isVisible ? 'px-2' : 'hidden'
        }`}>
<EstimateCard
  urlKey={estimate.urlKey}
  estimateID={estimate.estimateID}
  items={estimate.items} // Assuming this is the stringified JSON of items
  displayName={estimate.displayName}
  familyName={estimate.familyName}
  givenName={estimate.givenName}
  companyName={estimate.companyName}
  primaryEmail={estimate.primaryEmail}
  primaryPhone={estimate.primaryPhone}
  billAddressCity={estimate.billAddressCity}
  billAddressLine1={estimate.billAddressLine1}
  billAddressLine2={estimate.billAddressLine2} // Assuming there's a mechanism to handle null values
  billAddressState={estimate.billAddressState}
  billAddressPostalCode={estimate.billAddressPostalCode}
  country={estimate.country}
  fullyQualifiedName={estimate.fullyQualifiedName}
  shipAddressState={estimate.shipAddressState}
  shipAddressCity={estimate.shipAddressCity}
  shipAddressLine1={estimate.shipAddressLine1}
  shipAddressLine2={estimate.shipAddressLine2} // Assuming there's a mechanism to handle null values
  shipAddressPostalCode={estimate.shipAddressPostalCode}
  customerType={estimate.customerType}
  mobilePhone={estimate.mobilePhone}
  tax={estimate.tax}
  subtotal={estimate.subtotal} // Notice the change from subTotal to subtotal if necessary
  discount={estimate.discount}
  message={estimate.message}
  emails={estimate.emails} // Assuming this is the stringified JSON of emails
  expirationDate={estimate.expirationDate}
  dateCreated={estimate.dateCreated}
  origin={estimate.origin}
  converted={estimate.converted}
  isVisible={estimate.isVisible}
  selectedCustomer={estimate.selectedCustomer}
  notes={estimate.notes}

/>

          </div>
        ))}
      
      </div>
      
      </div>
    </section>
  )
}

export default Estimates;
