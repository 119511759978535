'use client'

import React, { useEffect, useState, useRef } from 'react'


export interface VendorItem {
  vName: string
  vUrl: string
  vPartNumber: string
  vLeadTime: number
  vCost: number
  vPaymentMethod: string
  qboID: number
  isVisible: boolean
  [key: string]: string | number | boolean;
  
}

interface VendorProps {
  //changeCustomer: () => void;
  handleAddVendor: (vendor:VendorItem) => void; // Callback now accepts a single VendorItem
}

const VendorSearch: React.FC<VendorProps> = ({ handleAddVendor }) => {
  const [vendors, setVendors] = useState<VendorItem[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showAddVendor, setShowAddVendor] = useState(false)
  const [vendorSaving, setVendorSaving] = useState(false)
  const [newVendorName, setNewVendorName] = useState('');
  const [newVendorNumber, setNewVendorNumber] = useState('');


const vendorList = [
  {
      "vName": "7-Eleven",
      "qboID": 162
  },
  {
      "vName": "Ace Hardware",
      "qboID": 153
  },
  {
      "vName": "Advance Auto Parts",
      "qboID": 126
  },
  {
      "vName": "Alibaba.com",
      "qboID": 72
  },
  {
      "vName": "Allstate",
      "qboID": 211
  },
  {
      "vName": "Amazon",
      "qboID": 147
  },
  {
      "vName": "Amazon American Express",
      "qboID": 48
  },
  {
      "vName": "American Airlines",
      "qboID": 173
  },
  {
      "vName": "American Welding & Gas, Inc.",
      "qboID": 35
  },
  {
      "vName": "Apple",
      "qboID": 177
  },
  {
      "vName": "Asheville Hwy Rentals",
      "qboID": 133
  },
  {
      "vName": "AT&T",
      "qboID": 47
  },
  {
      "vName": "Atlantic Pipe & Steel",
      "qboID": 102
  },
  {
      "vName": "Automation Direct",
      "qboID": 239
  },
  {
      "vName": "B & A Trailers",
      "qboID": 7
  },
  {
      "vName": "Bailey Hydraulics",
      "qboID": 437
  },
  {
      "vName": "Bailey Sale Corporation",
      "qboID": 252
  },
  {
      "vName": "Barbco, Inc",
      "qboID": 216
  },
  {
      "vName": "BARNDOOR AG",
      "qboID": 414
  },
  {
      "vName": "BARNDOORAG.COM",
      "qboID": 403
  },
  {
      "vName": "Bay City Industrial Supply",
      "qboID": 160
  },
  {
      "vName": "Beijing Zipei Technology CO, LTD",
      "qboID": 199
  },
  {
      "vName": "Bobcat of Spartanburg",
      "qboID": 145
  },
  {
      "vName": "BP Fuel",
      "qboID": 22
  },
  {
      "vName": "Calhoun, Bhella & Sechrest",
      "qboID": 154
  },
  {
      "vName": "Carolina Bearing & Belting",
      "qboID": 286
  },
  {
      "vName": "Central Metal Service, Inc",
      "qboID": 156
  },
  {
      "vName": "Central Saw Works",
      "qboID": 349
  },
  {
      "vName": "Chastity Marie Norelli",
      "qboID": 111
  },
  {
      "vName": "Chick-Fil-A",
      "qboID": 188
  },
  {
      "vName": "Circle K",
      "qboID": 151
  },
  {
      "vName": "Citgo",
      "qboID": 148
  },
  {
      "vName": "ComFab, Inc",
      "qboID": 122
  },
  {
      "vName": "COMOSO",
      "qboID": 130
  },
  {
      "vName": "Cosco in Spartanburg",
      "qboID": 436
  },
  {
      "vName": "Costco",
      "qboID": 235
  },
  {
      "vName": "Coyote Logistics LLC",
      "qboID": 353
  },
  {
      "vName": "Danny Prince",
      "qboID": 6
  },
  {
      "vName": "De Bruin Law Firm",
      "qboID": 248
  },
  {
      "vName": "Denny's",
      "qboID": 214
  },
  {
      "vName": "DHL",
      "qboID": 358
  },
  {
      "vName": "Dollar General",
      "qboID": 289
  },
  {
      "vName": "Dollarama",
      "qboID": 365
  },
  {
      "vName": "DP Containers",
      "qboID": 99
  },
  {
      "vName": "Duke Energy",
      "qboID": 10
  },
  {
      "vName": "EARL M. JORGENSEN",
      "qboID": 1
  },
  {
      "vName": "ECO Waste Services",
      "qboID": 20
  },
  {
      "vName": "EMJ",
      "qboID": 369
  },
  {
      "vName": "Enterprise Rent-A-Car",
      "qboID": 209
  },
  {
      "vName": "ES Construction LP",
      "qboID": 69
  },
  {
      "vName": "Eskridge, Inc",
      "qboID": 190
  },
  {
      "vName": "Exxon",
      "qboID": 189
  },
  {
      "vName": "Fabco Steel Fabrication, Inc",
      "qboID": 41
  },
  {
      "vName": "Fast Signs",
      "qboID": 30
  },
  {
      "vName": "FedEx Freight",
      "qboID": 134
  },
  {
      "vName": "Finish Master Inc",
      "qboID": 73
  },
  {
      "vName": "First Citizens Bank",
      "qboID": 127
  },
  {
      "vName": "Flodraulic Group Inc.",
      "qboID": 52
  },
  {
      "vName": "Four Corners Fencing",
      "qboID": 24
  },
  {
      "vName": "Global Industrial",
      "qboID": 443
  },
  {
      "vName": "GoDaddy",
      "qboID": 202
  },
  {
      "vName": "Google",
      "qboID": 308
  },
  {
      "vName": "Hampton Inn",
      "qboID": 210
  },
  {
      "vName": "Harbor Freight Tools",
      "qboID": 230
  },
  {
      "vName": "Hawk Ridge Systems LLC",
      "qboID": 313
  },
  {
      "vName": "HDPE Supply",
      "qboID": 146
  },
  {
      "vName": "HelloWorld2",
      "qboID": 400
  },
  {
      "vName": "helloWorld3",
      "qboID": 401
  },
  {
      "vName": "Herrenknecht Tunnelling Systems USA, Inc",
      "qboID": 25
  },
  {
      "vName": "Hilton",
      "qboID": 213
  },
  {
      "vName": "Holiday Inn",
      "qboID": 172
  },
  {
      "vName": "Home Depot",
      "qboID": 124
  },
  {
      "vName": "Hydraulic Supply Company",
      "qboID": 125
  },
  {
      "vName": "Hydraulics Direct",
      "qboID": 44
  },
  {
      "vName": "Indeed",
      "qboID": 366
  },
  {
      "vName": "INDUSTRIAL SAFETY",
      "qboID": 71
  },
  {
      "vName": "Industrial Tube and Steel",
      "qboID": 299
  },
  {
      "vName": "Inman Stone Company",
      "qboID": 219
  },
  {
      "vName": "Internal Revenue Service",
      "qboID": 179
  },
  {
      "vName": "Intex",
      "qboID": 169
  },
  {
      "vName": "Intuit",
      "qboID": 97
  },
  {
      "vName": "IRS",
      "qboID": 17
  },
  {
      "vName": "J and J Forklift Services LLC",
      "qboID": 128
  },
  {
      "vName": "Jobsite Trailers and Attachments",
      "qboID": 33
  },
  {
      "vName": "John Gillespie",
      "qboID": 96
  },
  {
      "vName": "John Johnson",
      "qboID": 348
  },
  {
      "vName": "Josh McAbee",
      "qboID": 9
  },
  {
      "vName": "Joyce Malcolm",
      "qboID": 53
  },
  {
      "vName": "King Kong Tools",
      "qboID": 79
  },
  {
      "vName": "King of Freight LLC",
      "qboID": 141
  },
  {
      "vName": "Kurtis Missen",
      "qboID": 317
  },
  {
      "vName": "Logan Clutch Corporation",
      "qboID": 270
  },
  {
      "vName": "Lowe's Business Advantage",
      "qboID": 46
  },
  {
      "vName": "Luoyang Longda Bearing Co, LTD",
      "qboID": 89
  },
  {
      "vName": "Malcolm Family  Trust",
      "qboID": 16
  },
  {
      "vName": "MasCo Equipment, LLC",
      "qboID": 68
  },
  {
      "vName": "Maverik",
      "qboID": 212
  },
  {
      "vName": "McMaster-Carr",
      "qboID": 29
  },
  {
      "vName": "Michael Byrne Manufacturing",
      "qboID": 43
  },
  {
      "vName": "Mission Steel Fabrication",
      "qboID": 50
  },
  {
      "vName": "Morey CPA & Associates",
      "qboID": 217
  },
  {
      "vName": "North American Implements",
      "qboID": 55
  },
  {
      "vName": "Northern Tool",
      "qboID": 205
  },
  {
      "vName": "NOVAK CONVERSIONS",
      "qboID": 459
  },
  {
      "vName": "Office Depot",
      "qboID": 23
  },
  {
      "vName": "Ohio Power Tool",
      "qboID": 77
  },
  {
      "vName": "Onlinecomponents.com",
      "qboID": 74
  },
  {
      "vName": "Panda Express",
      "qboID": 175
  },
  {
      "vName": "Papa John's",
      "qboID": 207
  },
  {
      "vName": "Piedmont Concrete Products, Inc.",
      "qboID": 159
  },
  {
      "vName": "Piedmont Overhead Door",
      "qboID": 31
  },
  {
      "vName": "Pilot",
      "qboID": 150
  },
  {
      "vName": "Pizza Perfect Pizza",
      "qboID": 164
  },
  {
      "vName": "PNC Equipment Finance",
      "qboID": 120
  },
  {
      "vName": "Polycase",
      "qboID": 49
  },
  {
      "vName": "Pressure Washers Direct",
      "qboID": 76
  },
  {
      "vName": "Prestige Metal Fabrication and Welding",
      "qboID": 228
  },
  {
      "vName": "Prince Construction Co Inc",
      "qboID": 8
  },
  {
      "vName": "QuickBooks Payments",
      "qboID": 82
  },
  {
      "vName": "QuikTrip",
      "qboID": 149
  },
  {
      "vName": "RaceTrac",
      "qboID": 171
  },
  {
      "vName": "Radwell International LLC",
      "qboID": 236
  },
  {
      "vName": "Ram Air",
      "qboID": 90
  },
  {
      "vName": "Ram Meter",
      "qboID": 237
  },
  {
      "vName": "Ram3 Logistics",
      "qboID": 200
  },
  {
      "vName": "Roger Shiflett Ford",
      "qboID": 93
  },
  {
      "vName": "Rowe Inc",
      "qboID": 198
  },
  {
      "vName": "Ryerson",
      "qboID": 112
  },
  {
      "vName": "SAIA Freight",
      "qboID": 343
  },
  {
      "vName": "SC Department of Revenue",
      "qboID": 18
  },
  {
      "vName": "SC Dept of Revenue",
      "qboID": 4
  },
  {
      "vName": "SCESC",
      "qboID": 56
  },
  {
      "vName": "SHC, Inc.",
      "qboID": 359
  },
  {
      "vName": "Shell",
      "qboID": 152
  },
  {
      "vName": "Shenzhen Taida Century Technology Co, Limitied",
      "qboID": 100
  },
  {
      "vName": "Sherwin Williams",
      "qboID": 218
  },
  {
      "vName": "SimCal Machining",
      "qboID": 107
  },
  {
      "vName": "Siskin Steel & Supply",
      "qboID": 157
  },
  {
      "vName": "Skid Pro Attachments",
      "qboID": 34
  },
  {
      "vName": "Southwest",
      "qboID": 238
  },
  {
      "vName": "Spartanburg County Treasurer",
      "qboID": 39
  },
  {
      "vName": "Spartanburg Water",
      "qboID": 21
  },
  {
      "vName": "Spectrum",
      "qboID": 187
  },
  {
      "vName": "Speedway",
      "qboID": 290
  },
  {
      "vName": "State Disbursement Unit",
      "qboID": 320
  },
  {
      "vName": "State Farm - Marty Joiner",
      "qboID": 203
  },
  {
      "vName": "Steel Plate Akron/Atlanta",
      "qboID": 32
  },
  {
      "vName": "Subsurface Inc",
      "qboID": 201
  },
  {
      "vName": "Subway",
      "qboID": 341
  },
  {
      "vName": "Sunbelt Rentals",
      "qboID": 37
  },
  {
      "vName": "SURPLUS CENTER",
      "qboID": 441
  },
  {
      "vName": "Taizhou Chuanhu Hydraulic Machinery CO, Ltd",
      "qboID": 110
  },
  {
      "vName": "Tech Products",
      "qboID": 250
  },
  {
      "vName": "TITAN FITTINGS",
      "qboID": 428
  },
  {
      "vName": "TME",
      "qboID": 75
  },
  {
      "vName": "Total Quality Logistics (TQL)",
      "qboID": 259
  },
  {
      "vName": "ULINE",
      "qboID": 297
  },
  {
      "vName": "United Airlines",
      "qboID": 241
  },
  {
      "vName": "United States Liability Insurance Company",
      "qboID": 109
  },
  {
      "vName": "UPS",
      "qboID": 158
  },
  {
      "vName": "Upstate Equipment Repair",
      "qboID": 142
  },
  {
      "vName": "USPS",
      "qboID": 260
  },
  {
      "vName": "VistaPrint",
      "qboID": 322
  },
  {
      "vName": "Waffle House",
      "qboID": 176
  },
  {
      "vName": "Wal-Mart",
      "qboID": 174
  },
  {
      "vName": "Watkins Brothers, Inc.",
      "qboID": 223
  },
  {
      "vName": "Weldors Supply House, Inc",
      "qboID": 58
  },
  {
      "vName": "Wells Fargo",
      "qboID": 161
  },
  {
      "vName": "Wendy's",
      "qboID": 163
  },
  {
      "vName": "Wildcat Advanced Land Clearing",
      "qboID": 2
  },
  {
      "vName": "Zaxby's",
      "qboID": 321
  }
];

  useEffect(() => {
    fetchVendors()
  }, [])

  const fetchVendors = async () => {
    setIsLoading(true)
    if (Array.isArray(vendorList)) {
      const itemsWithVisibility = vendorList.map((item) => ({
        ...item,
        vUrl:'', 
        vPartNumber: '', vLeadTime: 0, vCost:0, vPaymentMethod: '',
        isVisible: true, // Set isVisible to true for all items initially
      }))

      setVendors(itemsWithVisibility)
      setIsLoading(false)
    }
      
  }

  const addNewVendor = async () => {
    setVendorSaving(true)

    

        setTimeout(() => {
          setNewVendorName("");
          setNewVendorNumber("");
          fetchVendors(); 
          setVendorSaving(false)
          setShowAddVendor(false) 
      }, 5000);
      
  }

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value.toLowerCase()
    setSearchTerm(newSearchTerm)

    if (!newSearchTerm.trim()) {
      // If the search term is empty, set all items to visible
      setVendors(vendors.map((item) => ({ ...item, isVisible: true })))
    } else {
      // Otherwise, filter based on the search term
      const searchWords = newSearchTerm.split(/\s+/)
      setVendors(
        vendors.map((item) => {
          const itemText = `${item.vName}`.toLowerCase()
          const isVisible = searchWords.every((word) => itemText.includes(word))
          return { ...item, isVisible }
        })
      )
    }
  }



  const visibleVendor = vendors.filter(
    (item) =>
      item.isVisible &&
      (item.vName.toLowerCase().includes(searchTerm.toLowerCase()))
  )





  return (
    <section className="text-gray-600">

      <div className="">

        {!showAddVendor && <div className="md:flex mx-1 py-2">
          <div
            className={`md:flex-grow w-full flex justify-between items-end ${
              isInputFocused
                ? 'border-gray-500'
                : 'border-gray-200 hover:border-gray-300'
            } border bg-white rounded-lg py-1 pl-3`}
          >
            <input
              type="text"
              id="searchInput"
              className="flex-grow outline-none focus:outline-none focus:ring-0 placeholder:text-gray-500 placeholder:font-light"
              placeholder="Search..."
              value={searchTerm} // Ensure this correctly references the state
              onChange={handleSearch}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />

          </div>
          <button className="whitespace-nowrap px-1" onClick={()=>setShowAddVendor(true)}>+ Vendor</button>
        </div>}

        {isLoading && (
          <div className="flex w-full justify-center items-center">
            <div>
              <p className="text-center animate-pulse">Loading...</p>
            </div>
          </div>
        )}

        {!isLoading && visibleVendor.length > 0 && !showAddVendor && (
          <div className="">
            
            <div className="h-48 overflow-y-auto">
            <table className="leading-normal w-full table-fixed">

  <tbody>
    {visibleVendor.map((vendor, index) => (
      <tr key={index} className="border-b h-12 border-gray-200 hover:bg-gray-50 text-center cursor-pointer"
          onClick={() => {
            const vendorWithDefaults: VendorItem = {
              ...vendor,
              vUrl: vendor.vUrl || '',
              vPartNumber: vendor.vPartNumber || '',
              vLeadTime: vendor.vLeadTime || 0,
              vCost: vendor.vCost || 0,
              vPaymentMethod: vendor.vPaymentMethod || ''
            };
            handleAddVendor(vendorWithDefaults);
            }}>
        <td className="whitespace-normal break-words px-1">
          {vendor.vName}
        </td>
      </tr>
    ))}
  </tbody>
</table>
            </div>
          
          </div>
        )}
      
      {showAddVendor && 
      <div className="text-left">
        <p className="w-full bg-gray-100 text-gray-900 text-center border-t border-b border-gray-300 ">New Vendor</p>
        <div className="m-1">
        <label htmlFor="vendorName">Name:</label>
        <input
          type="text"
          className="w-full bg-gray-100 rounded-md border border-gray-300 px-1"
          value={newVendorName}
          onChange={(e) => setNewVendorName(e.target.value)}
        />
      </div>
      <div className="mx-1 mb-4">
        <label htmlFor="vendorNumber">Phone Number:</label>
        <input
          type="tel"
          className="w-full bg-gray-100 rounded-md border border-gray-300 px-1"
          value={newVendorNumber}
          onChange={(e) => setNewVendorNumber(e.target.value)}
        />
      </div>
        <button className="bg-black text-white text-center px-2 w-full" onClick={addNewVendor}>{vendorSaving ? "Saving..." :"Add New Vendor"}</button>
        <button className="bg-gray-600 border-t border-white text-white text-center px-2 w-full" onClick={()=>setShowAddVendor(false)}>Back</button>

        </div>
      }
      </div>

    </section>
  )
}

export default VendorSearch
